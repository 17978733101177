/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { updateStandardWorksheetAccordion } from "../../../app/actions/worksheetActions";
import {
  putVariableDisclosures,
  putVariableVerifyAuthentication,
} from "../../../app/thunks/worksheetThunks";
import DisclosureSwitch from "../DisclosureSwitch";
import AuthVerifySwitch from "../AuthVerifySwitch";
import VerifyContactSwitch from "./buttons/VerifyContactSwitch";


const StandardAuthentication = ({ verifySwitchValue }: any) => {
  const dispatch = useAppDispatch();
  // global states

  const metadata: any = useAppSelector((state) => state.main.metadata);
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const discData: any = useAppSelector(
    (state) => state.callWorksheets.disclosures.data
  );
  const authData: any = useAppSelector(
    (state) => state.callWorksheets.authData
  );
  const callType: any = useAppSelector((state) => state.main.callType);
  const skipTrace: any = useAppSelector((state) => state.skipTrace);
  const borrowerInfo: any = useAppSelector(
    (state) => state.displayInfo.borrower
  );
  // local States
  const [verifyState, setVerifyState] = useState({});
  const [callRecording, setCallRecording] = useState({});


  useEffect(() => {
    let val;
    if (authData.verify === "null") {
      val = false;
      const data: any = {};
      data.id = "verify";
      data.value = false;
      const array = [];
      array.push(data);
      dispatch(
        putVariableVerifyAuthentication(metadata.data, authData, callIds, array)
      );
    } else {
      val = authData.verify;
    }
    setVerifyState({
      id: "verify",
      value: val,
      text: "Authenticate Name and Property Address",
    });
  }, [authData]);

  useEffect(() => {
    let val;
    if (discData.cr === "null") {
      val = false;
      const data: any = {};
      data.id = "cr";
      data.value = false;
      const array = [];
      array.push(data);
      dispatch(putVariableDisclosures(metadata.data, discData, callIds, array));
    } else {
      val = discData.cr;
    }
    setCallRecording({ id: "cr", value: val, text: "Call Recording" });
  }, [discData]);

  // TODO PUT IN OB CALL SCRIPT

  const buildAuthNotification = () => {
    if (callType !== "OB") {
      if (authData.method === "ivr") {
        return <h5>Caller Authenticated in the IVR</h5>;
      } else {
        return <h5>Caller Authenticated by Agent</h5>;
      }
    } else {
      return (
        <>
          <h5>Confirm Caller</h5>
          <p>
            "Hello, this is <span className="text-warning">[your name]</span>{" "}
            with RoundPoint Mortgage on a recorded line. Am I speaking with{" "}
            <span className="text-info">{borrowerInfo.concat}</span>?"
          </p>
          <h5>Confirm Address</h5>
          <p>"To begin, can you please provide your full property address?"</p>
        </>
      );
    }
  };

  return (
    <>
      <DisclosureSwitch item={callRecording} />
      {buildAuthNotification()}
      <AuthVerifySwitch
        item={verifyState}
        verifySwitchValue={verifySwitchValue}
      />
      <VerifyContactSwitch />
    </>
  );
};

export default StandardAuthentication;
