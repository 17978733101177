import React from "react";
import { useAppSelector } from "../../app/hooks";
import { Card, Tab } from "react-bootstrap";
import WorksheetNavWrapper from "./worksheetNav/WorksheetNavWrapper";
import WorksheetTabContent from "./worksheetNav/WorksheetTabContent";

const WorksheetWrapper = () => {
  //global states
  const worksheetTabs: any = useAppSelector((state) => state.display.worksheet);

  return (
    <Card id="worksheet_wrapper">
      <Card.Header>
        <Card.Title>
          <strong>Call Worksheets</strong> 
        </Card.Title>
      </Card.Header>
      <Card.Body className="p-0">
        <Tab.Container
          id="call_worksheets_wrapper"
          activeKey={worksheetTabs.current}
        >
          <WorksheetNavWrapper />
          
            <WorksheetTabContent />
          
        </Tab.Container>
      </Card.Body>
    </Card>
  );
};

export default WorksheetWrapper;
