/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { putSkipTraceVariables } from "../../../app/thunks/worksheetThunks";
import { closeSkipWindow } from "../../../app/slices/features/skipTraceSlice";
import { Modal } from "react-bootstrap";
import SkipTraceForm from "./SkipTraceForm";
import './SkipTraceModal.css'

const SkipTraceModal = () => {
  const dispatch = useAppDispatch();

  const skip: any = useAppSelector((state) => state.skipTrace);


  return (
    <Modal
      backdrop={true}
      id="skip_modal"
      show={skip.window}
      onHide={() => dispatch(closeSkipWindow())}
    >
      <Modal.Header closeVariant="white" closeButton>
        <Modal.Title>Skip Trace Bad Number</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SkipTraceForm />
      </Modal.Body>

    </Modal>
  );
};

export default SkipTraceModal;
