/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React from "react";
import { useAppSelector } from "../../../app/hooks";

import { Accordion } from "react-bootstrap";
const ContactVerificationScript = () => {
  // global states
  const verification: any = useAppSelector(
    (state) => state.contactVerification
  );
  return (
    <Accordion defaultActiveKey="cv_phone_pitch">
      <Accordion.Item eventKey="cv_phone_pitch">
        <Accordion.Header>Phone Number Script</Accordion.Header>
        <Accordion.Body>
          {verification.ivaUsed === "Y" ? (
            <p>
              Based on your selections in our automated system, I understand you
              have contact information in need of an update. Please provide me
              with the best contact number to reach you?
            </p>
          ) : (
            <p>
              To ensure we have the best contact information on file can you
              please provide me with the best telephone number to reach you?
            </p>
          )}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="cv_phone_rebuttal">
        <Accordion.Header>Phone Number Rebuttal</Accordion.Header>
        <Accordion.Body>
          <p>
            Ok. Please note: To quickly provide you with important information
            regarding your loan we need up to date contact information. With
            this is mind, can you provide the most up to date telephone number?
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="cv_phone_refusal">
        <Accordion.Header>Phone Number Refusal</Accordion.Header>
        <Accordion.Body>
          <p>
            I understand. I will ensure your account is updated. How can I
            assist you today?
          </p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="cv_email_pitch">
        <Accordion.Header>Email Script</Accordion.Header>
        <Accordion.Body>
          {verification.ivaUsed === "Y" ? (
            <p>
              Based on your selections in our automated system, I understand you
              have contact information in need of an update. Please provide an
              e-mail where we can send all important information to?
            </p>
          ) : (
            <p>
              To ensure we have the best means of electronic communication on
              file can you please provide me with the best email address we can
              send important information to?
            </p>
          )}

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="cv_email_rebuttal">
        <Accordion.Header>Email Rebuttal</Accordion.Header>
        <Accordion.Body>
          <p>
            Ok. Please note: To quickly provide you with important information
            regarding your loan providing an up-to-date email would be helpful.
            With this is mind, can you provide the most up to date e-mail
            address?
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="cv_email_refusal">
        <Accordion.Header>Email Refusal</Accordion.Header>
        <Accordion.Body>
          <p>
            I understand. I will ensure your account is updated. How can I
            assist you today?
          </p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ContactVerificationScript;
