import { createSlice, createAction, nanoid } from "@reduxjs/toolkit";
import { consoleLogIt, consoleErrorIt, reportError } from "../../log";
import {
  GET_CALLDATA_SUCCESS,
  GET_CALLDATA_SUCCESS_NO_LOAN,
} from "../../actions/apisActions";
import { PrivateLabelObject } from "../../types";


const initialState = {
  label: "",
  brand: "",
  plsId: "",
 
} as PrivateLabelObject;

export const privateLabel = createSlice({
  name: "privateLabel",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GET_CALLDATA_SUCCESS_NO_LOAN, (state, action) => {
        const { payload }: any = action;
        state.brand = payload.data.variables["864"]
        switch (state.brand) {
          case "001":
            state.label = "RoundPoint";
            break;
          case "002":
            state.label = "Embrace Home Loans";
            break;
          case "003":
            state.label = "ORNL FCU";
            break;
          case "004":
            state.label = "7 Mortgage";
            break;
          case "005":
            state.label = "7 Mortgage";
            break;
          case "006":
            state.label = "7 Mortgage";
            break;
          case "007":
            state.label = "Parkside Lending";
            break;
          case "009":
            state.label = "Equity Prime Mortgage";
            break;
          default:
            state.label = "Unknown";
            break;
        }
      })
      .addCase(GET_CALLDATA_SUCCESS, (state, action) => {
        const { payload }: any = action;
        // build display
        let tag = "";
        state.brand = payload.data.variables["864"]
        state.plsId = payload.data.activeContact.fields["1163"] 
        if (state.brand === undefined) {
          if (state.plsId !== undefined) {
            if (state.plsId.length === 1) {
              tag = `00${state.plsId}`;
            } else {
              tag = state.plsId;
            }
          } else {
            tag = state.brand;
          }
        } else {
          tag = state.brand;
        }
        switch (tag) {
          case "002":
            state.label = "Embrace Home Loans";
            break;
          case "003":
            state.label = "ORNL FCU";
            break;
          case "004":
            state.label = "7 Mortgage";
            break;
          case "005":
            state.label = "7 Mortgage";
            break;
          case "006":
            state.label = "7 Mortgage";
            break;
          case "007":
            state.label = "Parkside Lending";
            break;
          case "009":
            state.label = "Equity Prime Mortgage";
            break;
          default:
            state.label = "RoundPoint";
            break;
       }
      })
      .addCase('azure/contactFields/succeeded', (state, action) => {
        const { payload }: any = action;
        // build display
        let tag = "";

        state.plsId = payload.data.items[0].plsId
        if (state.brand === "") {
          if (state.plsId !== null) {
            if (state.plsId.length === 1) {
              tag = `00${state.plsId}`;
            } else {
              tag = state.plsId;
            }
          } else {
            tag = state.brand;
          }
        } else {
          tag = state.brand;
        }
        switch (tag) {
          case "002":
            state.label = "Embrace Home Loans";
            break;
          case "003":
            state.label = "ORNL FCU";
            break;
          case "004":
            state.label = "7 Mortgage";
            break;
          case "005":
            state.label = "7 Mortgage";
            break;
          case "006":
            state.label = "7 Mortgage";
            break;
          case "007":
            state.label = "Parkside Lending";
            break;
          case "009":
            state.label = "Equity Prime Mortgage";
            break;
          default:
            state.label = "RoundPoint";
            break;
        }
      });
  },
});
// thunks

// Action creators are generated for each case reducer function
// export const {
//   openTestingModal,
//   closeTestingModal,

// } = azureContactFields.actions;

export default privateLabel.reducer;
