import React, {useEffect, useState} from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { putVariableDisclosures } from "../../../app/thunks/worksheetThunks";

const UpdateVariablesOnStartup = () => {
  const dispatch = useAppDispatch();
  const disclosures = useAppSelector((state) => state.callWorksheets.disclosures);
  const metadata = useAppSelector((state) => state.main.metadata)
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const appPath = useAppSelector((state) => state.main.appPath);
  useEffect(() => {
    if(appPath === "Standard" && disclosures.callData.initialUpdate === false) {
      dispatch(
        putVariableDisclosures(metadata.data, disclosures.data, callIds, disclosures.array)
      );
    }
  }, [appPath])
  return (<></>)
}

export default UpdateVariablesOnStartup;