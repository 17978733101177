/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from "react";
import { transferWorksheetQuestions } from "./transferWorksheetQuestions";
import ButtonQAndA from "../ButtonQAndA";

const BuildTransferQuestions = ({
  transferData,
  setFinalButtonDisabled,
  setFinalOutcome,
}: any) => {
  const questions: any = transferWorksheetQuestions();
  const worksheet = questions[transferData.worksheet];
  const [questionArray, setQuestionArray] = useState([worksheet.WQ01]);

  const [incentivePath, setIncentivePath] = useState([]);

  const returnCurrentObject = (target: any) => {
    for (let i = 0; i < worksheet[target.name].answers.length; i++) {
      if (worksheet[target.name].answers[i].text === target.value) {
        return worksheet[target.name].answers[i];
      }
    }
  };
  const updateIncentivePath = (answer: any, n: number) => {
    if (answer.outcome !== undefined) {
      setFinalOutcome(answer.outcome);
    }

    if (n === 1) {
      const array: any = [];

      array.push(answer);
      setIncentivePath(array.slice());
      // console.log("IncentivePath", questionArray);
    } else {
      const array: any = [
        ...incentivePath.filter((ques: any) => ques.place < n),
      ];
      array.push(answer);
      setIncentivePath(array.slice());
      // console.log("IncentivePath", questionArray);
    }
  };

  const updateQuestionArray = (target: any) => {
    // get current answer and section
    const answer = returnCurrentObject(target);
    const n = worksheet[target.name].place;

    updateIncentivePath(answer, n);

    // TODO EDIT THESE FOR DIFFERENT OPTIONS
    if (answer.next === "WQ99") {
      const array = questionArray.filter((ques) => ques.place <= n);
      setQuestionArray(array.slice());
      setFinalButtonDisabled(false);
    } else {
      const nextQuestion = worksheet[answer.next];
      const array = questionArray.filter((ques) => ques.place <= n);
      array.push(nextQuestion);
      setQuestionArray(array.slice());
      if (answer.next === "WQ60") {
        setFinalButtonDisabled(false);
      } else {
        setFinalButtonDisabled(true);
      }
    }
  };

  return (
    <div className="incentive-worksheets">
      {questionArray.map((question, i) => {
        return (
          <div key={i}>
            <ButtonQAndA
              qna={question}
              updateQuestionsArray={updateQuestionArray}
              incentivePath={incentivePath}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BuildTransferQuestions;
