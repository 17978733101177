/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  getMetadataInProgress,
  getMetadataSuccess,
  getMetadataFailed,
  getCalldataInProgress,
  getCalldataSuccess,
  getCalldataSuccessNoLoan,
  getCalldataFailed,
  getWorksheetDataInProgress,
  getWorksheetDataSuccess,
  getWorksheetDataFailed,
  updateCalldataSuccess,
  updateCalldataInProgress,
  updateCalldataFailed,
} from "../actions/apisActions";
import { consoleLogIt } from "../log";

export const getMetadataApi = () => async (dispatch: any) => {
  try {
    dispatch(getMetadataInProgress());
    // eslint-disable-next-line no-undef
    const response = await fetch(
      "https://app.five9.com/supsvcs/rs/svc/auth/metadata",
      { credentials: "include" }
    );
    const data = await response.json();

    const code = await response.ok;
    if (code === true) {
      consoleLogIt("Get MetaData Successful");
      dispatch(getMetadataSuccess(data));
    } else {
      consoleLogIt("Get MetaData Failed");
      dispatch(getMetadataFailed());
    }
  } catch (e) {
    // eslint-disable-next-line no-undef

    consoleLogIt("Get MetaData Failed - Catch E", e);
    dispatch(getMetadataFailed());
  }
};

export const getCallData = (metadata: any) => async (dispatch: any) => {
  try {
    dispatch(getCalldataInProgress());
    // eslint-disable-next-line no-undef
    const response = await fetch(
      `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}/appsvcs/rs/svc/agents/${metadata.userId}/interactions/calls`,
      { credentials: "include" }
    );
    const data = await response.json();
    const code = await response.ok;

    if (code === true) {
      if (data.length === 0) {
        consoleLogIt("Get Call Data Failed");
        dispatch(getCalldataFailed());
      } else {
        try {
          if (data[0].activeContact.fields["1033"] !== undefined) {
            consoleLogIt("Get CallData Successful");
            dispatch(getCalldataSuccess(data[0]));
          } else {
            consoleLogIt("Get CallData No Loan");
            dispatch(getCalldataSuccessNoLoan(data[0]));
          }
        } catch (e) {
          consoleLogIt("Get CallData No Loan - Catch E - ", e);
          dispatch(getCalldataSuccessNoLoan(data[0]));
        }
      }
    } else {
      dispatch(getCalldataFailed());
      consoleLogIt("Get Call Data Failed");
    }
  } catch (e) {
    // eslint-disable-next-line no-undef
    console.error(e);
    consoleLogIt("Get Call Data Failed - Catch E", e);
    dispatch(getCalldataFailed());
  }
};

export const updateCallData = (metadata: any) => async (dispatch: any) => {
  try {
    dispatch(updateCalldataInProgress());
    // eslint-disable-next-line no-undef
    const response = await fetch(
      `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}/appsvcs/rs/svc/agents/${metadata.userId}/interactions/calls`,
      { credentials: "include" }
    );
    const data = await response.json();
    const code = await response.ok;

    if (code === true) {
      if (data.length === 0) {
        consoleLogIt("Get Call Data Failed");
        dispatch(updateCalldataFailed());
      } else {
        consoleLogIt("Get CallData Successful");
        dispatch(updateCalldataSuccess(data[0]));
      }
    } else {
      dispatch(updateCalldataFailed());
      consoleLogIt("Get Call Data Failed");
    }
  } catch (e) {
    // eslint-disable-next-line no-undef
    console.error(e);
    consoleLogIt("Get Call Data Failed - Catch E", e);
    dispatch(updateCalldataFailed());
  }
};

export const getWorksheetDataApi =
  (metadata: any, callIds: any) => async (dispatch: any) => {
    try {
      dispatch(getWorksheetDataInProgress());
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}/appsvcs/rs/svc/orgs/${metadata.orgId}/campaigns/${callIds.campaignId}/worksheet_questions`,
        { credentials: "include" }
      );
      const data = await response.json();
      const code = await response.ok;

      if (code === true) {
        if (data.length === 0) {
          consoleLogIt("Get Worksheet Data Failed");
          dispatch(getWorksheetDataFailed());
        } else {
          // try {
          //   dispatch(getWorksheetDataSuccess(data[0]));
          // } catch (e) {
          //   dispatch(getCalldataSuccessNoLoan(data[0]));
          // }
          consoleLogIt("Get Worksheet Data Successful");
          dispatch(getWorksheetDataSuccess(data[0]));
        }
      } else {
        consoleLogIt("Get Worksheet Data Failed");
        dispatch(getWorksheetDataFailed());
      }
    } catch (e) {
      consoleLogIt("Get Worksheet Data Failed");
      dispatch(getWorksheetDataFailed());
    }
  };
