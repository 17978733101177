/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  updateColumn2Tabs,
  openEmailVivrWindow,
} from "../../../app/actions/standardActions";
import {
  selectedIncentiveMortgageScript,
  selectedIncentiveOtherScript,
} from "../../../app/actions/worksheetActions";
import { openSafeHavenForm } from "../../offCanvas/safeHavenForm/safeHavenFormSlice";
import { Card, Accordion } from "react-bootstrap";
import ButtonQAndA from "../ButtonQAndA";
import IncentiveCompleteButton from "./IncentiveCompleteButton";
import "./IncentiveWorksheets.css";

const IncentiveWorksheet = ({ questionsList }: any) => {
  const dispatch = useAppDispatch();
  const loanInfo = useAppSelector((state) => state.displayInfo);
  const mainInfo = useAppSelector((state) => state.main);
  const [questionArray, setQuestionArray] = useState([questionsList.WQ01]);

  const [incentivePath, setIncentivePath] = useState([]);
  const [sectionSelection, setSectionSelection] = useState(
    `${questionsList.type}_pitch`
  );
  const [finalOutcome, setFinalOutcome] = useState();
  const [finalReason, setFinalReason] = useState("null");
  const [finalButtonDisabled, setFinalButtonDisabled] = useState(true);
  const [finalButtonText, setFinalButtonText] = useState(
    questionsList.completeBtn
  );
  const [email, setEmail] = useState();
  const [transferId, setTransferId] = useState(null);

  const returnCurrentObject = (target: any) => {
    for (let i = 0; i < questionsList[target.name].answers.length; i++) {
      if (questionsList[target.name].answers[i].text === target.value) {
        return questionsList[target.name].answers[i];
      }
    }
  };

  const updateIncentivePath = (answer: any, n: number) => {
    if (answer.reason === undefined) {
      if (answer.outcome !== undefined) {
        setFinalOutcome(answer.outcome);
      }
    } else {
      setFinalReason(answer.reason);
    }

    if (n === 1) {
      const array: any = [];

      array.push(answer);
      setIncentivePath(array.slice());
      //console.log("IncentivePath", questionArray);
    } else {
      const array: any = [
        ...incentivePath.filter((ques: any) => ques.place < n),
      ];
      array.push(answer);
      setIncentivePath(array.slice());
      //console.log("IncentivePath", questionArray);
    }
  };

  const updateScriptTab = (answer: any) => {
    if (answer.script !== "null") {
      if (questionsList.type === "oth") {
        dispatch(selectedIncentiveOtherScript(answer.script));
        dispatch(updateColumn2Tabs(questionsList.tab));
      } else {
        dispatch(selectedIncentiveMortgageScript(answer.script));
        dispatch(updateColumn2Tabs(questionsList.tab));
      }
    }
  };

  const updateQuestionArray = (target: any) => {
    // get current answer and section
    const answer = returnCurrentObject(target);
    const n = questionsList[target.name].place;
    updateScriptTab(answer);
    updateIncentivePath(answer, n);

    if (answer.transfer) {
      setTransferId(answer.transferId);
      setFinalButtonText(questionsList.transferBtn);
    } else {
      setTransferId(null);
      setFinalButtonText(questionsList.completeBtn);
    }

    if (
      answer.next === "WQ99" ||
      answer.next === "WQ94" ||
      answer.next === "WQ95"
    ) {
      if (answer.next === "WQ94") {
        dispatch(openSafeHavenForm({ loanInfo, mainInfo, form: "callback" }));
      } else if (answer.next === "WQ95") {
        dispatch(openSafeHavenForm({ loanInfo, mainInfo, form: "transfer" }));
      } 
      
      const array = questionArray.filter((ques) => ques.place <= n);
      setQuestionArray(array.slice());
      setFinalButtonDisabled(false);
      if (answer.email === true) {
        dispatch(
          openEmailVivrWindow({ type: answer.emailType, address: email })
        );
      }
    } else {
      const nextQuestion = questionsList[answer.next];
      const array = questionArray.filter((ques) => ques.place <= n);
      array.push(nextQuestion);
      setQuestionArray(array.slice());
      if (answer.next === "WQ60") {
        if (nextQuestion.section === "Outcome") {
          const section = `${questionsList.type}_outcome`;
          setSectionSelection(section.slice());
        }
        setFinalButtonDisabled(false);
      } else {
        setFinalButtonDisabled(true);
        if (nextQuestion.section === "Outcome") {
          const section = `${questionsList.type}_outcome`;
          setSectionSelection(section.slice());
        }
      }
    }
  };

  return (
    <Card className="incentive-worksheets">
      <Card.Body>
        <Accordion activeKey={sectionSelection} flush>
          <Accordion.Item eventKey={`${questionsList.type}_pitch`}>
            <Accordion.Header
              onClick={() => {
                const section = `${questionsList.type}_pitch`;
                setSectionSelection(section.slice());
              }}
            >
              Pitch
            </Accordion.Header>
            <Accordion.Body>
              {questionArray
                .filter((question) => question.section === "Pitch")
                .map((question, i) => {
                  return (
                    <div key={`inc_pitch_${i}`}>
                      <ButtonQAndA
                        qna={question}
                        updateQuestionsArray={updateQuestionArray}
                        incentivePath={incentivePath}
                        email={email}
                        setEmail={setEmail}
                      />
                    </div>
                  );
                })}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={`${questionsList.type}_outcome`}>
            <Accordion.Header
              onClick={() => {
                const section = `${questionsList.type}_outcome`;
                setSectionSelection(section.slice());
              }}
            >
              Outcome
            </Accordion.Header>
            <Accordion.Body>
              {questionArray
                .filter((question) => question.section === "Outcome")
                .map((question, i) => {
                  return (
                    <div key={`inc_outcome_${i}`}>
                      <ButtonQAndA
                        qna={question}
                        updateQuestionsArray={updateQuestionArray}
                        incentivePath={incentivePath}
                        email={email}
                        setEmail={setEmail}
                      />
                    </div>
                  );
                })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card.Body>
      <Card.Footer>
        <IncentiveCompleteButton
          finalButtonText={finalButtonText}
          finalButtonDisabled={finalButtonDisabled}
          questionsList={questionsList}
          finalOutcome={finalOutcome}
          finalReason={finalReason}
          setFinalButtonDisabled={setFinalButtonDisabled}
          transferId={transferId}
        />
      </Card.Footer>
    </Card>
  );
};

export default IncentiveWorksheet;
