/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */


import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { updateCommentsApi } from "../../app/thunks/otherThunks";
import {
  Card,
  InputGroup,
  FormControl,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

const CommentsBox = () => {

  const dispatch = useAppDispatch();
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const callIds: any = useAppSelector((state) => state.main.callIds);

  const [comment, setComment] = useState("");
  const [copyState, setCopyState] = useState("Copy!");
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {copyState}
    </Tooltip>
  );
  return (
    <Card className="mb-2">
      <InputGroup>
        <FormControl
          as="textarea"
          placeholder="Interaction Notes..."
          aria-label="Comments"
          aria-describedby="comments-copy"
          onBlur={(e) => {
            //console.log("on Blur event", e.currentTarget.value);
            setComment(e.currentTarget.value);
            dispatch(updateCommentsApi(metadata.data, callIds, e.currentTarget.value));
          }}
        />
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 250 }}
          overlay={renderTooltip}
        >
          <Button
            variant="secondary"
            id="comments-copy"
            onClick={() => {
              // eslint-disable-next-line no-undef
              navigator.clipboard.writeText(comment);
              setCopyState("Copied!");
            }}
          >
            <svg 
              stroke="currentColor"
              fill="currentColor"
              // eslint-disable-next-line react/no-unknown-property
              strokeWidth="0"
              viewBox="0 0 1024 1024"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"></path>
            </svg>
          </Button>
        </OverlayTrigger>
      </InputGroup>
    </Card>
  );
};


export default CommentsBox;
