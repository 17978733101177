import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  VerbalComplaintInterface,
  submitVerbalComplaint,
} from "./verbalComplaintSlice";
import { openTestingModal } from "../../features/testing/testingSlice";

const VerbalComplaintFooter = () => {
  const dispatch = useAppDispatch();
  const verbalComplaint: VerbalComplaintInterface = useAppSelector(
    (state) => state.verbalComplaint
  );
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const [disabled, setDisabled] = useState(true);

  const handleSubmit = () => {
    dispatch(openTestingModal());
    dispatch(submitVerbalComplaint({ callIds: callIds, verbalComplaint }));
  };
  useEffect(() => {
    if (
      verbalComplaint.rootCause !== "" &&
      verbalComplaint.resolutionNotes !== ""
    ) {
      if (verbalComplaint.complete === true || verbalComplaint.apiStatus === "pending") {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }
  }, [verbalComplaint]);

  return (
    <div id="verbal_complaint_footer" className="d-grid gap-2 p-2">
      <Button
        variant="secondary"
        size="lg"
        disabled={disabled}
        onClick={handleSubmit}
      >
        Submit Verbal Complaint
      </Button>
    </div>
  );
};

export default VerbalComplaintFooter;
