import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { submitDCCData } from "../DCCConsultationQRPCSlice";
import { successNotification } from "../../../../app/slices/features/notificationsSlice";
import { consoleLogIt } from "../../../../app/log";

const DCCCompleteButton = ({ stage, dccConsultationQRPC }: any) => {
  const dispatch = useAppDispatch();

  const [disable, setDisable] = useState(true);
  
  useEffect(()=>{
    consoleLogIt("use Effect DCC Complete", dccConsultationQRPC.data.sentData)
    if(dccConsultationQRPC.data.dataSent === true){
      setDisable(true);
    } else if(stage > 10) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [stage, dccConsultationQRPC])

  const handleSubmit = () => {
    dispatch(submitDCCData());
    dispatch(
      successNotification({
        title: "DCC Form Submitted",
        text: `The data has been successfully submitted.`,
      })
    );
  };

  return (
    <div id="dcc_footer" className="d-grid gap-2 p-2">
      <Button
        variant="secondary"
        size="lg"
        onClick={handleSubmit}
        disabled={disable}
      >
        Complete DCC Consultation Form
      </Button>
    </div>
  );
};

export default DCCCompleteButton;
