/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createAction, nanoid } from "@reduxjs/toolkit";
// **********************************************
// UPDATE SCREEN WIDTH
// **********************************************
export const UPDATE_SCREEN_WIDTH = "UPDATE_SCREEN_WIDTH";
export const updateScreenWidth = (width: number) => ({
  type: UPDATE_SCREEN_WIDTH,
  payload: width,
});


// Standard Actions
export const UPDATE_COLUMN_2_TABS = "UPDATE_COLUMN_2_TABS";
export const updateColumn2Tabs = (tab: string) => ({
  type: UPDATE_COLUMN_2_TABS,
  payload: tab,
});

export const UPDATE_WORKSHEET_TABS = "UPDATE_WORKSHEET_TABS";
export const updateWorksheetTabs = (tab: string) => ({
  type: UPDATE_WORKSHEET_TABS,
  payload: tab,
});

export const RETURN_PREVIOUS_WORKSHEET_TAB = "RETURN_PREVIOUS_WORKSHEET_TAB";
export const returnPreviousWorksheetTab = () => ({
  type: RETURN_PREVIOUS_WORKSHEET_TAB
});


// **********************************************
// UPDATE TRANSFER OBJECT
// **********************************************
export const CLEAR_TRANSFER_OBJECT =
  "CLEAR_TRANSFER_OBJECT";
export const clearTransferObject = () => ({
  type: CLEAR_TRANSFER_OBJECT,
});

export const CLEAR_CONFERENCE_OBJECT=
  "CLEAR_CONFERENCE_OBJECT";
export const clearConferenceObject = () => ({
  type: CLEAR_CONFERENCE_OBJECT,
}); 

//Tools
export const OPEN_TRANSFER_ASSIST_WINDOW = "OPEN_TRANSFER_ASSIST_WINDOW";
export const openTransferAsssitWindow = () => ({
  type: OPEN_TRANSFER_ASSIST_WINDOW,
});

export const CLOSE_TRANSFER_ASSIST_WINDOW = "CLOSE_TRANSFER_ASSIST_WINDOW";
export const closeTransferAsssitWindow = () => ({
  type: CLOSE_TRANSFER_ASSIST_WINDOW,
});



export const INITIATE_SKIP_TRACE_FORM = "INITIATE_SKIP_TRACE_FORM";
export const initiateSkipTraceForm = () => ({
  type: INITIATE_SKIP_TRACE_FORM,
});

export const OPEN_DISCLOSURE_WINDOW = "OPEN_DISCLOSURE_WINDOW";
export const openDisclosureWindow = (payload: string) => ({
  type: OPEN_DISCLOSURE_WINDOW,
  payload: payload,
});

export const CLOSE_DISCLOSURE_WINDOW = "CLOSE_DISCLOSURE_WINDOW";
export const closeDisclosureWindow = () => ({
  type: CLOSE_DISCLOSURE_WINDOW,
});



export const OPEN_EMAIL_VIVR_WINDOW = "OPEN_EMAIL_VIVR_WINDOW";
export const openEmailVivrWindow = (payload: any) => ({
  type: OPEN_EMAIL_VIVR_WINDOW,
  payload: payload,
});

export const CLOSE_EMAIL_VIVR_WINDOW = "CLOSE_EMAIL_VIVR_WINDOW";
export const closeEmailVivrWindow = () => ({
  type: CLOSE_EMAIL_VIVR_WINDOW,
});


export const OPEN_WEBSOCKET_MODAL = "OPEN_WEBSOCKET_MODAL";
export const openWebsocketModal = () => ({
  type: OPEN_WEBSOCKET_MODAL,
});

export const CLOSE_WEBSOCKET_MODAL = "CLOSE_WEBSOCKET_MODAL";
export const closeWebsocketModal = () => ({
  type: CLOSE_WEBSOCKET_MODAL,
});


export const variablesUpdatedV2 = createAction(
  "app/variablesUpdatedV2",
  function prepare(data) {
    return {
      payload: { data, id: nanoid() },
    };
  }
);