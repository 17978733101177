/* eslint-disable react/prop-types */
import React from "react";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const DisclosureScript = ({ script }: any) => {
  return (
    <>
      {script.map((item: any, i: number) => {
        if (item.header === null) {
          return (
            <p key={i} className="pl-2">
              {item.body}
            </p>
          );
        } else {
          return (
            <>
              <h6 key={i} className="mb-0">
                <b>{item.header}</b>
              </h6>
              <p className="pl-2">{item.body}</p>
            </>
          );
        }
      })}
    </>
  );
};

export default DisclosureScript;
