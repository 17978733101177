import { createSlice } from "@reduxjs/toolkit";
import { GET_CALLDATA_SUCCESS } from "../../actions/apisActions";
import moment from "moment";
import { MessagingAndStatusObject } from "../../types";

const initialState = {
  obCampaignName: "",
  delinquencyBucket: "",
  loanStatus: "",
  miniMiranda: "",
  isBankruptcy: "",
  newCustomerVIVR: "",
  newCustomerVIVRDate: null,
  display: [],
} as MessagingAndStatusObject;

export const messagingAndStatus = createSlice({
  name: "messagingAndStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GET_CALLDATA_SUCCESS, (state, action) => {
        const { payload }: any = action;
        state.display = [];

        state.obCampaignName = payload.data.activeContact.fields["1210"];
        state.delinquencyBucket = payload.data.activeContact.fields["1424"];
        state.loanStatus = payload.data.activeContact.fields["1041"];
        state.miniMiranda = payload.data.activeContact.fields["1158"];
        state.isBankruptcy = payload.data.activeContact.fields["1036"];

        const triggerDate = "2022-09-01";
        if (payload.data.activeContact.fields["1362"] === undefined) {
          state.newCustomerVIVR = "N";
          state.newCustomerVIVRDate = null;
        } else {
          state.newCustomerVIVRDate = moment(
            payload.data.activeContact.fields["1362"]
          ).format("YYYY-MM-DD");
          if (moment(state.newCustomerVIVRDate) > moment(triggerDate)) {
            state.newCustomerVIVR = "Y";
          }
        }

        state.display.push({
          key: "obCampaignName",
          label: "OB Campaign Name:",
          value: state.obCampaignName,
          cols: 12,
        });
        state.display.push({
          key: "delinquencyBucket",
          label: "Delinquency Bucket:",
          value: state.delinquencyBucket,
          cols: 6,
        });
        state.display.push({
          key: "loanStatus",
          label: "Loan Status:",
          value: state.loanStatus,
          cols: 6,
        });
        state.display.push({
          key: "miniMiranda",
          label: "Mini Miranda:",
          value: state.miniMiranda,
          cols: 4,
        });
        state.display.push({
          key: "isBankruptcy",
          label: "Bankruptcy:",
          value: state.isBankruptcy,
          cols: 4,
        });

        state.display.push({
          key: "welcomeVIVR",
          label: "Welcome Experience:",
          value: state.newCustomerVIVR,
          cols: 4,
        });
      })
      .addCase("azure/contactFields/succeeded", (state, action) => {
        const { payload }: any = action;
        const fields = payload.data.items[0];
        state.display = [];

        state.delinquencyBucket = fields.delinquencyBucket;
        state.loanStatus = fields.status;
        state.miniMiranda = fields.miniMiranda;
        state.isBankruptcy = fields.isBankruptcy;

        state.display.push({
          key: "obCampaignName",
          label: "OB Campaign Name:",
          value: state.obCampaignName,
          cols: 12,
        });
        state.display.push({
          key: "delinquencyBucket",
          label: "Delinquency Bucket:",
          value: state.delinquencyBucket,
          cols: 6,
        });
        state.display.push({
          key: "loanStatus",
          label: "Loan Status:",
          value: state.loanStatus,
          cols: 6,
        });
        state.display.push({
          key: "miniMiranda",
          label: "Mini Miranda:",
          value: state.miniMiranda,
          cols: 4,
        });
        state.display.push({
          key: "isBankruptcy",
          label: "Bankruptcy:",
          value: state.isBankruptcy,
          cols: 4,
        });

        state.display.push({
          key: "welcomeVIVR",
          label: "Welcome Experience:",
          value: state.newCustomerVIVR,
          cols: 4,
        });
      });
  },
});
// thunks

// Action creators are generated for each case reducer function
// export const {
//   openTestingModal,
//   closeTestingModal,

// } = azureContactFields.actions;

export default messagingAndStatus.reducer;
