/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { Modal, Row, Col } from "react-bootstrap";
import { closeWindow } from "../../../app/slices/features/contactVerificationSlice";
import { postUpdateCVBypass } from "../../../app/slices/features/contactVerificationUnwrap";
import ContactVerificationScript from "./ContactVerificationScript";
import ContactVerificationCard from "./ContactVerificationCard";
import ContactVerificationFooter from "./ContactVerificationFooter";

import "./ContactVerificationModal.css";
const ContactVerificationModal = () => {
  const dispatch = useAppDispatch();
  // global states
  const verification: any = useAppSelector(
    (state) => state.contactVerification
  );
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const callIds: any = useAppSelector((state) => state.main.callIds);

  useEffect(() => {
    
    if (verification.bypass === "Y" && (verification.verifiedBy === "" || verification.verifiedBy === "IVA")) {
      postUpdateCVBypass(metadata, callIds,"Y", dispatch);
    }  else {
      postUpdateCVBypass(metadata, callIds,"N", dispatch);
    }
  }, verification.bypass);

  return (
    <Modal
      size={"lg"}
      id="contact_verfication"
      aria-labelledby="contact_verfication_header"
      show={verification.window}
      onHide={() => {
        dispatch(closeWindow());
      }}
    >
      <Modal.Header
        closeVariant="white"
        id="contact_verfication_header"
        closeButton
      >
        <Modal.Title>Contact Verification Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs>
            <ContactVerificationCard />
          </Col>

          <Col xs>
            <ContactVerificationScript />
          </Col>
        </Row>
      </Modal.Body>
      <ContactVerificationFooter />
    </Modal>
  );
};

export default ContactVerificationModal;
