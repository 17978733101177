/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import { Card, Image, Row, Col } from "react-bootstrap";
import TransferNoLoan from "./components/noLoanTransfer/TransferNoLoan";
import "./NoLoanModal.css";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const contact_record_image = require("./img/contact_record_image.PNG");

const NoLoanModal = () => {
  return (
    <Row>
      <Col>
        <Card id="no_loan_modal">
          <Card.Header>
            <Card.Title>No Loan Present</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="p-3 bg-light">
              <div className="mb-2">
                <h5 className="p-0">
                  <b>Intro</b>
                </h5>
                <p>
                  Hello, this is <i className="text-info">[your name]</i> with
                  RoundPoint Mortgage on a recorded line. Who am I speaking with
                  today?"
                </p>
                <h5 className="p-0">Lookup Contact Record</h5>
                <p>
                  Please search for the customers loan using Five9 by selecting{" "}
                  <Image
                    src={contact_record_image}
                    style={{ border: "1px solid black" }}
                  />
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <TransferNoLoan/>
    </Row>
  );
};

export default NoLoanModal;
