import React from "react";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { thresholdReached } from "./ThresholdApi";
import { consoleLogIt } from "../../../../app/log";
interface State {
  time: number;
  seconds: number;
  minutes: number;
}

const ACWTimer = ({ time }: any) => {
  const dispatch = useAppDispatch();
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const [state, setState] = React.useState<State>({
    time,
    seconds: Math.floor((time - 1) / 60) * 60 - 1,
    minutes: time - Math.floor((time - 1) / 60),
  });
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (state.time === 0) {
        dispatch(thresholdReached(callIds, "acw"));

        return;
      }
      setState({
        time: state.time - 1,
        minutes: Math.floor((state.time - 1) / 60),
        seconds: state.time - Math.floor((state.time - 1) / 60) * 60 - 1,
      });

      return function cleanup() {
        clearTimeout(timeout);
      };
    }, 1000);
  }, [state.time]);
  return <></>;
};

export default ACWTimer;
