import React from "react";
import { useAppDispatch } from "../../../app/hooks";
import { Toast } from "react-bootstrap";
import { removeNotification } from "../../../app/slices/features/notificationsSlice";
import { consoleLogIt } from "../../../app/log";

function NotificationsToast({ obj, idx }: any) {
  const dispatch = useAppDispatch();
  //const moment = require("moment"); // require

  const onCloseHandler = () => {
    dispatch(removeNotification(idx));
  };

  return (
    <Toast onClose={onCloseHandler}>
      <Toast.Header className={`bg-${obj.type} text-white`}>
        <strong className="me-auto">{obj.title}</strong>
        {/* <small className="text-white">{moment().format("hh:mm A")} </small> */}
      </Toast.Header>
      <Toast.Body>{obj.text}</Toast.Body>
    </Toast>
  );
}

export default NotificationsToast;
