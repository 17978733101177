
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from "react";
import { Card, ButtonGroup, ToggleButton } from "react-bootstrap";
import AddressMailingBody from "./AddressMailingBody";
import AddressPropertyBody from "./AddressPropertyBody";

const AddressCardNew = () => {
  const [radioValue, setRadioValue] = useState("property");

  const radios = [
    { name: "Property Address", value: "property" },
    { name: "Mailing Address", value: "mailing" },
  ];

  return (
    <Card>
      <Card.Body>
        <div className="address-toggle">
          <ButtonGroup className="">
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant="light"
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
                className={radio.value === radioValue ? "active" : ""}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
        {radioValue === "property" ? (
          <AddressPropertyBody />
        ) : (
          <AddressMailingBody />
        )}
      </Card.Body>
    </Card>
  );
};

export default AddressCardNew;
