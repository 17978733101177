/* eslint-disable react/no-unescaped-entities */

/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppSelector } from "../../app/hooks";

const AuthScriptTab = () => {
  
  const borrower: any = useAppSelector((state) => state.displayInfo.borrower);
  const name: string = borrower.concat;
  return (
    <div className="p-3 bg-light">
      <div className="mb-2">
        <h5 className="p-0">
          <b>Intro</b>
        </h5>
        <p>
          Hello, this is <i className="text-info">[your name]</i> with RoundPoint
          Mortgage on a recorded line. Am I speaking with
          <span className="text-info"> {name}</span>?"
        </p>
      </div>
      <div className="mb-2">
        <h5 className="p-0">
          <b>Address</b>
        </h5>
        <span>
          "To begin, can you please provide your full property address?"
        </span>
      </div>
      <div>
        <h5 className="p-0">
          <b>2 Additional NON-PII</b>
        </h5>
        <p className="mb-0">
          "Can you also provide
          <i className="text-info">[SELECT TWO OF THE FOLLOWING]</i>:
        </p>
        <ul>
          <li> Authorized Cell Phone</li>
          <li> Loan Number</li>
          <li> Last 4 SSN</li>
          <li> Birth date</li>
          <li> Original UPB</li>
          <li> Interest Rate</li>
          <li> Payment Amount</li>
        </ul>
      </div>
    </div>
  );
};



export default AuthScriptTab;
