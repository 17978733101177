/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";

import { updateAuthArray } from "../../app/actions/worksheetActions";
import { InputGroup, Form } from "react-bootstrap";

const AuthSwitches = ({ item, verify }: any) => {
  const dispatch = useAppDispatch();
  const authArray: any = useAppSelector(
    (state) => state.callWorksheets.authArray
  );
  const inProgress: any = useAppSelector((state) => state.features.putInProgress);

  const itemValue = () => {
    if (item.value === true) {
      return true;
    } else {
      return false;
    }
  };
  const disabled = () => {
    if (verify === true) {
      if (inProgress === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <InputGroup>
      <Form.Check
        type="switch"
        id={`verify_${item.id}`}
        disabled={disabled()}
        label={item.text}
        defaultChecked={itemValue()}
        onChange={(e: any) => {
          if (e.currentTarget.checked === true) {
            const data: any = {};
            data.id = item.id;
            data.value = true;
            const update: any = {};
            update.update = data;
            update.array = authArray;
            dispatch(updateAuthArray(update));
          } else {
            const data: any = {};
            data.id = item.id;
            data.value = false;
            const update: any = {};
            update.update = data;
            update.array = authArray;
            dispatch(updateAuthArray(update));
          }
        }}
      />
    </InputGroup>
  );
};

export default AuthSwitches;
