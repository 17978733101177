/* eslint-disable @typescript-eslint/explicit-function-return-type */

export const QuickdrawList = (loanNumber: any, callIds: any) => {
  return [
    {
      unauthenticated: true,
      noLoan: true,
      section: "External_Links",
      title: "Online Flight Manual Beta",
      action: "Pop-Up",
      windowName: "Online_Flight_Manual",
      url: "https://flight-manual.stonly.com/kb/en?lang=en",
      event: "onlineFlightManual",
    },
    {
      unauthenticated: true,
      noLoan: false,
      section: "External_Links",
      title: "seCUREpay",
      action: "Pop-Up",
      windowName: "seCUREpay",
      url: `https://five9-payment-app-prod.azurewebsites.net?frontline=true`,
      event: "securepay",
    },
    // {
    //   unauthenticated: false,
    //   noLoan: false,
    //   section: "External_Links",
    //   title: "Ventanex",
    //   action: "Pop-Up",
    //   windowName: "Ventanex",
    //   url: `https://hq.secureconduit.net/msp_loans/${loanNumber}/msp_payments/new?override=false`,
    //   event: "ventanex",
    // },
    // {
    //   unauthenticated: false,
    //   noLoan: false,
    //   section: "External_Links",
    //   title: "Clarifier",
    //   action: "Pop-Up",
    //   windowName: "Clarifier",
    //   url: `https://roundpoint.eclarifire.com/Clarifire/ClarifireContact/Default.aspx?LoanNumber=${loanNumber}`,
    //   event: "clarifier",
    // },
    // {
    //   unauthenticated: false,
    //   noLoan: false,
    //   section: "External_Links",
    //   title: "CCN",
    //   action: "Pop-Up",
    //   windowName: "CCN",
    //   url: "https://servicinghome.ccnadmin.com/ccn/adm/servicinghome.html#pid=HOME-A",
    //   event: "ccn",
    // },    
    {
      unauthenticated: true,
      noLoan: true,
      section: "External_Links",
      title: "Flight Manual",
      action: "Pop-Up",
      windowName: "Flight_Manual",
      url: "http://neighborhood/sites/callcenter/default.aspx",
      event: "flightManual",
    },
    {
      unauthenticated: false,
      noLoan: false,
      section: "Tools",
      title: "DCC Consultation Form",
      action: "Run",
      component: "DCC_Consultation_Form",
      event: "dccConsultationForm",
    },
    {
      unauthenticated: false,
      noLoan: false,
      section: "Tools",
      title: "Good Call Email",
      action: "Run",
      component: "Email",
      event: "goodCallEmail",
    },
    {
      unauthenticated: true,
      noLoan: true,
      section: "Tools",
      title: "Transfer Assist",
      action: "Run",
      component: "Transfer_Assist",
      event: "transferAssist",
    },
    {
      unauthenticated: true,
      noLoan: false,
      section: "Tools",
      title: "Skip Trace Bad Number",
      action: "Run",
      component: "Skip_Trace",
      event: "skipTrace",
    },
    // {
    //   unauthenticated: false,
    //   noLoan: false,
    //   section: "Tools",
    //   title: "Contact Preference",
    //   action: "Run",
    //   component: "Contact_Preference",
    //   event: "contactPreference",
    // },

    {
      unauthenticated: false,
      noLoan: false,
      section: "Tools",
      title: "SMS Opt In/Out",
      action: "Pop-Up",
      component: "SMS_Subscribe_VIVR",
      url: `https://api.five9.com/ivr/1/109388/campaigns/1140698/new_ivr_session?VIVR.Call_Id=${callIds.rptCallId}&VIVR.Agent_Username=${callIds.userName}&VIVR.LoanNumParameter=${loanNumber}`,
      event: "smsSubscribe",
    },
    // {
    //   unauthenticated: true,
    //   section: "Tools",
    //   title: "Debug",
    //   action: "Run",
    //   component: "debug_tool",
    //   event: "debug_tool",
    // },
    {
      unauthenticated: true,
      noLoan: true,
      section: "Feature_Requests",
      title: "Frontline Feature Request",
      action: "Pop-Up",
      component: "Frontline_Feature_Request",
      url: "https://roundpointmortgage.sharepoint.com/teams/ContactCenterFeatureRequest/Lists/Frontline%20Feature%20Requests/Feature%20Requests.aspx",
      event: "frontlineFeatureRequest",
    },
  ];
};