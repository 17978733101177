/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { updateValue } from "../../../app/slices/features/contactVerificationSlice";

const toggleArray: any = [
  { value: "NA", text: "N/A", name: "none" },
  { value: "C", text: "Correct", name: "yes" },
  { value: "U", text: "Update", name: "no" },
];

const ContactVerificationToggle = ({ item, value, name }: any) => {
  const dispatch = useAppDispatch();
  // global states

  const inProgress: any = useAppSelector(
    (state) => state.features.putInProgress
  );

  const [radioValue, setRadioValue] = useState(value);
  useEffect(() => {
    if (item === "" || item === undefined) {
      setRadioValue("");
      dispatch(updateValue({name, value: ""}))
    }
  },[item])


  // TODO ADD REMOVE BUTTONS FOR PHONE AND EMAIL
  return (
    <ButtonGroup size="sm">
      {toggleArray.map((radio: any, idx: number) => {

        return (
          <ToggleButton
            key={idx}
            id={`cv_${name}-${radio.name}`}
            size="sm"
            type="radio"
            disabled={item === "" || item === undefined ? true : false}
            variant="outline-secondary"
            name={`cv_${name}`}
            value={radio.value}
            checked={radioValue === radio.value}
            onChange={(e) => {
              setRadioValue(e.currentTarget.value);
              dispatch(updateValue({name, value: e.currentTarget.value}))
            }}
          >
            {radio.text}
          </ToggleButton>
        );
      })}
    </ButtonGroup>
  );
};

export default ContactVerificationToggle;
