import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { main } from "./reducers/main";
import { display } from "./reducers/display";
import { callWorksheets } from "./reducers/callWorksheets";
import { displayInfo } from "./reducers/info";
import { features } from "./reducers/features";
import { lcomObject } from './reducers/lcom';
import { transferData } from "./reducers/transfers";
import { conferenceObject } from "./reducers/conference";
import notifications from './slices/features/notificationsSlice';
import skipTrace from "./slices/features/skipTraceSlice";
import contactVerification from "./slices/features/contactVerificationSlice";
import prefReducer from "../components/features/prefModal/PrefWindowSlice";
import verbalComplaint from "../components/offCanvas/verbalComplaint/verbalComplaintSlice";
import azureContactFields from "./newAzure/getContactFieldsSlice";
import safeHavenForm from "../components/offCanvas/safeHavenForm/safeHavenFormSlice";
import testing from "../components/features/testing/testingSlice";
import betaUser from "./slices/features/betaUserSlice";
import dccConsultationQRPC from "../components/offCanvas/dccConsultationQRPC/DCCConsultationQRPCSlice";
export const store = configureStore({
  reducer: {
    main,
    azureContactFields,
    display,
    callWorksheets,
    skipTrace,
    displayInfo,
    features,
    lcomObject,
    transferData,
    conferenceObject,
    pref: prefReducer,
    contactVerification,
    notifications,
    verbalComplaint,
    testing,
    betaUser,
    safeHavenForm,
    dccConsultationQRPC
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
