/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { Form } from "react-bootstrap";
import { dccQuestionsList } from "./DCCQRPCLists";
import DCCRadioButtons from "./questions/DCCRadioButtons";
import DCCToggleButtons from "./questions/DCCToggleButtons";
import DCCDatesInput from "./questions/DCCDatesInput";
import DCCIncomeInput from "./questions/DCCIncomeInput";
import DCCCompleteButton from "./questions/DCCCompleteButton";
import { consoleLogIt } from "../../../app/log";

const DCCConsultationForm = () => {

  const dccConsultationQRPC: any = useAppSelector(
    (state) => state.dccConsultationQRPC
  );
  const [stage, setStage] = useState(0);

  useEffect(()=> {
    consoleLogIt("stage Number: ",stage) 
  },[stage])
  useEffect(() => {

    if (dccConsultationQRPC.questions.employment === null) {
      setStage(0);
    } else if (dccConsultationQRPC.questions.hardshipReason === null) {
      setStage(1);
    } else if (dccConsultationQRPC.questions.hardshipLength === null) {
      setStage(2);
    } else if (dccConsultationQRPC.questions.hardshipResolved === null) {
      setStage(3);
    } else {
      if (dccConsultationQRPC.questions.hardshipResolved === "Yes") {
        if (
          dccConsultationQRPC.questions.hardshipBegin === null ||
          dccConsultationQRPC.questions.hardshipEnd === null
        ) {
          setStage(4);
        } else if (dccConsultationQRPC.questions.hardshipCovid === null) {
          setStage(5);
        } else if (
          dccConsultationQRPC.questions.hardshipFemaDisaster === null ||
          (dccConsultationQRPC.questions.hardshipFemaDisaster === "Yes" &&
            dccConsultationQRPC.questions.hardshipFemaDisasterType === null)
        ) {
          setStage(6);
        } else if (dccConsultationQRPC.questions.propertyIntentions === null) {
          setStage(7);
        } else if (
          dccConsultationQRPC.questions.propertyOccupancy === null ||
          (dccConsultationQRPC.questions.propertyOccupancy === "Vacant" &&
            (dccConsultationQRPC.questions.propertyAbandoned === null ||
              dccConsultationQRPC.questions.propertyCondemned === null))
        ) {
          setStage(8);
        } else if (dccConsultationQRPC.questions.affordability === null) {
          setStage(9);

        } else {
          setStage(11);
        }
      } else {
        if (dccConsultationQRPC.questions.hardshipBegin === null) {
          setStage(4);
        } else if (dccConsultationQRPC.questions.hardshipCovid === null) {
          setStage(5);
        } else if (
          dccConsultationQRPC.questions.hardshipFemaDisaster === null ||
          (dccConsultationQRPC.questions.hardshipFemaDisaster === "Yes" &&
            dccConsultationQRPC.questions.hardshipFemaDisasterType === null)
        ) {
          setStage(6);
        } else if (dccConsultationQRPC.questions.propertyIntentions === null) {
          setStage(7);
        } else if (
          dccConsultationQRPC.questions.propertyOccupancy === null ||
          (dccConsultationQRPC.questions.propertyOccupancy === "Vacant" &&
            (dccConsultationQRPC.questions.propertyAbandoned === null ||
              dccConsultationQRPC.questions.propertyCondemned === null))
        ) {
          setStage(8);
        } else if (dccConsultationQRPC.questions.affordability === null) {
          setStage(9);
     
        } else {
          setStage(11);
        }
      }
    }

  }, [dccConsultationQRPC]);

  return (
    <>
      <Form>
        <DCCRadioButtons
          qna={dccQuestionsList("employment")}
          dccConsultationQRPC={dccConsultationQRPC}
        />
        {stage > 0 ? (
          <DCCToggleButtons
            qna={dccQuestionsList("hardshipReason")}
            dccConsultationQRPC={dccConsultationQRPC}
          />
        ) : (
          <></>
        )}
        {stage > 1 ? (
          <DCCRadioButtons
            qna={dccQuestionsList("hardshipLength")}
            dccConsultationQRPC={dccConsultationQRPC}
          />
        ) : (
          <></>
        )}
        {stage > 2 ? (
          <DCCRadioButtons
            qna={dccQuestionsList("hardshipResolved")}
            dccConsultationQRPC={dccConsultationQRPC}
          />
        ) : (
          <></>
        )}
        {stage > 3 ? (
          <DCCDatesInput dccConsultationQRPC={dccConsultationQRPC} />
        ) : (
          <></>
        )}
        {stage > 4 ? (
          <DCCRadioButtons
            qna={dccQuestionsList("hardshipCovid")}
            dccConsultationQRPC={dccConsultationQRPC}
          />
        ) : (
          <></>
        )}

        {stage > 5 ? (
          <DCCRadioButtons
            qna={dccQuestionsList("hardshipFemaDisaster")}
            dccConsultationQRPC={dccConsultationQRPC}
          />
        ) : (
          <></>
        )}
        {stage > 5 &&
        dccConsultationQRPC.questions.hardshipFemaDisaster === "Yes" ? (
          <DCCRadioButtons
            qna={dccQuestionsList("hardshipFemaDisasterType")}
            dccConsultationQRPC={dccConsultationQRPC}
          />
        ) : (
          <></>
        )}
        {stage > 6 ? (
          <DCCRadioButtons
            qna={dccQuestionsList("propertyIntentions")}
            dccConsultationQRPC={dccConsultationQRPC}
          />
        ) : (
          <></>
        )}
        {stage > 7 ? (
          <DCCRadioButtons
            qna={dccQuestionsList("propertyOccupancy")}
            dccConsultationQRPC={dccConsultationQRPC}
          />
        ) : (
          <></>
        )}
        {stage > 7 &&
        dccConsultationQRPC.questions.propertyOccupancy === "Vacant" ? (
          <DCCRadioButtons
            qna={dccQuestionsList("propertyAbandoned")}
            dccConsultationQRPC={dccConsultationQRPC}
          />
        ) : (
          <></>
        )}
        {stage > 7 &&
        dccConsultationQRPC.questions.propertyOccupancy === "Vacant" &&
        dccConsultationQRPC.questions.propertyAbandoned !== null ? (
          <DCCRadioButtons
            qna={dccQuestionsList("propertyCondemned")}
            dccConsultationQRPC={dccConsultationQRPC}
          />
        ) : (
          <></>
        )}

        {stage > 8 ? (
          <DCCRadioButtons
            qna={dccQuestionsList("affordability")}
            dccConsultationQRPC={dccConsultationQRPC}
          />
        ) : (
          <></>
        )}
        {stage > 9 ? (
          <DCCIncomeInput dccConsultationQRPC={dccConsultationQRPC} />
        ) : (
          <></>
        )}
      </Form>
      <DCCCompleteButton
        stage={stage}
        dccConsultationQRPC={dccConsultationQRPC}
      />
    </>
  );
};

export default DCCConsultationForm;
