import React from "react";
import { useAppSelector } from "../../../app/hooks";
import NavButton from "./NavButton";

// all mortgage incentive options
const RefiIncentiveNav = ({ appPath }: any) => {
  // global State

  const incentiveOptions: any = useAppSelector(
    (state) => state.callWorksheets.incentiveOptions
  );
  let complete = false;
  let className = "incentive";
  if (incentiveOptions.refiO !== "null") {
    complete = true;
    if (incentiveOptions.refiO !== "Did Not Pitch") {
      className = "incentive-complete";
    }
  }

  if (appPath !== "Unauthenticated") {
    switch (incentiveOptions.refiWS) {
      case "F Refi":
        return (
          <NavButton
            eKey="refi"
            title="Freedom Refi"
            className={className}
            complete={complete}
          />
        );
      case "F CO":
        return (
          <NavButton
            eKey="cashout"
            title="Freedom CashOut"
            className={className}
            complete={complete}
          />
        );
      case "Reverse":
        return (
          <NavButton
            eKey="reverse"
            title="Reverse"
            className={className}
            complete={complete}
          />
        );
      default:
        return <></>;
    }
  } else {
    return <></>;
  }
};

export default RefiIncentiveNav;
