/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { Form } from "react-bootstrap";
import {
  updateRecieptOutcomeVariable,
  OutcomeObject,
  worksheetOutcomeUpdateSuccess,
  worksheetOutcomeUpdateFailed,
} from "../../../app/slices/callWorksheet/outcome";
import { openVerbalComplaint } from "../../offCanvas/verbalComplaint/verbalComplaintSlice";
import { consoleLogIt, reportError } from "../../../app/log";

const outcomeArray = [
  {
    id: "complaint1",
    name: "complaint",
    text: "Customer mention an issue",
    value: "Mentioned Issue",
  },
  {
    id: "complaint2",
    name: "complaint",
    text: "Customer is dissatisfied with a RoundPoint Process",
    value: "Dissatisfied with Process",
  },
  {
    id: "complaint3",
    name: "complaint",
    text: "Customer is dissatisfied with the outcome of a RoundPoint Process",
    value: "Dissatisfied with Outcome",
  },
  {
    id: "complaint4",
    name: "complaint",
    text: "None of these occurred",
    value: "None",
  },
];

const StandardOutcomeRadio = () => {
  const dispatch = useAppDispatch();
  // global states
  const outcome: OutcomeObject = useAppSelector(
    (state) => state.callWorksheets.outcome
  );
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const callIds: any = useAppSelector((state) => state.main.callIds);

  const inProgress: any = useAppSelector(
    (state) => state.features.putInProgress
  );

  const disableThis = () => {
    if (inProgress === true) {
      return true;
    } else {
      return false;
    }
  };

  const updateVariables = async (name: string, value: string) => {

    try {
      const newOutcome: any = {};
      newOutcome.mood = outcome.mood;
      newOutcome.abusive = outcome.abusive;
      newOutcome.escalation = outcome.escalation;
      newOutcome.resolution = outcome.resolution;
      newOutcome.complaint = outcome.complaint;
      newOutcome[name] = value;
      const resultAction = await dispatch(
        updateRecieptOutcomeVariable({
          metadata: metadata,
          callIds,
          outcome,
          update: {
            id: name,
            value: value,
          },
        })
      );

      const originalPromiseResult = unwrapResult(resultAction);
      // handle Result here
      if (originalPromiseResult[1079] === JSON.stringify(newOutcome)) {
        consoleLogIt("promise came back successfull");
        dispatch(worksheetOutcomeUpdateSuccess(originalPromiseResult));
      } else {
        dispatch(reportError({
          callIds,
          title: "Update Variable Did Not Match",
          text: "Frontline.Reciept_Outcome",
        }));
        dispatch(worksheetOutcomeUpdateFailed(originalPromiseResult));
      }
      consoleLogIt("Original Promise Result", originalPromiseResult);
    } catch (e) {
      // error
      dispatch(worksheetOutcomeUpdateFailed(e));
      dispatch(reportError({
        callIds,
        title: "Update Variable Failed",
        text: "Frontline.Reciept_Outcome",
      }));
    }
  };

  return (
    <>
      <Form>
        {outcomeArray.map((item: any) => {
          if (item.value === outcome.complaint && item.id !== "complaint4") {
            return (
              <Form.Check
                key={item.id}
                type="radio"
                id={item.id}
                disabled={disableThis()}
                checked={item.value === outcome.complaint}
                label={item.text}
                value={item.value}
                name={item.name}
                onChange={(event) => {
                  if (event.currentTarget.id !== "complaint4") {
                    dispatch(openVerbalComplaint());
                  }

                  updateVariables(
                    event.currentTarget.name,
                    event.currentTarget.value
                  );
                }}
                onClick={() => {
                  dispatch(openVerbalComplaint());
                }}
              />
            );
          } else {
            return (
              <Form.Check
                key={item.id}
                type="radio"
                id={item.id}
                disabled={disableThis()}
                checked={item.value === outcome.complaint}
                label={item.text}
                value={item.value}
                name={item.name}
                onChange={(event) => {
                  if (event.currentTarget.id !== "complaint4") {
                    dispatch(openVerbalComplaint());
                  }

                  updateVariables(
                    event.currentTarget.name,
                    event.currentTarget.value
                  );
                }}
              />
            );
          }
        })}
      </Form>
    </>
  );
};

export default StandardOutcomeRadio;
