
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "./app/hooks";
import { getMetadataApi } from "./app/thunks/getApisThunks";
import WebSocket from "./components/features/websocket/WebSocket";
import AppWrapper from "./AppWrapper";
import "./App.css";


function App() {
  const dispatch = useAppDispatch();
  const metadata: any  = useAppSelector((state) => state.main.metadata);

  useEffect(() => {
    dispatch(getMetadataApi());
    // test
  }, []);

  if (metadata.status === "Loaded") {
    return (
      <>
        <WebSocket />
        <AppWrapper />
      </>
    );
  } else if (metadata.status === "Loading") {
    return <div>Fetching permissions.</div>;
  } else {
    return <div>Failed to get permissions.</div>;
  }
}

export default App;
