import { createSlice, createAction, nanoid } from "@reduxjs/toolkit";
import { consoleLogIt, consoleErrorIt, reportError } from "../../log";
import {
  GET_CALLDATA_SUCCESS,
  GET_CALLDATA_SUCCESS_NO_LOAN,
} from "../../actions/apisActions";
import {ValidEmailObject} from "../../types";


const initialState = {
  status: false,
  email: "",
} as ValidEmailObject;

export const validEmail = createSlice({
  name: "validEmail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GET_CALLDATA_SUCCESS, (state, action) => {
      const { payload }: any = action;
      // build display
      const plsid: string = payload.data.activeContact.fields["1163"];
      const borrowerEmail: string = payload.data.activeContact.fields["1142"];
      const coborrowerEmail: string = payload.data.activeContact.fields["1145"];
      if (plsid === "001" || plsid === "1" || plsid === "ZZ3") {
        if (borrowerEmail === undefined || borrowerEmail === "") {
          if (coborrowerEmail === undefined || coborrowerEmail === "") {
            state.status = false;
            state.email = "";
          } else {
            state.status = true;
            state.email = coborrowerEmail;
          }
        } else {
          state.status = true;
          state.email = borrowerEmail;
        }
      } else {
        state.status = false;
        state.email = "";
      }
    }).addCase("azure/contactFields/succeeded", (state, action) => {
      const { payload }: any = action;
      // build display
      const plsid: string = payload.data.items[0].plsId;
      const borrowerEmail: string = payload.data.items[0].borrowerEmail;
      const coborrowerEmail: string = payload.data.items[0].coBorrowerEmail;
      if (plsid === "001" || plsid === "1" || plsid === "ZZ3") {
        if (borrowerEmail === null || borrowerEmail === "") {
          if (coborrowerEmail === null || coborrowerEmail === "") {
            state.status = false;
            state.email = "";
          } else {
            state.status = true;
            state.email = coborrowerEmail;
          }
        } else {
          state.status = true;
          state.email = borrowerEmail;
        }
      } else {
        state.status = false;
        state.email = "";
      }
    });
  },
});
// thunks

// Action creators are generated for each case reducer function
// export const {
//   openTestingModal,
//   closeTestingModal,

// } = azureContactFields.actions;

export default validEmail.reducer;
