import React from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { Nav } from "react-bootstrap";
import NavButton from "./NavButton";

const CallWorksheetNav = ({appPath}: any) => {
  // global State
  const standardComplete: any = useAppSelector(
    (state) => state.callWorksheets.standardComplete
  );
  if (appPath === "Unauthenticated") {
    return (
      <NavButton
        eKey="unauthenticated"
        title="Unauthenticated"
        className=""
        complete={false}
      />
    );
  } else {
    return (
      <NavButton
        eKey="standard"
        title="Standard"
        className=""
        complete={standardComplete}
      />
    );
  }
};

export default CallWorksheetNav;
