/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { putSkipTraceVariables } from "../../../app/thunks/worksheetThunks";
import { Form, InputGroup, Button } from "react-bootstrap";

const SkipTraceForm = () => {
  const dispatch = useAppDispatch();
  const skip: any = useAppSelector((state) => state.skipTrace);
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const callIds: any = useAppSelector((state) => state.main.callIds);

  const [badNumber, setBadNumber] = useState(() =>
    skip.badNumber === null || skip.badNumber === ""
      ? callIds.custPhone
      : skip.skipNumber
  );

  const handleBadNumberChange = (e: any) => {
    const { value } = e.target;
    setBadNumber(value);
  };

  return (
    <Form id="skip_trace_form">
      <p>
        Enter in the incorrect skip trace phone number below and hit submit.
      </p>
      <InputGroup size="sm" className="mb-3">
        <Form.Control
          type="tel"
          value={badNumber}
          onChange={handleBadNumberChange}
        />
        <Button
          variant="primary"
          id="skip_trace_submit"
          onClick={(e) => {
            dispatch(putSkipTraceVariables(metadata.data, skip, callIds));
          }}
        >
          Submit
        </Button>
      </InputGroup>
    </Form>
  );
};

export default SkipTraceForm;
