/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  closeVerbalComplaint,
  VerbalComplaintInterface,
  clearVerbalComplaint,
} from "./verbalComplaintSlice";
import { Offcanvas, Button } from "react-bootstrap";
import VCQuestions from "./questions/VCQuestions";
import VerbalComplaintFooter from "./VerbalComplaintFooter";
import "./VerbalComplaint.css";

const VerbalComplaintOffcanvas = () => {
  const dispatch = useAppDispatch();
  const verbalComplaint: VerbalComplaintInterface = useAppSelector(
    (state) => state.verbalComplaint
  );
  const handleClose = () => {
    dispatch(closeVerbalComplaint());
  };

  return (
    <Offcanvas
      show={verbalComplaint.show}
      onHide={handleClose}
      placement="end"
      name="verbal_complaint"
      id="verbal_complaint"
    >
      <Offcanvas.Header closeVariant="white" closeButton>
        <Offcanvas.Title>Verbal Complaint Tool</Offcanvas.Title>

      </Offcanvas.Header>
      <Offcanvas.Body>
      {verbalComplaint.apiStatus === "success" ? (
          <Button
            size="sm"
            variant="warning"
            onClick={() => {
              dispatch(clearVerbalComplaint());
            }}
          >
            Submit an Additional Verbal Complaint
          </Button>
        ) : (
          <></>
        )}
        <VCQuestions />
      </Offcanvas.Body>
      <VerbalComplaintFooter />
    </Offcanvas>
  );
};

export default VerbalComplaintOffcanvas;
