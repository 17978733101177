import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { sentDataToSafeHaven } from "../../offCanvas/safeHavenForm/safeHavenFormSlice";
import { consoleLogIt } from "../../../app/log";
import "./HiddenIframe.css";

const HiddenIframeSafeHaven = () => {
  const dispatch = useAppDispatch();
  const safeHavenInfo = useAppSelector((state) => state.safeHavenForm);
  const callIds = useAppSelector((state) => state.main.callIds);
  const [send, setSend] = useState(false);
  const [url, setUrl] = useState("");
  useEffect(() => {
    if (safeHavenInfo.sentDataToSafeHaven !== true) {
      if (safeHavenInfo.sendData === true) {
        let dataUrl = `https://api.five9.com/ivr/1/109388/campaigns/300000000000069/new_ivr_session?`;
        dataUrl = dataUrl + `agent=${callIds.userName}`;
        dataUrl = dataUrl + `&callId=${callIds.rptCallId}`;
        dataUrl = dataUrl + `&loanNumber=${callIds.loanNumber}`;
        dataUrl = dataUrl + `&email=${safeHavenInfo.email}`;
        dataUrl = dataUrl + `&firstName=${safeHavenInfo.firstName}`;
        dataUrl = dataUrl + `&lastName=${safeHavenInfo.lastName}`;
        dataUrl = dataUrl + `&city=${safeHavenInfo.city}`;
        dataUrl = dataUrl + `&streetAddress=${safeHavenInfo.address}`;
        dataUrl = dataUrl + `&state=${safeHavenInfo.state}`;
        dataUrl = dataUrl + `&zip=${safeHavenInfo.zip}`;
        dataUrl = dataUrl + `&primaryNumber=${safeHavenInfo.phoneNumber}`;
        dataUrl = dataUrl + `&form=${safeHavenInfo.form}`;
        if (safeHavenInfo.form === "transfer") {
          dataUrl = dataUrl + `&leadNotes=${safeHavenInfo.notes}`;
        } else {
          dataUrl =
            dataUrl +
            `&leadNotes=${safeHavenInfo.date} - ${safeHavenInfo.time} - ${safeHavenInfo.notes}`;
        }

        setUrl(dataUrl);
        dispatch(sentDataToSafeHaven())
        setSend(true);
      }
    }
  }, [safeHavenInfo]);
  if (send === true) {
    return (
      <iframe className="hidden" src={url} title="Send data to Five9"></iframe>
    );
  } else {
    return <></>;
  }
};

export default HiddenIframeSafeHaven;
