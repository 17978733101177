
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import ContactInfoCallTypeId from './ContactInfoCallTypeId';
import LoanCard from './LoanCard';
import BorrowerInfo from './BorrowerInfo';
import ContactInfoCard from './ContactInfoCard';
import AddressCardNew from './AddressCardNew';
import './ContactInfo.css';

const ContactInfo = () => {
  return (
    <div id="contact-information" className="field-display">
      <ContactInfoCallTypeId />
      <LoanCard />
      <BorrowerInfo />
      <ContactInfoCard/>
      <AddressCardNew />
    </div>
  )
}
export default ContactInfo;