import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Card } from "react-bootstrap";
import WebSocketModalSearchColumn from "./WebSocketModalSearchColumn";
import "./WebSocket.css";

const WebSocketModal = ({ messageHistory, modalShow, closeModal }: any) => {
  const [show, setShow] = useState(true);
  const [selected, setSelected]: any = useState(null);
  const [display, setDisplay]: any = useState({})
  useEffect(() => {
    console.log("Selected Change", selected);
    setDisplay(selected)
  }, [selected]);

  useEffect(()=> {
    setShow(modalShow);
  },[modalShow])
  return (
    <Modal show={show} fullscreen={true} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>WebSocket Events</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <WebSocketModalSearchColumn
            messageHistory={messageHistory}
            selected={selected}
            setSelected={setSelected}
          />
          <Col xs={4} className="websocket-columns">
            <Card>
              <Card.Header>
                <Card.Title>Context</Card.Title>
              </Card.Header>
              <Card.Body>
                {selected !== null ? (
                  <textarea value={JSON.stringify(selected.context, null, 1)} readOnly/>
                ) : (
                  <></>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={4} className="websocket-columns">
            <Card>
              <Card.Header>
                <Card.Title>Payload</Card.Title>
              </Card.Header>
              <Card.Body>
                {selected !== null ? (
                  <textarea value={JSON.stringify(selected.payLoad, null, 1)} readOnly/>
                ) : (
                  <></>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default WebSocketModal;
