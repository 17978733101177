/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { InputGroup, Button, Form } from "react-bootstrap";
import { updateVerbalComplaint } from "../verbalComplaintSlice";

const VCIssueNotes = ({ verbalComplaint }: any) => {
  const dispatch = useAppDispatch();
  const [text, setText] = useState("");
  const [char, setChar] = useState(verbalComplaint.resolutionNotes.length);
  const [disable, setDisable] = useState(true);
  const [error, setError] = useState(false);

  const handleTextChange = (e: any) => {
    setText(e.target.value);
    setChar(e.target.value.length);
    if (e.target.value.length > 500) {
      setDisable(true);
      setError(true);
    } else if (e.target.value.length > 5) {
      setDisable(false);
      setError(false);
    } else {
      setDisable(true);
      setError(false);
    }
  };
  const clickSubmitButton = () => {
    const updateText = text.replace(/['"]+/g, "''");

    dispatch(updateVerbalComplaint({ name: "vcIssue", value: updateText }));
  };
  return (
    <>
      <span className={`character-count ${error ? "error-red" : ""}`}>
        {char}/500
      </span>
      <InputGroup>
        <Form.Control
          size="sm"
          as="textarea"
          className={error ? "error-red" : ""}
          rows={3}
          id="vc_Issue_notes_text"
          onChange={handleTextChange}
        />

        <Button
          variant="primary"
          id="vc_issues_notes_submit"
          onClick={clickSubmitButton}
          disabled={disable}
        >
          Submit
        </Button>
      </InputGroup>
    </>
  );
};

export default VCIssueNotes;
