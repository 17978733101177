
import { createAsyncThunk } from "@reduxjs/toolkit";
import { consoleLogIt, consoleErrorIt, reportError } from "../log";
import { errorNotification } from "../slices/features/notificationsSlice";

export const getContactFieldsApi = createAsyncThunk(
  "contactFields/get",
  async ({ callIds,calldata, token }: any, { dispatch, rejectWithValue }) => {
    try {
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `https://five9-webservice-prod.azurewebsites.net//api/v1/ContactField/GetContactField?loannumber=${callIds.loanNumber}`,
        {
          method: "Get",
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        }
      );
        consoleLogIt("response ", response)
      // const data = await response.json();
      const status = await response.status;
      if (status === 200) {
        consoleLogIt("Get Contact Info", response);
        return response.json();
      } else {
        consoleErrorIt("Failed to Get Contact Info", response);
        dispatch(
          reportError({
            callIds,
            title: "Failed to Get Contact Info",
            text: "Future state - Body Example",
          })
        );
        if(calldata.dataDate === undefined || calldata.dataDate === null || calldata.dataDate === "" ) {
          dispatch(
            errorNotification({
              title: "Unable to Connect to Loan Database",
              text: "Please check MSP for currnet loan information.",
            })
          );
        } else {
          dispatch(
            errorNotification({
              title: "Unable to Connect to Loan Database",
              text: `Loan information was last updated on ${calldata.dataDate}.`,
            })
          );
        }


        return rejectWithValue(response);
      }
    } catch (e) {
      consoleErrorIt("Failed to Get Contact Info", e);
      reportError({
        callIds,
        title: "Failed to Get Contact Info",
        text: "Complete Failure - Catch e",
      });
      return rejectWithValue(e);
    }
  }
);