/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { InputGroup, Form, Dropdown } from "react-bootstrap";
import TransferOptions from "./TransferOptions.json";
import TransferAssistDropdown from "./TransferAssistDropdown";
const TransferAssistDDWrapper = ({
  setSelectedTransfer,
  selectedTransfer,
}: any) => {

  const [inputEntry, setInputEntry]: any = useState('');
  const [dropdownOpen, setDropdownOpen]: any = useState(false);

  useEffect(() => {
    console.log("selected transfer changed to ", selectedTransfer);
    if (selectedTransfer !== null) {
      const selected: any = TransferOptions.find(
        (item) => item.id === selectedTransfer
      );
      setInputEntry(selected.name);
    } else {
      console.log("set input changed to null");
      setInputEntry(null);
    }
  }, [selectedTransfer]);

  const changeOfSearch = (e: any) => {
    if (selectedTransfer !== null) {
      setSelectedTransfer(null);

      setDropdownOpen(false);
    }
    setInputEntry(e.target.value);
    setDropdownOpen(true);
  };

  return (
    <>
      <Form.Label className="mb-0">
        <b>Transfer Options:</b>
      </Form.Label>
      <InputGroup id="transfer_list" className="mb-2 mt-2">
        <Form.Control
          as="input"
          type="search"
          placeholder="Search...."
          autoComplete="off"
          value={inputEntry}
          onChange={(e) => {
            changeOfSearch(e);
          }}
        />
        <TransferAssistDropdown
          setSelectedTransfer={setSelectedTransfer}
          setDropdownOpen={setDropdownOpen}
          dropdownOpen={dropdownOpen}
          inputEntry={inputEntry}
        />
      </InputGroup>
    </>
  );
};

export default TransferAssistDDWrapper;
