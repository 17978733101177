import { createSlice, createAction, nanoid } from "@reduxjs/toolkit";
import { consoleLogIt, consoleErrorIt, reportError } from "../../log";
import {
  GET_CALLDATA_SUCCESS,
  GET_CALLDATA_SUCCESS_NO_LOAN,
} from "../../actions/apisActions";
import { BannerObject } from "../../types";

const initialState = {
  bannerText: "",
  bannerHeader: "",
  staleData: false,
  data: {
    staleDataThreshold: 5,
    callType: "",
    customerCallReason: "",
    campaignName: "",
  },
} as BannerObject;

export const banner = createSlice({
  name: "banner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GET_CALLDATA_SUCCESS, (state, action) => {
      const { payload }: any = action;
      state.data.staleDataThreshold = Number(payload.data.variables["1351"]);
      state.data.callType = payload.data.variables["44"];
      state.data.customerCallReason = payload.data.variables["801"];
      state.data.campaignName = payload.data.activeContact.fields["1210"];
      const dataLoadDate = new Date(payload.data.activeContact.fields["1224"]);
      const da4 = new Date();
      da4.setDate(da4.getDate() - state.data.staleDataThreshold);
      if (dataLoadDate <= da4) {
        state.staleData = true;
      } else {
        state.staleData = false;
      }
      // banner
      switch (state.data.callType) {
        case "Inbound":
          state.bannerHeader = "Inbound Path Selected";
          if (payload.data.variables["801"] === undefined) {
            state.bannerText = "Inbound Path Undefined";
          } else {
            state.bannerText = payload.data.variables["801"];
          }
          break;
        case "Visual":
          //VISUAL QUEUE CALLBACKS
          state.bannerHeader = "Inbound Path Selected";

          if (payload.data.variables["801"] === undefined) {
            state.bannerText = "Inbound Path Undefined";
          } else {
            state.bannerText = payload.data.variables["801"];
          }
          break;
          case "Manual":
            //MANUAL
            state.bannerHeader = "Five9 Campaign Name";
            state.bannerText = payload.data.variables["46"];
            break;
        default:
          if (
            payload.data.activeContact.fields["1210"] === undefined ||
            payload.data.activeContact.fields["1210"] === null
          ) {
            state.bannerHeader = "Five9 Campaign Name";
            state.bannerText = payload.data.variables["46"];
          } else {
            state.bannerHeader = "OB Campaign Name";
            state.bannerText = payload.data.activeContact.fields["1210"];
          }

          break;
      }
    });
  },
});
// thunks

// Action creators are generated for each case reducer function
// export const {
//   openTestingModal,
//   closeTestingModal,

// } = azureContactFields.actions;

export default banner.reducer;
