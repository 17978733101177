/* eslint-disable @typescript-eslint/explicit-function-return-type */

// Standard Actions
export const UPDATE_LCOM_OBJECT = "UPDATE_LCOM_OBJECT";
export const updateLCOMObject = (id: any, key: any, value: any) => ({
  type: UPDATE_LCOM_OBJECT,
  payload: { id, key, value },
});

export const OPEN_LCOM_WINDOW = "OPEN_LCOM_WINDOW";
export const openLCOMWindow = () => ({
  type: OPEN_LCOM_WINDOW,
});

export const CLOSE_LCOM_WINDOW = "CLOSE_LCOM_WINDOW";
export const closeLCOMWindow = () => ({
  type: CLOSE_LCOM_WINDOW,
});

// Submit LCOM Update to WhenDu

export const UPDATE_LCOM_PAGE_IN_PROGRESS = "UPDATE_LCOM_PAGE_IN_PROGRESS";
export const updateLCOMPageInProgress = (payload: any) => ({
  type: UPDATE_LCOM_PAGE_IN_PROGRESS,
  payload: payload,
});
export const UPDATE_LCOM_PAGE_SUCCESS = "UPDATE_LCOM_PAGE_SUCCESS";
export const updateLCOMPageSuccess = (payload: any) => ({
  type: UPDATE_LCOM_PAGE_SUCCESS,
  payload: payload,
});
export const UPDATE_LCOM_PAGE_FAILED = "UPDATE_LCOM_PAGE_FAILED";
export const updateLCOMPageFailed = (payload: any) => ({
  type: UPDATE_LCOM_PAGE_FAILED,
  payload: payload,
});
export const UPDATE_LCOM_PAGE_FAILED_COMPLETELY =
  "UPDATE_LCOM_PAGE_FAILED_COMPLETELY";
export const updateLCOMPageFailedCompletely = (payload: any) => ({
  type: UPDATE_LCOM_PAGE_FAILED_COMPLETELY,
  payload: payload,
});

