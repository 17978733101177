
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import {Col, Container} from 'react-bootstrap';
import ContactInfo from './ContactInfo';

const Column1 = () => {
  return (
    <Col sm className="p-0 pb-2" id="column_1">
      <Container fluid className="p-0">
        <ContactInfo/>
      </Container>
    </Col>
  )
}

export default Column1;