import { createSlice } from "@reduxjs/toolkit";
import { GET_METADATA_SUCCESS } from "../../actions/apisActions";
// import { consoleLogIt, consoleErrorIt } from "../../log";

const betaUsers = [
  { id: "4087247", name: "Anisa Williams" },
  { id: "3703867", name: "Alexander Latimer" },
  { id: "3921321", name: "Shalauna Gore" },
  { id: "3721347", name: "Jami Stone" },
  { id: "2804909", name: "Marjorie Pope" },
  { id: "2828486", name: "Tehvin Douglas" },
  { id: "2713429", name: "Jeremy Thompson"},
  {id: "300000001422156", name: "Jennifer Simmonds"}
];
const initialState = {
  securePay: false,
};

export const betaUser = createSlice({
  name: "betaUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GET_METADATA_SUCCESS, (state, action) => {
      const { payload }: any = action;
      if (betaUsers.some((user) => user.id === payload.userId)) {
        state.securePay = true;
      }
    });
  },
});

export default betaUser.reducer;
