/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { combineReducers } from "redux";
import {
  GET_CALLDATA_SUCCESS,
  GET_CALLDATA_SUCCESS_NO_LOAN,
} from "../actions/apisActions";
import banner from "../slices/loanInfo/banner";
import borrower from "../slices/loanInfo/borrower";
import contact from "../slices/loanInfo/contact";
import address from "../slices/loanInfo/address";
import messagingAndStatus from "../slices/loanInfo/messagingAndStatus";
import other from "../slices/loanInfo/other"


export const tickerAnnouncements = (state = {}, action: any) => {
  const { type, payload } = action;

  if (type === GET_CALLDATA_SUCCESS || type === GET_CALLDATA_SUCCESS_NO_LOAN) {
    const array = [];
    let count = 0;
    const ticker = [];
    const a1t = payload.data.variables["1203"];
    const a1u = payload.data.variables["1205"];
    const a2t = payload.data.variables["1206"];
    const a2u = payload.data.variables["1209"];
    const a3t = payload.data.variables["1212"];
    const a3u = payload.data.variables["1210"];
    const a4t = payload.data.variables["1214"];
    const a4u = payload.data.variables["1211"];
    array.push({ text: a1t, url: a1u });
    array.push({ text: a2t, url: a2u });
    array.push({ text: a3t, url: a3u });
    array.push({ text: a4t, url: a4u });
    for (let i = 0; i < array.length; i++) {
      if (array[i].text !== "null") {
        count = count + 1;
        ticker.push(array[i]);
        // announcements.characterCount = array[i].length;
      }
    }
    return { ...state, count: count, ticker: ticker };
  } else {
    return state;
  }
};
export const displayInfo = combineReducers({
  banner,
  borrower,
  contact,
  address,
  messagingAndStatus,
  other,
  tickerAnnouncements,
});


// const banner = (state = {}, action: any) => {
//   const { type, payload } = action;

//   let staleData = false;
//   let bannerHeader;
//   let bannerText;
//   switch (type) {
//     case GET_CALLDATA_SUCCESS:
//       try {
//         const number = Number(payload.data.variables["1351"]);
//         const dataLoadDate = new Date(
//           payload.data.activeContact.fields["1224"]
//         );
//         const da4 = new Date();
//         da4.setDate(da4.getDate() - number);
//         //debugLog("4 days ago ", da4);
//         if (dataLoadDate <= da4) {
//           staleData = true;
//         } else {
//           staleData = false;
//         }
//       } catch (e) {
//         //console.error(e);
//       }

//       switch (payload.data.variables["44"]) {
//         case "Inbound":
//           bannerHeader = "Inbound Path Selected";

//           if (payload.data.variables["801"] === undefined) {
//             bannerText = "Inbound Path Undefined";
//           } else {
//             bannerText = payload.data.variables["801"];
//           }
//           return {
//             bannerText: bannerText,
//             bannerHeader: bannerHeader,
//             staleData: staleData,
//           };

//         case "Visual":
//           //VISUAL QUEUE CALLBACKS
//           bannerHeader = "Inbound Path Selected";

//           if (payload.data.variables["801"] === undefined) {
//             bannerText = "Inbound Path Undefined";
//           } else {
//             bannerText = payload.data.variables["801"];
//           }
//           return {
//             bannerText: bannerText,
//             bannerHeader: bannerHeader,
//             staleData: staleData,
//           };

//         case "Manual":
//           //MANUAL
//           bannerHeader = "Five9 Campaign Name";
//           bannerText = payload.data.variables["46"];
//           if (payload.data.variables["1054"] === "1") {
//             return {
//               bannerText: bannerText,
//               bannerHeader: bannerHeader,
//               staleData: staleData,
//             };
//           } else {
//             return {
//               bannerText: bannerText,
//               bannerHeader: bannerHeader,
//               staleData: staleData,
//             };
//           }

//         default:
//           //OUTBOUND
//           //TODO - Find Callbacks and Use Message 1261
//           if (
//             payload.data.activeContact.fields["1210"] === undefined ||
//             payload.data.activeContact.fields["1210"] === null
//           ) {
//             bannerHeader = "Five9 Campaign Name";
//             bannerText = payload.data.variables["46"];
//           } else {
//             bannerHeader = "OB Campaign Name";
//             bannerText = payload.data.activeContact.fields["1210"];
//           }

//           return {
//             ...state,
//             bannerText: bannerText,
//             bannerHeader: bannerHeader,
//             staleData: staleData,
//           };
//       }
//     default:
//       return state;
//   }
// };
// const borrower = (state = {}, action: any) => {
//   const { type, payload } = action;

//   if (type === GET_CALLDATA_SUCCESS) {
//     const bname = payload.data.activeContact.fields["1056"];
//     const cname = payload.data.activeContact.fields["1217"];
//     let concat;
//     const array = [];
//     array.push({
//       key: "borrowerFullName",
//       label: "Borrower:",
//       value: bname,
//       cols: 6,
//     });
//     array.push({
//       key: "corrowerFullName",
//       label: "Coborrower:",
//       value: cname,
//       cols: 6,
//     });

//     if (cname === undefined) {
//       concat = bname;
//     } else {
//       concat = `${bname}/${cname}`;
//     }

//     return {
//       borrowerFullName: bname,
//       corrowerFullName: cname,
//       concat: concat,
//       display: array,
//     };
//   } else {
//     return state;
//   }
// };


// const contact = (state = {}, action: any) => {
//   const { type, payload } = action;

//   if (type === GET_CALLDATA_SUCCESS) {
//     const array = [];
//     array.push({
//       key: "number1",
//       label: "Primary:",
//       value: payload.data.activeContact.fields["1310"],
//       cols: 4,
//     });
//     array.push({
//       key: "number2",
//       label: "Alternative #1:",
//       value: payload.data.activeContact.fields["1311"],
//       cols: 4,
//     });
//     array.push({
//       key: "number3",
//       label: "Alternative #2:",
//       value: payload.data.activeContact.fields["1312"],
//       cols: 4,
//     });
//     array.push({
//       key: "borrowerEmail",
//       label: "Borrower Email:",
//       value: payload.data.activeContact.fields["1142"],
//       cols: 12,
//     });
//     array.push({
//       key: "coborrowerEmail",
//       label: "Coborrower Email:",
//       value: payload.data.activeContact.fields["1145"],
//       cols: 12,
//     });

//     return {
//       number1: payload.data.activeContact.fields["1310"],
//       number2: payload.data.activeContact.fields["1311"],
//       number3: payload.data.activeContact.fields["1312"],
//       number1Code: payload.data.activeContact.fields["1251"],
//       number2Code: payload.data.activeContact.fields["1252"],
//       number3Code: payload.data.activeContact.fields["1253"],
//       borrowerEmail: payload.data.activeContact.fields["1142"],
//       coborrowerEmail: payload.data.activeContact.fields["1145"],
//       display: array,
//     };
//   } else {
//     return state;
//   }
// };

// const address = (state = {}, action: any) => {
//   const { type, payload } = action;

//   if (type === GET_CALLDATA_SUCCESS) {
//     const parray = [];
//     const marray = [];
//     parray.push({
//       key: "propertyAddress",
//       label: "Property Address:",
//       value: payload.data.activeContact.fields["1079"],
//       cols: 12,
//     });
//     parray.push({
//       key: "propertyCity",
//       label: "City:",
//       value: payload.data.activeContact.fields["1080"],
//       cols: 6,
//     });
//     parray.push({
//       key: "propertyState",
//       label: "State:",
//       value: payload.data.activeContact.fields["1081"],
//       cols: 3,
//     });
//     parray.push({
//       key: "propertyZip",
//       label: "Zip:",
//       value: payload.data.activeContact.fields["1082"],
//       cols: 3,
//     });

//     marray.push({
//       key: "mailingAddress",
//       label: "Mailing Address:",
//       value: payload.data.activeContact.fields["1155"],
//       cols: 12,
//     });
//     marray.push({
//       key: "mailingCity",
//       label: "City:",
//       value: payload.data.activeContact.fields["1154"],
//       cols: 6,
//     });
//     marray.push({
//       key: "mailingState",
//       label: "State:",
//       value: payload.data.activeContact.fields["1156"],
//       cols: 3,
//     });
//     marray.push({
//       key: "mailingZip",
//       label: "Zip:",
//       value: payload.data.activeContact.fields["1157"],
//       cols: 3,
//     });
//     return {

//       propertyDisplay: parray,
//       mailingDisplay: marray,
//     };
//   } else {
//     return state;
//   }
// };


// const messagingAndStatus = (state = {}, action: any) => {
//   const { type, payload } = action;

//   if (type === GET_CALLDATA_SUCCESS) {
//     const array = [];
//     let newCustomerVIVR = "N"
//     const dateAdded = new Date(payload.data.activeContact.fields["1362"]);
//     const triggerDate = new Date("2022-09-01");

//     if (dateAdded > triggerDate) {
//       newCustomerVIVR = "Y"
//     }

//     array.push({
//       key: "obCampaignName",
//       label: "OB Campaign Name:",
//       value: payload.data.activeContact.fields["1210"],
//       cols: "1",
//     });
//     array.push({
//       key: "assignedConcierge",
//       label: "Delinquency Bucket:",
//       value: payload.data.activeContact.fields["1424"],
//       cols: "2",
//     });
//     array.push({
//       key: "loanStatus",
//       label: "Loan Status:",
//       value: payload.data.activeContact.fields["1041"],
//       cols: "2",
//     });
//     array.push({
//       key: "miniMiranda",
//       label: "Mini Miranda:",
//       value: payload.data.activeContact.fields["1158"],
//       cols: "3",
//     });
//     array.push({
//       key: "isBankruptcy",
//       label: "Bankruptcy:",
//       value: payload.data.activeContact.fields["1036"],
//       cols: "3",
//     });

//     array.push({
//       key: "welcomeVIVR",
//       label: "Welcome Experience:",
//       value: newCustomerVIVR,
//       cols: "3",
//     });


//     return {
//       obCampaignName: payload.data.activeContact.fields["1210"],
//       assignedConcierge: payload.data.activeContact.fields["1424"],
//       loanStatus: payload.data.activeContact.fields["1041"],
//       miniMiranda: payload.data.activeContact.fields["1158"],
//       isBankruptcy: payload.data.activeContact.fields["1036"],
//       display: array,
//     };
//   } else {
//     return state;
//   }
// };
// const other = (state = {}, action: any) => {
//   const { type, payload } = action;

//   if (type === GET_CALLDATA_SUCCESS) {
//     const array = [];
//     array.push({
//       key: "investorName",
//       label: "Investor Name:",
//       value: payload.data.activeContact.fields["1150"],
//       cols: "1",
//     });
//     array.push({
//       key: "escrowedIndicator",
//       label: "Escrowed:",
//       value: payload.data.activeContact.fields["1058"],
//       cols: "2",
//     });
//     array.push({
//       key: "nextPaymentDueDate",
//       label: "Next Due Date:",
//       value: payload.data.activeContact.fields["1230"],
//       cols: "2",
//     });
//     array.push({
//       key: "totalMonthlyPayment",
//       label: "Total Monthly Payment:",
//       value: payload.data.activeContact.fields["1201"],
//       cols: "2",
//     });
//     array.push({
//       key: "upb",
//       label: "Unpaid Principle Bal:",
//       value: payload.data.activeContact.fields["1195"],
//       cols: "2",
//     });
//     array.push({
//       key: "servicingAcqDate",
//       label: "Acq. Date/Anniversary:",
//       value: payload.data.activeContact.fields["1231"],
//       cols: "2",
//     });
//     array.push({
//       key: "loanMaturesDate",
//       label: "Loan Maturity Date:",
//       value: payload.data.activeContact.fields["1228"],
//       cols: "2",
//     });
//     array.push({
//       key: "lastFullPaymentDate",
//       label: "Last Payment Date:",
//       value: payload.data.activeContact.fields["1225"],
//       cols: "2",
//     });
//     array.push({
//       key: "recastMilestone",
//       label: "Recast Milestone:",
//       value: payload.data.activeContact.fields["1370"],
//       cols: "2",
//     });
//     array.push({
//       key: "lmMileStone",
//       label: "LM Milestone:",
//       value: payload.data.activeContact.fields["1292"],
//       cols: "2",
//     });
//     array.push({
//       key: "dealType",
//       label: "Deal Type:",
//       value: payload.data.activeContact.fields["1351"],
//       cols: "2",
//     });

//     return {
//       investorName: payload.data.activeContact.fields["1210"],
//       escrowedIndicator: payload.data.activeContact.fields["1058"],
//       nextPaymentDueDate: payload.data.activeContact.fields["1230"],
//       totalMonthlyPayment: payload.data.activeContact.fields["1201"],
//       upb: payload.data.activeContact.fields["1195"],
//       servicingAcqDate: payload.data.activeContact.fields["1231"],
//       loanMaturesDate: payload.data.activeContact.fields["1228"],
//       lastFullPaymentDate: payload.data.activeContact.fields["1225"],
//       recastMilestone: payload.data.activeContact.fields["1370"],
//       lmMileStone: payload.data.activeContact.fields["1292"],
//       dealType: payload.data.activeContact.fields["1351"],
//       display: array,
//     };
//   } else {
//     return state;
//   }
// };