/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppSelector } from "../../app/hooks";
import { Card, Row } from "react-bootstrap";

import InfoRowWithCopy from "./InfoRowWithCopy";

const LoanCard = () => {
  const callIds: any = useAppSelector((state) => state.main.callIds);
  return (
    <Card>
      <Card.Body>
        <h5>Loan Number</h5>
        <Row>
          <InfoRowWithCopy group="contact" array={callIds.display} />
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LoanCard;
