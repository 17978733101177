/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from "react";
import moment from "moment";
import { useAppDispatch } from "../../../../app/hooks";
import { Form, InputGroup } from "react-bootstrap";
import { updateDCCQuestion } from "../DCCConsultationQRPCSlice";

const DCCDatesInput = ({ dccConsultationQRPC }: any) => {
  const dispatch = useAppDispatch();
  const today = moment().format("YYYY-MM-DD");

  const beginDateOnChange = (e: any) => {
    dispatch(
      updateDCCQuestion({ name: "hardshipBegin", value: e.target.value })
    );
  };
  const endDateOnChange = (e: any) => {
    dispatch(updateDCCQuestion({ name: "hardshipEnd", value: e.target.value }));
  };

  if (dccConsultationQRPC.questions.hardshipResolved === "No") {
    return (
      <div id="dcc_form_hardshipBegin">
        <div>
          <b>When did your hardship begin?</b>
        </div>
        <div>
        <Form.Control
            size="sm"
            type="date"
            max={today}
            onChange={beginDateOnChange}
            value={dccConsultationQRPC.questions.hardshipBegin}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div id="dcc_form_hardshipBeginEnd">
        <div>
          <b>When did your hardship begin and end?</b>
        </div>
        <InputGroup>
          <Form.Control
            size="sm"
            type="date"
            max={today}
            onChange={beginDateOnChange}
            value={dccConsultationQRPC.questions.hardshipBegin}
          />
          <InputGroup.Text> - </InputGroup.Text>
          <Form.Control
            size="sm"
            type="date"
            onChange={endDateOnChange}
            value={dccConsultationQRPC.questions.hardshipEnd}
          />
        </InputGroup>
      </div>
    );
  }
};

export default DCCDatesInput;
