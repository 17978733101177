
import { createAsyncThunk } from "@reduxjs/toolkit";
import { consoleLogIt, consoleErrorIt, reportError } from "../log";
import { errorNotification } from "../slices/features/notificationsSlice";

export const getAzureTokenApi = createAsyncThunk(
  "azureTokenApi/get",
  async (callIds,{ dispatch, rejectWithValue }) => {
    try {
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `https://five9-webservice-prod.azurewebsites.net/api/v1/Token?clientId=maOPQ89eAvN3X91Pt5%2FfTZlN%2Bfx2qyoA08gU0wtBlK2cdKBd0C%2B2Lsktsi49JVbB&clientSecret=HX0ArFO6XiZ9jhwaqiYnPwmPAIma5pOI2d3ucXOeRW%2BBXQlKMk6kovFL6Pyqlm0u`,
        {
          method: "Get",
        }
      );

      // const data = await response.json();
      const code = await response.ok;
      if (code === true) {
        consoleLogIt("Azure Token Retrieved", response);
        return response.json();
      } else {
        consoleErrorIt("Failed to Azure Get Token", response);
        dispatch(
          reportError({
            title: "Failed to Azure Get Token",
            text: "Future state - Body Example",
          })
        );


        return rejectWithValue(response);
      }
    } catch (e) {
      consoleErrorIt("Failed to Azure Get Token", e);
      reportError({
        title: "Failed to Azure Get Token",
        text: "Complete Failure - Catch e",
      });
      return rejectWithValue(e);
    }
  }
);