/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { combineReducers } from "redux";

import { GET_CALLDATA_SUCCESS } from "../actions/apisActions";

import {
  UPDATE_WORKSHEET_VERIFY_SUCCESS,
  TRIGGER_BUILD_AUTH_ARRAY,
  UPDATE_AUTH_ARRAY,
  COMPLETE_STANDARD_WORKSHEET,
  
} from "../actions/worksheetActions";
import incentiveOptions from "../slices/callWorksheet/incentiveOptions";
import disclosures from "../slices/callWorksheet/disclosures"
import outcome from "../slices/callWorksheet/outcome";

const authData = (state = {}, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CALLDATA_SUCCESS:
      if (
        payload.data.callType === "QUEUE_CALLBACK" &&
        payload.data.variables["1054"] !== "1"
      ) {
        return {
          ...state,
          method: null,
          verify: false,
          ani: false,
          loanNumber: false,
          l4ssn: false,
          fullSsn: false,
          zip: false,
          birthDate: false,
          originalUpb: false,
          interestRate: false,
          payAmount: false,
          authorizedCell: false,
        };
      } else {
        const data = JSON.parse(payload.data.variables["1074"]);
        return {
          ...state,
          method: data.method,
          verify: data.verify,
          ani: data.ani,
          loanNumber: data.loanNumber,
          l4ssn: data.l4ssn,
          fullSsn: data.fullSsn,
          zip: data.zip,
          birthDate: data.birthDate,
          originalUpb: data.originalUpb,
          interestRate: data.interestRate,
          payAmount: data.payAmount,
          authorizedCell: data.authorizedCell,
        };
      }
    case UPDATE_WORKSHEET_VERIFY_SUCCESS:
      const data = JSON.parse(payload[1074]);
      return {
        ...state,
        method: data.method,
        verify: data.verify,
        ani: data.ani,
        loanNumber: data.loanNumber,
        l4ssn: data.l4ssn,
        fullSsn: data.fullSsn,
        zip: data.zip,
        birthDate: data.birthDate,
        originalUpb: data.originalUpb,
        interestRate: data.interestRate,
        payAmount: data.payAmount,
        authorizedCell: data.authorizedCell,
      };
    default:
      return state;
  }
};

// Unauthenticated Worksheet States
const authArray = (state = [], action: any) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CALLDATA_SUCCESS:
      const data = JSON.parse(payload.data.variables["1074"]);
      const array = [];
      array.push({
        id: "authorizedCell",
        value: data.authorizedCell,
        text: "Authorized Cell Phone",
      });
      array.push({
        id: "loanNumber",
        value: data.loanNumber,
        text: "Loan Number",
      });
      array.push({ id: "l4ssn", value: payload.l4ssn, text: "Last 4 SSN" });
      array.push({
        id: "birthDate",
        value: data.birthDate,
        text: "Birth Date",
      });
      array.push({
        id: "originalUpb",
        value: data.originalUpb,
        text: "Original UPB",
      });
      array.push({
        id: "interestRate",
        value: data.interestRate,
        text: "Interest Rate",
      });
      array.push({
        id: "payAmount",
        value: data.payAmount,
        text: "Payment Amount",
      });
      return array;
    case UPDATE_AUTH_ARRAY:
      return state.map((item: any) => {
        if (item.id === payload.update.id) {
          return { ...item, value: payload.update.value };
        }
        return item;
      });
    default:
      return state;
  }
};

const authCount = (state = {}, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case TRIGGER_BUILD_AUTH_ARRAY:
      const array = [];
      let count = 0;
      array.push({
        id: "authorizedCell",
        value: payload.authorizedCell,
        text: "Authorized Cell Phone",
      });
      array.push({
        id: "loanNumber",
        value: payload.loanNumber,
        text: "Loan Number",
      });
      array.push({ id: "l4ssn", value: payload.l4ssn, text: "Last 4 SSN" });
      array.push({
        id: "birthDate",
        value: payload.birthDate,
        text: "Birth Date",
      });
      array.push({
        id: "originalUpb",
        value: payload.originalUpb,
        text: "Original UPB",
      });
      array.push({
        id: "interestRate",
        value: payload.interestRate,
        text: "Interest Rate",
      });
      array.push({
        id: "payAmount",
        value: payload.payAmount,
        text: "Payment Amount",
      });
      array.map((item) => {
        if (item.value === true) {
          count = count + 1;
        }
      });

      return count;
    case UPDATE_AUTH_ARRAY:
      let count2 = 0;

      //console.log("Payload");
      payload.array.map((item: any) => {
        if (item.id === payload.update.id) {
          if (payload.update.value === true) {
            count2 = count2 + 1;
          }
        } else {
          if (item.value === true) {
            count2 = count2 + 1;
          }
        }
      });

      return count2;
    default:
      return state;
  }
};

const standardComplete = (state = false, action: any) => {
  const { type } = action;

  switch (type) {
    case COMPLETE_STANDARD_WORKSHEET:
      return true;

    default:
      return state;
  }
};

export const callWorksheets = combineReducers({
  incentiveOptions,
  authData,
  disclosures,
  authArray,
  authCount,
  outcome,
  standardComplete,
});


// old Code
// const discData = (state = {}, action: any) => {
// //   const { type, payload } = action;
//   switch (type) {
//     case GET_CALLDATA_SUCCESS:
//       if (
//         payload.data.callType === "QUEUE_CALLBACK" &&
//         payload.data.variables["1054"] !== "1"
//       ) {
//         // clear disclosures on Queue Callbacks
//         const data = JSON.parse(payload.data.variables["1075"]);
//         return {
//           ...state,
//           bk: "null",
//           dc7: "null",
//           mm: "null",
//           cell: data.cell,
//           cr: "false",
//           ca5: "null",
//           ca30: "null",
//         };
//       } else {
//         const data = JSON.parse(payload.data.variables["1075"]);

//         return {
//           ...state,
//           bk: data.bk,
//           dc7: data.dc7,
//           mm: data.mm,
//           cell: data.cell,
//           cr: data.cr,
//           ca5: data.ca5,
//           ca30: data.ca30,
//         };
//       }
//     case UPDATE_WORKSHEET_DISCLOSURES_SUCCESS:
//       const data = JSON.parse(payload[1075]);

//       return {
//         ...state,
//         bk: data.bk,
//         dc7: data.dc7,
//         mm: data.mm,
//         cell: data.cell,
//         cr: data.cr,
//         ca5: data.ca5,
//         ca30: data.ca30,
//       };

//     default:
//       return state;
//   }
// };
// const discArray = (state = [], action: any) => {
//   const { type, payload } = action;
//   let callType: string;
//   let discVar: any;
//   const array = [];
//   // miranda warning variables
//   let isBankruptcy: string;
//   let miniMiranda: string;
//   let chapter7: string;
//   // CA variables
//   let propState: string;
//   let lang: string;
//   let daysPastDue: number;
//   const dt = new Date();
//   const day = dt.getDate();
//   let currentLmM: string;
//   // cell authorization variables
//   let ani: string;
//   let dnis: string;
//   const phoneArray = [];
//   let data: any;
//   switch (type) {
//     case GET_CALLDATA_SUCCESS:
//       //set main Vars
//       callType = payload.data.callType;
//       discVar = JSON.parse(payload.data.variables["1075"]);

//       // miranda warning variables
//       isBankruptcy = payload.data.activeContact.fields["1036"];
//       miniMiranda = payload.data.activeContact.fields["1158"];
//       chapter7 = payload.data.activeContact.fields["1171"];
//       // CA variables
//       propState = payload.data.activeContact.fields["1081"];
//       lang = payload.data.activeContact.fields["1151"];

//       if (payload.data.activeContact.fields["1060"] === undefined) {
//         daysPastDue = 0;
//       } else {
//         daysPastDue = Number(payload.data.activeContact.fields["1060"]);
//       }

//       currentLmM = payload.data.activeContact.fields["1350"];
//       // cell authorization variables

//       phoneArray.push({
//         num: payload.data.activeContact.fields["1310"],
//         code: payload.data.activeContact.fields["1251"],
//       });
//       phoneArray.push({
//         num: payload.data.activeContact.fields["1311"],
//         code: payload.data.activeContact.fields["1252"],
//       });
//       phoneArray.push({
//         num: payload.data.activeContact.fields["1312"],
//         code: payload.data.activeContact.fields["1253"],
//       });
//       ani = payload.data.ani;
//       dnis = payload.data.dnis;
//       // call recording
//       if (
//         discVar.cr === "agent" ||
//         (discVar.cr === "ivr" && callType !== "QUEUE_CALLBACK")
//       ) {
//         array.push({
//           id: "cr",
//           value: discVar.cr,
//           isRequired: true,
//           text: "Call Recording",
//         });
//       } else {
//         array.push({
//           id: "cr",
//           value: "false",
//           isRequired: true,
//           text: "Call Recording",
//         });
//       }

//       // miranda warning waterfall
//       if (isBankruptcy === "Y") {
//         console.log("bk");
//         if (
//           discVar.bk === "agent" ||
//           (discVar.bk === "ivr" && callType !== "QUEUE_CALLBACK")
//         ) {
//           console.log("bk push");
//           array.push({
//             id: "bk",
//             value: discVar.bk,
//             isRequired: true,
//             text: "Active Bankruptcy",
//           });
//         } else {
//           array.push({
//             id: "bk",
//             value: "false",
//             isRequired: true,
//             text: "Active Bankruptcy",
//           });
//         }
//       } else if (chapter7 === "Y") {
//         if (
//           discVar.dc7 === "agent" ||
//           (discVar.dc7 === "ivr" && callType !== "QUEUE_CALLBACK")
//         ) {
//           array.push({
//             id: "dc7",
//             value: discVar.dc7,
//             isRequired: true,
//             text: "Discharged Bankruptcy 7",
//           });
//         } else {
//           array.push({
//             id: "dc7",
//             value: "false",
//             isRequired: true,
//             text: "Discharged Bankruptcy 7",
//           });
//         }
//       } else if (miniMiranda === "Y") {
//         if (
//           discVar.mm === "agent" ||
//           (discVar.mm === "ivr" && callType !== "QUEUE_CALLBACK")
//         ) {
//           array.push({
//             id: "mm",
//             value: discVar.mm,
//             isRequired: true,
//             text: "Mini Miranda",
//           });
//         } else {
//           array.push({
//             id: "mm",
//             value: "false",
//             isRequired: true,
//             text: "Mini Miranda",
//           });
//         }
//       }
//       // California 5 Languages

//       if (
//         propState === "CA" &&
//         (lang === "SP" ||
//           lang === "MC" ||
//           lang === "KO" ||
//           lang === "TA" ||
//           lang === "VT") &&
//         currentLmM === "DocsSentToBorrower"
//       ) {
//         if (
//           discVar.ca5 === "agent" ||
//           (discVar.ca5 === "ivr" && callType !== "QUEUE_CALLBACK")
//         ) {
//           array.push({
//             id: "ca5",
//             value: discVar.ca5,
//             isRequired: true,
//             text: "California 5 Languages",
//           });
//         } else {
//           array.push({
//             id: "ca5",
//             value: "false",
//             isRequired: true,
//             text: "California 5 Languages",
//           });
//         }
//       }
//       // ca, nv, wa 30 days delinquent
//       if (
//         (propState === "CA" || propState === "NV" || propState === "WA") &&
//         daysPastDue <= 45 &&
//         daysPastDue >= 30 &&
//         day < 15
//       ) {
//         if (
//           discVar.ca30 === "agent" ||
//           (discVar.ca30 === "ivr" && callType !== "QUEUE_CALLBACK")
//         ) {
//           array.push({
//             id: "ca30",
//             value: discVar.ca30,
//             isRequired: true,
//             text: "CA, NV, WA 30+ Delinquent Loans",
//           });
//         } else {
//           array.push({
//             id: "ca30",
//             value: "false",
//             isRequired: true,
//             text: "CA, NV, WA 30+ Delinquent Loans",
//           });
//         }
//       }

//       //Cell Authorization

//       phoneArray.map((item) => {
//         if ((item.num === ani || item.num === dnis) && item.code === "U") {
//           if (
//             discVar.cell === "agent" ||
//             (discVar.cell === "ivr" && callType !== "QUEUE_CALLBACK")
//           ) {
//             array.push({
//               id: "cell",
//               value: discVar.cell,
//               isRequired: true,
//               text: "Cell Authorization",
//             });
//           } else {
//             array.push({
//               id: "cell",
//               value: 'false',
//               isRequired: true,
//               text: "Cell Authorization",
//             });
//           }
//         }
//       });

//       return array;

//     case UPDATE_WORKSHEET_DISCLOSURES_SUCCESS:
//       data = JSON.parse(payload[1075]);

//       return state.map((item: any) => {
//         return { ...item, value: data[item.id] };
//       });
//     default:
//       return state;
//   }
// };

// incentive worksheets scripts

// const incentiveOptions = (state = {}, action: any) => {
//   const { type, payload } = action;

//   switch (type) {
//     case GET_CALLDATA_SUCCESS:
//       const data = JSON.parse(payload.data.variables["1248"]);
//       const freedomLoan = payload.data.activeContact.fields["1380"];
//       let mortgage;
//       let other;
//       if (
//         freedomLoan === "C" ||
//         freedomLoan === "T" ||
//         freedomLoan === "G" ||
//         freedomLoan === "A"
//       ) {
//         mortgage = "F Refi";
//       } else if (freedomLoan === "O") {
//         mortgage = "F CO";
//       } else if (freedomLoan === "R") {
//         mortgage = "Reverse";
//       } else {
//         mortgage = "null";
//       }

//       if (payload.data.activeContact.fields["1162"] === "Y") {
//         other = "M HOI";
//       } else {
//         other = "null";
//       }
//       return {
//         ...state,
//         refiWS: mortgage,
//         refiO: data.refiO,
//         refiR: data.refiR,
//         othWS: other,
//         othO: data.othO,
//         othR: data.othR,
//       };
//     case UPDATE_INCENTIVES_VARIABLE_SUCCESS:
//       const newData = JSON.parse(payload[1248]);

//       return {
//         ...state,
//         refiWS: newData.refiWS,
//         refiO: newData.refiO,
//         refiR: newData.refiR,
//         othWS: newData.othWS,
//         othO: newData.othO,
//         othR: newData.othR,
//       };
//     default:
//       return state;
//   }
// };

// Standard worksheet states

// export const skipTrace = (state = {}, action: any) => {
//   const { type, payload } = action;
//   switch (type) {
//     case GET_CALLDATA_SUCCESS:
//       const storage1 = JSON.parse(payload.data.variables["1261"]);
//       const campId = payload.data.campaignId;
//       const skipCampaigns = [
//         "1138766",
//         "1138767",
//         "1138873",
//         "1138875",
//         "1138953",
//         "1138954",
//         "1140066",
//         "1140254",
//         "1140259",
//         "1140579",
//         "1140478",
//         "1140633",
//         "1140632",
//         "1140634",
//       ];

//       if (storage1.skipTrace !== true) {
//         if (skipCampaigns.indexOf(campId)) {
//           storage1.skipTrace = true;
//         }
//       }

//       return {
//         skipTrace: storage1.skipTrace,
//         skipTraceResult: storage1.skipTraceResult,
//         skipBadPhone: storage1.skipBadPhone,
//       };
//     case UPDATE_SKIP_TRACE_VARIABLE_SUCCESS:
//       //console.log(payload);
//       const newStorage1 = JSON.parse(payload[1261]);
//       return {
//         skipTrace: newStorage1.skipTrace,
//         skipTraceResult: newStorage1.skipTraceResult,
//         skipBadPhone: newStorage1.skipBadPhone,
//       };
//     case INITIATE_SKIP_TRACE_FORM:
//       return {
//         skipTrace: true,
//         skipTraceResult: null,
//         skipBadPhone: null,
//       };

//     default:
//       return state;
//   }
// };


// const outcomeArray = (state = [], action: any) => {
//   const { type, payload } = action;
//   switch (type) {
//     case GET_CALLDATA_SUCCESS:
//       const array = [];

//       const data = JSON.parse(payload.data.variables["1079"]);
//       const moodArray = [
//         { id: "mood1", name: "mood", text: "Caller Mood:", value: "null" },
//         { id: "mood2", name: "mood", text: "Happy", value: "Happy" },
//         { id: "mood3", name: "mood", text: "Neutral", value: "Neutral" },
//         { id: "mood4", name: "mood", text: "Mad", value: "Mad" },
//       ];
//       const abusiveArray = [
//         {
//           id: "abusive1",
//           name: "abusive",
//           text: "Abusive Caller:",
//           value: "null",
//         },
//         { id: "abusive2", name: "abusive", text: "Yes", value: "Yes" },
//         { id: "abusive3", name: "abusive", text: "No", value: "No" },
//       ];
//       const escalationArray = [
//         {
//           id: "escalation1",
//           name: "escalation",
//           text: "Request Escalation:",
//           value: "null",
//         },
//         { id: "escalation2", name: "escalation", text: "Yes", value: "Yes" },
//         { id: "escalation3", name: "escalation", text: "No", value: "No" },
//       ];
//       const resolutionArray = [
//         {
//           id: "resolution1",
//           name: "resolution",
//           text: "Resolved:",
//           value: "null",
//         },
//         { id: "resolution2", name: "resolution", text: "Yes", value: "Yes" },
//         { id: "resolution3", name: "resolution", text: "No", value: "No" },
//       ];
//       const complaintArray = [
//         {
//           id: "complaint1",
//           name: "complaint",
//           text: "Verbal Complaint:",
//           value: "null",
//         },
//         { id: "complaint2", name: "complaint", text: "Yes", value: "Yes" },
//         { id: "complaint3", name: "complaint", text: "No", value: "No" },
//       ];

//       array.push({ id: "mood", value: data.mood, array: moodArray });
//       array.push({ id: "abusive", value: data.abusive, array: abusiveArray });
//       array.push({
//         id: "escalation",
//         value: data.escalation,
//         array: escalationArray,
//       });
//       array.push({
//         id: "resolution",
//         value: data.resolution,
//         array: resolutionArray,
//       });
//       array.push({
//         id: "complaint",
//         value: data.complaint,
//         array: complaintArray,
//       });
//       //console.log("Outcome Array", array);

//       return array;

//     case UPDATE_WORKSHEET_OUTCOME_SUCCESS:
//       const newData = JSON.parse(payload[1079]);

//       return state.map((item: any) => {
//         return { ...item, value: newData[item.id] };
//       });
//     default:
//       return state;
//   }
// };

// const outcomeData = (state = {}, action: any) => {
//   const { type, payload } = action;

//   switch (type) {
//     case GET_CALLDATA_SUCCESS:
//       const data = JSON.parse(payload.data.variables["1079"]);

//       return {
//         ...state,
//         mood: data.mood,
//         abusive: data.abusive,
//         escalation: data.escalation,
//         resolution: data.resolution,
//         complaint: data.complaint,
//       };
//     case UPDATE_WORKSHEET_OUTCOME_SUCCESS:
//       const newData = JSON.parse(payload[1079]);

//       return {
//         ...state,
//         mood: newData.mood,
//         abusive: newData.abusive,
//         escalation: newData.escalation,
//         resolution: newData.resolution,
//         complaint: newData.complaint,
//       };
//     default:
//       return state;
//   }
// };

