/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React from "react";
import FooterTickerLink from "./FooterTickerLink";

const FooterTicker = ({ announcements }: any) => (
  <div className="ticker">
    {announcements.ticker.map((tick: any, i: number) => (
      <div key={i} className="ticker_item">
        <span>{tick.text}</span>
        <FooterTickerLink url={tick.url} />
      </div>
    ))}
    {announcements.ticker.map((tick: any, i: number) => (
      <div key={i} className="ticker_item">
        <span>{tick.text}</span>
        <FooterTickerLink url={tick.url} />
      </div>
    ))}
    {announcements.ticker.map((tick: any, i: number) => (
      <div key={i} className="ticker_item">
        <span>{tick.text}</span>
        <FooterTickerLink url={tick.url} />
      </div>
    ))}
  </div>
);

export default FooterTicker;
