/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  initiateTransferInProgress,
  initiateTransferSuccess,
  initiateTransferFailed,
  cancelTransferInProgress,
  cancelTransferSuccess,
  cancelTransferFailed,
  updateTransferFlagInProgress,
  updateTransferFlagSuccess,
  updateTransferFlagFailed,
  completeTransferApiInProgress,
  completeTransferApiSuccess,
  completeTransferApiFailed,
  initiateConferenceInProgress,
  initiateConferenceSuccess,
  initiateConferenceFailed,
  cancelConferenceFailed,
  conferenceParticipantRemovedFailed,
  joinConferenceApiFailed,
  leaveConferenceApiInProgress,
  leaveConferenceApiSuccess,
  leaveConferenceApiFailed,
} from "../actions/apisActions";

import {
  closeTransferAsssitWindow,
  clearTransferObject,
  clearConferenceObject,
  returnPreviousWorksheetTab,
} from "../actions/standardActions";
import { consoleLogIt } from "../log";

// TRANSFER APIS
// **********************************************
export const initiateTransferApi =
  (metadata: any, ids: any, obj: any, warm: any) => async (dispatch: any) => {
    try {
      dispatch(initiateTransferInProgress(obj));
      const baseUrl = `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}`;
      const context = "/appsvcs/rs/svc";
      const userId = metadata.userId;
      const callId = ids.systemCallId;
      let body: any = { campaignId: obj.transfeId, warm: warm };
      let endpoint = "transfer_to_campaign";
      if (obj.method === "external") {
        endpoint = "transfer_to_external_number";
        body = { warm: warm, destination: { number: obj.transfeId } };
      }
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `${baseUrl}${context}/agents/${userId}/interactions/calls/${callId}/${endpoint}`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      // const data = await response.json();
      const code = await response.ok;

      if (code === true) {
        consoleLogIt('Initiate Transfer Success', obj)
        dispatch(initiateTransferSuccess(obj));
        closeTransferAsssitWindow();
      } else {
        consoleLogIt('Initiate Transfer Failed', obj)
        dispatch(initiateTransferFailed(obj));
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      consoleLogIt('Initiate Transfer Failed', {object: obj, error: e})
      dispatch(initiateTransferFailed(obj));
    }
  };

export const cancelTransferApi =
  (metadata: any, ids: any, obj: any) => async (dispatch: any) => {
    try {
      const baseUrl = `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}`;
      const context = "/appsvcs/rs/svc";
      const userId = metadata.userId;
      const callId = ids.systemCallId;
      dispatch(cancelTransferInProgress(obj));
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `${baseUrl}${context}/agents/${userId}/interactions/calls/${callId}/cancel_transfer`,
        {
          method: "Put",
          credentials: "include",
        }
      );
      // const data = await response.json();
      const code = await response.ok;

      if (code === true) {
        // eslint-disable-next-line no-undef
        fetch(
          `${baseUrl}${context}/agents/${userId}/interactions/calls/${callId}/unhold`,
          {
            method: "Put",
            credentials: "include",
          }
        );
        dispatch(cancelTransferSuccess(obj));
        consoleLogIt('Cancel Transfer Success')
      } else {
        dispatch(cancelTransferFailed(obj));
        consoleLogIt('Cancel Transfer Failed')
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      consoleLogIt('Cancel Transfer Failed', e)
      dispatch(cancelTransferFailed(obj));
    }
  };

export const putCompleteTransferApi =
  (metadata: any, ids: any, obj: any) => async (dispatch: any) => {
    try {
      dispatch(completeTransferApiInProgress(obj));
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}/appsvcs/rs/svc/agents/${metadata.userId}/interactions/calls/${ids.systemCallId}/complete_transfer`,
        {
          method: "Put",
          credentials: "include",
        }
      );
      // const data = await response.json();
      const code = await response.ok;

      if (code === true) {
        consoleLogIt('Complete Transfer Success');
        dispatch(completeTransferApiSuccess(obj));
      } else {
        consoleLogIt('Complete Transfer Failed');
        dispatch(completeTransferApiFailed(obj));
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      consoleLogIt('Complete Transfer Failed',e);
      dispatch(completeTransferApiFailed(obj));
    }
  };

// UPDATE TRANSFER VARIABLE APIS
// **********************************************
export const putTransferFlagVariable =
  (metadata: any, ids: any, update: any) => async (dispatch: any) => {
    try {
      dispatch(updateTransferFlagInProgress());
      const body = { 915: update };
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}/appsvcs/rs/svc/agents/${metadata.userId}/interactions/calls/${ids.systemCallId}/variables_2`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data: any = await response.json();
      const code = await response.ok;
      //console.log("Variable Data", data);
      if (code === true) {
        if (data[915] === update) {
          consoleLogIt('Update Transfer Flag Success', body);
          dispatch(updateTransferFlagSuccess(data));
          dispatch(returnPreviousWorksheetTab());
        } else {
          consoleLogIt('Update Transfer Flag Failed');
          dispatch(updateTransferFlagFailed());
        }
      } else {
        consoleLogIt('Update Transfer Flag Failed');
        dispatch(updateTransferFlagFailed());
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      consoleLogIt('Update Transfer Flag Error - Catch E',e);
      dispatch(updateTransferFlagFailed());
    }
  };

//CONFERENCE APIS
// **********************************************
export const initiateConferenceApi =
  (metadata: any, ids: any, obj: any) => async (dispatch: any) => {
    try {
      dispatch(initiateConferenceInProgress(obj));
      const baseUrl = `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}`;
      const context = "/appsvcs/rs/svc";
      const userId = metadata.userId;
      const callId = ids.systemCallId;
      let body: any = { campaignId: obj.transfeId, warm: true };
      let endpoint = "add_campaign_to_conference";
      if (obj.method === "external") {
        endpoint = "add_external_to_conference";
        body = {
          number: obj.transfeId,
          warm: true,
          checkDNC: true,
          includeCallerInfo: true,
        };
      }
      //console.log("body of Conference", body);
      //transfer_to_external_number
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `${baseUrl}${context}/agents/${userId}/interactions/calls/${callId}/${endpoint}`,
        {
          method: "Post",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      // const data = await response.json();
      const code = await response.ok;

      if (code === true) {
        dispatch(initiateConferenceSuccess(obj));
        closeTransferAsssitWindow();
      } else {
        dispatch(initiateConferenceFailed(obj));
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      console.error(e);
      dispatch(initiateConferenceFailed(obj));
    }
  };

export const disconnectConferenceParticipantApi =
  (metadata: any, participant: any) => async (dispatch: any) => {
    try {
      // console.log(participant);
      if (participant.eventId === "5") {
        dispatch(clearTransferObject());
        dispatch(returnPreviousWorksheetTab());
      } else {
        const baseUrl = `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}`;
        const context = "/appsvcs/rs/svc";
        const userId = metadata.userId;
        const callId = participant.id;
        //transfer_to_external_number
        // eslint-disable-next-line no-undef
        const response = await fetch(
          `${baseUrl}${context}/agents/${userId}/interactions/calls/${callId}/disconnectConferenceParticipant`,
          {
            method: "Put",
            credentials: "include",
          }
        );
        // const data = await response.json();
        const code = await response.ok;
        //console.log(response);
        if (code === true) {
          //console.log("Frontline - Successful Participant Removal");

          dispatch(clearTransferObject());
          dispatch(clearConferenceObject());
          dispatch(returnPreviousWorksheetTab());
        } else {
          dispatch(conferenceParticipantRemovedFailed(participant));
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      console.error(e);

      dispatch(conferenceParticipantRemovedFailed(participant));
    }
  };

export const cancelConferenceParticipantApi =
  (metadata: any, participant: any) => async (dispatch: any) => {
    try {
      const baseUrl = `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}`;
      const context = "/appsvcs/rs/svc";
      const userId = metadata.userId;
      const callId = participant.id;
      //transfer_to_external_number
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `${baseUrl}${context}/agents/${userId}/interactions/calls/${callId}/disconnectConferenceParticipant`,
        {
          method: "Put",
          credentials: "include",
        }
      );
      // const data = await response.json();
      const code = await response.ok;

      if (code === true) {
        // dispatch(conferenceParticipantRemovedSuccess(participant));
        // console.log("Frontline - Successful Participant Removal");
      } else {
        dispatch(cancelConferenceFailed(participant));
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      console.error(e);
      dispatch(cancelConferenceFailed(participant));
    }
  };

export const joinConferenceApi =
  (metadata: any, participant: any) => async (dispatch: any) => {
    try {
      // console.log("started join conference");
      const baseUrl = `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}`;
      const context = "/appsvcs/rs/svc";
      const userId = metadata.userId;
      const callId = participant.id;
      //transfer_to_external_number
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `${baseUrl}${context}/agents/${userId}/interactions/calls/${callId}/complete_warm_conference`,
        {
          method: "Post",
          credentials: "include",
        }
      );
      // const data = await response.json();
      const code = await response.ok;

      if (code !== true) {
        dispatch(joinConferenceApiFailed(participant));
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      console.error(e);

      dispatch(joinConferenceApiFailed(participant));
    }
  };

export const leaveConferenceApi =
  (metadata: any, ids: any) => async (dispatch: any) => {
    try {
      dispatch(leaveConferenceApiInProgress());
      const baseUrl = `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}`;
      const context = "/appsvcs/rs/svc";
      const userId = metadata.userId;
      const callId = ids.systemCallId;
      const body = { dispositionId: "1137478" };
      //transfer_to_external_number
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `${baseUrl}${context}/agents/${userId}/interactions/calls/${callId}/leave_conference`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      // const data = await response.json();
      const code = await response.ok;

      if (code === true) {
        dispatch(leaveConferenceApiSuccess());
        dispatch(clearConferenceObject());
        closeTransferAsssitWindow();
      } else {
        dispatch(leaveConferenceApiFailed());
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      console.error(e);
      dispatch(leaveConferenceApiFailed());
    }
  };
