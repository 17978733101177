import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "./app/hooks";
import { updateFive9WorksheetApi } from "./app/thunks/otherThunks";

const Five9WorksheetCheck = () => {
  const dispatch = useAppDispatch();
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const banner: any = useAppSelector((state) => state.displayInfo.banner);
  const worksheetData: any = useAppSelector(
    (state) => state.main.worksheetData
  );
  const appPath: any = useAppSelector((state) => state.main.appPath);
  const incentiveOptions: any = useAppSelector(
    (state) => state.callWorksheets.incentiveOptions
  );
  const [worksheetComplete, setWorksheetComplete] = useState(() => {
    let mortgage = "complete";
    let other = "complete";
    if (
      incentiveOptions.refiWS !== "null" &&
      incentiveOptions.refiO === "null"
    ) {
      mortgage = "incomplete";
    }
    if (incentiveOptions.othWS !== "null" && incentiveOptions.othO === "null") {
      other = "incomplete";
    }
    return { mortgage: mortgage, other: other };
  });

  useEffect(() => {
    if (banner.staleData !== true) {
      let mortgage = "complete";
      let other = "complete";
      if (
        incentiveOptions.refiWS !== "null" &&
        incentiveOptions.refiO === "null"
      ) {
        mortgage = "incomplete";
      }
      if (
        incentiveOptions.othWS !== "null" &&
        incentiveOptions.othO === "null"
      ) {
        other = "incomplete";
      }
      setWorksheetComplete({ mortgage: mortgage, other: other });
    } else {
      setWorksheetComplete({ mortgage: "complete", other: "complete" });
    }
    //console.log(`mortgage: ${mortgage}`, `other: ${other}`)
  }, [incentiveOptions]);
  useEffect(() => {
    if (appPath === "Unauthenticated") {
      dispatch(
        updateFive9WorksheetApi(metadata.data, callIds, worksheetData, true)
      );
    } else {
      if (
        worksheetComplete.mortgage === "complete" &&
        worksheetComplete.other === "complete"
      ) {
        dispatch(
          updateFive9WorksheetApi(metadata.data, callIds, worksheetData, true)
        );
      } else {
        dispatch(
          updateFive9WorksheetApi(metadata.data, callIds, worksheetData, false)
        );
      }
    }
  }, [worksheetComplete, appPath]);

  return <></>;
};

export default Five9WorksheetCheck;
