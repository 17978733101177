/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { triggerBuildAuthArray } from "../../../app/actions/worksheetActions";
import { updateWorksheetTabs } from "../../../app/actions/standardActions";
import { putVariableAuthentication } from "../../../app/thunks/worksheetThunks";
import { Card, Button } from "react-bootstrap";
import DisclosureSwitch from "../DisclosureSwitch";
import AuthVerifySwitch from "../AuthVerifySwitch";
import AuthSwitches from "../AuthSwitches";

const UnauthenticatedWs = () => {
  const dispatch = useAppDispatch();
  // global states
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const discData: any = useAppSelector(
    (state) => state.callWorksheets.disclosures.data
  );

  const authData: any = useAppSelector(
    (state) => state.callWorksheets.authData
  );
  const authArray: any = useAppSelector(
    (state) => state.callWorksheets.authArray
  );
  const skipTrace: any = useAppSelector((state) => state.skipTrace);
  const authCount: any = useAppSelector(
    (state) => state.callWorksheets.authCount
  );


  //console.log(authArray)
  // local states
  const [verify, setVerify] = useState(false);

  const verifySwitchValue = (val: boolean) => {
    //console.log("verify toggle hit");
    setVerify(val);
  };
  const buildDisclosureSwitch = () => {
    //check call recording for completion.
    const callRecording = () => {
      let val;
      if (discData.cr === "null") {
        val = false;
        //TODO Insert update to variable and state
      } else {
        val = discData.cr;
      }
      return { id: "cr", value: val, text: "Call Recording" };
    };

    return <DisclosureSwitch item={callRecording()} />;
  };
  const buildVerifySwitch = () => {
    //check call recording for completion.
    const verify = () => {
      let val;
      if (authData.verify === "null" || authData.verify === false) {
        val = false;
        //TODO Insert update to variable and state
      } else {
        val = authData.verify;
      }
      return {
        id: "verify",
        value: val,
        text: "Authenticate Name and property Address",
      };
    };

    return (
      <AuthVerifySwitch item={verify()} verifySwitchValue={verifySwitchValue} />
    );
  };
  const buildCompleteButton = () => {
    let disabled;
    let color;
    if (authCount > 1) {
      color = "primary";
      disabled = false;
    } else {
      color = "secondary";
      disabled = true;
    }
    return (
      <div className="d-grid gap-2">
        <Button
          variant={color}
          disabled={disabled}
          size="lg"
          onClick={() => {
            dispatch(
              putVariableAuthentication(
                metadata.data,
                authData,
                callIds,
                authArray
              )
            );
            dispatch(updateWorksheetTabs("standard"));
          }}
        >
          Loan Verified!
        </Button>
      </div>
    );
  };

  return (
    <Card>
      <Card.Body className="pt-2 pb-2">
        <h5 >Call Recording Disclosure</h5>
        {buildDisclosureSwitch()}
        <h5 id={`authentication_confirm_customer`}>Confirm Customer</h5>
        {buildVerifySwitch()}
        <h5>Select 2 Additional PII to Authenticate</h5>
        {authArray.map((item: any) => (
          <div key={item.id}>
            <AuthSwitches item={item} verify={verify} />
          </div>
        ))}
      </Card.Body>
      <Card.Footer>
        <p
          style={{
            fontSize: ".8rem",
            color: "red",
            marginBottom: ".2rem",
            textAlign: "center",
          }}
        >
          By selecting this button you are verifying that {callIds.loanNumber}{" "}
          is the correct loan for this call.
        </p>
        {buildCompleteButton()}
      </Card.Footer>
    </Card>
  );
};

export default UnauthenticatedWs;
