/* eslint-disable no-case-declarations */

import { combineReducers } from "redux";
import {
  CREATE_CONFERENCE_OBJECT,
  UPDATE_CONFERENCE_OBJECT_STATE,
  CONFERENCE_PARTICIPANT_REMOVED_SUCCESS,
} from "../actions/apisActions";
import {CLEAR_CONFERENCE_OBJECT} from "../actions/standardActions";

const status = (state = false, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_CONFERENCE_OBJECT:
      return true;

    case CLEAR_CONFERENCE_OBJECT:
      return false;
    default:
      return state;
  }
}


const participant1 = (state: any = { }, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CONFERENCE_OBJECT:
      return payload.one;
    case UPDATE_CONFERENCE_OBJECT_STATE:
      const partId = payload.id;
      const newState = payload.state;
      const newEvent = payload.event;
      const newEventId = payload.eventId;
      if(partId === state.id) {
        return {
          ...state,
          state: newState,
          event: newEvent,
          eventId: newEventId,
        };
      } else {
        return state;
      }


    default:
      return state;
  }
};



const participant2 = (state: any = { }, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CONFERENCE_OBJECT:
      return payload.two;
    case UPDATE_CONFERENCE_OBJECT_STATE:
      const partId = payload.id;
      const newState = payload.state;
      const newEvent = payload.event;
      const newEventId = payload.eventId;
      if(partId === state.id) {
        return {
          ...state,
          state: newState,
          event: newEvent,
          eventId: newEventId,
        };
      } else {
        return state;
      }


    default:
      return state;
  }
};

export const conferenceObject = combineReducers({
  status,
  participant1,
  participant2,
});
