/* eslint-disable @typescript-eslint/explicit-function-return-type */
// **********************************************
// Update disclosruse
// **********************************************
export const UPDATE_WORKSHEET_DISCLOSURES_IN_PROGRESS =
  "UPDATE_WORKSHEET_DISCLOSURES_IN_PROGRESS";
export const updateWorksheetDisclosuresProgress = () => ({
  type: UPDATE_WORKSHEET_DISCLOSURES_IN_PROGRESS,

});

export const UPDATE_WORKSHEET_DISCLOSURES_FAILED =
  "UPDATE_WORKSHEET_DISCLOSURES_FAILED";
export const updateWorksheetDisclosuresFailed = () => ({
  type: UPDATE_WORKSHEET_DISCLOSURES_FAILED,

});

export const UPDATE_WORKSHEET_DISCLOSURES_SUCCESS =
  "UPDATE_WORKSHEET_DISCLOSURES_SUCCESS";
export const updateWorksheetDisclosuresSuccess = (payload: any) => ({
  type: UPDATE_WORKSHEET_DISCLOSURES_SUCCESS,
  payload: payload,
});

// **********************************************
// Update VERIFY NAME AND ADDRESS
// **********************************************
export const CHECK_VERIFY_NAME_AND_ADDRESS =
  "CHECK_VERIFY_NAME_AND_ADDRESS";
export const checkVerifyNameAndAddress = () => ({
  type: CHECK_VERIFY_NAME_AND_ADDRESS,
});

export const UNCHECK_VERIFY_NAME_AND_ADDRESS =
  "UNCHECK_VERIFY_NAME_AND_ADDRESS";
export const uncheckVerifyNameAndAddress = () => ({
  type: UNCHECK_VERIFY_NAME_AND_ADDRESS,
});


// **********************************************
// Update disclosruse
// **********************************************
export const UPDATE_WORKSHEET_VERIFY_IN_PROGRESS =
  "UPDATE_WORKSHEET_VERIFY_IN_PROGRESS";
export const updateWorksheetVerifyProgress = () => ({
  type: UPDATE_WORKSHEET_VERIFY_IN_PROGRESS,

});

export const UPDATE_WORKSHEET_VERIFY_FAILED =
  "UPDATE_WORKSHEET_VERIFY_FAILED";
export const updateWorksheetVerifyFailed = () => ({
  type: UPDATE_WORKSHEET_VERIFY_FAILED,
});

export const UPDATE_WORKSHEET_VERIFY_SUCCESS =
  "UPDATE_WORKSHEET_VERIFY_SUCCESS";
export const updateWorksheetVerifySuccess = (payload: any) => ({
  type: UPDATE_WORKSHEET_VERIFY_SUCCESS,
  payload: payload,
});

// **********************************************
// AUTH ARRAY
// **********************************************
export const TRIGGER_BUILD_AUTH_ARRAY =
  "TRIGGER_BUILD_AUTH_ARRAY";
export const triggerBuildAuthArray = (payload: any) => ({
  type: TRIGGER_BUILD_AUTH_ARRAY,
  payload: payload,
});

export const UPDATE_AUTH_ARRAY =
  "UPDATE_AUTH_ARRAY";
export const updateAuthArray = (payload: any) => ({
  type: UPDATE_AUTH_ARRAY,
  payload: payload,
});
// **********************************************
// Update Authentication
// **********************************************
export const UPDATE_WORKSHEET_AUTH_IN_PROGRESS =
  "UPDATE_WORKSHEET_AUTH_IN_PROGRESS";
export const updateWorksheetAuthProgress = () => ({
  type: UPDATE_WORKSHEET_AUTH_IN_PROGRESS,

});

export const UPDATE_WORKSHEET_AUTH_FAILED =
  "UPDATE_WORKSHEET_AUTH_FAILED";
export const updateWorksheetAuthFailed = () => ({
  type: UPDATE_WORKSHEET_AUTH_FAILED,
});

export const UPDATE_WORKSHEET_AUTH_SUCCESS =
  "UPDATE_WORKSHEET_AUTH_SUCCESS";
export const updateWorksheetAuthSuccess = (payload: any) => ({
  type: UPDATE_WORKSHEET_AUTH_SUCCESS,
  payload: payload,
});

// **********************************************
// Update Outcomes
// **********************************************
export const UPDATE_WORKSHEET_OUTCOME_IN_PROGRESS =
  "UPDATE_WORKSHEET_OUTCOME_IN_PROGRESS";
export const updateWorksheetOutcomeProgress = () => ({
  type: UPDATE_WORKSHEET_OUTCOME_IN_PROGRESS,

});

export const UPDATE_WORKSHEET_OUTCOME_FAILED =
  "UPDATE_WORKSHEET_OUTCOME_FAILED";
export const updateWorksheetOutcomeFailed = () => ({
  type: UPDATE_WORKSHEET_OUTCOME_FAILED,
});

export const UPDATE_WORKSHEET_OUTCOME_SUCCESS =
  "UPDATE_WORKSHEET_OUTCOME_SUCCESS";
export const updateWorksheetOutcomeSuccess = (payload: any) => ({
  type: UPDATE_WORKSHEET_OUTCOME_SUCCESS,
  payload: payload,
});

// **********************************************
// UPDATE SCRIPT TAB SCRIPTS
// **********************************************

export const SELECTED_INCENTIVE_MORTAGE_SCRIPT =
  "SELECTED_INCENTIVE_MORTAGE_SCRIPT";
export const selectedIncentiveMortgageScript = (payload: string) => ({
  type: SELECTED_INCENTIVE_MORTAGE_SCRIPT,
  payload: payload,
});

export const SELECTED_INCENTIVE_OTHER_SCRIPT =
  "SELECTED_INCENTIVE_OTHER_SCRIPT";
export const selectedIncentiveOtherScript = (payload: string) => ({
  type: SELECTED_INCENTIVE_OTHER_SCRIPT,
  payload: payload,
});

// **********************************************
// FREEDOM\ STATE CODES
// **********************************************

export const FREEDOM_CODE_SELECTED =
  "FREEDOM_CODE_SELECTED";
export const freedomCodeSelected = (payload: any) => ({
  type: FREEDOM_CODE_SELECTED,
  payload: payload,
});


// **********************************************
// Update INCENTIVE VARIABLE
// **********************************************

export const UPDATE_INCENTIVES_VARIABLE_IN_PROGRESS =
  "UPDATE_INCENTIVES_VARIABLE_IN_PROGRESS";
export const updateIncentivesVariableInProgress = () => ({
  type: UPDATE_INCENTIVES_VARIABLE_IN_PROGRESS,

});

export const UPDATE_INCENTIVES_VARIABLE_FAILED =
  "UPDATE_INCENTIVES_VARIABLE_FAILED";
export const updateIncentivesVariableFailed = () => ({
  type: UPDATE_INCENTIVES_VARIABLE_FAILED,
});

export const UPDATE_INCENTIVES_VARIABLE_SUCCESS =
  "UPDATE_INCENTIVES_VARIABLE_SUCCESS";
export const updateIncentivesVariableSuccess = (payload: any) => ({
  type: UPDATE_INCENTIVES_VARIABLE_SUCCESS,
  payload: payload,
});




// **********************************************
// UPDATE SKIP TRACE VARIABLES
// **********************************************
export const UPDATE_SKIP_TRACE_VARIABLE_IN_PROGRESS =
  "UPDATE_SKIP_TRACE_VARIABLE_IN_PROGRESS";
export const updateSkipTraceVariableInProgress = () => ({
  type: UPDATE_SKIP_TRACE_VARIABLE_IN_PROGRESS,

});

export const UPDATE_SKIP_TRACE_VARIABLE_FAILED =
  "UPDATE_SKIP_TRACE_VARIABLE_FAILED";
export const updateSkipTraceVariableFailed = () => ({
  type: UPDATE_SKIP_TRACE_VARIABLE_FAILED,
});

export const UPDATE_SKIP_TRACE_VARIABLE_SUCCESS =
  "UPDATE_SKIP_TRACE_VARIABLE_SUCCESS";
export const updateSkipTraceVariableSuccess = (payload: any) => ({
  type: UPDATE_SKIP_TRACE_VARIABLE_SUCCESS,
  payload: payload,
});


export const COMPLETE_STANDARD_WORKSHEET =
  "COMPLETE_STANDARD_WORKSHEET";
export const completeStandardWorksheet = () => ({
  type: COMPLETE_STANDARD_WORKSHEET,
 
});


export const UPDATE_STANDARD_WORKSHEET_ACCORDION =
  "UPDATE_STANDARD_WORKSHEET_ACCORDION";
export const updateStandardWorksheetAccordion = (section: string) => ({
  type: UPDATE_STANDARD_WORKSHEET_ACCORDION,
  payload: section,
});

