/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { InputGroup, Button, Form } from "react-bootstrap";
import { updateVerbalComplaint } from "../verbalComplaintSlice";

const ServicingProcessAreaInput = ({ verbalComplaint }: any) => {
  const dispatch = useAppDispatch();
  const [text, setText] = useState("");
  const [char, setChar] = useState(verbalComplaint.resolutionNotes.length);
  const [disable, setDisable] = useState(true);
  const [error, setError] = useState(false);
  const handleTextChange = (e: any) => {
    setText(e.target.value);
    setChar(e.target.value.length);
    if (e.target.value.length > 200) {
      setDisable(true);
      setError(true);
    } else if (e.target.value.length > 5) {
      setDisable(false);
      setError(false);
    } else {
      setDisable(true);
    }
  };
  const clickSubmitButton = () => {
    const updateText = text.replace(/['"]+/g, "''");

    dispatch(updateVerbalComplaint({ name: "servicingProcessArea", value: updateText }));
  };
  return (
    <>
          <span className={`character-count ${error ? "error-red" : ""}`}>
        {char}/200
      </span>
    <InputGroup>
      <Form.Control
        size="sm"
        type="text"
        id="vc_spa_other_input"
        className={error ? "error-red" : ""}
        placeholder="Please Declare Issue"
        onChange={handleTextChange}
      />
      <Button
        variant="primary"
        id="vc_spa_other_submit"
        onClick={clickSubmitButton}
        disabled={disable}
      >
        Submit
      </Button>
    </InputGroup>
    </>
  );
};

export default ServicingProcessAreaInput;
