/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const CashOutWorksheetQuestions = () => {
  const json = {
    title: "Freedom CashOut",
    type: "refi",
    tab: "cashout_scripts",
    completeBtn: "Complete the Freedom CashOut Worksheet",
    transferBtn: "Transfer Call To Freedom",
    WQ01: {
      place: 1,
      name: "WQ01",
      worksheet: "cashout",
      section: "Pitch",
      type: "btns",
      question:
        "Is the customer interested in saving money through a refinance?",
      answers: [
        {
          place: 1,
          name: "WQ01",
          option: "1",
          text: "Yes - Interested",
          outcome: "null",
          next: "WQ05",
          script: "cashout_script_02",
        },
        {
          place: 1,
          name: "WQ01",
          option: "2",
          text: "No - Not Interested",
          outcome: "null",
          next: "WQ02",
          script: "null",
        },
        {
          place: 1,
          name: "WQ01",
          option: "3",
          text: "Did Not Pitch",
          outcome: "Did Not Pitch",
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ02: {
      place: 2,
      name: "WQ02",
      worksheet: "cashout",
      section: "Pitch",
      type: "btns",
      question: "Why aren't they interested in saving money on their mortgage?",
      answers: [
        {
          place: 2,
          name: "WQ02",
          option: "1",
          text: "Purchasing 2nd Home",
          reason: "2nd Home",
          next: "WQ22",
          script: "cashout_script_20",
        },
        {
          place: 2,
          name: "WQ02",
          option: "2",
          text: "Not Interested, Too busy, long process",
          reason: "No Interest-Busy",
          next: "WQ03",
          script: "cashout_script_03",
        },
        {
          place: 2,
          name: "WQ02",
          option: "3",
          text: "Working with different lender",
          reason: "Different Lender",
          next: "WQ03",
          script: "cashout_script_04",
        },
        {
          place: 2,
          name: "WQ02",
          option: "4",
          text: "Recently refinanced",
          reason: "Recently refi",
          next: "WQ03",
          script: "cashout_script_05",
        },
        {
          place: 2,
          name: "WQ02",
          option: "5",
          text: "Refi recently declined",
          reason: "Recently declined",
          next: "WQ03",
          script: "cashout_script_06",
        },
        {
          place: 2,
          name: "WQ02",
          option: "6",
          text: "Speak with Spouse/Partner/Co-Borrower",
          reason: "Speak Co-Borrower",
          next: "WQ03",
          script: "cashout_script_07",
        },
        {
          place: 2,
          name: "WQ02",
          option: "7",
          text: "Not another 30 year mortgage",
          reason: "No 30 Year",
          next: "WQ03",
          script: "cashout_script_08",
        },
        {
          place: 2,
          name: "WQ02",
          option: "8",
          text: "Working on credit score",
          reason: "Credit Score",
          next: "WQ03",
          script: "cashout_script_09",
        },
        {
          place: 2,
          name: "WQ02",
          option: "9",
          text: "What’s in it for you / Why should I do this?",
          reason: "What/Why",
          next: "WQ03",
          script: "cashout_script_10",
        },
      ],
    },
    WQ03: {
      place: 3,
      name: "WQ03",
      worksheet: "cashout",
      section: "Pitch",
      type: "btns",
      question: "After offering your rebuttal what was the outcome?",
      answers: [
        {
          place: 3,
          name: "WQ03",
          option: "1",
          text: "Yes Interested",
          outcome: `Interested - CO`,
          next: "WQ05",
          script: "cashout_script_01",
        },
        {
          place: 3,
          name: "WQ03",
          option: "2",
          text: "Not Interested",
          outcome: `Not Interested - CO`,
          next: "WQ99",
          script: "null",
        },
        {
          place: 3,
          name: "WQ03",
          option: "3",
          text: "Not Interested - DNC",
          outcome: `Not Interested - CO - DNC`,
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ05: {
      place: 5,
      name: "WQ05",
      worksheet: "cashout",
      section: "Outcome",
      type: "btns",
      question: "What was the outcome of the offer?",
      answers: [
        {
          place: 5,
          name: "WQ05",
          option: "1",
          text: "Transfer Call To Freedom",
          outcome: `Interested - CO - Transfer`,
          transfer: true,
          transferId: "tran_ext_refi",
          next: "WQ60",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "2",
          text: "Email Information",
          outcome: `Interested - CO - Email`,
          next: "WQ10",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "3",
          text: "Interested in Purchase",
          outcome: `Interested - Purchase`,
          next: "WQ22",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "4",
          text: "Customer Will CallBack",
          outcome: `Interested - CO - Customer Will CB`,
          next: "WQ99",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "5",
          text: "Caller Disconnected",
          outcome: `Interested - CO - Caller Disconnected`,
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ10: {
      place: 10,
      name: "WQ10",
      worksheet: "cashout",
      section: "Outcome",
      type: "email",
      answers: [
        {
          place: 10,
          name: "WQ10",
          option: "1",
          text: "Submit",
          email: true,
          emailType: "CC_cashout",
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ21: {
      place: 21,
      name: "WQ21",
      worksheet: "cashout",
      section: "Pitch",
      type: "btns",
      question:
        "Is the customer interested in speaking with a Freedom LA about 2nd home loan?",
      answers: [
        {
          place: 21,
          name: "WQ21",
          option: "1",
          text: "Yes Interested",
          outcome: "Interested - Purchase",
          transfer: true,
          next: "WQ60",
          script: "rras_b_12",
        },
        {
          place: 21,
          name: "WQ21",
          option: "2",
          text: "Not Interested",
          outcome: "Not Interested - Purchase",
          next: "WQ99",
          script: "rras_b_13",
        },
        {
          place: 21,
          name: "WQ21",
          option: "3",
          text: "Not Interested - DNC",
          outcome: "Not Interested - Purchase - DNC",
          next: "WQ99",
          script: "rras_b_13",
        },
      ],
    },
    WQ22: {
      place: 22,
      name: "WQ22",
      worksheet: "cashout",
      section: "Outcome",
      type: "btns",
      question:
        "Is the customer interested in speaking with a Freedom LA about 2nd home loan?",
      answers: [
        {
          place: 22,
          name: "WQ22",
          option: "1",
          text: "Yes Interested",
          outcome: "Interested - Purchase",
          transfer: true,
          transferId: "tran_ext_pur",
          next: "WQ60",
          script: "cashout_script_21",
        },
        {
          place: 22,
          name: "WQ22",
          option: "2",
          text: "Not Interested",
          outcome: "Not Interested - Purchase",
          next: "WQ99",
          script: "cashout_script_22",
        },
        {
          place: 22,
          name: "WQ22",
          option: "3",
          text: "Not Interested - DNC",
          outcome: "Not Interested - Purchase - DNC",
          next: "WQ99",
          script: "cashout_script_22",
        },
      ],
    },
    WQ60: {
      place: 60,
      name: "WQ60",
      worksheet: "cashout",
      section: "Outcome",
      type: "fState",
      answers: [
        {
          place: 60,
          option: "1",
          text: "Submit",
          next: "WQ99",
          script: "null",
        },
      ],
    },
  };

  return json;
};

export const MaticWorksheetQuestions = () => {
  const json = {
    title: "Matic HOI",
    type: "oth",
    tab: "matic_scripts",
    completeBtn: "Complete the Matic Worksheet",
    transferBtn: "Transfer Call To Matic",
    WQ01: {
      place: 1,
      name: "WQ01",
      worksheet: "matic",
      section: "Pitch",
      type: "btns",
      question:
        "Is the customer interested in saving money on their Homeowners Insurance?",
      answers: [
        {
          place: 1,
          name: "WQ01",
          option: "1",
          text: "Yes - Interested",
          outcome: "null",
          next: "WQ05",
          script: "matic_script_02",
        },
        {
          place: 1,
          name: "WQ01",
          option: "2",
          text: "No - Not Interested",
          outcome: "null",
          next: "WQ02",
          script: "null",
        },
        {
          place: 1,
          name: "WQ01",
          option: "3",
          text: "Did Not Pitch",
          outcome: "Did Not Pitch",
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ02: {
      place: 2,
      name: "WQ02",
      worksheet: "matic",
      section: "Pitch",
      type: "btns",
      question:
        "Why aren't they interested in saving money on the Homeowners Insurance?",
      answers: [
        {
          place: 2,
          name: "WQ02",
          option: "1",
          text: "Not Interested, Too busy, long process",
          reason: "No Interest-Busy",
          next: "WQ03",
          script: "matic_script_03",
        },
        {
          place: 2,
          name: "WQ02",
          option: "2",
          text: "Renewing existing policy",
          reason: "Renew Existing",
          next: "WQ03",
          script: "matic_script_04",
        },
      ],
    },
    WQ03: {
      place: 3,
      name: "WQ03",
      worksheet: "matic",
      section: "Pitch",
      type: "btns",
      question: "After offering your rebuttal what was the outcome?",
      answers: [
        {
          place: 3,
          name: "WQ03",
          option: "1",
          text: "Yes Interested",
          outcome: `Yes Interested`,
          next: "WQ05",
          script: "cashout_script_01",
        },
        {
          place: 3,
          name: "WQ03",
          option: "2",
          text: "Not Interested",
          outcome: `Not Interested`,
          next: "WQ99",
          script: "null",
        },
        {
          place: 3,
          name: "WQ03",
          option: "3",
          text: "Not Interested - DNC",
          outcome: `Not Interested - DNC`,
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ05: {
      place: 5,
      name: "WQ05",
      worksheet: "matic",
      section: "Outcome",
      type: "btns",
      question: "What was the outcome of the offer?",
      answers: [
        {
          place: 5,
          name: "WQ05",
          option: "1",
          text: "Transfer Call To Matic",
          outcome: `Interested - Transfer`,
          transfer: true,
          transferId: "tran_ext_matic",
          next: "WQ99",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "2",
          text: "Email Information",
          outcome: `Interested - Email`,
          next: "WQ10",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "4",
          text: "Customer Will CallBack",
          outcome: `Interested - Customer Will CB`,
          next: "WQ99",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "5",
          text: "Caller Disconnected",
          outcome: `Interested - Caller Disconnected`,
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ10: {
      place: 10,
      name: "WQ10",
      worksheet: "matic",
      section: "Outcome",
      type: "email",
      answers: [
        {
          place: 10,
          name: "WQ10",
          option: "1",
          text: "Submit",
          email: true,
          emailType: "CC_Matic",
          next: "WQ99",
          script: "null",
        },
      ],
    },
  };
  return json;
};

export const RefiWorksheetQuestions = () => {
  const json = {
    title: "Freedom Refi",
    type: "refi",
    tab: "refi_scripts",
    completeBtn: "Complete the Freedom Refi Worksheet",
    transferBtn: "Transfer Call To Freedom",
    WQ01: {
      place: 1,
      name: "WQ01",
      worksheet: "refi",
      section: "Pitch",
      type: "btns",
      question:
        "Is the customer interested in saving money through a refinance?",
      answers: [
        {
          place: 1,
          name: "WQ01",
          option: "1",
          text: "Yes - Interested",
          outcome: "null",
          next: "WQ05",
          script: "refi_script_02",
        },
        {
          place: 1,
          name: "WQ01",
          option: "2",
          text: "No - Not Interested",
          outcome: "null",
          next: "WQ02",
          script: "null",
        },
        {
          place: 1,
          name: "WQ01",
          option: "3",
          text: "Did Not Pitch",
          outcome: "Did Not Pitch",
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ02: {
      place: 2,
      name: "WQ02",
      worksheet: "refi",
      section: "Pitch",
      type: "btns",
      question: "Why aren't they interested in saving money on their mortgage?",
      answers: [
        {
          place: 2,
          name: "WQ02",
          option: "1",
          text: "Purchasing 2nd Home",
          reason: "2nd Home",
          next: "WQ22",
          script: "refi_script_20",
        },
        {
          place: 2,
          name: "WQ02",
          option: "2",
          text: "Not Interested, Too busy, long process",
          reason: "No Interest-Busy",
          next: "WQ03",
          script: "refi_script_03",
        },
        {
          place: 2,
          name: "WQ02",
          option: "3",
          text: "Working with different lender",
          reason: "Different Lender",
          next: "WQ03",
          script: "refi_script_04",
        },
        {
          place: 2,
          name: "WQ02",
          option: "4",
          text: "Recently refinanced",
          reason: "Recently Refi",
          next: "WQ03",
          script: "refi_script_05",
        },
        {
          place: 2,
          name: "WQ02",
          option: "5",
          text: "Refi recently declined",
          reason: "Recently declined",
          next: "WQ03",
          script: "refi_script_06",
        },
        {
          place: 2,
          name: "WQ02",
          option: "6",
          text: "Speak with Spouse/Partner/Co-Borrower",
          reason: "Speak Co-Borrower",
          next: "WQ03",
          script: "refi_script_07",
        },
        {
          place: 2,
          name: "WQ02",
          option: "7",
          text: "Not another 30 year mortgage",
          reason: "No 30 Year",
          next: "WQ03",
          script: "refi_script_08",
        },
        {
          place: 2,
          name: "WQ02",
          option: "8",
          text: "High Interest Rates",
          reason: "High Rates",
          next: "WQ03",
          script: "refi_script_09",
        },
        {
          place: 2,
          name: "WQ02",
          option: "9",
          text: "Rate is already low",
          reason: "Rate is Low",
          next: "WQ03",
          script: "refi_script_10",
        },
        {
          place: 2,
          name: "WQ02",
          option: "10",
          text: "Waiting until I have no PMI",
          reason: "No PMI",
          next: "WQ03",
          script: "refi_script_11",
        },
      ],
    },
    WQ03: {
      place: 3,
      name: "WQ03",
      worksheet: "refi",
      section: "Pitch",
      type: "btns",
      question: "After offering your rebuttal what was the outcome?",
      answers: [
        {
          place: 3,
          name: "WQ03",
          option: "1",
          text: "Yes Interested",
          outcome: `Interested - Refi`,
          next: "WQ05",
          script: "refi_script_01",
        },
        {
          place: 3,
          name: "WQ03",
          option: "2",
          text: "Not Interested",
          outcome: `Not Interested - Refi`,
          next: "WQ99",
          script: "null",
        },
        {
          place: 3,
          name: "WQ03",
          option: "3",
          text: "Not Interested - DNC",
          outcome: `Not Interested - Refi - DNC`,
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ05: {
      place: 5,
      name: "WQ05",
      worksheet: "refi",
      section: "Outcome",
      type: "btns",
      question: "What was the outcome of the offer?",
      answers: [
        {
          place: 5,
          name: "WQ05",
          option: "1",
          text: "Transfer Call To Freedom",
          outcome: `Interested - Refi - Transfer`,
          transfer: true,
          transferId: "tran_ext_refi",
          next: "WQ60",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "2",
          text: "Email Information",
          outcome: `Interested - Refi - Email`,

          next: "WQ10",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "3",
          text: "Interested in Purchase",
          outcome: `Interested - Purchase`,
          next: "WQ22",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "4",
          text: "Customer Will CallBack",
          outcome: `Interested - Refi - Customer Will CB`,
          next: "WQ99",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "5",
          text: "Caller Disconnected",
          outcome: `Interested - Refi - Caller Disconnected`,
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ10: {
      place: 10,
      name: "WQ10",
      worksheet: "refi",
      section: "Outcome",
      type: "email",
      answers: [
        {
          place: 10,
          name: "WQ10",
          option: "1",
          text: "Submit",
          email: true,
          emailType: "CC_Refi",
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ21: {
      place: 21,
      name: "WQ21",
      worksheet: "refi",
      section: "Pitch",
      type: "btns",
      question:
        "Is the customer interested in speaking with a Freedom LA about 2nd home loan?",
      answers: [
        {
          place: 21,
          name: "WQ21",
          option: "1",
          text: "Yes Interested",
          outcome: "Interested - Purchase",
          transfer: true,
          next: "WQ60",
          script: "rras_b_12",
        },
        {
          place: 21,
          name: "WQ21",
          option: "2",
          text: "Not Interested",
          outcome: "Not Interested - Purchase",
          next: "WQ99",
          script: "rras_b_13",
        },
        {
          place: 21,
          name: "WQ21",
          option: "3",
          text: "Not Interested - DNC",
          outcome: "Not Interested - Purchase - DNC",
          next: "WQ99",
          script: "rras_b_13",
        },
      ],
    },
    WQ22: {
      place: 22,
      name: "WQ22",
      worksheet: "refi",
      section: "Outcome",
      type: "btns",
      question:
        "Is the customer interested in speaking with a Freedom LA about 2nd home loan?",
      answers: [
        {
          place: 22,
          name: "WQ22",
          option: "1",
          text: "Yes Interested",
          outcome: "Interested - Purchase",
          transfer: true,
          transferId: "tran_ext_pur",
          next: "WQ60",
          script: "rras_b_12",
        },
        {
          place: 22,
          name: "WQ22",
          option: "2",
          text: "Not Interested",
          outcome: "Not Interested - Purchase",
          next: "WQ99",
          script: "rras_b_13",
        },
        {
          place: 22,
          name: "WQ22",
          option: "3",
          text: "Not Interested - DNC",
          outcome: "Not Interested - Purchase - DNC",
          next: "WQ99",
          script: "rras_b_13",
        },
      ],
    },
    WQ60: {
      place: 60,
      name: "WQ60",
      worksheet: "refi",
      section: "Outcome",
      type: "fState",
      answers: [
        {
          place: 60,
          option: "1",
          text: "Submit",
          next: "WQ99",
          script: "null",
        },
      ],
    },
  };

  return json;
};

export const ReverseWorksheetQuestions = () => {
  const json = {
    title: "Reverse",
    type: "refi",
    tab: "reverse_scripts",
    completeBtn: "Complete the Reverse Worksheet",
    transferBtn: "Transfer Call To Reverse",
    WQ01: {
      place: 1,
      name: "WQ01",
      worksheet: "reverse",
      section: "Pitch",
      type: "btns",
      question:
        "Is the customer interested in eliminating their monthly mortgage payment or getting additional cash out of their home?",
      answers: [
        {
          place: 1,
          name: "WQ01",
          option: "1",
          text: "Yes - Interested",
          outcome: "null",
          next: "WQ05",
          script: "reverse_script_02",
        },
        {
          place: 1,
          name: "WQ01",
          option: "2",
          text: "No - Not Interested",
          outcome: "null",
          next: "WQ02",
          script: "null",
        },
        {
          place: 1,
          name: "WQ01",
          option: "3",
          text: "Did Not Pitch",
          outcome: "Did Not Pitch",
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ02: {
      place: 2,
      name: "WQ02",
      worksheet: "reverse",
      section: "Pitch",
      type: "btns",
      question:
        "Why aren't they interested in eliminating their mortgage opt taking cash?",
      answers: [
        {
          place: 2,
          name: "WQ02",
          option: "1",
          text: "Worried that the bank will own my home",
          reason: "Bank Own Home",
          next: "WQ03",
          script: "reverse_script_03",
        },
        {
          place: 2,
          name: "WQ02",
          option: "2",
          text: "Not Interested, Too busy, Long process",
          reason: "No Interest-Busy",
          next: "WQ03",
          script: "reverse_script_06",
        },
        {
          place: 2,
          name: "WQ02",
          option: "3",
          text: "Speak with Spouse/Partner/Co-Borrower",
          reason: "Speak Co-Borrower",
          next: "WQ03",
          script: "reverse_script_07",
        },
        {
          place: 2,
          name: "WQ02",
          option: "4",
          text: "Reverse mortgages are designed to take advantage of retirees",
          reason: "RM Takes Advantage",
          next: "WQ03",
          script: "reverse_script_04",
        },
        {
          place: 2,
          name: "WQ02",
          option: "5",
          text: "I wont be able to leave my home to my heirs",
          reason: "Home to Heirs",
          next: "WQ03",
          script: "reverse_script_05",
        },
      ],
    },
    WQ03: {
      place: 3,
      name: "WQ03",
      worksheet: "reverse",
      section: "Pitch",
      type: "btns",
      question: "After offering your rebuttal what was the outcome?",
      answers: [
        {
          place: 3,
          name: "WQ03",
          option: "1",
          text: "Yes Interested",
          outcome: `Interested`,
          next: "WQ05",
          script: "reverse_script_02",
        },
        {
          place: 3,
          name: "WQ03",
          option: "2",
          text: "Not Interested",
          outcome: `Not Interested`,
          next: "WQ99",
          script: "null",
        },
        {
          place: 3,
          name: "WQ03",
          option: "3",
          text: "Not Interested - DNC",
          outcome: `Not Interested - DNC`,
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ05: {
      place: 5,
      name: "WQ05",
      worksheet: "reverse",
      section: "Outcome",
      type: "btns",
      question: "What was the outcome of the offer?",
      answers: [
        {
          place: 5,
          name: "WQ05",
          option: "1",
          text: "Transfer Call",
          outcome: `Interested - Transfer`,
          transfer: true,
          transferId: "tran_ext_rev",
          next: "WQ99",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "2",
          text: "Provide Customer w/ Phone Number",
          outcome: `Interested - Provide Number`,

          next: "WQ10",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "5",
          text: "Caller Disconnected",
          outcome: `Interested - Caller Disconnected`,
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ10: {
      place: 10,
      name: "WQ10",
      worksheet: "reverse",
      section: "Outcome",
      type: "btns",
      question:
        "Inform the customer to call 855-441-3556 if they wish to speak more about Reverse Mortgages.",
      answers: [
        {
          place: 10,
          name: "WQ05",
          option: "5",
          text: "Complete",
          outcome: `Interested - Provide Phone`,
          next: "WQ99",
          script: "null",
        },
      ],
    },
  };

  return json;
};

export const SafeHavenWorksheetQuestions = (hoursOfOperation: any) => {
  const json = {
    title: "Safe Haven",
    type: "oth",
    tab: "safe_haven_scripts",
    completeBtn: "Complete the Safe Haven Worksheet",
    transferBtn: "Transfer Call To Safe Haven",
    WQ01: {
      place: 1,
      name: "WQ01",
      worksheet: "safe_haven",
      section: "Pitch",
      type: "btns",
      question:
        "Is the customer interested an ADT monitored home security system?",
      answers: [
        {
          place: 1,
          name: "WQ01",
          option: "1",
          text: "Yes - Interested",
          outcome: "null",
          next: "WQ05",
          script: "safe_haven_script_02",
        },
        {
          place: 1,
          name: "WQ01",
          option: "2",
          text: "No - Not Interested",
          outcome: "null",
          next: "WQ02",
          script: "safe_haven_script_03",
        },
        {
          place: 1,
          name: "WQ01",
          option: "3",
          text: "Did Not Pitch",
          outcome: "Did Not Pitch",
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ02: {
      place: 2,
      name: "WQ02",
      worksheet: "safe_haven",
      section: "Pitch",
      type: "btns",
      question:
        "Why aren't they interested in a home security monitoring system?",
      answers: [
        {
          place: 2,
          name: "WQ02",
          option: "1",
          text: "No Time",
          reason: "No Time",
          next: "WQ03",
          script: "safe_haven_script_04",
        },
        {
          place: 2,
          name: "WQ02",
          option: "2",
          text: "Already have a security system ",
          reason: "Already Have System",
          next: "WQ03",
          script: "safe_haven_script_07",
        },
        {
          place: 2,
          name: "WQ02",
          option: "3",
          text: "We are moving",
          reason: "Moving",
          next: "WQ03",
          script: "null",
        },
        {
          place: 2,
          name: "WQ02",
          option: "4",
          text: "Hard No",
          reason: "Hard No",
          next: "WQ03",
          script: "safe_haven_script_06",
        },
      ],
    },
    WQ03: {
      place: 3,
      name: "WQ03",
      worksheet: "safe_haven",
      section: "Pitch",
      type: "btns",
      question: "After offering your rebuttal what was the outcome?",
      answers: [
        {
          place: 3,
          name: "WQ03",
          option: "1",
          text: "Yes Interested",
          outcome: `Interested`,
          next: "WQ05",
          script: "safe_haven_script_02",
        },
        {
          place: 3,
          name: "WQ03",
          option: "2",
          text: "Not Interested",
          outcome: `Not Interested`,
          next: "WQ99",
          script: "safe_haven_script_03",
        },
        {
          place: 3,
          name: "WQ03",
          option: "3",
          text: "Not Interested - DNC",
          outcome: `Not Interested - DNC`,
          next: "WQ99",
          script: "null",
        },
      ],
    },
    WQ05: {
      place: 5,
      name: "WQ05",
      worksheet: "safe_haven",
      section: "Outcome",
      type: "btns",
      question: "What was the outcome of the offer?",
      answers: [
        {
          place: 5,
          name: "WQ05",
          option: "1",
          text: "Transfer Call",
          outcome: `Interested - Transfer`,
          transfer: true,
          transferId: "tran_ext_safehaven",
          next: "WQ95",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "2",
          text: "Schedule a Callback w/ Safe Haven",
          outcome: `Interested - Online Form`,
          next: "WQ94",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "3",
          text: "Email Information",
          outcome: `Interested - Email`,
          next: "WQ11",
          script: "null",
        },

        {
          place: 5,
          name: "WQ05",
          option: "4",
          text: "Provide Customer w/ Phone Number",
          outcome: `Interested - Provide Number`,
          next: "WQ10",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "5",
          text: "Caller Disconnected",
          outcome: `Interested - Caller Disconnected`,
          next: "WQ99",
          script: "null",
        },
      ],
    },
    
    WQ10: {
      place: 10,
      name: "WQ10",
      worksheet: "safe_haven",
      section: "Outcome",
      type: "btns",
      question:
        "Inform the customer to call 877-850-1472 if they wish to speak to Safe Haven.",
      answers: [
        {
          place: 10,
          name: "WQ05",
          option: "5",
          text: "Complete",
          outcome: `Interested - Provide Phone`,
          next: "WQ99",
          script: "null",
        },
      ],
    },

    WQ11: {
      place: 11,
      name: "WQ11",
      worksheet: "safe_haven",
      section: "Outcome",
      type: "email",
      answers: [
        {
          place: 10,
          name: "WQ11",
          option: "1",
          text: "Submit",
          email: true,
          emailType: "CC_Safe_Haven",
          next: "WQ99",
          script: "null",
        },
      ],
    },
  };

  if(hoursOfOperation.safeHaven === false) {
    json.WQ05 ={
      place: 5,
      name: "WQ05",
      worksheet: "safe_haven",
      section: "Outcome",
      type: "btns",
      question: "What was the outcome of the offer?",
      answers: [
        {
          place: 5,
          name: "WQ05",
          option: "2",
          text: "Schedule a Callback w/ Safe Haven",
          outcome: `Interested - Online Form`,
          next: "WQ94",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "3",
          text: "Email Information",
          outcome: `Interested - Email`,
          next: "WQ11",
          script: "null",
        },

        {
          place: 5,
          name: "WQ05",
          option: "4",
          text: "Provide Customer w/ Phone Number",
          outcome: `Interested - Provide Number`,
          next: "WQ10",
          script: "null",
        },
        {
          place: 5,
          name: "WQ05",
          option: "5",
          text: "Caller Disconnected",
          outcome: `Interested - Caller Disconnected`,
          next: "WQ99",
          script: "null",
        },
      ],
    }
  }
  

  return json;
};
