/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React from "react";
import { useAppSelector } from "../../app/hooks";
import QuickdrawSecion from "./QuickdrawSection";
import { QuickdrawList } from "./QuickdrawList";
import { Dropdown, Badge } from "react-bootstrap";
import "./HeaderQuickdraw.css";

const HeaderQuickdraw = ({ appPath, callIds }: any) => {
  const skipTrace: any = useAppSelector((state) => state.skipTrace);
  const validEmail: any = useAppSelector((state) => state.main.validEmail);

  const incentives: any = useAppSelector(
    (state) => state.callWorksheets.incentiveOptions
  );
  const queryParams = new URLSearchParams(window.location.search);
  const debug = queryParams.get("debug");
  const getMenu = () => {
    if (appPath === "Unauthenticated") {
      let newArray = [];
      if (callIds.loanNumber === undefined) {
        newArray = QuickdrawList(callIds.loanNumber, callIds).filter(
          (item) => item.noLoan
        );
      } else {
        newArray = QuickdrawList(callIds.loanNumber, callIds).filter(
          (item) => item.unauthenticated
        );
      }
      return newArray;
    } else {
      if (validEmail.status === false) {
        return QuickdrawList(callIds.loanNumber, callIds).filter(
          (item) => item.component !== "Email"
        );
      } else {
        return QuickdrawList(callIds.loanNumber, callIds);
      }
    }
  };

  const menus = getMenu();
  const external = menus.filter((item) => item.section === "External_Links");
  const tools = menus.filter((item) => item.section === "Tools");
  const feature = menus.filter((item) => item.section === "Feature_Requests");

  // if(betaUser.securePay === true && appPath !== "Unauthenticated") {
  // const objBeta = {
  //   unauthenticated: false,
  //   noLoan: false,
  //   section: "External_Links",
  //   title: "seCUREpay Beta",
  //   action: "Pop-Up",
  //   windowName: "seCUREpay",
  //   url: `https://five9-payment-app-prod.azurewebsites.net?frontline=true`,
  //   event: "securepay",
  // };
  //   external.push(objBeta);
  // }
  if (debug === "true") {
    const obj = {
      unauthenticated: true,
      noLoan: true,
      section: "Tools",
      title: "Debug",
      action: "Run",
      component: "debug_tool",
      event: "debug_tool",
    };
    tools.push(obj);
  }

  return (
    <div className="col">
      <Dropdown className="d-grid gap-2 d-md-flex justify-content-md-end">
        {incentives.allowed === "false" ? (
          <Badge
            bg="warning"
            text="dark"
            className="m-1 pt-1 pb-0 align-middle"
            id="incentives-disabled"
          >
            Incentives Disabled
          </Badge>
        ) : (
          <></>
        )}

        <Dropdown.Toggle
          variant="primary"
          id="dropdown-quickdraw"
          className="btn m-1 pt-0 pb-0 btn-sm rounded-pill"
        >
          Quickdraw
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-1">
          <QuickdrawSecion
            title="External Links"
            array={external}
            skipTrace={skipTrace}
          />
          <QuickdrawSecion title="Tools" array={tools} skipTrace={skipTrace} />
          <QuickdrawSecion
            title="Feature Requests"
            array={feature}
            skipTrace={skipTrace}
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default HeaderQuickdraw;
