/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { combineReducers } from "redux";

import {
  UPDATE_SCREEN_WIDTH,
  UPDATE_COLUMN_2_TABS,
  UPDATE_WORKSHEET_TABS,
  RETURN_PREVIOUS_WORKSHEET_TAB,
  OPEN_TRANSFER_ASSIST_WINDOW,
  CLOSE_TRANSFER_ASSIST_WINDOW,
  OPEN_DISCLOSURE_WINDOW,
  CLOSE_DISCLOSURE_WINDOW,
  OPEN_EMAIL_VIVR_WINDOW,
  CLOSE_EMAIL_VIVR_WINDOW,
  OPEN_WEBSOCKET_MODAL,
  CLOSE_WEBSOCKET_MODAL,
} from "../actions/standardActions";
import {
  OPEN_PREF_WORKSHEET,
  CLOSE_PREF_WORKSHEET,
  UPDATE_PREF_PAGE_IN_PROGRESS,
  UPDATE_PREF_PAGE_SUCCESS,
  UPDATE_PREF_PAGE_FAILED,
  UPDATE_PREF_PAGE_FAILED_COMPLETELY,
} from "../actions/prefActions";

import {
  INITIATE_CONFERENCE_SUCCESS,
  INITIATE_TRANSFER_SUCCESS,
} from "../actions/apisActions";

import {
  UPDATE_WORKSHEET_DISCLOSURES_SUCCESS,
  UPDATE_WORKSHEET_DISCLOSURES_IN_PROGRESS,
  UPDATE_WORKSHEET_DISCLOSURES_FAILED,
  UPDATE_WORKSHEET_VERIFY_IN_PROGRESS,
  UPDATE_WORKSHEET_VERIFY_FAILED,
  UPDATE_WORKSHEET_VERIFY_SUCCESS,
  UPDATE_WORKSHEET_AUTH_IN_PROGRESS,
  UPDATE_WORKSHEET_AUTH_FAILED,
  UPDATE_WORKSHEET_AUTH_SUCCESS,
  UPDATE_WORKSHEET_OUTCOME_SUCCESS,
  UPDATE_WORKSHEET_OUTCOME_FAILED,
  UPDATE_WORKSHEET_OUTCOME_IN_PROGRESS,
  FREEDOM_CODE_SELECTED,
  SELECTED_INCENTIVE_MORTAGE_SCRIPT,
  SELECTED_INCENTIVE_OTHER_SCRIPT,
} from "../actions/worksheetActions";

const transferAssistModal = (state = false, action: any) => {
  const { type } = action;
  switch (type) {
    case OPEN_TRANSFER_ASSIST_WINDOW:
      return true;
    case CLOSE_TRANSFER_ASSIST_WINDOW:
      return false;
    default:
      return state;
  }
};

const discScriptObj = (state = {}, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case OPEN_DISCLOSURE_WINDOW:
      return { status: true, script: payload };
    case CLOSE_DISCLOSURE_WINDOW:
      return { ...state, status: false };
    default:
      return state;
  }
};

const emailVivrObj = (state = {}, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case OPEN_EMAIL_VIVR_WINDOW:
      // eslint-disable-next-line no-case-declarations
      let data = null;
      switch (payload.type) {
        case "CC_Refi":
          data = {
            type: payload.type,
            address: payload.address,
            number: "844-629-7712",
          };
          break;
        case "CC_Cash_Out":
          data = {
            type: payload.type,
            address: payload.address,
            number: "844-806-2720",
          };
          break;
        case "CC_Matic":
          data = {
            type: payload.type,
            address: payload.address,
            number: "614-441-9948",
          };
          break;
        default:
          data = {
            type: payload.type,
            address: payload.address,
            number: "null",
          };
          break;
      }
      return { status: true, email: data };
    case CLOSE_EMAIL_VIVR_WINDOW:
      return { status: false, script: null };
    default:
      return state;
  }
};

const websocketModal = (state = false, action: any) => {
  const { type } = action;

  switch (type) {
    case OPEN_WEBSOCKET_MODAL:
      return true;
    case CLOSE_WEBSOCKET_MODAL:
      return false;
    default:
      return state;
  }
};

const freedomCodeSelect = (state = {}, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case FREEDOM_CODE_SELECTED:
      return payload;
    default:
      return state;
  }
};

// Disables some operations while processes are running
const putInProgress = (state = {}, action: any) => {
  const { type } = action;
  if (
    type === UPDATE_WORKSHEET_DISCLOSURES_IN_PROGRESS ||
    type === UPDATE_WORKSHEET_VERIFY_IN_PROGRESS ||
    type === UPDATE_WORKSHEET_AUTH_IN_PROGRESS ||
    type === UPDATE_WORKSHEET_OUTCOME_IN_PROGRESS
  ) {
    return { ...state, status: true };
  } else if (
    type === UPDATE_WORKSHEET_DISCLOSURES_SUCCESS ||
    type === UPDATE_WORKSHEET_DISCLOSURES_FAILED ||
    type === UPDATE_WORKSHEET_VERIFY_SUCCESS ||
    type === UPDATE_WORKSHEET_VERIFY_FAILED ||
    type === UPDATE_WORKSHEET_AUTH_SUCCESS ||
    type === UPDATE_WORKSHEET_AUTH_FAILED ||
    type === UPDATE_WORKSHEET_OUTCOME_FAILED ||
    type === UPDATE_WORKSHEET_OUTCOME_SUCCESS
  ) {
    return { ...state, status: false };
  } else {
    return state;
  }
}


const prefModal = (state = false, action: any) => {
  const { type } = action;
  switch (type) {
    case OPEN_PREF_WORKSHEET:
      return true;
    case CLOSE_PREF_WORKSHEET:
      return false;
    default:
      return state;
  }
};

const prefWhenDu = (state = "", action: any) => {
  const { type } = action;
  switch (type) {
    case UPDATE_PREF_PAGE_IN_PROGRESS:
      return "In Progress";
    case UPDATE_PREF_PAGE_SUCCESS:
      return "Success";
    case UPDATE_PREF_PAGE_FAILED:
      return "Failed";
    case UPDATE_PREF_PAGE_FAILED_COMPLETELY:
      return "Failed";
    default:
      return state;
  }
};



export const features = combineReducers({
  freedomCodeSelect,
  transferAssistModal,
  discScriptObj,
  emailVivrObj,
  websocketModal,
  putInProgress,
  prefModal,
  prefWhenDu,
});
