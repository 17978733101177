/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */

import React from "react";
import { useAppDispatch } from "../../../app/hooks";
import {
  selectedIncentiveMortgageScript,
  selectedIncentiveOtherScript,
} from "../../../app/actions/worksheetActions";
import { Accordion } from "react-bootstrap";

//TODO make the necessary adjustments for screen size

const IncentiveScriptBuild = ({ script }: any) => {
  const dispatch = useAppDispatch();
  const PitchScriptBuild = () => {
    return (
      <ul className="mb-0">
        <li>
          Agent Pitch
          <ul>
            <li>"{script.pitch}"</li>
          </ul>
        </li>
      </ul>
    );
  };
  const StandardScriptBuild = () => {
    return (
      <ul className="mb-0">
        <li>
          Customer Response
          <ul>
            <li>"{script.customer}"</li>
          </ul>
        </li>
        <li>
          Agent Response
          <ul>
            <li>"{script.agent}"</li>
          </ul>
        </li>
      </ul>
    );
  };

  return (
    <Accordion.Item
      eventKey={`${script.option}_script_${script.id}`}
      id={`${script.option}_script_${script.id}`}
    >
      <Accordion.Header
        onClick={() => {
          if (script.option === "matic" || script.option === "safe_haven") {
            dispatch(
              selectedIncentiveOtherScript(
                `${script.option}_script_${script.id}`
              )
            );
          } else {
            dispatch(
              selectedIncentiveMortgageScript(
                `${script.option}_script_${script.id}`
              )
            );
          }
        }}
      >
        {script.heading}
      </Accordion.Header>
      <Accordion.Body>
        {script.pitch === undefined
          ? StandardScriptBuild()
          : PitchScriptBuild()}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default IncentiveScriptBuild;
