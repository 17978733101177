/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";

import { updateColumn2Tabs } from "../../app/actions/standardActions";
import { Tabs, Tab, Alert } from "react-bootstrap";
import MessagingAndStatusNew from "./MessagingAndStatusNew";
import LoanInfoNew from "./LoanInfoNew";
import AuthScriptTab from "./AuthScriptTab";
import IncentiveScriptsAccordion from "./incentiveScripts/IncentiveScriptsAccordion";
import ContactInfo from "../column1/ContactInfo";
import "./Column2Tabs.css";

//TODO make the necessary adjustments for screen size
const Column2Tabs = () => {
  const dispatch = useAppDispatch();

  const appPath: any = useAppSelector((state) => state.main.appPath);
  const column2Tab: any = useAppSelector((state) => state.display.column2Tab);
  const incentiveOptions: any = useAppSelector(
    (state) => state.callWorksheets.incentiveOptions
  );
  const columns: any = useAppSelector((state) => state.display.layout.columns);
  const banner: any = useAppSelector((state) => state.displayInfo.banner);
  const hoursOfOperation: any = useAppSelector(
    (state) => state.main.hoursOfOperation
  );

  // controls Column 2 Tabs
  const [tabKey, setTabKey] = useState(() => {
    if (appPath === "Unauthenticated") {
      //console.log('tabKey is auth')
      return "auth";
    } else {
      if (columns !== "3") {
        //console.log('tabKey is main')
        return "main";
      } else {
        //console.log('tabKey is loan')
        return "loan";
      }
    }
  });
  // monitors column 2 tabs redux
  useEffect(() => {
    if (column2Tab !== "") {
      setTabKey(column2Tab);
    }
  }, [column2Tab]);

  // builds the auth script tab
  const buildAuthScriptTab = () => {
    return (
      <Tab eventKey="auth" title="Auth Script">
        <AuthScriptTab />
      </Tab>
    );
  };

  // builds the Refi script tab
  const buildRefiScripts = () => {
    if (banner.staleData === false) {
      switch (incentiveOptions.refiWS) {
        case "F Refi":
          return (
            <Tab eventKey="refi_scripts" title="Refi">
              <IncentiveScriptsAccordion pre="refi" />
            </Tab>
          );
        case "F CO":
          return (
            <Tab eventKey="cashout_scripts" title="CashOut">
              <IncentiveScriptsAccordion pre="cashout" />
            </Tab>
          );

        case "Reverse":
          return (
            <Tab eventKey="reverse_scripts" title="Reverse">
              <IncentiveScriptsAccordion pre="reverse" />
            </Tab>
          );
        default:
          return <></>;
      }
    } else {
      return <></>;
    }
  };
  // builds the matic / other script tab
  const buildOtherIncentiveScripts = () => {
    if (banner.staleData === false) {
      switch (incentiveOptions.othWS) {
        case "M HOI":
          return (
            <Tab eventKey="matic_scripts" title="Matic">
              <IncentiveScriptsAccordion pre="matic" />
            </Tab>
          );
        case "SHaven":
          return (
            <Tab eventKey="safe_haven_scripts" title="Safe Haven">
              <IncentiveScriptsAccordion pre="safe_haven" />
            </Tab>
          );

        default:
          return <></>;
      }
    } else {
      return <></>;
    }
  };

  // Contact tab (only when screen width requires only 2 columns)
  const buildContactInfo = () => {
    return (
      <Tab eventKey="main" title="Main">
        <ContactInfo />
      </Tab>
    );
  };

  // build column 2 tabs
  return (
    <Tabs
      activeKey={tabKey}
      id="column2_tabs"
      className="mb-2"
      onSelect={(k: any) => dispatch(updateColumn2Tabs(k))}
    >
      {/* when columns is reduced to 2 contact information will go in the column 2 tab */}
      {columns !== "3" ? buildContactInfo() : <></>}
      <Tab eventKey="loan" title="Loan">
        {banner.staleData === true ? (
          <div>
            <Alert variant="danger">
              <Alert.Heading>
                <b>Outdated Data</b>
              </Alert.Heading>
              <p>
                This data is outdated. Please reference MSP for up to date loan
                information.
              </p>
            </Alert>
          </div>
        ) : (
          <div className="field-display">
            <MessagingAndStatusNew />
            <LoanInfoNew />
          </div>
        )}
      </Tab>
      {appPath === "Unauthenticated"
        ? buildAuthScriptTab()
        : buildRefiScripts()}
      {appPath === "Unauthenticated" ? <></> : buildOtherIncentiveScripts()}
      {/* {columns === "1" ? (
        <Tab eventKey="worksheets_2" title="Worksheets">
          <WorksheetWrapper />
        </Tab>
      ) : (
        <></>
      )} */}
    </Tabs>
  );
};

export default Column2Tabs;
