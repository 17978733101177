/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { openLCOMWindow } from "../../app/actions/lcomActions";
import { Card, Row, Button } from "react-bootstrap";
import InfoRowWithCopy from "./InfoRowWithCopy";

const ContactInfoCard = () => {
  const dispatch = useAppDispatch();
  const contactInfo: any = useAppSelector((state) => state.displayInfo.contact);

  return (
    <Card>
      <Card.Body>
        <h5 className="d-flex justify-content-between">
          Contact Information{" "}
          {/* <Button className="p-0" size="sm" variant="link" onClick={() => dispatch(openLCOMWindow())}>
            <b>Edit</b>
          </Button> */}
        </h5>
        <Row>
          <InfoRowWithCopy group="contactInfo" array={contactInfo.display} />
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ContactInfoCard;
