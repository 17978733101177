/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { updateVerbalComplaint } from "../verbalComplaintSlice";
import ButtonsVerbalComplaint from "./ButtonsVerbalComplaint";
import { vcQuestions } from "./VCQuestionList";

const VCQuestions = () => {
  const dispatch = useAppDispatch();
  const verbalComplaint: any = useAppSelector((state) => state.verbalComplaint);

  useEffect(() => {
    if (verbalComplaint.vcIssue !== "") {
      const text = `Customer asserts error with ${verbalComplaint.servicingProcessArea}. 
      Customer dissatisfied with ${verbalComplaint.vcIssue}`;
      dispatch(
        updateVerbalComplaint({ name: "summaryOfComplaint", value: text })
      );
    }
  }, [verbalComplaint.vcIssue]);

  return (
    <div>
      <ButtonsVerbalComplaint
        qna={vcQuestions("udaapFairServicingFlag", verbalComplaint)}
      />
      {verbalComplaint.udaapFairServicingFlag !== "" ? (
        <ButtonsVerbalComplaint
          qna={vcQuestions("complaintType", verbalComplaint)}
        />
      ) : (
        <></>
      )}
      {verbalComplaint.complaintType !== "" ? (
        <ButtonsVerbalComplaint
          qna={vcQuestions("complaintSource", verbalComplaint)}
        />
      ) : (
        <></>
      )}
      {verbalComplaint.complaintSource !== "" ? (
        <ButtonsVerbalComplaint
          qna={vcQuestions("servicingProcessArea", verbalComplaint)}
        />
      ) : (
        <></>
      )}
      {verbalComplaint.servicingProcessArea !== "" ? (
        <ButtonsVerbalComplaint qna={vcQuestions("vcIssue", verbalComplaint)} />
      ) : (
        <></>
      )}
      {verbalComplaint.vcIssue !== "" ? (
        <ButtonsVerbalComplaint
          qna={vcQuestions("resolutionSummary", verbalComplaint)}
        />
      ) : (
        <></>
      )}
      {verbalComplaint.resolutionSummary !== "" ? (
        <ButtonsVerbalComplaint
          qna={vcQuestions("resolutionNotes", verbalComplaint)}
        />
      ) : (
        <></>
      )}
      {verbalComplaint.resolutionNotes !== "" && verbalComplaint.rootCause !== "N/A" ? (
        <ButtonsVerbalComplaint
          qna={vcQuestions("rootCause", verbalComplaint)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default VCQuestions;
