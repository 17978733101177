/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";

import { Col, Container } from "react-bootstrap";
import CommentsBox from "./CommentsBox";
import WorksheetWrapper from "./WorksheetWrapper";

import "./Column3.css";

const Column3 = () => {
  return (
    <Col xs id="column_3">
      <Container fluid>
        <CommentsBox />
        <WorksheetWrapper /> 
      </Container>
    </Col>
  );
};

export default Column3;
