/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";

import { completeStandardWorksheet } from "../../../app/actions/worksheetActions";
import { Accordion } from "react-bootstrap";
import StandardAuthentication from "./StandardAuthentication";
import StandardDisclosures from "./StandardDisclosures";
import StandardOutcome from "./StandardOutcome";
import "./StandardWorksheet.css";

const StandardWorksheet = () => {
  const dispatch = useAppDispatch();
  // global states
  const authData: any = useAppSelector(
    (state) => state.callWorksheets.authData
  );
  const reqDisc: any = useAppSelector(
    (state) => state.callWorksheets.disclosures.array
  );
  const outcome: any = useAppSelector((state) => state.callWorksheets.outcome);
  // const outcomeArray: any = useAppSelector(
  //   (state) => state.callWorksheets.outcomeArray
  // );
  const verification: any = useAppSelector(
    (state) => state.contactVerification
  );

  const [verify, setVerify] = useState(authData.verify);
  const [activeSection, setActiveSection] = useState("0");
  const [discComplete, setDiscComplete] = useState(false);
  useEffect(() => {
    console.log("Set active key ", verify);
    if (authData.verify === true && verification.complete === true) {
      const completed = reqDisc.filter(
        (disc: any) => disc.value === "agent" || disc.value === "ivr"
      );
      if (completed.length === reqDisc.length) {
        setActiveSection("2");
      } else {
        setActiveSection("1");
      }
    } else {
      setActiveSection("0");
    }
    const completedDisc = reqDisc.filter(
      (disc: any) => disc.value === "agent" || disc.value === "ivr"
    );
    if (completedDisc.length === reqDisc.length) {
      setDiscComplete(true);
    } else {
      setDiscComplete(false);
    }
  }, [reqDisc, authData.verify, verification]);

  const checkMark = () => {
    return (
      <span className="worksheet_section_check">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-check2-circle"
          viewBox="0 0 16 16"
        >
          <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
          <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
        </svg>
      </span>
    );
  };

  const verifySwitchValue = (val: boolean) => {
    setVerify(val);
  };

  // determine worksheet complete
  if (authData.verify === true) {
    const completed = reqDisc.filter(
      (disc: any) => disc.value === "agent" || disc.value === "ivr"
    );
    if (completed.length === reqDisc.length) {
      if (outcome.complaint !== null) {
        dispatch(completeStandardWorksheet());
      }
    }
  }

  return (
    <Accordion activeKey={activeSection} flush={true}>
      <Accordion.Item eventKey="0">
        <Accordion.Header
          onClick={() => {
            setActiveSection("0");
          }}
        >
          Authentication {authData.verify === true ? checkMark() : <></>}
        </Accordion.Header>
        <Accordion.Body>
          <StandardAuthentication verifySwitchValue={verifySwitchValue} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header
          onClick={() => {
            setActiveSection("1");
          }}
        >
          Disclosures {discComplete === true ? checkMark() : <></>}
        </Accordion.Header>
        <Accordion.Body>
          <StandardDisclosures />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <StandardOutcome setActiveSection={setActiveSection} />
      </Accordion.Item>
    </Accordion>
  );
};

export default StandardWorksheet;
