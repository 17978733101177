/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { combineReducers } from "redux";

import {
  GET_METADATA_IN_PROGRESS,
  GET_METADATA_SUCCESS,
  GET_METADATA_FAILED,
  GET_CALLDATA_IN_PROGRESS,
  GET_CALLDATA_SUCCESS,
  GET_CALLDATA_FAILED,
  GET_CALLDATA_SUCCESS_NO_LOAN,
  GET_WORKSHEET_DATA_IN_PROGRESS,
  GET_WORKSHEET_DATA_SUCCESS,
  GET_WORKSHEET_DATA_FAILED,
  UPDATE_CALLDATA_SUCCESS,
} from "../actions/apisActions";

import { UPDATE_WORKSHEET_AUTH_SUCCESS } from "../actions/worksheetActions";
import callIds from "../slices/main/callIds";
import privateLabel from "../slices/main/privateLabel";
import validEmail from "../slices/main/validEmail";
import hoursOfOperation from "../slices/main/hoursOfOperation";
import { consoleLogIt } from "../log";

const metadata = (state = { status: "", data: {} }, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case GET_METADATA_SUCCESS: {
      return { ...state, status: "Loaded", data: payload };
    }
    case GET_METADATA_IN_PROGRESS:
      return { ...state, status: "Loading" };
    case GET_METADATA_FAILED:
      return { ...state, status: "Failed" };
    default:
      return state;
  }
};

const calldata = (state = {}, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CALLDATA_SUCCESS: {
      //console.log("CallDataSuccess ", payload);Loaded
      return {
        ...state,
        status: "Loaded",
        data: payload.data,
        dataDate: payload.data.activeContact.fields["1224"],
      };
    }
    case GET_CALLDATA_SUCCESS_NO_LOAN: {
      return { ...state, status: "NoLoan", data: payload };
    }
    case GET_CALLDATA_IN_PROGRESS:
      return { ...state, status: "Loading" };
    case GET_CALLDATA_FAILED:
      return { ...state, status: "Failed" };
    case UPDATE_CALLDATA_SUCCESS:
      return { ...state, data: payload };
    default:
      return state;
  }
};

const callType = (state = "", action: any) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CALLDATA_SUCCESS:
      switch (payload.data.variables["44"]) {
        case "Inbound":
          if (payload.data.callType === "QUEUE_CALLBACK") {
            //QUEUE CALLBACKS
            return "QCB";
          } else {
            //Inbound
            return "IB";
          }
        case "Visual":
          //VISUAL QUEUE CALLBACKS
          return "QCB";
        case "Manual":
          //MANUAL
          return "MAN";
        default:
          //OUTBOUND
          return "OB";
      }
    default:
      return state;
  }
};

const appPath = (state = "Unauthenticated", action: any) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CALLDATA_SUCCESS:
      switch (payload.data.variables["44"]) {
        case "Inbound":
          if (payload.data.callType === "QUEUE_CALLBACK") {
            //QUEUE CALLBACKS
            if (payload.data.variables["1054"] === "1") {
              return "Standard";
            } else {
              return "Unauthenticated";
            }
          } else {
            //INBOUND
            if (payload.data.variables["253"] === "true") {
              return "Standard";
            } else {
              if (payload.data.variables["1054"] === "1") {
                return "Standard";
              } else {
                return "Unauthenticated";
              }
            }
          }
        case "Visual":
          //VISUAL QUEUE CALLBACKS

          if (payload.data.variables["1054"] === "1") {
            return "Standard";
          } else {
            return "Unauthenticated";
          }

        case "Manual":
          if (payload.data.variables["1054"] === "1") {
            return "Standard";
          } else {
            return "Unauthenticated";
          }

        default:
          //OUTBOUND
          return "Standard";
      }

    case UPDATE_WORKSHEET_AUTH_SUCCESS:
      return "Standard";
    default:
      return state;
  }
};

// five9 worksheet data
const worksheetData = (state = {}, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case GET_WORKSHEET_DATA_IN_PROGRESS:
      return {
        status: "in_progress",
        data: {},
      };
    case GET_WORKSHEET_DATA_FAILED:
      return {
        status: "failed",
        data: {},
      };
    case GET_WORKSHEET_DATA_SUCCESS:
      return {
        status: "success",
        data: payload.data,
      };
    default:
      return state;
  }
};
export const main = combineReducers({
  metadata,
  calldata,
  callIds,
  privateLabel,
  callType,
  appPath,
  validEmail,
  worksheetData,
  hoursOfOperation,
});
