import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { dccDataSubmitted } from "../../offCanvas/dccConsultationQRPC/DCCConsultationQRPCSlice";
import moment from "moment";
import { consoleLogIt } from "../../../app/log";
import "./HiddenIframe.css";

const HiddenIframeDCCConsultation = () => {
  const dispatch = useAppDispatch();
  const dccConsultationQRPC: any = useAppSelector(
    (state) => state.dccConsultationQRPC
  );
  const callIds = useAppSelector((state) => state.main.callIds);
  const [send, setSend] = useState(false);
  const [url, setUrl] = useState("");
  useEffect(() => {
    if (dccConsultationQRPC.data.dataSent !== true) {
      if (dccConsultationQRPC.data.send === true) {
        let dataUrl = `https://api.five9.com/ivr/1/109388/campaigns/1140970/new_ivr_session?`;
        dataUrl = dataUrl + `agent=${callIds.userName}`;
        dataUrl = dataUrl + `&callId=${callIds.rptCallId}`;
        dataUrl = dataUrl + `&loanNumber=${callIds.loanNumber}`;
        dataUrl = dataUrl + `&employment=${dccConsultationQRPC.questions.employment}`;
        dataUrl = dataUrl + `&hardshipReason=${dccConsultationQRPC.questions.hardshipReason}`;
        dataUrl = dataUrl + `&hardshipLength=${dccConsultationQRPC.questions.hardshipLength}`;
        dataUrl = dataUrl + `&hardshipResolved=${dccConsultationQRPC.questions.hardshipResolved}`;
        dataUrl = dataUrl + `&hardshipBegin=${moment(dccConsultationQRPC.questions.hardshipBegin).format("YYYY-MM-DD")}`;
        dataUrl = dataUrl + `&hardshipEnd=${moment(dccConsultationQRPC.questions.hardshipEnd).format("YYYY-MM-DD")}`;
        dataUrl = dataUrl + `&hardshipCovid=${dccConsultationQRPC.questions.hardshipCovid}`;
        dataUrl = dataUrl + `&hardshipFemaDisaster=${dccConsultationQRPC.questions.hardshipFemaDisaster}`;
        dataUrl = dataUrl + `&hardshipFemaDisasterType=${dccConsultationQRPC.questions.hardshipFemaDisasterType}`;
        dataUrl = dataUrl + `&propertyIntentions=${dccConsultationQRPC.questions.propertyIntentions}`;
        dataUrl = dataUrl + `&propertyOccupancy=${dccConsultationQRPC.questions.propertyOccupancy}`;
        dataUrl = dataUrl + `&propertyAbandoned=${dccConsultationQRPC.questions.propertyAbandoned}`;
        dataUrl = dataUrl + `&propertyCondemned=${dccConsultationQRPC.questions.propertyCondemned}`;
        dataUrl = dataUrl + `&affordability=${dccConsultationQRPC.questions.affordability}`;
        dataUrl = dataUrl + `&grossIncome=${dccConsultationQRPC.questions.grossHouseholdIncome}`;

        setUrl(dataUrl);
        dispatch(dccDataSubmitted())
        setSend(true);
      }
    }
  }, [dccConsultationQRPC]);
  if (send === true) {
    return (
      <iframe className="hidden" src={url} title="Send data to Five9"></iframe>
    );
  } else {
    return <></>;
  }
};

export default HiddenIframeDCCConsultation;
