/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { InputGroup, Form, Dropdown } from "react-bootstrap";
import TransferOptions from "./TransferOptions.json";
import TransferAssistDropdownSection from "./TransferAssistDropdownSection";

const TransferAssistDropdown = ({
  setSelectedTransfer,
  
  dropdownOpen,
  setDropdownOpen,
  inputEntry,
}: any) => {
  const [transferOptionList, setTransferOptionsList]: any =
    useState(TransferOptions);

  const internal = transferOptionList.filter(
    (item: any) => item.section === "Internal"
  );
  const external = transferOptionList.filter(
    (item: any) => item.section === "External"
  );
  const conference = transferOptionList.filter(
    (item: any) => item.section === "Conferences"
  );

  useEffect(() => {
    if (inputEntry !== undefined && inputEntry !== null) {
      const filter = inputEntry.toLowerCase();
      const newArray = TransferOptions.filter((item: any) =>
        item.name.toLowerCase().includes(filter)
      );
      setTransferOptionsList(newArray);
    } else {
      setTransferOptionsList(TransferOptions);
    }
  }, [inputEntry]);

  return (
    <Dropdown show={dropdownOpen}>
      <Dropdown.Toggle
        id="transfer-dropdown-button"
        onClick={() => {
          if (dropdownOpen === true) {
            setDropdownOpen(false);
          } else {
            setDropdownOpen(true);
          }
        }}
      ></Dropdown.Toggle>
      <Dropdown.Menu align="end">
        <TransferAssistDropdownSection
          title="Internal Transfers"
          array={internal}
          setSelectedTransfer={setSelectedTransfer}
          setDropdownOpen={setDropdownOpen}
        />
        <TransferAssistDropdownSection
          title="External Transfers"
          array={external}
          setSelectedTransfer={setSelectedTransfer}
          setDropdownOpen={setDropdownOpen}
        />
        <TransferAssistDropdownSection
          title="Conferences"
          array={conference}
          setSelectedTransfer={setSelectedTransfer}
          setDropdownOpen={setDropdownOpen}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TransferAssistDropdown;
