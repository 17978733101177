/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { putVariableVerifyAuthentication } from "../../app/thunks/worksheetThunks";
import { InputGroup, Form } from "react-bootstrap";

const AuthVerifySwitch = ({
  item,

  verifySwitchValue,
}: any) => {
  const dispatch = useAppDispatch();
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const authData: any = useAppSelector(
    (state) => state.callWorksheets.authData
  );
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const inProgress: any = useAppSelector((state) => state.features.putInProgress);

  const itemValue = () => {
    if (item.value === true) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <InputGroup id={`input_group_verify_na`}>
      <Form.Check
        type="switch"
        id={`verify_${item.id}`}
        disabled={inProgress.status}
        label={item.text}
        checked={itemValue()}
        onChange={(e) => {
          if (e.currentTarget.checked === true) {
            verifySwitchValue(true);
            const data: any = {};
            data.id = item.id;
            data.value = true;
            const array = [];
            array.push(data);
            dispatch(
              putVariableVerifyAuthentication(
                metadata.data,
                authData,
                callIds,
                array
              )
            );
          } else {
            verifySwitchValue(false);
            const data: any = {};
            data.id = item.id;
            data.value = false;
            const array = [];
            array.push(data);
            dispatch(
              putVariableVerifyAuthentication(
                metadata.data,
                authData,
                callIds,
                array
              )
            );
          }
        }}
      />
    </InputGroup>
  );
};

export default AuthVerifySwitch;
