/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { InputGroup, Form, Row, Col } from "react-bootstrap";
import ContactVerificationToggle from "./ContactVerificationToggle";
import { consoleLogIt } from "../../../app/log";

const toggleArray: any = [];

const ContactVerificationObject = ({ item, value, name }: any) => {
  const dispatch = useAppDispatch();
  // global states
  consoleLogIt("Key is ", name)
  // TODO ADD REMOVE BUTTONS FOR PHONE AND EMAIL
  return (
    <Row className="mb-3">
      <Col>
        <InputGroup size="sm">
          <Form.Control type="input" value={item} readOnly={true} />
        </InputGroup>
      </Col>
      <Col>
        <ContactVerificationToggle item={item} value={value} name={name} />
      </Col>
    </Row>
  );
};

export default ContactVerificationObject;
