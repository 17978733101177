import { createSlice } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { consoleLogIt } from "../../../app/log";

export interface SafeHavenFormInterface {
  show: boolean;
  form: "callback" | "transfer" | "";
  email: string;
  phoneNumber: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  firstName: string;
  lastName: string;
  date: string | null;
  time: string | null;
  notes: string;
  sendData: boolean;
  sentDataToSafeHaven: boolean;
}

const initialState = {
  show: false,
  form: "",
  email: "",
  phoneNumber: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  firstName: "",
  lastName: "",
  date: "",
  time: "",
  notes: "",
  sendData: false,
  sentDataToSafeHaven: false,
};

export const safeHavenForm = createSlice({
  name: "safeHavenForm",
  initialState,
  reducers: {
    openSafeHavenForm: (state, action) => {
      consoleLogIt("Safe Haven Form Opened");
      const { payload } = action;
      const address = payload.loanInfo.address;
      state.address = address.propertyAddress;
      state.city = address.propertyCity;
      state.state = address.propertyState;
      state.zip = address.propertyZip;
      if (state.email === "") {
        const emailValid = payload.mainInfo.validEmail;
        if (emailValid.status === true) {
          state.email = emailValid.email;
        } else {
          state.email = "noemailonfile@roundpointmortgage.com";
        }
      }
      if (state.firstName === "") {
        const fullNameArray =
          payload.loanInfo.borrower.borrowerFullName.split(" ");
        if (fullNameArray.length === 2) {
          state.firstName = fullNameArray[0];
          state.lastName = fullNameArray[1];
        } else {
          for (let i = 0; i < fullNameArray.length; i++) {
            if (i === 0) {
              state.firstName = fullNameArray[i];
            } else if (i === 1) {
              state.firstName = state.firstName + " " + fullNameArray[i];
            } else if (i === 2) {
              state.lastName = fullNameArray[i];
            } else {
              state.lastName = state.lastName + " " + fullNameArray[i];
            }
          }
        }
      }
      if (state.phoneNumber === "") {
        state.phoneNumber = payload.mainInfo.callIds.custPhone;
      }

      state.form = payload.form;
      state.show = true;
    },
    closeSafeHavenForm: (state) => {
      consoleLogIt("Safe Haven Form Closed");
      state.show = false;
    },
    sentDataToSafeHaven: (state) =>{
      state.sentDataToSafeHaven = true;
    },
    updateSHDate: (state, action) => {
      const { payload }: any = action;
      state.date = payload;
    },
    updateSHTime: (state, action) => {
      const { payload }: any = action;
      state.time = payload;
    },
    updateSHField: (state, action) => {
      const { payload }: any = action;

      switch (payload.name) {
        case "notes":
          state.notes = payload.value;
          break;
        case "phone":
          state.phoneNumber = payload.value;
          break;
        case "first":
          state.firstName = payload.value;
          break;
        case "last":
          state.lastName = payload.value;
          break;
        case "email":
          if (payload.value === "") {
            state.email = "noemailonfile@roundpointmortgage.com"
          } else {
            state.email = payload.value;
          }

          break;
        default:
          break;
      }
    },
    submitSafeHavenForm: (state) => {
      state.sendData = true;
      state.show = false;
    },
  },
  extraReducers: {},
});
// thunks

// Action creators are generated for each case reducer function
export const {
  openSafeHavenForm,
  closeSafeHavenForm,
  updateSHDate,
  updateSHTime,
  updateSHField,
  submitSafeHavenForm,
  sentDataToSafeHaven
} = safeHavenForm.actions;

export default safeHavenForm.reducer;
