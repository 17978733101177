/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { Form } from "react-bootstrap";
import { updateVerbalComplaint } from "../verbalComplaintSlice";
import ResolutionNotesTextArea from "./ResolutionNotesTextArea";

const VCRadioButtons = ({ qna, verbalComplaint }: any) => {
  const dispatch = useAppDispatch();
  const [collapseState, setCollapseState] = useState(
    verbalComplaint[qna.name] !== "" ? true : false
  );
  const [notes, setNotes] = useState(false);

  return (
    <div
      id={qna.name}
      className={
        verbalComplaint[qna.name] !== "" && collapseState === true
          ? "cc-vc-collapse"
          : ""
      }
    >
      <div
        onClick={() => {
          //console.log("On click triggered");
          if (collapseState === false) {
            //console.log("set collapse state to true");
            setCollapseState(true);
          } else {
            //console.log("set collapse state to false");
            setCollapseState(false);
          }
        }}
      >
        <b>{qna.title}</b>
        {/* <p className="mb-1 cc-vc-description">{qna.description}</p> */}
      </div>
      {qna.answers.map((answer: any) => {
        const determineButtonClassName = () => {
          if (verbalComplaint[qna.name] !== answer.value) {
            return "unchecked";
          } else {
            return "";
          }
        };
        const toggleHandleChange = () => {
          if (
            answer.name === "resolutionNotes" &&
            answer.value === "Additional Notes"
          ) {
            setNotes(true);
            setCollapseState(true);
          } else {
            setNotes(false);
            setCollapseState(true);
            dispatch(updateVerbalComplaint(answer));
          }
        };
        const determinChecked = () => {
          if (verbalComplaint[answer.name] === answer.value) {
            return true;
          } else if (answer.value === "Additional Notes" && notes === true) {
            return true;
          } else {
            return false;
          }
        };
        return (
          <Form.Check
            key={answer.id}
            type="radio"
            id={answer.id}
            className={determineButtonClassName()}
            checked={determinChecked()}
            label={answer.text}
            value={answer.value}
            name={answer.name}
            onChange={toggleHandleChange}
          />
        );
      })}
      {notes === true ? <ResolutionNotesTextArea verbalComplaint={verbalComplaint}/> : <></>}
    </div>
  );
};

export default VCRadioButtons;
