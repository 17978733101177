/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
import React from "react";
import { useAppSelector } from "../app/hooks";
import Column1 from "./column1/Column1";
import Column2 from "./column2/Column2";
import Column3 from "./column3/Column3";
import TransferAssistModal from "./features/transferAssist/TransferAssistModal";
import DisclosuresWindow from "./features/disclosureModal/DisclosuresWindow";
import EmailVIVRModal from "./features/EmailVIVRModal";
import ContactVerificationModal from "./features/verificationModal/ContactVerificationModal";

import "./Body.css";
import SkipTraceModal from "./features/skipTrace/SkipTraceModal";
import VerbalComplaintOffcanvas from "./offCanvas/verbalComplaint/VerbalComplaintOffcanvas";
import Notifications from "./features/notifications/Notifications";

import HiddenIframeSafeHaven from "./features/hiddenIframe/HiddenIframeSafeHaven";
import HiddenIframeVC from "./features/hiddenIframe/HiddenIframeVC";
import SafeHavenForm from "./offCanvas/safeHavenForm/SafeHavenForm";
import DCCConsultationOffCanvas from "./offCanvas/dccConsultationQRPC/DCCConsultationOffCanvas";
import HiddenIframeDCCConsultation from "./features/hiddenIframe/HiddenIframeDCCConsultation";
import UpdateVariablesOnStartup from "./features/startUpProcesses/UpdateVariablesOnStartup";
const Body = () => {
  const emailData: any = useAppSelector((state) => state.features.emailVivrObj);
  const columns: any = useAppSelector(
    (state) => state.display.layout.columns
  );


  return (
    <>
      <div className="app-body container-fluid p-1">
        <div className="app-body-wrapper row mx-sm-1 no-gutters bg-white rounded p-1">
           {columns === "3" ? <Column1 /> : <></>}
           <Column2 columns={columns} />
        <Column3 />
        </div>
      </div>
      <HiddenIframeVC />
      <TransferAssistModal />
      <DisclosuresWindow /> 
      <ContactVerificationModal />
      <SkipTraceModal />
      <VerbalComplaintOffcanvas />
      <SafeHavenForm />
      <Notifications />
      <HiddenIframeSafeHaven />
      <DCCConsultationOffCanvas />
      <HiddenIframeDCCConsultation />
      <UpdateVariablesOnStartup/>
      {/* <LCOMWindow />
      <PrefWindow /> */}
      {emailData.status ? <EmailVIVRModal /> : <></>} 
    </>
  );
};

export default Body;
