import { createSlice } from "@reduxjs/toolkit";
import { GET_CALLDATA_SUCCESS } from "../../actions/apisActions";
import { BorrowerObject } from "../../types";


const initialState = {
  borrowerFullName: "",
  corrowerFullName: "",
  concat: "",
  display: [],
} as BorrowerObject;

export const borrower = createSlice({
  name: "borrower",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GET_CALLDATA_SUCCESS, (state, action) => {
        const { payload }: any = action;
        state.display = [];

        state.borrowerFullName = payload.data.activeContact.fields["1056"];
        state.corrowerFullName = payload.data.activeContact.fields["1217"];
        state.display.push({
          key: "borrowerFullName",
          label: "Borrower:",
          value: state.borrowerFullName,
          cols: 6,
        });
        state.display.push({
          key: "corrowerFullName",
          label: "Coborrower:",
          value: state.corrowerFullName,
          cols: 6,
        });

        if (state.corrowerFullName === undefined) {
          state.concat = state.borrowerFullName;
        } else {
          state.concat = `${state.borrowerFullName}/${state.corrowerFullName}`;
        }
      })
      .addCase("azure/contactFields/succeeded", (state, action) => {
        const { payload }: any = action;
        state.display = [];
        const fields = payload.data.items[0];

        state.borrowerFullName = fields.borrowerFullName;
        state.corrowerFullName = fields.coBorrowerFullName;
        state.display.push({
          key: "borrowerFullName",
          label: "Borrower:",
          value: state.borrowerFullName,
          cols: 6,
        });
        state.display.push({
          key: "corrowerFullName",
          label: "Coborrower:",
          value: state.corrowerFullName,
          cols: 6,
        });
        if (state.corrowerFullName === null) {
          state.concat = state.borrowerFullName;
        } else {
          state.concat = `${state.borrowerFullName}/${state.corrowerFullName}`;
        }
      });
  },
});
// thunks

// Action creators are generated for each case reducer function
// export const {
//   openTestingModal,
//   closeTestingModal,

// } = azureContactFields.actions;

export default borrower.reducer;
