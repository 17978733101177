/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/no-unescaped-entities */

import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  cancelTransferApi,
  putCompleteTransferApi,
} from "../../../app/thunks/transferThunks";
import { Card, Button } from "react-bootstrap";
import TransferCompleteButton from "./TransferCompleteButton";
import BuildTransferQuestions from "./BuildTransferQuestions";

const TransferWorksheet = () => {
  const dispatch = useAppDispatch();
  // global states
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const transferStatus: string = useAppSelector(
    (state) => state.transferData.transferStatus
  );
  const transferData: any = useAppSelector(
    (state) => state.transferData.transferObject
  );
  const freedomCode: any = useAppSelector(
    (state) => state.features.freedomCodeSelect
  );

  // local states
  const [finalButtonDisabled, setFinalButtonDisabled] = useState(true);
  const [finalOutcome, setFinalOutcome] = useState("");
  const [transferCanceled, setTransferCanceled] = useState(false);

  // useEffect(() => {
  //   console.log("final outcome set to ", finalOutcome);
  // }, [finalOutcome]);

  const handleCancelTransfer = () => {
    dispatch(cancelTransferApi(metadata.data, callIds, transferData));
    setTransferCanceled(true);
  };

  const handleCompleteTransfer = () => {
    dispatch(putCompleteTransferApi(metadata.data, callIds, transferData));
  };
  if (transferStatus === "success" && transferData.type === "transfer") {
    return (
      <Card>
        <Card.Body>
          <p>
            To complete the transfer, when you reach the recieving department
            select 'Complete Transfer'
          </p>
          <p>
            If you are unable to transfer the call and need to bring the
            customer back on the line, select 'Cancel Transfer'.
          </p>
          {transferData.name === "Refinance" ||
          transferData.name === "Purchase" ? (
            <p>
              The Freedom Code for {freedomCode.state} is{" "}
              <b>{freedomCode.code}</b>.
            </p>
          ) : (
            <></>
          )}

          <div className="d-flex justify-content-evenly pb-3 border-bottom">
            <Button variant="primary" onClick={handleCompleteTransfer}>
              Complete Transfer
            </Button>
            <Button variant="primary" onClick={handleCancelTransfer}>
              Cancel Transfer
            </Button>
          </div>
          {transferCanceled ? (
            <BuildTransferQuestions
              transferData={transferData}
              setFinalButtonDisabled={setFinalButtonDisabled}
              setFinalOutcome={setFinalOutcome}
            />
          ) : (
            <></>
          )}
        </Card.Body>
        <Card.Footer>
          <TransferCompleteButton
            finalButtonDisabled={finalButtonDisabled}
            finalOutcome={finalOutcome}
            setFinalButtonDisabled={setFinalButtonDisabled}
            setFinalOutcome={setFinalOutcome}
            setTransferCanceled={setTransferCanceled}
          />
        </Card.Footer>
      </Card>
    );
  } else {
    return <></>;
  }
};

export default TransferWorksheet;
