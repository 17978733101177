/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { Dropdown } from "react-bootstrap";

const TransferAssistDropdownSection = ({
  setSelectedTransfer,
  setDropdownOpen,
  array,
  title,
}: any) => (
  <>
    <Dropdown.Header>{title}</Dropdown.Header>
    {array.map((item: any, i: number) => (
      <Dropdown.Item
        key={i}
        onClick={() => {
          setSelectedTransfer(item.id);
          setDropdownOpen(false);
        }}
      >
        {item.name}
      </Dropdown.Item>
    ))}
  </>
);

export default TransferAssistDropdownSection;
