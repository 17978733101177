import { createSlice } from "@reduxjs/toolkit";
import { GET_CALLDATA_SUCCESS } from "../../actions/apisActions";
import { UPDATE_WORKSHEET_DISCLOSURES_SUCCESS } from "../../actions/worksheetActions";
import { consoleLogIt } from "../../log";
import {
  DisclosuresPhoneArrayObj,
  DisclosuresArrayObj,
  DisclosuresObj,
} from "../../types";
import moment from "moment";

const initialState = {
  data: { bk: "", dc7: "", mm: "", cell: "", cr: "", ca5: "", ca30: "" },
  array: [],
  callData: {
    ani: "",
    dnis: "",
    phoneArray: [],
    currentLMM: "",
    languagePreference: "",
    propertyState: "",
    chapter7: "",
    miniMiranda: "",
    isBankruptcy: "",
    callType: "",
    daysPastDue: 0,
    hudDate: "2001-01-01",
    hudDateDifference: 100,
    initialUpdate: false,
  },
} as DisclosuresObj;

export const disclosures = createSlice({
  name: "disclosures",
  initialState,
  reducers: {
    initialUpdateProvided: (state) => {
      state.callData.initialUpdate = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GET_CALLDATA_SUCCESS, (state, action) => {
        const { payload }: any = action;
        const data = JSON.parse(payload.data.variables["1075"]);
        //Set CallData
        state.callData.callType = payload.data.callType;
        state.callData.ani = payload.data.ani;
        state.callData.dnis = payload.data.dnis;

        // set Contact part of Call Data
        // miranda warning variables
        state.callData.isBankruptcy = payload.data.activeContact.fields["1036"];
        state.callData.miniMiranda = payload.data.activeContact.fields["1158"];
        state.callData.chapter7 = payload.data.activeContact.fields["1171"];
        // CA variables
        state.callData.propertyState =
          payload.data.activeContact.fields["1081"];
        state.callData.languagePreference =
          payload.data.activeContact.fields["1151"];

        if (payload.data.activeContact.fields["1060"] === undefined) {
          state.callData.daysPastDue = 0;
        } else {
          state.callData.daysPastDue = Number(
            payload.data.activeContact.fields["1060"]
          );
        }
        if (
          payload.data.activeContact.fields["1232"] !== "" &&
          payload.data.activeContact.fields["1232"] !== undefined
        ) {
          state.callData.hudDate = moment(
            payload.data.activeContact.fields["1232"]
          ).format("YYYY-MM-DD");
        }
        state.callData.currentLMM = payload.data.activeContact.fields["1350"];
        // set phone array
        state.callData.phoneArray.push({
          num: payload.data.activeContact.fields["1310"],
          code: payload.data.activeContact.fields["1251"],
        });
        state.callData.phoneArray.push({
          num: payload.data.activeContact.fields["1311"],
          code: payload.data.activeContact.fields["1252"],
        });
        state.callData.phoneArray.push({
          num: payload.data.activeContact.fields["1312"],
          code: payload.data.activeContact.fields["1253"],
        });

        // set vars
        const dt = new Date();
        const day = dt.getDate();

        // Set Data
        if (
          state.callData.callType === "QUEUE_CALLBACK" &&
          payload.data.variables["1054"] !== "1"
        ) {
          // clear disclosures on Queue Callbacks
          state.data.bk = "null";
          state.data.dc7 = "null";
          state.data.mm = "null";
          state.data.cell = data.cell;
          state.data.cr = "false";
          state.data.ca5 = "null";
          state.data.ca30 = "null";
        } else {
          // not qcb
          state.data.bk = data.bk;
          state.data.dc7 = data.dc7;
          state.data.mm = data.mm;
          state.data.cell = data.cell;
          state.data.cr = data.cr;
          state.data.ca5 = data.ca5;
          state.data.ca30 = data.ca30;
        }

        // set array
        if (
          data.cr === "agent" ||
          (data.cr === "ivr" && state.callData.callType !== "QUEUE_CALLBACK")
        ) {
          state.array.push({
            id: "cr",
            value: data.cr,
            isRequired: true,
            text: "Call Recording",
          });
        } else {
          state.array.push({
            id: "cr",
            value: "false",
            isRequired: true,
            text: "Call Recording",
          });
        }

        // miranda warning waterfall
        if (state.callData.isBankruptcy === "Y") {
          console.log("bk");
          if (
            data.bk === "agent" ||
            (data.bk === "ivr" && state.callData.callType !== "QUEUE_CALLBACK")
          ) {
            console.log("bk push");
            state.array.push({
              id: "bk",
              value: data.bk,
              isRequired: true,
              text: "Active Bankruptcy",
            });
          } else {
            state.array.push({
              id: "bk",
              value: "false",
              isRequired: true,
              text: "Active Bankruptcy",
            });
          }
        } else if (state.callData.chapter7 === "Y") {
          if (
            data.dc7 === "agent" ||
            (data.dc7 === "ivr" && state.callData.callType !== "QUEUE_CALLBACK")
          ) {
            state.array.push({
              id: "dc7",
              value: data.dc7,
              isRequired: true,
              text: "Discharged Bankruptcy 7",
            });
          } else {
            state.array.push({
              id: "dc7",
              value: "false",
              isRequired: true,
              text: "Discharged Bankruptcy 7",
            });
          }
        } else if (state.callData.miniMiranda === "Y") {
          if (
            data.mm === "agent" ||
            (data.mm === "ivr" && state.callData.callType !== "QUEUE_CALLBACK")
          ) {
            state.array.push({
              id: "mm",
              value: data.mm,
              isRequired: true,
              text: "Mini Miranda",
            });
          } else {
            state.array.push({
              id: "mm",
              value: "false",
              isRequired: true,
              text: "Mini Miranda",
            });
          }
        }
        // California 5 Languages

        if (
          state.callData.propertyState === "CA" &&
          (state.callData.languagePreference === "SP" ||
            state.callData.languagePreference === "MC" ||
            state.callData.languagePreference === "KO" ||
            state.callData.languagePreference === "TA" ||
            state.callData.languagePreference === "VT") &&
          state.callData.currentLMM === "DocsSentToBorrower"
        ) {
          if (
            data.ca5 === "agent" ||
            (data.ca5 === "ivr" && state.callData.callType !== "QUEUE_CALLBACK")
          ) {
            state.array.push({
              id: "ca5",
              value: data.ca5,
              isRequired: true,
              text: "California 5 Languages",
            });
          } else {
            state.array.push({
              id: "ca5",
              value: "false",
              isRequired: true,
              text: "California 5 Languages",
            });
          }
        }

        // ca, nv, wa 30 days delinquent
        const a = moment(state.callData.hudDate);
        const b = moment();
        state.callData.hudDateDifference = b.diff(a, "days"); // 1
        if (
          state.callData.hudDateDifference <= 30 &&
          state.callData.hudDateDifference >= 0
        ) {
          consoleLogIt("HUD Disclosure Given Within 30 Days");
          state.array.push({
            id: "ca30",
            value: "bypass",
            isRequired: true,
            text: "CA, NV, WA 30+ Delinquent Loans",
          });
          state.data.ca30 = "bypass";
        } else {
          if (
            (state.callData.propertyState === "CA" ||
              state.callData.propertyState === "NV" ||
              state.callData.propertyState === "WA") &&
            state.callData.daysPastDue >= 30
          ) {
            if (
              data.ca30 === "agent" ||
              (data.ca30 === "ivr" &&
                state.callData.callType !== "QUEUE_CALLBACK")
            ) {
              state.array.push({
                id: "ca30",
                value: data.ca30,
                isRequired: true,
                text: "CA, NV, WA 30+ Delinquent Loans",
              });
            } else {
              state.array.push({
                id: "ca30",
                value: "false",
                isRequired: true,
                text: "CA, NV, WA 30+ Delinquent Loans",
              });
            }
          }
        }
        //Cell Authorization

        state.callData.phoneArray.map((item: DisclosuresPhoneArrayObj) => {
          if (
            (item.num === state.callData.ani ||
              item.num === state.callData.dnis) &&
            item.code === "U"
          ) {
            if (
              data.cell === "agent" ||
              (data.cell === "ivr" &&
                state.callData.callType !== "QUEUE_CALLBACK")
            ) {
              state.array.push({
                id: "cell",
                value: data.cell,
                isRequired: true,
                text: "Cell Authorization",
              });
            } else {
              state.array.push({
                id: "cell",
                value: "false",
                isRequired: true,
                text: "Cell Authorization",
              });
            }
          }
        });
      })
      .addCase("azure/contactFields/succeeded", (state, action) => {
        const { payload }: any = action;
        const data = state.data;
        // set Contact part of Call Data
        const fields: any = payload.data.items[0];
        // miranda warning variables
        state.callData.isBankruptcy = fields.isBankruptcy;
        state.callData.miniMiranda = fields.miniMiranda;
        state.callData.chapter7 = fields.bankruptcyChapter7DischargeIndicator;
        // CA variables
        state.callData.propertyState = fields.propertyState;
        state.callData.languagePreference = fields.languagePreference;

        if (fields.daysPastDue === null) {
          state.callData.daysPastDue = 0;
        } else {
          state.callData.daysPastDue = Number(fields.daysPastDue);
        }

        state.callData.currentLMM = fields.currentLmmilestone;
        // set phone array
        state.callData.phoneArray.push({
          num: fields.borrowerHomePhone,
          code: fields.phoneNumber1Code,
        });
        state.callData.phoneArray.push({
          num: fields.borrowerSecondaryPhone,
          code: fields.phoneNumber2Code,
        });
        state.callData.phoneArray.push({
          num: fields.borrowerOtherPhone1,
          code: fields.phoneNumber3Code,
        });

        // set vars
        const dt = new Date();
        const day = dt.getDate();

        // set array
        if (
          data.cr === "agent" ||
          (data.cr === "ivr" && state.callData.callType !== "QUEUE_CALLBACK")
        ) {
          state.array.push({
            id: "cr",
            value: data.cr,
            isRequired: true,
            text: "Call Recording",
          });
        } else {
          state.array.push({
            id: "cr",
            value: "false",
            isRequired: true,
            text: "Call Recording",
          });
        }

        // miranda warning waterfall
        if (state.callData.isBankruptcy === "Y") {
          console.log("bk");
          if (
            data.bk === "agent" ||
            (data.bk === "ivr" && state.callData.callType !== "QUEUE_CALLBACK")
          ) {
            console.log("bk push");
            state.array.push({
              id: "bk",
              value: data.bk,
              isRequired: true,
              text: "Active Bankruptcy",
            });
          } else {
            state.array.push({
              id: "bk",
              value: "false",
              isRequired: true,
              text: "Active Bankruptcy",
            });
          }
        } else if (state.callData.chapter7 === "Y") {
          if (
            data.dc7 === "agent" ||
            (data.dc7 === "ivr" && state.callData.callType !== "QUEUE_CALLBACK")
          ) {
            state.array.push({
              id: "dc7",
              value: data.dc7,
              isRequired: true,
              text: "Discharged Bankruptcy 7",
            });
          } else {
            state.array.push({
              id: "dc7",
              value: "false",
              isRequired: true,
              text: "Discharged Bankruptcy 7",
            });
          }
        } else if (state.callData.miniMiranda === "Y") {
          if (
            data.mm === "agent" ||
            (data.mm === "ivr" && state.callData.callType !== "QUEUE_CALLBACK")
          ) {
            state.array.push({
              id: "mm",
              value: data.mm,
              isRequired: true,
              text: "Mini Miranda",
            });
          } else {
            state.array.push({
              id: "mm",
              value: "false",
              isRequired: true,
              text: "Mini Miranda",
            });
          }
        }
        // California 5 Languages

        if (
          state.callData.propertyState === "CA" &&
          (state.callData.languagePreference === "SP" ||
            state.callData.languagePreference === "MC" ||
            state.callData.languagePreference === "KO" ||
            state.callData.languagePreference === "TA" ||
            state.callData.languagePreference === "VT") &&
          state.callData.currentLMM === "DocsSentToBorrower"
        ) {
          if (
            data.ca5 === "agent" ||
            (data.ca5 === "ivr" && state.callData.callType !== "QUEUE_CALLBACK")
          ) {
            state.array.push({
              id: "ca5",
              value: data.ca5,
              isRequired: true,
              text: "California 5 Languages",
            });
          } else {
            state.array.push({
              id: "ca5",
              value: "false",
              isRequired: true,
              text: "California 5 Languages",
            });
          }
        }
        // ca, nv, wa 30 days delinquent
        const a = moment(state.callData.hudDate);
        const b = moment();
        state.callData.hudDateDifference = b.diff(a, "days"); // 1
        if (
          state.callData.hudDateDifference <= 30 &&
          state.callData.hudDateDifference >= 0
        ) {
          consoleLogIt("HUD Disclosure Given Within 30 Days");
          state.array.push({
            id: "ca30",
            value: "bypass",
            isRequired: true,
            text: "CA, NV, WA 30+ Delinquent Loans",
          });
          state.data.ca30 = "bypass";
        } else {
          if (state.callData.hudDateDifference < 30)
            if (
              (state.callData.propertyState === "CA" ||
                state.callData.propertyState === "NV" ||
                state.callData.propertyState === "WA") &&
              state.callData.daysPastDue <= 45 &&
              state.callData.daysPastDue >= 30 &&
              day < 15
            ) {
              if (
                data.ca30 === "agent" ||
                (data.ca30 === "ivr" &&
                  state.callData.callType !== "QUEUE_CALLBACK")
              ) {
                state.array.push({
                  id: "ca30",
                  value: data.ca30,
                  isRequired: true,
                  text: "CA, NV, WA 30+ Delinquent Loans",
                });
              } else {
                state.array.push({
                  id: "ca30",
                  value: "false",
                  isRequired: true,
                  text: "CA, NV, WA 30+ Delinquent Loans",
                });
              }
            }
        }

        //Cell Authorization

        state.callData.phoneArray.map((item) => {
          if (
            (item.num === state.callData.ani ||
              item.num === state.callData.dnis) &&
            item.code === "U"
          ) {
            if (
              data.cell === "agent" ||
              (data.cell === "ivr" &&
                state.callData.callType !== "QUEUE_CALLBACK")
            ) {
              state.array.push({
                id: "cell",
                value: data.cell,
                isRequired: true,
                text: "Cell Authorization",
              });
            } else {
              state.array.push({
                id: "cell",
                value: "false",
                isRequired: true,
                text: "Cell Authorization",
              });
            }
          }
        });
      })
      .addCase(UPDATE_WORKSHEET_DISCLOSURES_SUCCESS, (state, action) => {
        const { payload }: any = action;
        const data = JSON.parse(payload[1075]);
        // update object
        state.data.bk = data.bk;
        state.data.dc7 = data.dc7;
        state.data.mm = data.mm;
        state.data.cell = data.cell;
        state.data.cr = data.cr;
        state.data.ca5 = data.ca5;
        state.data.ca30 = data.ca30;
        // update array
        const array = [];
        for (let i = 0; i < state.array.length; i++) {
          const item: DisclosuresArrayObj = state.array[i];
          item.value = data[item.id];
          array.push(item);
        }
        state.array = array;
      });
  },
});
// thunks

// Action creators are generated for each case reducer function
// export const {
//   openTestingModal,
//   closeTestingModal,

// } = azureContactFields.actions;

export default disclosures.reducer;
