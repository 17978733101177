/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { Button, Modal } from "react-bootstrap";
import {
  updateValue,
  closeVerificationWindow,
} from "../../../app/slices/features/contactVerificationSlice";

import {
  postVerificationVariables,
  postVerifcationContact,
} from "../../../app/slices/features/contactVerificationUnwrap";
import { updateCallData } from "../../../app/thunks/getApisThunks";
import { consoleLogIt } from "../../../app/log";
const names: string[] = ["num1", "num2", "num3", "email1", "email2"];

const ContactVerificationFooter = () => {
  const dispatch = useAppDispatch();
  // global states
  const verification: any = useAppSelector(
    (state) => state.contactVerification
  );
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const calldata: any = useAppSelector((state) => state.main.calldata);

  const [disable, setDisable] = useState(true);

  useEffect(() => {
    let c = 0;
    for (let i = 0; i < names.length; i++) {
      const k = names[i];
      if (verification[k] === "null") {
        c++;
      }
    }
    if (c === 0) {
      setDisable(false);
      dispatch(updateCallData(metadata.data));
      const t = new Date();
      const todayFormat =
        t.getFullYear() +
        "-" +
        ("0" + (t.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + t.getDate()).slice(-2);
      if (verification.date !== todayFormat) {
        dispatch(updateValue({ name: "date", value: todayFormat }));
      }
      if (verification.verifiedBy === "" || verification.verifiedBy === "IVA") {
        dispatch(updateValue({ name: "verifiedBy", value: callIds.userName }));
      }
    }
  }, [verification]);
  // TODO ADD REMOVE BUTTONS FOR PHONE AND EMAIL
  return (
    <Modal.Footer>
      <Button
        variant="primary"
        disabled={disable}
        onClick={() => {
          postVerificationVariables(metadata, callIds, verification, dispatch);

          if (
            verification.num1 === "NA" ||
            verification.num2 === "NA" ||
            verification.num3 === "NA" ||
            verification.email1 === "NA" ||
            verification.email2 === "NA"
          ) {
            dispatch(closeVerificationWindow());
            consoleLogIt("Verification Not Completed");
          } else {
            postVerifcationContact(metadata, callIds, verification, dispatch);
          }
        }}
      >
        Submit
      </Button>
    </Modal.Footer>
  );
};

export default ContactVerificationFooter;
