/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { updateVerbalComplaint } from "../verbalComplaintSlice";
import ServicingProcessAreaInput from "./ServicingProcessAreaInput";
import VCIssueNotes from "./VCIssueNotes";

const VCToggleButtons = ({ qna, verbalComplaint }: any) => {
  const dispatch = useAppDispatch();
  const [collapseState, setCollapseState] = useState(verbalComplaint[qna.name] !== "" ? true : false);
  const [spaOther, setSpaOther] = useState(false);
  const [issueOther, setIssueOther] = useState(false);

  return (
    <div
      id={qna.name}
      className={
        verbalComplaint[qna.name] !== "" && collapseState === true
          ? "cc-vc-collapse"
          : ""
      }
    >
      <div
        onClick={() => {
          //console.log("On click triggered");
          if (collapseState === false) {
            //console.log("set collapse state to true");
            setCollapseState(true);
          } else {
            //console.log("set collapse state to false");
            setCollapseState(false);
          }
        }}
      >
        <b>{qna.title}</b>
        {/* <p className="mb-1 cc-vc-description">{qna.description}</p> */}
      </div>
      <ButtonGroup className="d-flex flex-wrap justify-content-start mt-1">
        {qna.answers.map((answer: any) => {
          const determineButtonClassName = () => {
            if (verbalComplaint[qna.name] !== answer.value) {
              return "unchecked";
            } else {
              return "";
            }
          };

          const toggleHandleChange = () => {
            if (
              answer.name === "servicingProcessArea" &&
              answer.value === "Other"
            ) {
              setSpaOther(true);
              setCollapseState(true);
            } else if(answer.name === "vcIssue" &&
            answer.value === "Other (see my notes below)"){
              setIssueOther(true);
              setCollapseState(true);
            }else{
              setSpaOther(false);
              setCollapseState(true);
              dispatch(updateVerbalComplaint(answer));
            }
          };

          const determinChecked = () => {
            if (verbalComplaint[answer.name] === answer.value) {
              return true;
            } else if (answer.value === "Other" && spaOther === true) {
              return true;
            } else {
              return false;
            }
          };
          return (
            <ToggleButton
              key={`${answer.id}`}
              id={`${answer.id}`}
              type="radio"
              variant="outline-secondary"
              name={answer.name}
              checked={determinChecked()}
              className={determineButtonClassName()}
              value={answer.value}
              onChange={toggleHandleChange}
            >
              {answer.text}
            </ToggleButton>
          );
        })}
      </ButtonGroup>
      {spaOther === true ? <ServicingProcessAreaInput verbalComplaint={verbalComplaint}/> : <></>}
      {issueOther === true ? <VCIssueNotes verbalComplaint={verbalComplaint}/> : <></>}
    </div>
  );
};

export default VCToggleButtons;
