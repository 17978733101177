/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { combineReducers } from "redux";
import {
  INITIATE_TRANSFER_IN_PROGRESS,
  INITIATE_TRANSFER_SUCCESS,
  INITIATE_TRANSFER_FAILED,
  INITIATE_CONFERENCE_IN_PROGRESS,
  INITIATE_CONFERENCE_SUCCESS,
  INITIATE_CONFERENCE_FAILED,
  UPDATE_TRANSFER_FLAG_IN_PROGRESS,
  UPDATE_TRANSFER_FLAG_SUCCESS,
  UPDATE_TRANSFER_FLAG_FAILED,
  COMPLETE_TRANSFER_API_IN_PROGRESS,
  COMPLETE_TRANSFER_API_SUCCESS,
  COMPLETE_TRANSFER_API_FAILED,
} from "../actions/apisActions";

import { CLEAR_TRANSFER_OBJECT } from "../actions/standardActions";

const transferStatus = (state = "null", action: any) => {
  const { type } = action;
  switch (type) {
    case INITIATE_CONFERENCE_IN_PROGRESS:
      return "in_progress";
    case INITIATE_TRANSFER_IN_PROGRESS:
      return "in_progress";

    case INITIATE_CONFERENCE_SUCCESS:
      return "success";
    case INITIATE_TRANSFER_SUCCESS:
      return "success";
    case INITIATE_CONFERENCE_FAILED:
      return "failed";
    case INITIATE_TRANSFER_FAILED:
      return "failed";
    case CLEAR_TRANSFER_OBJECT:
      return "";
    case UPDATE_TRANSFER_FLAG_SUCCESS:
      return "";
    default:
      return state;
  }
};
const transferObject = (state = { status: "null" }, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case INITIATE_CONFERENCE_IN_PROGRESS:
      return {
        id: payload.id,
        type: payload.type,
        worksheet: payload.worksheet,
        transferId: payload.transfeId,
        name: payload.name,
      };
    case INITIATE_TRANSFER_IN_PROGRESS:
      return {
        id: payload.id,
        type: payload.type,
        worksheet: payload.worksheet,
        transferId: payload.transfeId,
        name: payload.name,
      };

    default:
      return state;
  }
};

const transferFlag = (state = {}, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_TRANSFER_FLAG_IN_PROGRESS:
      return { status: "In Progress" };
    case UPDATE_TRANSFER_FLAG_SUCCESS:
      return { status: "Success" };
    case UPDATE_TRANSFER_FLAG_FAILED:
      return { status: "Failed" };
    default:
      return state;
  }
};

const completeTransfer = (state = {}, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case COMPLETE_TRANSFER_API_IN_PROGRESS:
      return { status: "In Progress" };
    case COMPLETE_TRANSFER_API_SUCCESS:
      return { status: "Success" };
    case COMPLETE_TRANSFER_API_FAILED:
      return { status: "Failed" };
    default:
      return state;
  }
};

export const transferData = combineReducers({
  transferStatus,
  transferObject,
  transferFlag,
  completeTransfer,

});
