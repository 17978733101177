import { createSlice, createAction, nanoid } from "@reduxjs/toolkit";
import { consoleLogIt, consoleErrorIt, reportError } from "../../log";
import {
  GET_CALLDATA_SUCCESS,
  GET_CALLDATA_SUCCESS_NO_LOAN,
} from "../../actions/apisActions";
import { CallIdObject } from "../../types";



const initialState = {
  loanNumber: "",
  numbers: {
    p: "",
    s: "",
    o: "",
  },
  rptCallId: "",
  userId: "",
  userName: "",
  systemCallId: "",
  campaignId: "",
  custPhone: "",
  contactId: "",
  contactModTime: "",
  display: [],
} as CallIdObject;

export const callIds = createSlice({
  name: "callIds",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GET_CALLDATA_SUCCESS_NO_LOAN, (state, action) => {
        const { payload }: any = action;

        if (
          payload.data.variables["44"] === "Inbound" &&
          payload.data.callType !== "QUEUE_CALLBACK"
        ) {
          state.custPhone = payload.data.ani;
        } else {
          state.custPhone = payload.data.dnis;
        }
        state.rptCallId = payload.data.variables["42"];
        state.userId = payload.data.variables["75"];
        state.userName = payload.data.variables["76"];
        state.systemCallId = payload.data.id;
      })
      .addCase(GET_CALLDATA_SUCCESS, (state, action) => {
        const { payload }: any = action;
        // build display
        state.display.push({
          key: "msploan",
          label: "MSP Loan#:",
          value: payload.data.activeContact.fields["1033"],
          cols: 12,
        });
        // customer phone number
        if (
          payload.data.variables["44"] === "Inbound" &&
          payload.data.callType !== "QUEUE_CALLBACK"
        ) {
          state.custPhone = payload.data.ani;
        } else {
          state.custPhone = payload.data.dnis;
        }
        state.loanNumber = payload.data.activeContact.fields["1033"];

        state.numbers.p = payload.data.activeContact.fields["1017"];
        state.numbers.s = payload.data.activeContact.fields["1018"];
        state.numbers.o = payload.data.activeContact.fields["1019"];

        state.rptCallId = payload.data.variables["42"];
        state.userId = payload.data.variables["75"];
        state.userName = payload.data.variables["76"];
        state.systemCallId = payload.data.id;
        state.campaignId = payload.data.campaignId;

        state.contactId = payload.data.activeContact.id;
        state.contactModTime = payload.data.activeContact.modificationTime;
      });
  },
});
// thunks

// Action creators are generated for each case reducer function
// export const {
//   openTestingModal,
//   closeTestingModal,

// } = azureContactFields.actions;

export default callIds.reducer;
