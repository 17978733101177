import { createSlice } from "@reduxjs/toolkit";
import { GET_CALLDATA_SUCCESS } from "../../actions/apisActions";
import { UPDATE_SKIP_TRACE_VARIABLE_SUCCESS } from "../../actions/worksheetActions";
import { consoleLogIt, consoleErrorIt } from "../../log";

const initialState = {
  badNumber: "",
  window: false,
  variablePost: "",
};

export const skipTrace = createSlice({
  name: "skipTrace",
  initialState,
  reducers: {
    updateSkipValue: (state: any, action: any) => {
      const { payload }: any = action;

      state.badNumber = payload;
    },
    closeSkipWindow: (state: any) => {
      state.window = false;
    },
    openSkipWindow: (state: any) => {
      state.window = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GET_CALLDATA_SUCCESS, (state, action) => {
        const { payload }: any = action;
        if(payload.data.activeContact.fields["432"] !== undefined) {
          state.badNumber = payload.data.activeContact.fields["432"];
        } 

      })

      .addCase(UPDATE_SKIP_TRACE_VARIABLE_SUCCESS, (state) => {
        state.variablePost = "success";
        state.window = false;
      });
  },
});
// thunks

// Action creators are generated for each case reducer function
export const { updateSkipValue, closeSkipWindow, openSkipWindow } =
  skipTrace.actions;

export default skipTrace.reducer;
