import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { consoleErrorIt } from "../../../app/log";

// export const submitPrefToWhendu = createAsyncThunk(
//   (data: any, section: string, loanNumber: string) => {
//     try {
//       // eslint-disable-next-line no-undef
//       const response = await fetch(`${baseUrl}${endPoint}`, {
//         method: "Post",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(body),
//       });

//       // const data = await response.json();
//       const code = await response.ok;
//       if (code === true) {
//         dispatch(updateLCOMPageSuccess(body));
//       } else {
//         dispatch(updateLCOMPageFailed(body));
//       }
//     } catch (e) {
//       consoleErrorIt("Failed to update Preferences", data);
//     }
//   }
// );


export const prefSlice = createSlice({
  name: "pref",
  initialState: {
    num1: {
      id: "",
      call: "Y",
      text: "Y",
      timezone: "0",
      morning: "Y",
      afternoon: "Y",
      evening: "Y",
      monday: "Y",
      tuesday: "Y",
      wednesday: "Y",
      thursday: "Y",
      friday: "Y",
      saturday: "Y",
      submitted: false,
    },

    num2: {
      id: "",
      call: "Y",
      text: "Y",
      timezone: "0",
      morning: "Y",
      afternoon: "Y",
      evening: "Y",
      monday: "Y",
      tuesday: "Y",
      wednesday: "Y",
      thursday: "Y",
      friday: "Y",
      saturday: "Y",
      submitted: false,
    },
    num3: {
      id: "",
      call: "Y",
      text: "Y",
      timezone: "0",
      morning: "Y",
      afternoon: "Y",
      evening: "Y",
      monday: "Y",
      tuesday: "Y",
      wednesday: "Y",
      thursday: "Y",
      friday: "Y",
      saturday: "Y",
      submitted: false,
    },
    email1: {
      section: "",
      email: "Y",
      timezone: "0",
      morning: "Y",
      afternoon: "Y",
      evening: "Y",
      monday: "Y",
      tuesday: "Y",
      wednesday: "Y",
      thursday: "Y",
      friday: "Y",
      saturday: "Y",
      submitted: false,
    },
    email2: {
      section: "",
      email: "Y",
      timezone: "0",
      morning: "Y",
      afternoon: "Y",
      evening: "Y",
      monday: "Y",
      tuesday: "Y",
      wednesday: "Y",
      thursday: "Y",
      friday: "Y",
      saturday: "Y",
      submitted: false,
    },
    created: false,
  },
  reducers: {
    create: (state, action: any) => {
      const obj = state;
      if (
        action.payload.number1.input !== "" ||
        action.payload.number1.input !== undefined
      ) {
        obj.num1.id = action.payload.number1.id;
      }
      if (
        action.payload.number2.input !== "" ||
        action.payload.number2.input !== undefined
      ) {
        obj.num2.id = action.payload.number2.id;
      }
      if (
        action.payload.number3.input !== "" ||
        action.payload.number3.input !== undefined
      ) {
        obj.num3.id = action.payload.number3.id;
      }
      if (
        action.payload.email1.input === "" ||
        action.payload.email1.input === undefined
      ) {
        obj.email1.section = "borrower";
      }
      if (
        action.payload.email2.input === "" ||
        action.payload.email2.input === undefined
      ) {
        obj.email2.section = "coborrower";
      }
      obj.created = true;
      state = obj;
    },
    update: (state, action: any) => {
      const section: string = action.payload.section;
      const data: any = state;
      const obj: any = data[section];
      obj[action.payload.type] = action.payload.value;
      obj.submitted = false;

      data[section] = obj;
      state = data;
    },
  },
});
// thunks

// Action creators are generated for each case reducer function
export const { update } = prefSlice.actions;

export default prefSlice.reducer;
