/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const OPEN_PREF_WORKSHEET = "OPEN_PREF_WORKSHEET";
export const openPrefWorksheet = () => ({
  type: OPEN_PREF_WORKSHEET,
});

export const CLOSE_PREF_WORKSHEET = "CLOSE_PREF_WORKSHEET";
export const closePrefWorksheet = () => ({
  type: CLOSE_PREF_WORKSHEET,
});


// Submit Pref Update to WhenDu

export const UPDATE_PREF_PAGE_IN_PROGRESS = "UPDATE_PREF_PAGE_IN_PROGRESS";
export const updatePrefPageInProgress = () => ({
  type: UPDATE_PREF_PAGE_IN_PROGRESS,
});
export const UPDATE_PREF_PAGE_SUCCESS = "UPDATE_PREF_PAGE_SUCCESS";
export const updatePrefPageSuccess = () => ({
  type: UPDATE_PREF_PAGE_SUCCESS,
});
export const UPDATE_PREF_PAGE_FAILED = "UPDATE_PREF_PAGE_FAILED";
export const updatePrefPageFailed = () => ({
  type: UPDATE_PREF_PAGE_FAILED,
});
export const UPDATE_PREF_PAGE_FAILED_COMPLETELY =
  "UPDATE_PREF_PAGE_FAILED_COMPLETELY";
export const updatePrefPageFailedCompletely = () => ({
  type: UPDATE_PREF_PAGE_FAILED_COMPLETELY,
});