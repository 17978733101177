/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppDispatch } from "../../../app/hooks";
import {
  updateWorksheetTabs,
  updateColumn2Tabs,
} from "../../../app/actions/standardActions";
import { Nav } from "react-bootstrap";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NavButton = ({ eKey, title, className, complete }: any) => {
  const dispatch = useAppDispatch();
  if (complete === false) {
    return (
      <Nav.Item>
        <Nav.Link
          eventKey={eKey}
          className={`btn btn-sm ${className}`}
          onClick={() => {
            dispatch(updateWorksheetTabs(eKey));
            switch (eKey) {
              case "refi":
                dispatch(updateColumn2Tabs("refi_scripts"));
                break;
              case "matic":
                dispatch(updateColumn2Tabs("matic_scripts"));
                break;
              case "cashout":
                dispatch(updateColumn2Tabs("cashout_scripts"));
                break;
              case "reverse":
                dispatch(updateColumn2Tabs("reverse_scripts"));
                break;
              case "safe_haven":
                dispatch(updateColumn2Tabs("safe_haven_scripts"));
                break;
              default:
                break;
            }
          }}
        >
          {title}
        </Nav.Link>
      </Nav.Item>
    );
  } else {
    return (
      <Nav.Item>
        <Nav.Link
          eventKey={eKey}
          className={`btn btn-sm ${className}`}
          onClick={() => {
            dispatch(updateWorksheetTabs(eKey));
            switch (eKey) {
              case "refi":
                dispatch(updateColumn2Tabs("refi_scripts"));
                break;
              case "matic":
                dispatch(updateColumn2Tabs("matic_scripts"));
                break;
              case "cashout":
                dispatch(updateColumn2Tabs("cashout_scripts"));
                break;
              case "reverse":
                dispatch(updateColumn2Tabs("reverse_scripts"));
                break;
                case "safe_haven":
                  dispatch(updateColumn2Tabs("safe_haven_scripts"));
                  break;
              default:
                break;
            }
          }}
        >
          {title}{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-check2-circle"
            viewBox="0 0 16 16"
          >
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>
        </Nav.Link>
      </Nav.Item>
    );
  }
};

export default NavButton;
