import { createSlice } from "@reduxjs/toolkit";

export interface DCCConsultationQRPCInterface {
  questions: {
    employment: null | string;

    hardshipReason: null | string;
    hardshipLength: null | string;
    hardshipResolved: null | string;
    hardshipBegin: null | Date;
    hardshipEnd: null | Date;
    hardshipCovid: null | string;
    hardshipFemaDisaster: null | string;
    hardshipFemaDisasterType: null | string;

    propertyIntentions: null | string;
    propertyOccupancy: null | string;
    propertyAbandoned: null | string;
    propertyCondemned: null | string;

    affordability: null | string;
    grossHouseholdIncome: null | string;
  };
  data: {
    apiStatus: null | "success" | "failed";
    show: boolean;
    stage: number;
    dataSent: boolean;
    send: boolean;
  };
}

const initialState = {
  questions: {
    employment: null,
    hardshipReason: null,
    hardshipLength: null,
    hardshipResolved: null,
    hardshipBegin: null,
    hardshipEnd: null,
    hardshipCovid: null,
    hardshipFemaDisaster: null,
    hardshipFemaDisasterType: null,
    propertyIntentions: null,
    propertyOccupancy: null,
    propertyAbandoned: null,
    propertyCondemned: null,
    affordability: null,
    grossHouseholdIncome: "0.00"
  },
  data: {
    apiStatus: null,
    show: false,
    stage: 0,
    dataSent: false,
    send: false,
  },
} as DCCConsultationQRPCInterface;

export const dccConsultationQRPC = createSlice({
  name: "incentiveOptions",
  initialState,
  reducers: {
    openDCCConsultation: (state) => {
      state.data.show = true;
    },
    closeDCCConsultation: (state) => {
      state.data.show = false;
    },
    updateDCCQuestion: (state: any, action: any) => {
      const { payload }: any = action;
      state.questions[payload.name] = payload.value;
      if (payload.name === "hardshipReason") {
        if (payload.value === "Energy-environment") {
          state.questions.hardshipCovid = "Yes";
        } else {
          state.questions.hardshipCovid = null;
        }
      }
    },
    updateDCCIncome: (state: any, action: any) => {
      const { payload }: any = action;
      state.questions.grossHouseholdIncome = Number(payload).toFixed(2);

    },
    updateDCCStage: (state: any) => {
      if (state.questions.employment === null) {
        state.data.stage === 0;
      }

      if (
        state.questions.employment !== null &&
        state.questions.hardshipReason === null
      ) {
        state.data.stage === 1;
      }
    },
    submitDCCData: (state: any) => {
      state.data.send = true;
    },
    dccDataSubmitted: (state: any) => {
      state.data.dataSent = true;
    },
  },
  extraReducers: {},
});

// Action creators are generated for each case reducer function
export const {
  openDCCConsultation,
  closeDCCConsultation,
  updateDCCQuestion,
  updateDCCStage,
  submitDCCData,
  dccDataSubmitted,
  updateDCCIncome,
} = dccConsultationQRPC.actions;

export default dccConsultationQRPC.reducer;
