/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { Form, Col } from "react-bootstrap";

const InfoRowNew = ({ array, group }: any) => {


  return (
    <>
      {array.map((item: any, i: number) => (
        <Col sm={item.cols} key={group + i} className="info-row">
          <Form.Group controlId={item.key}>
            <Form.Label>{item.label}</Form.Label>
            <Form.Control
              type="text"
              size="sm"
              defaultValue={item.value}
              readOnly
            />
          </Form.Group>
        </Col>
      ))}
    </>
  );
};

export default InfoRowNew;
