import { createSlice} from "@reduxjs/toolkit";
import {
  GET_CALLDATA_SUCCESS,
} from "../../actions/apisActions";
import { ContactObject } from "../../types";


const initialState = {
  number1: "",
  number2: "",
  number3: "",
  number1Code: "",
  number2Code: "",
  number3Code: "",
  borrowerEmail: "",
  coborrowerEmail: "",
  display: [],
} as ContactObject;

export const contact = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GET_CALLDATA_SUCCESS, (state, action) => {
        const { payload }: any = action;
        state.display = [];
        state.number1 = payload.data.activeContact.fields["1310"];
        state.number2 = payload.data.activeContact.fields["1311"];
        state.number3 = payload.data.activeContact.fields["1312"];
        state.number1Code = payload.data.activeContact.fields["1251"];
        state.number2Code = payload.data.activeContact.fields["1252"];
        state.number3Code = payload.data.activeContact.fields["1253"];
        state.borrowerEmail = payload.data.activeContact.fields["1142"];
        state.coborrowerEmail = payload.data.activeContact.fields["1145"];

        state.display.push({
          key: "number1",
          label: "Primary:",
          value: state.number1,
          cols: 4,
        });
        state.display.push({
          key: "number2",
          label: "Alternative #1:",
          value: state.number2,
          cols: 4,
        });
        state.display.push({
          key: "number3",
          label: "Alternative #2:",
          value: state.number3,
          cols: 4,
        });
        state.display.push({
          key: "borrowerEmail",
          label: "Borrower Email:",
          value: state.borrowerEmail,
          cols: 12,
        });
        state.display.push({
          key: "coborrowerEmail",
          label: "Coborrower Email:",
          value: state.coborrowerEmail,
          cols: 12,
        });
      })
      .addCase("azure/contactFields/succeeded", (state, action) => {
        const { payload }: any = action;
        const fields = payload.data.items[0];
        state.display = [];
        state.number1 = fields.borrowerHomePhone;
        state.number2 = fields.borrowerSecondaryPhone;
        state.number3 = fields.borrowerOtherPhone1;
        state.number1Code = fields.phoneNumber1Code;
        state.number2Code = fields.phoneNumber2Code;
        state.number3Code = fields.phoneNumber3Code;
        state.borrowerEmail = fields.borrowerEmail;
        state.coborrowerEmail = fields.coBorrowerEmail;

        state.display.push({
          key: "number1",
          label: "Primary:",
          value: state.number1,
          cols: 4,
        });
        state.display.push({
          key: "number2",
          label: "Alternative #1:",
          value: state.number2,
          cols: 4,
        });
        state.display.push({
          key: "number3",
          label: "Alternative #2:",
          value: state.number3,
          cols: 4,
        });
        state.display.push({
          key: "borrowerEmail",
          label: "Borrower Email:",
          value: state.borrowerEmail,
          cols: 12,
        });
        state.display.push({
          key: "coborrowerEmail",
          label: "Coborrower Email:",
          value: state.coborrowerEmail,
          cols: 12,
        });
      });
  },
});
// thunks

// Action creators are generated for each case reducer function
// export const {
//   openTestingModal,
//   closeTestingModal,

// } = azureContactFields.actions;

export default contact.reducer;
