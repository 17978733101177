
import { consoleLogIt, consoleErrorIt } from "../../../../app/log";


export const thresholdReached =
(ids: any, type: string) => async (disaptch: any) => {
  try {
    // dispatch(initiateConferenceInProgress(obj));
    const body = {
      threshold: type,
      "call_id": ids.rptCallId,
      "user_id": ids.userId,
      username: ids.userName,
    };
    const response = await fetch(
      `https://api.prod2.us.whendu.com/v1/webhooks/86146d82-eb2f-47f1-9d61-9816492f6965/catch/RHa8BpjgV4U5LM172vMb4OQqb8kVEz4r/event/Threshold Reached`,
      {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    const code = await response.ok;

    if (code === true) {
      consoleLogIt(
        `Threshold Reached for ${type} - Sent to WhenDu Successful`
      );
    } else {
      consoleErrorIt(`Threshold Reached for ${type} - Sent to WhenDu Failed`);
    }
  } catch (e) {
    // eslint-disable-next-line no-undef
    consoleErrorIt("WhenDu Theshold Reached Failed - Catch E", e);
  }
};
