import React from "react";
import { Dropdown } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  openTransferAsssitWindow,
  openWebsocketModal,
  openEmailVivrWindow,
} from "../../app/actions/standardActions";
import { openSkipWindow } from "../../app/slices/features/skipTraceSlice";
import { openPrefWorksheet } from "../../app/actions/prefActions";
import { openVerbalComplaint } from "../offCanvas/verbalComplaint/verbalComplaintSlice";
import { openDCCConsultation } from "../offCanvas/dccConsultationQRPC/DCCConsultationQRPCSlice";

const QuickdrawSecion = ({ title, array, skipTrace }: any) => {
  const dispatch = useAppDispatch();
  const validEmail: any = useAppSelector((state) => state.main.validEmail);

  const checkRunEvent = (selected: any) => {
    switch (selected.action) {
      case "Run":
        if (selected.event === "transferAssist") {
          dispatch(openTransferAsssitWindow());
        } else if (selected.event === "skipTrace") {
          dispatch(openSkipWindow());
        } else if (selected.event === "debug_tool") {
          dispatch(openWebsocketModal());
        } else if (selected.event === "contactPreference") {
          dispatch(openPrefWorksheet());
        } else if (selected.event === "goodCallEmail") {
          dispatch(
            openEmailVivrWindow({ type: "GoodCall", address: validEmail.email })
          );
        } else if (selected.event === "verbalComplaint") {
          dispatch(openVerbalComplaint());
        } else if (selected.event === "dccConsultationForm") {
          dispatch(openDCCConsultation());
        }

        break;
      case "Pop-Up":
        // eslint-disable-next-line no-undef
        if (
          selected.event === "securepay" ||
          selected.event === "onlineFlightManual"
        ) {
          window.open(
            selected.url,
            selected.component,
            "width=1300,height=700,scrollbars=yes"
          );
        } else {
          window.open(
            selected.url,
            selected.component,
            "width=800,height=700,scrollbars=yes"
          );
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Dropdown.Header>{title}</Dropdown.Header>
      {array.map((item: any) => {
        if (item.event === "skipTrace" && skipTrace.skipTrace === true) {
          return <div key={item.event}></div>;
        } else {
          return (
            <Dropdown.Item
              key={item.event}
              eventKey={item.event}
              onClick={() => {
                checkRunEvent(item);
              }}
            >
              {item.title}
            </Dropdown.Item>
          );
        }
      })}
    </>
  );
};

export default QuickdrawSecion;
