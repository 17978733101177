/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  leaveConferenceApi,
  joinConferenceApi,
  disconnectConferenceParticipantApi,
  cancelConferenceParticipantApi,
} from "../../../app/thunks/transferThunks";
import { Card, Button } from "react-bootstrap";
import ConferenceCompleteButton from "./ConferenceCompleteButton";
import BuildTransferQuestions from "./BuildTransferQuestions";

const ConferenceWorksheet = () => {
  const dispatch = useAppDispatch();
  // global states
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const transferStatus: any = useAppSelector(
    (state) => state.transferData.transferStatus
  );
  const transferData: any = useAppSelector(
    (state) => state.transferData.transferObject
  );

  const conferenceObject: any = useAppSelector(
    (state) => state.conferenceObject
  );


  // local states
  const [finalButtonDisabled, setFinalButtonDisabled] = useState(true);
  const [finalOutcome, setFinalOutcome] = useState("");
  const [conferenceCanceled, setConferenceCanceled] = useState(false);
  const [joinDisabled, setJoinedDisabled] = useState(true);
  const [joinedCalls, setJoinedCalls] = useState(false);

  useEffect(() => {
    if (conferenceObject.status === true) {
     // console.log("conference array use effect");
      const participant = conferenceObject.participant2;
     // console.log(participant)
      if (participant.state === "CONFERENCE_PARTICIPANT_CONSULTING") {
        setJoinedDisabled(false);
      } else {
        setJoinedDisabled(true);
      }
      if (
        participant.event !== "CONFERENCE_PARTICIPANT_RINGING" &&
        participant.state === "CONFERENCE_PARTICIPANT_TALKING"
      ) {
        setJoinedCalls(true);
      } else {
        setJoinedCalls(false);
      }
    }
  }, [conferenceObject]);

  const conferenceButtonsInitial = () => {
    const handleCancelConference = () => {
      const participant = conferenceObject.participant2;
      setConferenceCanceled(true);
      dispatch(cancelConferenceParticipantApi(metadata.data, participant));
    };

    const handleJoinConference = () => {
      const participant = conferenceObject.participant2;
      dispatch(joinConferenceApi(metadata.data, participant));
    };

    return (
      <div className="d-flex justify-content-evenly pb-3 border-bottom">
        <Button
          variant="primary"
          onClick={handleJoinConference}
          disabled={joinDisabled}
        >
          Join Parties
        </Button>
        <Button variant="primary" onClick={handleCancelConference}>
          Cancel Conference
        </Button>
      </div>
    );
  };

  const conferenceButtonsJoined = () => {
    const handleDisconnectParticipant = () => {
      const participant = conferenceObject.participant2;

      dispatch(disconnectConferenceParticipantApi(metadata.data, participant));
    };

    // const handleLeaveConference = () => {
    //   dispatch(leaveConferenceApi(metadata.data, callIds));
    // };

    return (
      <div className="d-flex justify-content-evenly pb-3 border-bottom">
        {/* <Button variant="primary" onClick={handleLeaveConference}>
          Leave Conference
        </Button> */}
        <Button variant="primary" onClick={handleDisconnectParticipant}>
          Disconnect - {transferData.name}
        </Button>
      </div>
    );
  };
  if (conferenceObject.status === true) {
    if (transferStatus === "success" && transferData.type === "conference") {
      return (
        <Card>
          <Card.Body>
            <p>To join the 2 calls together select 'Join Calls'</p>
            <p>
              If you are unable to complete the conference and need to bring the
              customer back on the line, select 'Cancel Conference'.
            </p>

            {joinedCalls
              ? conferenceButtonsJoined()
              : conferenceButtonsInitial()}
            {conferenceCanceled ? (
              <BuildTransferQuestions
                transferData={transferData}
                setFinalButtonDisabled={setFinalButtonDisabled}
                setFinalOutcome={setFinalOutcome}
              />
            ) : (
              <></>
            )}
          </Card.Body>
          <Card.Footer>
            <ConferenceCompleteButton
              finalButtonDisabled={finalButtonDisabled}
              finalOutcome={finalOutcome}
              setFinalOutcome={setFinalOutcome}
              setFinalButtonDisabled={setFinalButtonDisabled}
              setJoinedCalls={setJoinedCalls}
              setConferenceCanceled={setConferenceCanceled}
            />
          </Card.Footer>
        </Card>
      );
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
};

export default ConferenceWorksheet;
