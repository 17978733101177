import { consoleLogIt } from "../../../../app/log";

const issueArray: any = [
  {
    text: `ACH Process - Bi-Weekly`,
    category: `Cashiering`,
    value: `ACH Process - Bi-Weekly`,
    id: "VC_issue_01",
    name: "vcIssue",
  },
  {
    text: `Bad expectation on missing docs`,
    category: `Contact Center`,
    value: `Bad expectation on missing docs`,
    id: "VC_issue_02",
    name: "vcIssue",
  },
  {
    text: `Bilingual Representative Requested`,
    category: `Vendor`,
    value: `Bilingual Representative Requested`,
    id: "VC_issue_03",
    name: "vcIssue",
  },
  {
    text: `Billing Statements`,
    category: `Correspondence Fulfillment`,
    value: `Billing Statements`,
    id: "VC_issue_04",
    name: "vcIssue",
  },
  {
    text: `Can't reach SPOC (always someone else)`,
    category: `Command Center`,
    value: `Can''t reach SPOC (always someone else)`,
    id: "VC_issue_05",
    name: "vcIssue",
  },
  {
    text: `Can't Self-Repair`,
    category: `Escrow`,
    value: `Can''t Self-Repair`,
    id: "VC_issue_06",
    name: "vcIssue",
  },
  {
    text: `Can't talk to customer active in bankruptcy`,
    category: `Contact Center`,
    value: `Can''t talk to customer active in bankruptcy`,
    id: "VC_issue_07",
    name: "vcIssue",
  },
  {
    text: `Contractor Requirements - License/Bonding/Approval`,
    category: `Escrow`,
    value: `Contractor Requirements - License/Bonding/Approval`,
    id: "VC_issue_08",
    name: "vcIssue",
  },
  {
    text: `Coupon instructions ignored when payment posted`,
    category: `Cashiering`,
    value: `Coupon instructions ignored when payment posted`,
    id: "VC_issue_09",
    name: "vcIssue",
  },
  {
    text: `COVID-19: Post Forbearance`,
    category: `Loss Mitigation`,
    value: `COVID-19: Post Forbearance`,
    id: "VC_issue_10",
    name: "vcIssue",
  },
  {
    text: `Credit Reporting`,
    category: `Credit`,
    value: `Credit Reporting`,
    id: "VC_issue_11",
    name: "vcIssue",
  },
  {
    text: `Deed in Lieu/Short Sale Process`,
    category: `Loss Mitigation`,
    value: `Deed in Lieu/Short Sale Process`,
    id: "VC_issue_12",
    name: "vcIssue",
  },
  {
    text: `Deferment`,
    category: `Loss Mitigation`,
    value: `Deferment`,
    id: "VC_issue_13",
    name: "vcIssue",
  },
  {
    text: `Dialer Issue`,
    category: `Command Center`,
    value: `Dialer Issue`,
    id: "VC_issue_14",
    name: "vcIssue",
  },
  {
    text: `Dissatisfied with Verification Process`,
    category: `Product Management`,
    value: `Dissatisfied with Verification Process`,
    id: "VC_issue_15",
    name: "vcIssue",
  },
  {
    text: `Do Not Solicit - Refinance`,
    category: `Marketing`,
    value: `Do Not Solicit - Refinance`,
    id: "VC_issue_16",
    name: "vcIssue",
  },
  {
    text: `Doc Requirements-Borrower keeps sending in docs but file still incomplete`,
    category: `Loss Mitigation`,
    value: `Doc Requirements-Borrower keeps sending in docs but file still incomplete`,
    id: "VC_issue_17",
    name: "vcIssue",
  },
  {
    text: `Document Process/Timeframe`,
    category: `All`,
    value: `Document Process/Timeframe`,
    id: "VC_issue_18",
    name: "vcIssue",
  },
  {
    text: `Document Request`,
    category: `Correspondence Fulfillment`,
    value: `Document Request`,
    id: "VC_issue_19",
    name: "vcIssue",
  },
  {
    text: `Document Requirements`,
    category: `All`,
    value: `Document Requirements`,
    id: "VC_issue_20",
    name: "vcIssue",
  },
  {
    text: `Documents - Unaware of what's missing`,
    category: `Loss Mitigation`,
    value: `Documents - Unaware of what''s missing`,
    id: "VC_issue_21",
    name: "vcIssue",
  },
  {
    text: `Documents received but not reviewed`,
    category: `Loss Mitigation`,
    value: `Documents received but not reviewed`,
    id: "VC_issue_22",
    name: "vcIssue",
  },
  {
    text: `Escalated file not reviewed timely`,
    category: `Loss Mitigation`,
    value: `Escalated file not reviewed timely`,
    id: "VC_issue_23",
    name: "vcIssue",
  },
  {
    text: `Escrow - Escrow Analysis/Shortage`,
    category: `Escrow`,
    value: `Escrow - Escrow Analysis/Shortage`,
    id: "VC_issue_24",
    name: "vcIssue",
  },
  {
    text: `Escrow refund after loan paid off - where is it?`,
    category: `Correspondence Fulfillment`,
    value: `Escrow refund after loan paid off - where is it?`,
    id: "VC_issue_25",
    name: "vcIssue",
  },
  {
    text: `E-Setup process/issue(s)`,
    category: `Product Management`,
    value: `E-Setup process/issue(s)`,
    id: "VC_issue_26",
    name: "vcIssue",
  },

  {
    text: `Extended & required docs not reviewed timely`,
    category: `Loss Mitigation`,
    value: `Extended & required docs not reviewed timely`,
    id: "VC_issue_28",
    name: "vcIssue",
  },
  {
    text: `Facially complete file removed by mistake`,
    category: `Loss Mitigation`,
    value: `Facially complete file removed by mistake`,
    id: "VC_issue_29",
    name: "vcIssue",
  },
  {
    text: `FC Sales <37 days & full LM package is in`,
    category: `Loss Mitigation`,
    value: `FC Sales <37 days & full LM package is in`,
    id: "VC_issue_30",
    name: "vcIssue",
  },
  {
    text: `Fee Waiver -NSF/Late Charge/Payoff Quote`,
    category: `Contact Center`,
    value: `Fee Waiver -NSF/Late Charge/Payoff Quote`,
    id: "VC_issue_31",
    name: "vcIssue",
  },
  {
    text: `Freedom Mortgage Refinance`,
    category: `Marketing`,
    value: `Freedom Mortgage Refinance`,
    id: "VC_issue_32",
    name: "vcIssue",
  },
  {
    text: `Homeowner Assistance Fund (HAF)/Hardest Hit Fund (HHF)`,
    category: `Loss Mitigation`,
    value: `Homeowner Assistance Fund (HAF)/Hardest Hit Fund (HHF)`,
    id: "VC_issue_33",
    name: "vcIssue",
  },
  {
    text: `In processing >30 day & review not complete`,
    category: `Loss Mitigation`,
    value: `In processing >30 day & review not complete`,
    id: "VC_issue_34",
    name: "vcIssue",
  },
  {
    text: `Insurance`,
    category: `Escrow`,
    value: `Insurance`,
    id: "VC_issue_35",
    name: "vcIssue",
  },
  {
    text: `Issue with Notice/Correspondence`,
    category: `Correspondence Fulfillment`,
    value: `Issue with Notice/Correspondence`,
    id: "VC_issue_36",
    name: "vcIssue",
  },
  {
    text: `IVR issue `,
    category: `Command Center`,
    value: `IVR issue `,
    id: "VC_issue_37",
    name: "vcIssue",
  },
  {
    text: `Loss Draft Disbursement Issue(s)`,
    category: `Escrow`,
    value: `Loss Draft Disbursement Issue(s)`,
    id: "VC_issue_38",
    name: "vcIssue",
  },
  {
    text: `Loss Draft Document Issue(s)`,
    category: `Escrow`,
    value: `Loss Draft Document Issue(s)`,
    id: "VC_issue_39",
    name: "vcIssue",
  },
  {
    text: `Marketplace Vendor`,
    category: `Vendor`,
    value: `Marketplace Vendor`,
    id: "VC_issue_40",
    name: "vcIssue",
  },
  {
    text: `Mod system changes done yet LMT1 open`,
    category: `Loss Mitigation`,
    value: `Mod system changes done yet LMT1 open`,
    id: "VC_issue_41",
    name: "vcIssue",
  },
  {
    text: `Need perm conversion: plan kept > 14 days`,
    category: `Loss Mitigation`,
    value: `Need perm conversion: plan kept > 14 days`,
    id: "VC_issue_42",
    name: "vcIssue",
  },
  {
    text: `Need perm docs redraw`,
    category: `Loss Mitigation`,
    value: `Need perm docs redraw`,
    id: "VC_issue_43",
    name: "vcIssue",
  },
  {
    text: `Not called back when promised`,
    category: `Contact Center`,
    value: `Not called back when promised`,
    id: "VC_issue_44",
    name: "vcIssue",
  },

  {
    text: `Payment Misapplication`,
    category: `Cashiering`,
    value: `Payment Misapplication`,
    id: "VC_issue_46",
    name: "vcIssue",
  },
  {
    text: `Payoff - Interest/Charges`,
    category: `Cashiering`,
    value: `Payoff - Interest/Charges`,
    id: "VC_issue_47",
    name: "vcIssue",
  },
  {
    text: `Payoff - Requested but didn't receive`,
    category: `Cashiering`,
    value: `Payoff - Requested but didn''t receive`,
    id: "VC_issue_48",
    name: "vcIssue",
  },
  {
    text: `Prior agent provided bad information or caused an error`,
    category: `Contact Center`,
    value: `Prior agent provided bad information or caused an error`,
    id: "VC_issue_49",
    name: "vcIssue",
  },
  {
    text: `Private Mortgage Insurance (PMI) issues`,
    category: `Escrow`,
    value: `Private Mortgage Insurance (PMI) issues`,
    id: "VC_issue_50",
    name: "vcIssue",
  },
  {
    text: `Problem Making Payment Online`,
    category: `Product Management`,
    value: `Problem Making Payment Online`,
    id: "VC_issue_51",
    name: "vcIssue",
  },
  {
    text: `Process Requirements /Timeframe`,
    category: `All`,
    value: `Process Requirements /Timeframe`,
    id: "VC_issue_52",
    name: "vcIssue",
  },
  {
    text: `Property Inspection/Monitored Disbursement`,
    category: `Vendor`,
    value: `Property Inspection/Monitored Disbursement`,
    id: "VC_issue_53",
    name: "vcIssue",
  },
  {
    text: `Requested docs are wrong per program`,
    category: `Loss Mitigation`,
    value: `Requested docs are wrong per program`,
    id: "VC_issue_54",
    name: "vcIssue",
  },
  {
    text: `Reverse Mortgage`,
    category: `Marketing`,
    value: `Reverse Mortgage`,
    id: "VC_issue_55",
    name: "vcIssue",
  },
  {
    text: `Roundpointmortgage.com - Chat issues`,
    category: `Product Management`,
    value: `Roundpointmortgage.com - Chat issues`,
    id: "VC_issue_56",
    name: "vcIssue",
  },
  {
    text: `Servicing Digital`,
    category: `Product Management`,
    value: `Servicing Digital`,
    id: "VC_issue_57",
    name: "vcIssue",
  },
  {
    text: `Servicing transfer process/issues`,
    category: `Servicing Transfers`,
    value: `Servicing transfer process/issues`,
    id: "VC_issue_58",
    name: "vcIssue",
  },
  {
    text: `Someone provided bad experience (e.g., rude or unprofessional)`,
    category: `Contact Center`,
    value: `Someone provided bad experience (e.g., rude or unprofessional)`,
    id: "VC_issue_59",
    name: "vcIssue",
  },
  {
    text: `SS BPO not ordered`,
    category: `Loss Mitigation`,
    value: `SS BPO not ordered`,
    id: "VC_issue_60",
    name: "vcIssue",
  },
  {
    text: `Taxes`,
    category: `Escrow`,
    value: `Taxes`,
    id: "VC_issue_61",
    name: "vcIssue",
  },
  {
    text: `Translation Service/Bilingual Assistance Needed`,
    category: `Vendor`,
    value: `Translation Service/Bilingual Assistance Needed`,
    id: "VC_issue_62",
    name: "vcIssue",
  },
  {
    text: `Upset about Frequency of Calls`,
    category: `Command Center`,
    value: `Upset about Frequency of Calls`,
    id: "VC_issue_63",
    name: "vcIssue",
  },
  {
    text: `UTP seeking account information`,
    category: `Correspondence`,
    value: `UTP seeking account information`,
    id: "VC_issue_64",
    name: "vcIssue",
  },
  {
    text: `Wait time was too long to get to someone`,
    category: `Command Center`,
    value: `Wait time was too long to get to someone`,
    id: "VC_issue_65",
    name: "vcIssue",
  },
  {
    text: `WI Tax Letter - Options`,
    category: `Escrow`,
    value: `WI Tax Letter - Options`,
    id: "VC_issue_66",
    name: "vcIssue",
  },
  {
    text: `Workout Options - Doesn't Agree`,
    category: `Loss Mitigation`,
    value: `Workout Options - Doesn''t Agree`,
    id: "VC_issue_67",
    name: "vcIssue",
  },
  {
    text: `Year End Statements`,
    category: `Year End Reporting`,
    value: `Year End Statements`,
    id: "VC_issue_68",
    name: "vcIssue",
  },
    {
    text: `Other (see my notes below)`,
    category: `All`,
    value: `Other (see my notes below)`,
    id: "VC_issue_45",
    name: "vcIssue",
  },
];

export const vcQuestions = (question: string, verbalComplaint: any) => {
  switch (question) {
    case "udaapFairServicingFlag":
      return {
        title: "How does the caller feel?",
        description: `Flag or code assigned by the subservicer to identify a 
        complaint alleging a UDAAP or fair servicing violation.`,
        name: "udaapFairServicingFlag",
        type: "radio",
        answers: [
          {
            text: "Caller believes RP has participated in discriminatory practices against them",
            value: "UDAAP",
            id: "VC_UDAAP_1",
            name: "udaapFairServicingFlag",
          },
          {
            text: "Caller believes servicing of his/her loan is treated differently than other customers",
            value: "Fair Servicing",
            id: "VC_UDAAP_2",
            name: "udaapFairServicingFlag",
          },
          {
            text: "Neither",
            value: "N/A",
            id: "VC_UDAAP_3",
            name: "udaapFairServicingFlag",
          },
        ],
      };
      break;
    case "complaintType":
      return {
        title: "Complaint Type",
        description: `Indicates whether complaint is Escalated (e.g. 
          forwarded by the CFPB, OCC, or state regulator/AG; UDAAP/fair 
          servicing; escalated/OOTP); a Notice of Error (NOE); or Non 
          Escalated (e.g. phone, general correspondence, email, consumer 
            facing website, app, etc.)`,
        name: "complaintType",
        type: "radio",
        answers: [
          {
            text: "Caller believes RP made an error on their loan",
            value: "Notice of Error",
            id: "VC_ComplaintType_1",
            name: "complaintType",
          },
          {
            text: "Caller is dissatisfied with a RP process",
            value: "Process Dissatisfaction",
            id: "VC_ComplaintType_2",
            name: "complaintType",
          },
        ],
      };
      break;
    case "complaintSource":
      return {
        title: "Who did you talk to?",
        description: `The source (initiator) of the complaint 
          (e.g. whether the complaint was filed by a consumer or 
            forwarded from an`,
        name: "complaintSource",
        type: "radio",
        answers: [
          {
            text: "Customer",
            value: "Customer",
            id: "VC_ComplaintSource_1",
            name: "complaintSource",
          },
          {
            text: "Attorney",
            value: "Attorney",
            id: "VC_ComplaintSource_2",
            name: "complaintSource",
          },
          {
            text: "Third Party (ATP or UTP)",
            value: "Third Party (ATP or UTP)",
            id: "VC_ComplaintSource_3",
            name: "complaintSource",
          },
          {
            text: "Successor in Interest",
            value: "Successor in Interest",
            id: "VC_ComplaintSource_4",
            name: "complaintSource",
          },
          {
            text: "State Regulator",
            value: "State Regulator",
            id: "VC_ComplaintSource_5",
            name: "complaintSource",
          },
        ],
      };
      break;
    case "servicingProcessArea":
      return {
        title: "Category",
        description: `Servicing process or operational
         department responsible for the activity described in the complaint `,
        name: "servicingProcessArea",
        type: "btns",
        answers: [
          {
            text: `Bankruptcy`,
            value: `Bankruptcy`,
            id: `VC_ServicingProcess_01`,
            name: `servicingProcessArea`,
          },
          {
            text: `Caller is threatening legal action`,
            value: `Legal`,
            id: `VC_ServicingProcess_02`,
            name: `servicingProcessArea`,
          },
          {
            text: `Caller received another customer's email/mail, customer's information was shared, Privacy concerns`,
            value: `Privacy`,
            id: `VC_ServicingProcess_03`,
            name: `servicingProcessArea`,
          },
          {
            text: `Cashiering`,
            value: `Cashiering`,
            id: `VC_ServicingProcess_04`,
            name: `servicingProcessArea`,
          },
          {
            text: `Dialer/IVR`,
            value: `Command Center`,
            id: `VC_ServicingProcess_05`,
            name: `servicingProcessArea`,
          },
          {
            text: `Call Center`,
            value: `Contact Center`,
            id: `VC_ServicingProcess_06`,
            name: `servicingProcessArea`,
          },
          {
            text: `Correspondence`,
            value: `Correspondence`,
            id: `VC_ServicingProcess_07`,
            name: `servicingProcessArea`,
          },
          {
            text: `Credit`,
            value: `Credit`,
            id: `VC_ServicingProcess_08`,
            name: `servicingProcessArea`,
          },
          {
            text: `Escrow`,
            value: `Escrow`,
            id: `VC_ServicingProcess_09`,
            name: `servicingProcessArea`,
          },
          {
            text: `Foreclosure`,
            value: `Foreclosure`,
            id: `VC_ServicingProcess_10`,
            name: `servicingProcessArea`,
          },
          {
            text: `Fraud`,
            value: `Fraud`,
            id: `VC_ServicingProcess_11`,
            name: `servicingProcessArea`,
          },
          {
            text: `Inspection fees, locks changed, unable to access property`,
            value: `Property Preservation`,
            id: `VC_ServicingProcess_12`,
            name: `servicingProcessArea`,
          },
          {
            text: `Loan Boarding`,
            value: `Loan Boarding`,
            id: `VC_ServicingProcess_13`,
            name: `servicingProcessArea`,
          },
          {
            text: `Loss Mitigation`,
            value: `Loss Mitigation`,
            id: `VC_ServicingProcess_14`,
            name: `servicingProcessArea`,
          },
          {
            text: `Mail, Billing Statements, Letters`,
            value: `Correspondence Fulfillment`,
            id: `VC_ServicingProcess_15`,
            name: `servicingProcessArea`,
          },
          {
            text: `Optional Product, Translation Service, Guardian (inspections company)`,
            value: `Vendor`,
            id: `VC_ServicingProcess_16`,
            name: `servicingProcessArea`,
          },

          {
            text: `Partial Release/Subordinations`,
            value: `Partial Release/Subordinations`,
            id: `VC_ServicingProcess_18`,
            name: `servicingProcessArea`,
          },
          {
            text: `Refi, Reverse, Solicitation`,
            value: `Marketing`,
            id: `VC_ServicingProcess_19`,
            name: `servicingProcessArea`,
          },
          {
            text: `Satisfaction of Mortgage`,
            value: `Lien Release`,
            id: `VC_ServicingProcess_20`,
            name: `servicingProcessArea`,
          },
          {
            text: `Servicing Transfers`,
            value: `Servicing Transfers`,
            id: `VC_ServicingProcess_21`,
            name: `servicingProcessArea`,
          },
          {
            text: `Website, Servicing Digital, Chat Functionality, AI IVR`,
            value: `Product Management`,
            id: `VC_ServicingProcess_22`,
            name: `servicingProcessArea`,
          },
          {
            text: `Year End Reporting`,
            value: `Year End Reporting`,
            id: `VC_ServicingProcess_23`,
            name: `servicingProcessArea`,
          },
          // {
          //   text: `Other`,
          //   value: `Other`,
          //   id: `VC_ServicingProcess_17`,
          //   name: `servicingProcessArea`,
          // },
        ],
      };
      break;
    case "vcIssue":
      consoleLogIt(
        issueArray.filter(
          (item: any) =>
            item.category === "All" ||
            item.category === verbalComplaint.servicingProcessArea
        )
      );
      return {
        title: "Issue Description",
        description: `Specific issue or error described in the complaint (e.g. escrow analysis, misapplied payment, etc.)`,
        name: "vcIssue",
        type: "btns",
        answers: issueArray.filter(
          (item: any) =>
            item.category === "All" ||
            item.category === verbalComplaint.servicingProcessArea
        ),
      };
      break;
    case "resolutionSummary":
      return {
        title: "Result of Resolution",
        description: `Summary of complaint resolution and 
        response provided to the consumer or agency, as applicable`,
        name: "resolutionSummary",
        type: "radio",
        answers: [
          {
            text: "Error, explanation provided and caller satisfied",
            value: "Issue resolved, error occurred",
            id: "VC_ResolutionSummary_1",
            name: "resolutionSummary",
          },
          {
            text: "No error, explanation provided",
            value: "Issue resolved, no error",
            id: "VC_ResolutionSummary_2",
            name: "resolutionSummary",
          },
          {
            text: "No resolution, opened task",
            value: "No resolution on call: Research pending",
            id: "VC_ResolutionSummary_3",
            name: "resolutionSummary",
          },
          {
            text: "No resolution: Call Escalated",
            value: "No resolution: Call Escalated",
            id: "VC_ResolutionSummary_4",
            name: "resolutionSummary",
          },
        ],
      };
      break;
    case "resolutionNotes":
      return {
        title: "Resolution Notes",
        description: `Additional notes or comments applicable 
          to the complaint or resolution`,
        name: "resolutionNotes",
        type: "radio",
        answers: [
          {
            text: "No additional notes",
            value: "No additional notes",
            id: "VC_ResolutionNotes_1",
            name: "resolutionNotes",
          },
          {
            text: "Additional Notes",
            value: "Additional Notes",
            id: "VC_ResolutionNotes_2",
            name: "resolutionNotes",
          },
        ],
      };
      break;
    case "rootCause":
      return {
        title: "Cause of Complaint",
        description: `Summary description of the root cause of the issue`,
        name: "rootCause",
        type: "radio",
        answers: [
          {
            text: "RoundPoint Error",
            value: "RoundPoint Error further research needed",
            id: "VC_RootCause_1",
            name: "rootCause",
          },
          {
            text: "Vendor error/control issue",
            value: "Vendor error/control issue",
            id: "VC_RootCause_4",
            name: "rootCause",
          },
          {
            text: "Seller or Prior Servicer Error",
            value: "Seller or Prior Servicer Error",
            id: "VC_RootCause_5",
            name: "rootCause",
          },
          {
            text: "Prior agent provided inaccurate/incomplete information",
            value: "Missed verbal communication opportunity",
            id: "VC_RootCause_6",
            name: "rootCause",
          },

        ],
      };
      break;
  }
};
