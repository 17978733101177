import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { updateWorksheetTabs } from "../../../app/actions/standardActions";
import { Nav } from "react-bootstrap";
import CallWorksheetNav from "./CallWorksheetNav";
import RefiIncentiveNav from "./RefiIncentiveNav";
import OtherIncentiveNav from "./OtherIncentiveNav";
import TransConfWorksheetNav from "./TransConfWorksheetNav";
import './WorksheetNavWrapper.css'

const WorksheetNavWrapper = () => {
  const dispatch = useAppDispatch();
  const appPath: any = useAppSelector((state) => state.main.appPath);
  const callBanner: any = useAppSelector((state) => state.displayInfo.banner);
  const incentives: any = useAppSelector((state) => state.callWorksheets.incentiveOptions )

  useEffect(() => {
    if (appPath === "Unauthenticated") {
      dispatch(updateWorksheetTabs("unauthenticated"));
    } else {
      dispatch(updateWorksheetTabs("standard"));
    }
  }, [appPath]);
  return (
    <Nav id="worksheet_nav_wrapper">
      <CallWorksheetNav appPath={appPath} />
      {callBanner.staleData !== true && incentives.allowed !== "false" ? (
        <>
          <RefiIncentiveNav appPath={appPath} />
          <OtherIncentiveNav appPath={appPath} />
        </>
      ) : (
        <></>
      )}
      <TransConfWorksheetNav />
    </Nav>
  );
};

export default WorksheetNavWrapper;
