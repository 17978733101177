
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppSelector } from "../../app/hooks";
import {Card} from "react-bootstrap";

import './ContactInfoCallTypeId.css';

const ContactInfoCallTypeId = () => {
  const callBanner: any = useAppSelector((state) => state.displayInfo.banner);
  const callType: any = useAppSelector((state) => state.main.callType);
  const cardClasses = `mt-1 ${callType}`
  return (
    <Card className={cardClasses} id="call-type-identifier">
      <Card.Header>
        <h6 className="small p-0 m-0">{callBanner.bannerHeader}</h6>
        <h5 className="mb-0">{callBanner.bannerText}</h5>
      </Card.Header>
    </Card>
  )
}

export default ContactInfoCallTypeId;