/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { Form } from "react-bootstrap";
import { updateDCCIncome } from "../DCCConsultationQRPCSlice";
import NumberFormat from 'react-number-format';


const DCCIncomeInput = ({ dccConsultationQRPC }: any) => {
  const dispatch = useAppDispatch();
  const changeCurrencyInput = (e: any, sourceInfo: any) => {
    console.log(e, sourceInfo);

    if (sourceInfo.source === "event") {
      dispatch(
        updateDCCIncome(e.value)
      );
    }

  };
    return (
      <div id="dcc_form_grossHouseholdIncome">
        <div>
          <b>What is your current monthly gross household income?</b>
        </div>
        <div>
        <NumberFormat
          displayType="input"
          type="text"
          className={`form-control form-control-sm`}
          isNumericString={true}
          thousandSeparator={true}
          prefix={"$"}
          decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={true}
          value={dccConsultationQRPC.questions.grossHouseholdIncome}
          onValueChange={changeCurrencyInput}
          />
        </div>
      </div>
    );
};

export default DCCIncomeInput;
