import { createAsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import { consoleLogIt, consoleErrorIt } from "../../log";
import { cvBypassUpdate } from "./contactVerificationSlice";


// thunks
export const postContactVerificationVariable: any = createAsyncThunk(
  "contactVerificationVariable/post",
  async (
    { metadata, callIds, verification }: any,
    { dispatch, rejectWithValue }
  ) => {
    try {
      consoleLogIt(metadata);
      const baseUrl = metadata.data.metadata.dataCenters[0].apiUrls[0].host;
      const userId = metadata.data.userId;
      const body = {
        1392: verification.num1,
        1393: verification.num2,
        1394: verification.num3,
        1395: verification.email1,
        1396: verification.email2,
        1397: verification.verifiedBy,
      };
      const res = await fetch(
        `https://${baseUrl}/appsvcs/rs/svc/agents/${userId}/interactions/calls/${callIds.systemCallId}/variables_2`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      ).then((data: any) => {
        consoleLogIt("Post Verification Variables", data);

        return data.json();
      });
      return res;
    } catch (e) {
      consoleErrorIt("Failed to Post Verification Variables", e);
      return rejectWithValue(e);
    }
  }
);

// thunks
export const postUpdateVerificationBypass: any = createAsyncThunk(
  "verificationBypass/post",
  async ({ metadata, callIds, value }: any, { dispatch, rejectWithValue }) => {
    try {
      consoleLogIt(metadata);
      const baseUrl = metadata.data.metadata.dataCenters[0].apiUrls[0].host;
      const userId = metadata.data.userId;
      const body = {
        1427: value,
      };
      const res = await fetch(
        `https://${baseUrl}/appsvcs/rs/svc/agents/${userId}/interactions/calls/${callIds.systemCallId}/variables_2`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      ).then((data: any) => {
        consoleLogIt("Post Verification Bypass Variables", data);

        return data.json();
      });
      return res;
    } catch (e) {
      consoleErrorIt("Failed to Post Verification Bypass Variables", e);
      return rejectWithValue(e);
    }
  }
);

export const postContactVerificationContact: any = createAsyncThunk(
  "contactVerificationContact/post",
  async (
    { metadata, callIds, verification }: any,
    { dispatch, rejectWithValue }
  ) => {
    try {
      consoleLogIt(metadata);
      const baseUrl = metadata.data.metadata.dataCenters[0].apiUrls[0].host;
      const userId = metadata.data.userId;
      const body: any = {
        fields: {},
        id: callIds.contactId,
        modificationTime: callIds.contactModTime,
      };
      body.fields["1540"] = verification.date;
      body.fields["1017"] = callIds.numbers.p;
      body.fields["1018"] = callIds.numbers.s;
      body.fields["1019"] = callIds.numbers.o;

      const res = await fetch(
        `https://${baseUrl}/appsvcs/rs/svc/agents/${userId}/interactions/calls/${callIds.systemCallId}/update_contact`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      ).then((data: any) => {
        consoleLogIt("Post Verification Contact Fields", data);
        return data.json();
      });
      return res;
    } catch (e) {
      consoleErrorIt("Failed to Post Verification Contact Fields", e);
      return rejectWithValue(e);
    }
  }
);
