/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { combineReducers } from 'redux';
import { GET_CALLDATA_SUCCESS } from "../actions/apisActions";

import {
  UPDATE_LCOM_OBJECT,
  OPEN_LCOM_WINDOW,
  CLOSE_LCOM_WINDOW,
  UPDATE_LCOM_PAGE_IN_PROGRESS,
  UPDATE_LCOM_PAGE_SUCCESS,
  UPDATE_LCOM_PAGE_FAILED,
  UPDATE_LCOM_PAGE_FAILED_COMPLETELY,
} from "../actions/lcomActions";

export const modal = (state = false, action: any) => {
  const { type } = action;

  switch (type) {
    case OPEN_LCOM_WINDOW:
      return true;
    case CLOSE_LCOM_WINDOW:
      return false;
    default:
      return state;
  }
};

export const number1 = (state = {}, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CALLDATA_SUCCESS:
      return {
        ...state,

        input: payload.data.activeContact.fields["1310"],
        id: "",
        type: payload.data.activeContact.fields["1251"],
        scrCode: "",
        consent: "",
        consentDate: "",
        consentSource: "",
        bttc: "",
      };
    case UPDATE_LCOM_OBJECT:
      if (payload.id === "number1") {
        if (payload.key === "consent") {
          return {
            ...state,
            [payload.key]: payload.value,
            consentSource: "Customer call",
            whenDu: "",
          };
        } else {
          return { ...state, [payload.key]: payload.value, whenDu: "" };
        }
      } else {
        return state;
      }

    case UPDATE_LCOM_PAGE_IN_PROGRESS:
      if (payload === "number1") {
        return { ...state, whenDu: "In Progress" };
      } else {
        return state;
      }

    case UPDATE_LCOM_PAGE_FAILED_COMPLETELY:
      if (payload === "number1") {
        return { ...state, whenDu: "Failed" };
      } else {
        return state;
      }

    case UPDATE_LCOM_PAGE_FAILED:
      if (payload.contactField === "number1") {
        return { ...state, whenDu: "Failed" };
      } else {
        return state;
      }
    case UPDATE_LCOM_PAGE_SUCCESS:
      if (payload.contactField === "number1") {
        return { ...state, whenDu: "Success" };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const number2 = (state = {}, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CALLDATA_SUCCESS:
      return {
        ...state,

        input: payload.data.activeContact.fields["1311"],
        id: "",
        type: payload.data.activeContact.fields["1252"],
        scrCode: "",
        consent: "",
        consentDate: "",
        consentSource: "",
        bttc: "",
      };
    case UPDATE_LCOM_OBJECT:
      if (payload.id === "number2") {
        if (payload.key === "consent") {
          return {
            ...state,
            [payload.key]: payload.value,
            consentSource: "Customer call",
            whenDu: "",
          };
        } else {
          return { ...state, [payload.key]: payload.value, whenDu: "" };
        }
      } else {
        return state;
      }

    case UPDATE_LCOM_PAGE_IN_PROGRESS:
      if (payload === "number2") {
        return { ...state, whenDu: "In Progress" };
      } else {
        return state;
      }

    case UPDATE_LCOM_PAGE_FAILED_COMPLETELY:
      if (payload === "number2") {
        return { ...state, whenDu: "Failed" };
      } else {
        return state;
      }

    case UPDATE_LCOM_PAGE_FAILED:
      if (payload.contactField === "number2") {
        return { ...state, whenDu: "Failed" };
      } else {
        return state;
      }
    case UPDATE_LCOM_PAGE_SUCCESS:
      if (payload.contactField === "number2") {
        return { ...state, whenDu: "Success" };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const number3 = (state = {}, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CALLDATA_SUCCESS:
      return {
        ...state,

        input: payload.data.activeContact.fields["1312"],
        id: "",
        type: payload.data.activeContact.fields["1253"],
        scrCode: "",
        consent: "",
        consentDate: "",
        consentSource: "",
        bttc: "",
      };
    case UPDATE_LCOM_OBJECT:
      if (payload.id === "number3") {
        if (payload.key === "consent") {
          return {
            ...state,
            [payload.key]: payload.value,
            consentSource: "Customer call",
            whenDu: "",
          };
        } else {
          return { ...state, [payload.key]: payload.value, whenDu: "" };
        }
      } else {
        return state;
      }

    case UPDATE_LCOM_PAGE_IN_PROGRESS:
      if (payload === "number3") {
        return { ...state, whenDu: "In Progress" };
      } else {
        return state;
      }

    case UPDATE_LCOM_PAGE_FAILED_COMPLETELY:
      if (payload === "number3") {
        return { ...state, whenDu: "Failed" };
      } else {
        return state;
      }

    case UPDATE_LCOM_PAGE_FAILED:
      if (payload.contactField === "number3") {
        return { ...state, whenDu: "Failed" };
      } else {
        return state;
      }
    case UPDATE_LCOM_PAGE_SUCCESS:
      if (payload.contactField === "number3") {
        return { ...state, whenDu: "Success" };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const email1 = (state = {}, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CALLDATA_SUCCESS:
      return {
        ...state,
        input: payload.data.activeContact.fields["1142"],
        type: "",
        scrCode: "",
        consent: "",
        consentDate: "",
        consentSource: "",
        bttc: "",
      };
    case UPDATE_LCOM_OBJECT:
      if (payload.id === "email1") {
        if (payload.key === "consent") {
          return {
            ...state,
            [payload.key]: payload.value,
            consentSource: "Customer call",
            whenDu: "",
          };
        } else {
          return { ...state, [payload.key]: payload.value, whenDu: "" };
        }
      } else {
        return state;
      }
      
    case UPDATE_LCOM_PAGE_IN_PROGRESS:
      if (payload === "email1") {
        return { ...state, whenDu: "In Progress" };
      } else {
        return state;
      }

    case UPDATE_LCOM_PAGE_FAILED_COMPLETELY:
      if (payload === "email1") {
        return { ...state, whenDu: "Failed" };
      } else {
        return state;
      }

    case UPDATE_LCOM_PAGE_FAILED:
      if (payload.contactField === "email1") {
        return { ...state, whenDu: "Failed" };
      } else {
        return state;
      }
    case UPDATE_LCOM_PAGE_SUCCESS:
      if (payload.contactField === "email1") {
        return { ...state, whenDu: "Success" };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const email2 = (state = {}, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CALLDATA_SUCCESS:
      return {
        ...state,
        input: payload.data.activeContact.fields["1145"],
        type: "",
        scrCode: "",
        consent: "",
        consentDate: "",
        consentSource: "",
        bttc: "",
      };
    case UPDATE_LCOM_OBJECT:
      if (payload.id === "email2") {
        if (payload.key === "consent") {
          return {
            ...state,
            [payload.key]: payload.value,
            consentSource: "Customer call",
            whenDu: "",
          };
        } else {
          return { ...state, [payload.key]: payload.value, whenDu: "" };
        }
      } else {
        return state;
      }
      
    case UPDATE_LCOM_PAGE_IN_PROGRESS:
      if (payload === "email2") {
        return { ...state, whenDu: "In Progress" };
      } else {
        return state;
      }

    case UPDATE_LCOM_PAGE_FAILED_COMPLETELY:
      if (payload === "email2") {
        return { ...state, whenDu: "Failed" };
      } else {
        return state;
      }

    case UPDATE_LCOM_PAGE_FAILED:
      if (payload.contactField === "email2") {
        return { ...state, whenDu: "Failed" };
      } else {
        return state;
      }
    case UPDATE_LCOM_PAGE_SUCCESS:
      if (payload.contactField === "email2") {
        return { ...state, whenDu: "Success" };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const lcomObject = combineReducers({
  number1,
  number2, 
  number3,
  email1,
  email2,
  modal,
})