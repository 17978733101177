/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React from "react";
import { useAppSelector } from "../../app/hooks";
import { Card, Row } from "react-bootstrap";
import InfoRowNew from "./InfoRowNew";

const MessagingAndStatusNew = () => {
  const messagingAndStatus: any = useAppSelector(
    (state) => state.displayInfo.messagingAndStatus
  );
  return (
    <Card>
      <Card.Body>
        <h5>Messaging and Status</h5>
        <Row>
          <InfoRowNew group="otherLoan" array={messagingAndStatus.display} />
        </Row>
      </Card.Body>
    </Card>
  );
};

export default MessagingAndStatusNew;
