/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { Accordion } from "react-bootstrap";
import {
  RefiOnlyScripts,
  RefiAndCashOutScripts,
  RefiAndCashOutPurchaseScripts,
  CashOutOnlyScripts,
  MaticScripts,
  ReverseScripts,
  SafeHavenScripts
} from "./IncentiveScripts";
import IncentiveScriptBuild from "./IncentiveScriptBuild";
import "./IncentiveScripts.css";

//TODO make the necessary adjustments for screen size
const IncentiveScriptsAccordion = ({ pre }: any) => {
  const incentiveOptions: any = useAppSelector(
    (state) => state.callWorksheets.incentiveOptions
  );
  const borrowerInfo: any = useAppSelector(
    (state) => state.displayInfo.borrower
  );
  const mortgageScript: any = useAppSelector(
    (state) => state.display.incentiveScriptMort
  );
  const otherScript: any = useAppSelector(
    (state) => state.display.incentiveScriptOth
  );
  const hoursOfOperation: any = useAppSelector((state) => state.main.hoursOfOperation)

  const [activeScript, setActiveScript] = useState(() => {
    if (pre === "matic" || pre === "safe_haven") {
      if (mortgageScript === null) {
        return `${pre}_script_01`;
      } else {
        return otherScript;
      }
    } else {
      if (mortgageScript === null) {
        return `${pre}_script_01`;
      } else {
        return mortgageScript;
      }
    }
  });
  useEffect(() => {
    if (pre !== "matic" && pre !== "safe_haven") {
      if (mortgageScript !== null) {
        setActiveScript(mortgageScript.slice());
      }
    }
  }, [mortgageScript]);
  useEffect(() => {
    if (pre === "matic" || pre === "safe_haven") {
      if (otherScript !== null) {
        setActiveScript(otherScript.slice());
      }
    }
  }, [otherScript]);

  const accordionItems = () => {
    const refiAndCashOut = RefiAndCashOutScripts(
      borrowerInfo.concat,
      pre,
      incentiveOptions
    );
    const refiOnly = RefiOnlyScripts(borrowerInfo.concat);
    const purchase = RefiAndCashOutPurchaseScripts(borrowerInfo.concat, pre);
    const cashoutOnly = CashOutOnlyScripts(borrowerInfo.concat);
    const reverseArray = ReverseScripts(borrowerInfo.concat);
    const maticArray = MaticScripts(borrowerInfo.concat, incentiveOptions);
    const safeHavenArray = SafeHavenScripts(borrowerInfo.concat, hoursOfOperation);
    switch (pre) {
      case "refi":
        return (
          <>
            {refiAndCashOut.map((script, i) => (
              <IncentiveScriptBuild key={i} script={script} />
            ))}
            {refiOnly.map((script, i) => (
              <IncentiveScriptBuild key={i} script={script} />
            ))}
            {purchase.map((script, i) => (
              <IncentiveScriptBuild key={i} script={script} />
            ))}
          </>
        );
      case "cashout":
        return (
          <>
            {refiAndCashOut.map((script, i) => (
              <IncentiveScriptBuild key={i} script={script} />
            ))}
            {cashoutOnly.map((script, i) => (
              <IncentiveScriptBuild key={i} script={script} />
            ))}
            {purchase.map((script, i) => (
              <IncentiveScriptBuild key={i} script={script} />
            ))}
          </>
        );
      case "reverse":
        return (
          <>
            {reverseArray.map((script, i) => (
              <IncentiveScriptBuild key={i} script={script} />
            ))}
          </>
        );
      case "matic":
        return (
          <>
            {maticArray.map((script, i) => (
              <IncentiveScriptBuild key={i} script={script} />
            ))}
          </>
        );
        case "safe_haven":
          return (
            <>
              {safeHavenArray.map((script, i) => (
                <IncentiveScriptBuild key={i} script={script} />
              ))}
            </>
          );
      default:
        return <></>;
    }
  };

  return (
    <Accordion activeKey={activeScript} flush className="script-accordion">
      {accordionItems()}
    </Accordion>
  );
};

export default IncentiveScriptsAccordion;
