/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { updateDCCQuestion } from "../DCCConsultationQRPCSlice";
import DCCHardshipReasonTextBox from "./DCCHardshipReasonTextBox";

const DCCToggleButtons = ({ qna, dccConsultationQRPC }: any) => {
  const dispatch = useAppDispatch();
  const [collapseState, setCollapseState] = useState(
    dccConsultationQRPC.questions[qna.name] !== "" ? true : false
  );
  const [showTextBox, setShowTextBox] = useState(false);

  return (
    <div
      id={qna.name}
      className={
        (dccConsultationQRPC.questions[qna.name] !== null ||
          showTextBox === true) &&
        collapseState === true
          ? "cc-dcc-collapse"
          : ""
      }
    >
      <div
        onClick={() => {
          //console.log("On click triggered");
          if (collapseState === false) {
            //console.log("set collapse state to true");
            setCollapseState(true);
          } else {
            //console.log("set collapse state to false");
            setCollapseState(false);
          }
        }}
      >
        <b>{qna.title}</b>
        {/* <p className="mb-1 cc-vc-description">{qna.description}</p> */}
      </div>
      <ButtonGroup className="d-flex flex-wrap justify-content-start mt-1">
        {qna.answers.map((answer: any) => {
          const determineButtonClassName = () => {
            if (dccConsultationQRPC.questions[qna.name] !== answer.value) {
              return "unchecked";
            } else {
              return "";
            }
          };

          const toggleHandleChange = () => {
            if (answer.name === "hardshipReason") {
              if (answer.value === "Other") {
                setShowTextBox(true);
                dispatch(
                  updateDCCQuestion({ name: "hardshipReason", value: null })
                );
              } else {
                setShowTextBox(false);
                dispatch(updateDCCQuestion(answer));
              }
              setCollapseState(true);
            } else {
              setCollapseState(true);
              dispatch(updateDCCQuestion(answer));
            }
          };

          const determinChecked = () => {
            if (dccConsultationQRPC.questions[answer.name] === answer.value) {
              return true;
            } else {
              return false;
            }
          };
          return (
            <ToggleButton
              key={`dcc_${answer.name}_${answer.id}`}
              id={`dcc_${answer.name}_${answer.id}`}
              type="radio"
              variant="outline-secondary"
              name={answer.name}
              checked={determinChecked()}
              className={determineButtonClassName()}
              value={answer.value}
              onChange={toggleHandleChange}
            >
              {answer.text}
            </ToggleButton>
          );
        })}
      </ButtonGroup>
      {showTextBox === true ? (
        <DCCHardshipReasonTextBox dccConsultationQRPC={dccConsultationQRPC} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default DCCToggleButtons;
