/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  SafeHavenFormInterface,
  closeSafeHavenForm,
} from "./safeHavenFormSlice";
import { Offcanvas, Button } from "react-bootstrap";
import SHFormWrapper from "./SHFormWrapper";
import "./SafeHavenForm.css";
import SHFooter from "./SHFooter";
const validateEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
};
const SafeHavenForm = () => {
  const dispatch = useAppDispatch();
  const safeHavenForm = useAppSelector(
    (state) => state.safeHavenForm
  );
  const email = useAppSelector(state => state.main.validEmail.email)
  const [emailValidation, setEmailValidation] = useState(
    validateEmail(email)
  );
  const handleClose = () => {
    dispatch(closeSafeHavenForm());
  };

  return (
    <Offcanvas
      show={safeHavenForm.show}
      onHide={handleClose}
      enforceFocus={false}
      backdrop={false}
      placement="end"
      name="safe_haven_callback"
      id="safe_haven_callback"
    >
      <Offcanvas.Header closeVariant="white" closeButton>
        <Offcanvas.Title>{safeHavenForm.form === "transfer"?<>Safe Haven Transfer Form</>: <>Safe Haven Callback Form</>}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <SHFormWrapper
          emailValidation={emailValidation}
          setEmailValidation={setEmailValidation}
          validateEmail={validateEmail}
        />
      </Offcanvas.Body>
      <SHFooter emailValidation={emailValidation}/>
    </Offcanvas>
  );
};

export default SafeHavenForm;
