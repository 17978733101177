/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { closeDisclosureWindow } from "../../../app/actions/standardActions";
import { Modal } from "react-bootstrap";
import { disclosureScriptList } from "./DisclosureScriptList";
import LanguageToggleButton from "./LanguageToggleButton";
import DisclosureScript from "./DisclosureScript";

const DisclosuresWindow = () => {
  const dispatch = useAppDispatch();
  const discScriptObj: any = useAppSelector(
    (state) => state.features.discScriptObj
  );
  const borrower: any = useAppSelector((state) => state.displayInfo.borrower);
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const [language, setLanguage] = useState("english");

  const discloseScript = disclosureScriptList(
    discScriptObj.script,
    callIds,
    borrower
  );

  return (
    <Modal
      size="lg"
      backdrop={true}
      id="transfer_assist_modal"
      show={discScriptObj.status}
      onHide={() => dispatch(closeDisclosureWindow())}
    >
      <Modal.Header closeVariant="white" closeButton>
        <Modal.Title>{discloseScript.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <LanguageToggleButton language={language} setLanguage={setLanguage} />
        {language === "english" ? ( */}
          <DisclosureScript script={discloseScript.english} />
        {/* ) : (
          <DisclosureScript script={discloseScript.spanish} />
        )} */}
      </Modal.Body>
    </Modal>
  );
};

export default DisclosuresWindow;
