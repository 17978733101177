/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { InputGroup, Button, Form } from "react-bootstrap";
import { updateDCCQuestion } from "../DCCConsultationQRPCSlice";

const DCCPropertyIntentionsTextBox = ({ dccConsultationQRPC }: any) => {
  const dispatch = useAppDispatch();
  const [text, setText] = useState("");
  const [char, setChar] = useState(() => {
    if (dccConsultationQRPC.questions.propertyIntentions === null) {
      return 0;
    } else {
      return dccConsultationQRPC.questions.propertyIntentions.length;
    }
  });
  const [disable, setDisable] = useState(true);
  const [error, setError] = useState(false);
  const handleTextChange = (e: any) => {
    setText(e.target.value);
    setChar(e.target.value.length);
    if (e.target.value.length > 200) {
      setDisable(true);
      setError(true);
    } else if (e.target.value.length > 5) {
      setDisable(false);
      setError(false);
    } else {
      setDisable(true);
    }
  };
  const clickSubmitButton = () => {
    const updateText = text.replace(/['"]+/g, "''");
    dispatch(updateDCCQuestion({ name: "propertyIntentions", value: updateText }));
  };
  return (
    <>
      <span className={`character-count ${error ? "error-red" : ""}`}>
        {char}/200
      </span>
      <InputGroup>
        <Form.Control
          size="sm"
          type="text"
          id="dcc_property_intentions_input_box"
          className={error ? "error-red" : ""}
          placeholder="Please declare property intentions"
          onChange={handleTextChange}
        />
        <Button
          variant="primary"
          id="dcc_property_intentions_submit_button"
          onClick={clickSubmitButton}
          disabled={disable}
        >
          Submit
        </Button>
      </InputGroup>
    </>
  );
};

export default DCCPropertyIntentionsTextBox;
