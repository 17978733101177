/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { Col, Button, Form } from "react-bootstrap";

const WebSocketModalSearchColumn = ({
  messageHistory,
  selected,
  setSelected,
}: any) => {
  const [historyView, setHistoryView] = useState(messageHistory);
  const [searchBar, setSearchBar]: any = useState(null)
  useEffect(() => {
    if (
      searchBar === undefined ||
      searchBar === "" ||
      searchBar === null
    ) {
      setHistoryView(messageHistory);
    } else {
      
      const filter: string = searchBar.toLowerCase();
      const newArray: any = messageHistory.filter(
        (item: any) => {
          if(item.context.eventId.includes(filter)          ) {
            //item.context.eventReason.toLowerCase().includes(filter)
            return item;
          }
        }

      );
      setHistoryView(newArray);
    }
  }, [searchBar,messageHistory]);


  return (
    <Col xs={4}>
      <div className="d-grid gap-2" id="websocket_search_column">
      {/* <Form.Control
          type="search"
          size="sm"
          placeholder="Search..."
          onKeyUp={(e) => {
            if (
              e.currentTarget.value === undefined ||
              e.currentTarget.value === ""
            ) {
              setSearchBar(null);
            } else {
              setSearchBar(e.currentTarget.value);
            }
          }}
          onChange={(e) => {
            if (
              e.currentTarget.value === undefined ||
              e.currentTarget.value === ""
            ) {
              setSearchBar(null);
            } else {
              setSearchBar(e.currentTarget.value);
            }
          }}
        ></Form.Control> */}
      {historyView.map((item: any, idx: number) => {
        return (
          <Button
            key={`${item.context.timeStamp}_${idx}`}
            id={item.context.timeStamp}
            onClick={()=> {
              console.log(item)
              setSelected(item)
            }}
          >
            {item.context.eventId} - {item.context.eventReason} 
          </Button>
        );
      })}
      </div>
    </Col>
  );
};

export default WebSocketModalSearchColumn;
