import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { ToastContainer, Toast } from "react-bootstrap";
import NotificationsToast from "./NotificationsToast";
import "./Notifications.css";
import { consoleLogIt } from "../../../app/log";

function Notifications() {

  const notifications = useAppSelector((state) => state.notifications);

  const [notes, setNotes]: any = useState([]);

  useEffect(() => {

    setNotes(notifications.array);
  }, [notifications]);

  if (notes.length === 0) {
    return <></>;
  } else {
    return (
      <ToastContainer id="notification_block">
        {notes.map((item: any, idx: number) => {
          return (
            <div key={idx}>
              <NotificationsToast obj={item} idx={idx}/>
            </div>
          );
        })}
      </ToastContainer>
    );
  }
}

export default Notifications;
