/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React from "react";
import { useAppSelector } from "../../../app/hooks";
import {
  CashOutWorksheetQuestions,
  MaticWorksheetQuestions,
  RefiWorksheetQuestions,
  ReverseWorksheetQuestions,
  SafeHavenWorksheetQuestions
} from "../incentiveworksheets/WorksheetQuestions";
import { Tab } from "react-bootstrap";
import UnauthenticatedWs from "../callworksheet/UnauthenticatedWs";
import StandardWorksheet from "../callworksheet/StandardWorksheet";
import IncentiveWorksheets from "../incentiveworksheets/IncentiveWorksheets";
import TransferWorksheet from "../transferworksheets/TransferWorksheet";
import ConferenceWorksheet from "../transferworksheets/ConferenceWorksheet";

const WorksheetTabContent = () => {
  const hoursOfOperation = useAppSelector((state)=> state.main.hoursOfOperation)
  return (
    <Tab.Content>
      <Tab.Pane eventKey="unauthenticated">
        <UnauthenticatedWs />
      </Tab.Pane>
      <Tab.Pane eventKey="standard">
        <StandardWorksheet />
      </Tab.Pane>
      <Tab.Pane eventKey="refi">
        <IncentiveWorksheets questionsList={RefiWorksheetQuestions()} />
      </Tab.Pane>
      <Tab.Pane eventKey="cashout">
        <IncentiveWorksheets questionsList={CashOutWorksheetQuestions()} />
      </Tab.Pane>
      <Tab.Pane eventKey="reverse">
        <IncentiveWorksheets questionsList={ReverseWorksheetQuestions()} />
      </Tab.Pane>
      <Tab.Pane eventKey="matic">
        <IncentiveWorksheets questionsList={MaticWorksheetQuestions()} />
      </Tab.Pane>
      <Tab.Pane eventKey="safe_haven">
        <IncentiveWorksheets questionsList={SafeHavenWorksheetQuestions(hoursOfOperation)} />
      </Tab.Pane>
      <Tab.Pane eventKey="transfer">
        <TransferWorksheet />
      </Tab.Pane>
      <Tab.Pane eventKey="conference">
        <ConferenceWorksheet />
      </Tab.Pane>
    </Tab.Content>
  );
};

export default WorksheetTabContent;
