/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";

const EmailVIVRIframe = ({url}: any) => {
  //console.log(url)
  return (
    <iframe
      className="embed-responsive-item"
      src={url}
      height="400"
      width="600"
      title="email_iframe"
      key="email_iframe"
    ></iframe>
  );
};

export default EmailVIVRIframe;
