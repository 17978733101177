/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { consoleLogIt, consoleErrorIt, reportError } from "../../../app/log";
import { errorNotification } from "../../../app/slices/features/notificationsSlice";

export const submitVerbalComplaint = createAsyncThunk(
  "submitVerbalComplaint/post",
  async ({ callIds, verbalComplaint }: any, { dispatch, rejectWithValue }) => {
    try {
      const body = {
        loanNumber: callIds.loanNumber,
        callId: callIds.rptCallId,
        username: callIds.userName,
        udaapFairServicingFlag: verbalComplaint.udaapFairServicingFlag,
        complaintType: verbalComplaint.complaintType,
        complaintSource: verbalComplaint.complaintSource,
        servicingProcessArea: verbalComplaint.servicingProcessArea,
        vcIssue: verbalComplaint.vcIssue,
        summaryOfComplaint: verbalComplaint.summaryOfComplaint,
        resolutionSummary: verbalComplaint.resolutionSummary,
        resolutionNotes: verbalComplaint.resolutionNotes,
        rootCause: verbalComplaint.rootCause,
        test: "false",
      };
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `https://api.prod2.us.whendu.com/v1/webhooks/86146d82-eb2f-47f1-9d61-9816492f6965/catch/RHa8BpjgV4U5LM172vMb4OQqb8kVEz4r/event/Verbal Complaint`,
        {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      // const data = await response.json();
      const code = await response.ok;
      if (code === true) {
        consoleLogIt("Posted Verbal Complaint", response);
        return response.json();
      } else {
        consoleErrorIt("Failed to Post Verbal Complaint", response);
        dispatch(
          reportError({
            callIds,
            title: "Verbal Complaint Post",
            text: "Future state - Body Example",
          })
        );
        dispatch(
          errorNotification({
            title: "Verbal Complaint Post Failed",
            text: "The Verbal Complaint failed to post. Please try again.",
          })
        );

        return rejectWithValue(response);
      }
    } catch (e) {
      consoleErrorIt("Failed to Post Verbal Complaint", e);
      reportError({
        callIds,
        title: "Verbal Complaint Post",
        text: "Complete Failure - Catch e",
      });
      return rejectWithValue(e);
    }
  }
);

export interface VerbalComplaintInterface {
  show: boolean;
  apiStatus: "success" | "failed" | "pending" | "";
  udaapFairServicingFlag: string;
  complaintType: string;
  complaintSource: string;
  servicingProcessArea: string;
  vcIssue: string;
  summaryOfComplaint: string;
  resolutionSummary: string;
  resolutionNotes: string;
  rootCause: string;
  complete: boolean ;

}

const initialState = {
  show: false,
  apiStatus: "",
  udaapFairServicingFlag: "",
  complaintType: "",
  complaintSource: "",
  servicingProcessArea: "",
  vcIssue: "",
  summaryOfComplaint: "",
  resolutionSummary: "",
  resolutionNotes: "",
  rootCause: "",
  complete: false,
} as VerbalComplaintInterface;

export const verbalComplaint = createSlice({
  name: "verbalComplaint",
  initialState,
  reducers: {
    openVerbalComplaint: (state) => {
      state.show = true;
    },
    closeVerbalComplaint: (state) => {
      state.show = false;
    },
    updateVerbalComplaint: (state: any, { payload }: any) => {
      if(payload.name === "resolutionSummary" &&
      payload.value !== "Issue resolved, error occurred" &&
      payload.value !== "Issue resolved, no error") {
        state[payload.name] = payload.value;
        state.rootCause = "N/A"
      } else if (payload.name === "resolutionSummary" &&
      payload.value === "Issue resolved, error occurred" &&
      payload.value === "Issue resolved, no error"){
        state[payload.name] = payload.value;
        state.rootCause = ""
      } else {
      state[payload.name] = payload.value;
      }

    },
    clearVerbalComplaint: (state: any) => {
      state.apiStatus= "";
      state.udaapFairServicingFlag= "";
      state.complaintType= "";
      state.complaintSource= "";
      state.servicingProcessArea= "";
      state.vcIssue= "";
      state.summaryOfComplaint= "";
      state.resolutionSummary= "";
      state.resolutionNotes= "";
      state.rootCause= "";
      state.complete = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitVerbalComplaint.pending, (state) => {
        state.apiStatus = 'pending';
        state.complete = true;
      })
      .addCase(submitVerbalComplaint.fulfilled, (state) => {
        state.show = false;
        state.apiStatus = "success";
        state.complete = true;
      })
      .addCase(submitVerbalComplaint.rejected, (state) => {
        state.apiStatus = "failed";
        state.complete = false;
      });
  },
});
// thunks

// Action creators are generated for each case reducer function
export const {
  openVerbalComplaint,
  closeVerbalComplaint,
  updateVerbalComplaint,
  clearVerbalComplaint
} = verbalComplaint.actions;

export default verbalComplaint.reducer;
