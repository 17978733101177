/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppSelector } from "../../app/hooks";
import { Row } from "react-bootstrap";

import InfoRowWithCopy from "./InfoRowWithCopy";

const AddressPropertyBody = () => {
  const addressInfo: any = useAppSelector((state) => state.displayInfo.address);
  return (
    <Row>
      <InfoRowWithCopy
        group="propAddress"
        array={addressInfo.propertyDisplay}
      />
    </Row>
  );
};

export default AddressPropertyBody;
