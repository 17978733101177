/* eslint-disable @typescript-eslint/camelcase */
export const transferWorksheetQuestions = () => {
  const json = {
    internal_transfer: {
      WQ01: {
        place: 1,
        name: "WQ01",
        worksheet: "internal_transfer",
        section: null,
        type: "btns",
        question: "What is the reason for the canceled transfer?",
        answers: [
          {
            place: 1,
            name: "WQ01",
            option: "1",
            text: "Agent Canceled",
            outcome: "Agent Canceled",
            next: "WQ02",
            script: "null",
          },
          {
            place: 1,
            name: "WQ01",
            option: "2",
            text: "Call Disconnect",
            outcome: "Call Disconnect",
            next: "WQ03",
            script: "null",
          },
        ],
      },
      WQ02: {
        place: 2,
        name: "WQ02",
        worksheet: "internal_transfer",
        section: null,
        type: "btns",
        question: "Why did you cancel the transfer?",
        answers: [
          {
            place: 2,
            name: "WQ02",
            option: "1",
            text: "Transfer Error",
            outcome: "Agent Canceled-Transfer Error",
            next: "WQ99",
            script: "null",
          },
          {
            place: 2,
            name: "WQ02",
            option: "2",
            text: "Receiving Department Unable to Accept",
            outcome: "Agent Canceled-RD Unable to Accept",
            next: "WQ99",
            script: "null",
          },
          {
            place: 2,
            name: "WQ02",
            option: "3",
            text: "Extended Hold",
            outcome: "Agent Canceled-Extended Hold",
            next: "WQ99",
            script: "null",
          },
          {
            place: 2,
            name: "WQ02",
            option: "4",
            text: "System Issue/Dialing Issue",
            outcome: "Agent Canceled-System Issue",
            next: "WQ99",
            script: "null",
          },
        ],
      },
      WQ03: {
        place: 3,
        name: "WQ03",
        section: null,
        worksheet: "internal_transfer",
        type: "btns",
        question: "Why did the customer hang up?",
        answers: [
          {
            place: 3,
            name: "WQ03",
            option: "1",
            text: "Unclear",
            outcome: "Customer Drop-Unclear",
            next: "WQ99",
            script: "null",
          },
          {
            place: 3,
            name: "WQ03",
            option: "2",
            text: "Customer Unable to Wait",
            outcome: "Customer Drop-Long Wait",
            next: "WQ99",
            script: "null",
          },
          {
            place: 3,
            name: "WQ03",
            option: "3",
            text: "Extended Hold",
            outcome: "Customer Drop-Extended Hold",
            next: "WQ99",
            script: "null",
          },
        ],
      },
    },
    external_transfer: {
      WQ01: {
        place: 1,
        name: "WQ01",
        worksheet: "external_transfer",
        section: null,
        type: "btns",
        question: "What is the reason for the canceled transfer?",
        answers: [
          {
            place: 1,
            name: "WQ01",
            option: "1",
            text: "Agent Canceled",
            outcome: "Agent Canceled",
            next: "WQ02",
            script: "null",
          },
          {
            place: 1,
            name: "WQ01",
            option: "2",
            text: "Call Disconnect",
            outcome: "Call Disconnect",
            next: "WQ03",
            script: "null",
          },
        ],
      },
      WQ02: {
        place: 2,
        name: "WQ02",
        worksheet: "external_transfer",
        section: null,
        type: "btns",
        question: "Why did you cancel the transfer?",
        answers: [
          {
            place: 2,
            name: "WQ02",
            option: "1",
            text: "Transfer Error",
            outcome: "Agent Canceled-Transfer Error",
            next: "WQ99",
            script: "null",
          },
          {
            place: 2,
            name: "WQ02",
            option: "2",
            text: "Receiving Department Unable to Accept",
            outcome: "Agent Canceled-RD Unable to Accept",
            next: "WQ99",
            script: "null",
          },
          {
            place: 2,
            name: "WQ02",
            option: "3",
            text: "Extended Hold",
            outcome: "Agent Canceled-Extended Hold",
            next: "WQ99",
            script: "null",
          },
          {
            place: 2,
            name: "WQ02",
            option: "4",
            text: "System Issue/Dialing Issue",
            outcome: "Agent Canceled-System Issue",
            next: "WQ99",
            script: "null",
          },
          {
            place: 2,
            name: "WQ02",
            option: "5",
            text: "Outside of Receiving End Hours of Operation",
            outcome: "Agent Canceled-RD Closed",
            next: "WQ99",
            script: "null",
          },
        ],
      },
      WQ03: {
        place: 3,
        name: "WQ03",
        section: null,
        worksheet: "external_transfer",
        type: "btns",
        question: "Why did the customer hang up?",
        answers: [
          {
            place: 3,
            name: "WQ03",
            option: "1",
            text: "Unclear",
            outcome: "Customer Drop-Unclear",
            next: "WQ99",
            script: "null",
          },
          {
            place: 3,
            name: "WQ03",
            option: "2",
            text: "Customer Unable to Wait",
            outcome: "Customer Drop-Long Wait",
            next: "WQ99",
            script: "null",
          },
          {
            place: 3,
            name: "WQ03",
            option: "3",
            text: "Extended Hold",
            outcome: "Customer Drop-Extended Hold",
            next: "WQ99",
            script: "null",
          },
        ],
      },
    },
    conference_language: {
      WQ01: {
        place: 1,
        name: "WQ01",
        worksheet: "conference_language",
        section: null,
        type: "btns",
        question: "What is the reason for the canceled conference?",
        answers: [
          {
            place: 1,
            name: "WQ01",
            option: "1",
            text: "Agent Canceled",
            outcome: "Agent Canceled",
            next: "WQ02",
            script: "null",
          },
          {
            place: 1,
            name: "WQ01",
            option: "2",
            text: "Call Disconnect",
            outcome: "Call Disconnect",
            next: "WQ03",
            script: "null",
          },
        ],
      },
      WQ02: {
        place: 2,
        name: "WQ02",
        worksheet: "conference_language",
        section: null,
        type: "btns",
        question: "Why did you cancel the conference?",
        answers: [
          {
            place: 2,
            name: "WQ02",
            option: "1",
            text: "Conference Error",
            outcome: "Agent Canceled Conference-Error",
            next: "WQ99",
            script: "null",
          },
          {
            place: 2,
            name: "WQ02",
            option: "2",
            text: "Receiving Department Unable to Accept",
            outcome: "Agent Canceled Conference-RD Unable to Accept",
            next: "WQ99",
            script: "null",
          },
          {
            place: 2,
            name: "WQ02",
            option: "3",
            text: "Extended Hold",
            outcome: "Agent Canceled Conference-Extended Hold",
            next: "WQ99",
            script: "null",
          },
          {
            place: 2,
            name: "WQ02",
            option: "4",
            text: "System Issue/Dialing Issue",
            outcome: "Agent Canceled Conference-System Issue",
            next: "WQ99",
            script: "null",
          },
          {
            place: 2,
            name: "WQ02",
            option: "5",
            text: "Language Selected Not Offered",
            outcome: "Agent Canceled Conference-Language Not Offered",
            next: "WQ99",
            script: "null",
          },
          {
            place: 2,
            name: "WQ02",
            option: "6",
            text: "Outside of Receiving End Hours of Operation",
            outcome: "Agent Canceled Conference-RD Closed",
            next: "WQ99",
            script: "null",
          },
        ],
      },
      WQ03: {
        place: 3,
        name: "WQ03",
        section: null,
        worksheet: "conference_language",
        type: "btns",
        question: "Why did the customer hang up?",
        answers: [
          {
            place: 3,
            name: "WQ03",
            option: "1",
            text: "Unclear",
            outcome: "Customer Drop Conference-Unclear",
            next: "WQ99",
            script: "null",
          },
          {
            place: 3,
            name: "WQ03",
            option: "2",
            text: "Customer Unable to Wait",
            outcome: "Customer Drop Conference-Long Wait",
            next: "WQ99",
            script: "null",
          },
          {
            place: 3,
            name: "WQ03",
            option: "3",
            text: "Extended Hold",
            outcome: "Customer Drop Conference-Extended Hold",
            next: "WQ99",
            script: "null",
          },
        ],
      },
    },
  };

  return json;
};

