/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment";
import { consoleLogIt, consoleErrorIt, reportError } from "../../log";
import {
  cvBypassUpdate,
  cvContactUpdate,
  cvVariableUpdate,
} from "./contactVerificationSlice";
import {
  postUpdateVerificationBypass,
  postContactVerificationContact,
  postContactVerificationVariable,
} from "./contactVerificationThunks";

export const postUpdateCVBypass = async (
  metadata: any,
  callIds: any,
  value: any,
  dispatch: any
) => {
  try {
    const resultAction = await dispatch(
      postUpdateVerificationBypass({
        metadata: metadata,
        callIds,
        value
      })
    );

    const originalPromiseResult = unwrapResult(resultAction);
    // handle Result here
    if (originalPromiseResult[1427] === value) {
      consoleLogIt("Update Variable Customer_Verification.Bypass Succeeded");
      dispatch(cvBypassUpdate("success"));
      return "success";
    } else {
      consoleErrorIt(
        "Update Variable Customer_Verification.Bypass Did Not Match"
      );
      dispatch(
        reportError({
          callIds,
          title: "Update Variable Did Not Match",
          text: "Customer_Verification.Bypass",
        })
      );
      dispatch(cvBypassUpdate("failed"));
      return "failed";
    }
  } catch (e) {
    // error
    consoleErrorIt("Update Variable Customer_Verification.Bypass Failed");
    dispatch(cvBypassUpdate("failed"));
    dispatch(
      reportError({
        callIds,
        title: "Update Variable Failed",
        text: "Customer_Verification.Bypass",
      })
    );
    return "failed";
  }
};

export const postVerificationVariables = async (
  metadata: any,
  callIds: any,
  verification: any,
  dispatch: any
) => {
  try {
    const body = {
      1392: verification.num1,
      1393: verification.num2,
      1394: verification.num3,
      1395: verification.email1,
      1396: verification.email2,
      1397: verification.verifiedBy,
    };
    const resultAction = await dispatch(
      postContactVerificationVariable({
        metadata: metadata,
        callIds,
        verification,
      })
    );

    const originalPromiseResult = unwrapResult(resultAction);
    // handle Result here
    if (originalPromiseResult[1392] === body[1392] || body[1392] === "") {
      consoleLogIt("Update Variable Customer_Verification.Number1 Succeeded");
    } else {
      consoleErrorIt(
        "Update Variable Customer_Verification.Number1 Did Not Match"
      );
      dispatch(
        reportError({
          callIds,
          title: "Update Variable Did Not Match",
          text: "Customer_Verification.Number1",
        })
      );
    }
    if (originalPromiseResult[1393] === body[1393] || body[1393] === "") {
      consoleLogIt("Update Variable Customer_Verification.Number2 Succeeded");
    } else {
      consoleErrorIt(
        "Update Variable Customer_Verification.Number2 Did Not Match"
      );
      dispatch(
        reportError({
          callIds,
          title: "Update Variable Did Not Match",
          text: "Customer_Verification.Number2",
        })
      );
    }
    if (originalPromiseResult[1394] === body[1394] || body[1394] === "") {
      consoleLogIt("Update Variable Customer_Verification.Number3 Succeeded");
    } else {
      consoleErrorIt(
        "Update Variable Customer_Verification.Number3 Did Not Match"
      );
      dispatch(
        reportError({
          callIds,
          title: "Update Variable Did Not Match",
          text: "Customer_Verification.Number3",
        })
      );
    }
    if (originalPromiseResult[1395] === body[1395] || body[1395] === "") {
      consoleLogIt("Update Variable Customer_Verification.Email1 Succeeded");
    } else {
      consoleErrorIt(
        "Update Variable Customer_Verification.Email1 Did Not Match"
      );
      dispatch(
        reportError({
          callIds,
          title: "Update Variable Did Not Match",
          text: "Customer_Verification.Email1",
        })
      );
    }
    if (originalPromiseResult[1396] === body[1396] || body[1396] === "") {
      consoleLogIt("Update Variable Customer_Verification.Email2 Succeeded");
    } else {
      consoleErrorIt(
        "Update Variable Customer_Verification.Email2 Did Not Match"
      );
      dispatch(
        reportError({
          callIds,
          title: "Update Variable Did Not Match",
          text: "Customer_Verification.Email2",
        })
      );
    }
    if (originalPromiseResult[1397] === body[1397] || body[1397] === "") {
      consoleLogIt(
        "Update Variable Customer_Verification.Verified_By Succeeded"
      );
    } else {
      consoleErrorIt(
        "Update Variable Customer_Verification.Verified_By Did Not Match"
      );
      dispatch(
        reportError({
          callIds,
          title: "Update Variable Did Not Match",
          text: "Customer_Verification.Verified_By",
        })
      );
    }
    dispatch(cvVariableUpdate("success"));
    return "done";
  } catch (e) {
    // error
    consoleErrorIt("Update Variables Customer Verification Failed");
    dispatch(cvVariableUpdate("failed"));
    dispatch(
      reportError({
        callIds,
        title: "Update Variable Failed",
        text: "Customer Verification Variables",
      })
    );
    return "failed";
  }
};

export const postVerifcationContact = async (
  metadata: any,
  callIds: any,
  verification: any,

  dispatch: any
) => {
  try {
    const resultAction = await dispatch(
      postContactVerificationContact({
        metadata: metadata,
        callIds,
        verification,
      })
    );

    const originalPromiseResult = unwrapResult(resultAction);
    // handle Result here
    if (originalPromiseResult.activeContact.fields[1540] === moment(verification.date).format("YYYY-MM-DD")) {
      consoleLogIt("Update Contact Field IVAVerficationDate Succeeded");
      dispatch(cvContactUpdate("success"));
      return "success";
    } else {
      consoleErrorIt("Update Contact Field IVAVerficationDate Did Not Match");
      dispatch(
        reportError({
          callIds,
          title: "Update Contact Field Did Not Match",
          text: "IVAVerficationDate",
        })
      );
      dispatch(cvContactUpdate("failed"));
      return "failed";
    }
  } catch (e) {
    // error
    consoleErrorIt("Update Contact Field IVAVerficationDate Failed");
    dispatch(cvContactUpdate("failed"));
    dispatch(
      reportError({
        callIds,
        title: "Update Variable Failed",
        text: "IVAVerficationDate",
      })
    );
    return "failed";
  }
};
