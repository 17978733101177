/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  updateWorksheetDisclosuresProgress,
  updateWorksheetDisclosuresFailed,
  updateWorksheetDisclosuresSuccess,
  updateWorksheetVerifyProgress,
  updateWorksheetVerifyFailed,
  updateWorksheetVerifySuccess,
  updateWorksheetAuthProgress,
  updateWorksheetAuthFailed,
  updateWorksheetAuthSuccess,
  updateWorksheetOutcomeProgress,
  updateWorksheetOutcomeFailed,
  updateWorksheetOutcomeSuccess,
  updateIncentivesVariableInProgress,
  updateIncentivesVariableFailed,
  updateIncentivesVariableSuccess,
  updateSkipTraceVariableInProgress,
  updateSkipTraceVariableFailed,
  updateSkipTraceVariableSuccess,
} from "../actions/worksheetActions";

import { updateColumn2Tabs } from "../actions/standardActions";

export const putVariableDisclosures =
  (metadata: any, disc: any, ids: any, update: any) =>
  async (dispatch: any) => {
    try {
      const newDisc: any = {};
      newDisc.bk = disc.bk;
      newDisc.dc7 = disc.dc7;
      newDisc.mm = disc.mm;
      newDisc.cell = disc.cell;
      newDisc.cr = disc.cr;
      newDisc.ca5 = disc.ca5;
      newDisc.ca30 = disc.ca30;

      dispatch(updateWorksheetDisclosuresProgress());
      update.map((item: any) => {
        newDisc[item.id] = item.value;
      });

      const value = JSON.stringify(newDisc);
      const body = { 1075: value };
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}/appsvcs/rs/svc/agents/${metadata.userId}/interactions/calls/${ids.systemCallId}/variables_2`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();
      const code = await response.ok;
      // console.log("Variable Data", data);
      if (code === true) {
        if (data[1075] === value) {
          dispatch(updateWorksheetDisclosuresSuccess(data));
        } else {
          dispatch(updateWorksheetDisclosuresFailed());
        }
      } else {
        dispatch(updateWorksheetDisclosuresFailed());
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      console.error("error", e);
      dispatch(updateWorksheetDisclosuresFailed());
    }
  };

export const putVariableVerifyAuthentication =
  (metadata: any, auth: any, ids: any, update: any) =>
  async (dispatch: any) => {
    try {
      const copyAuth = (data: any) => {
        return {
          method: data.method,
          verify: data.verify,
          ani: data.ani,
          loanNumber: data.loanNumber,
          l4ssn: data.l4ssn,
          fullSsn: data.fullSsn,
          zip: data.zip,
          birthDate: data.birthDate,
          originalUpb: data.originalUpb,
          interestRate: data.interestRate,
          payAmount: data.payAmount,
          authorizedCell: data.authorizedCell,
        };
      };
      // console.log(copyAuth(auth));
      // console.log(auth);
      const newAuth: any = copyAuth(auth);

      dispatch(updateWorksheetVerifyProgress());
      update.map((item: any) => {
        newAuth[item.id] = item.value;
      });

      const value = JSON.stringify(newAuth);
      const body = { 1074: value };
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}/appsvcs/rs/svc/agents/${metadata.userId}/interactions/calls/${ids.systemCallId}/variables_2`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();
      const code = await response.ok;
      //console.log("Variable Data", data);
      if (code === true) {
        if (data[1074] === value) {
          dispatch(updateWorksheetVerifySuccess(data));
        } else {
          dispatch(updateWorksheetVerifyFailed());
        }
      } else {
        dispatch(updateWorksheetVerifyFailed());
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      console.error(e);
      dispatch(updateWorksheetVerifyFailed());
    }
  };

export const putVariableAuthentication =
  (metadata: any, auth: any, ids: any, update: any) =>
  async (dispatch: any) => {
    try {
      const copyAuth = (data: any) => {
        return {
          method: "agent",
          verify: data.verify,
          ani: data.ani,
          loanNumber: data.loanNumber,
          l4ssn: data.l4ssn,
          fullSsn: data.fullSsn,
          zip: data.zip,
          birthDate: data.birthDate,
          originalUpb: data.originalUpb,
          interestRate: data.interestRate,
          payAmount: data.payAmount,
          authorizedCell: data.authorizedCell,
        };
      };
      // console.log(copyAuth(auth));
      // console.log(auth);
      const newAuth: any = copyAuth(auth);

      dispatch(updateWorksheetAuthProgress());
      update.map((item: any) => {
        newAuth[item.id] = item.value;
      });

      const value = JSON.stringify(newAuth);
      const body = { 1074: value, 1054: "1" };
      dispatch(updateColumn2Tabs("loan"));
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}/appsvcs/rs/svc/agents/${metadata.userId}/interactions/calls/${ids.systemCallId}/variables_2`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();
      const code = await response.ok;
      //console.log("Variable Data", data);
      if (code === true) {
        if (data[1074] === value) {
          dispatch(updateWorksheetAuthSuccess(data));
        } else {
          dispatch(updateWorksheetAuthFailed());
        }
      } else {
        dispatch(updateWorksheetAuthFailed());
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      console.error(e);
      dispatch(updateWorksheetAuthFailed());
    }
  };

export const putVariableOutcomes =
  (metadata: any, outcome: any, ids: any, update: any) =>
  async (dispatch: any) => {
    try {
      const newOutcome: any = {};
      newOutcome.mood = outcome.mood;
      newOutcome.abusive = outcome.abusive;
      newOutcome.escalation = outcome.escalation;
      newOutcome.resolution = outcome.resolution;
      newOutcome.complaint = outcome.complaint;

      dispatch(updateWorksheetOutcomeProgress());
      //TODO fix function

      update.map((item: any) => {
        newOutcome[item.id] = item.value;
      });

      const value = JSON.stringify(newOutcome);
      const body = { 1079: value };
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}/appsvcs/rs/svc/agents/${metadata.userId}/interactions/calls/${ids.systemCallId}/variables_2`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();
      const code = await response.ok;
      //console.log("Variable Data", data);
      if (code === true) {
        if (data[1079] === value) {
          dispatch(updateWorksheetOutcomeSuccess(data));
        } else {
          dispatch(updateWorksheetOutcomeFailed());
        }
      } else {
        dispatch(updateWorksheetOutcomeFailed());
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      console.error(e);
      dispatch(updateWorksheetOutcomeFailed());
    }
  };

export const putIncentiveVariables =
  (metadata: any, incent: any, ids: any, update: any) =>
  async (dispatch: any) => {
    try {
      const newIncentives: any = {};
      newIncentives.refiWS = incent.refiWS;
      newIncentives.refiO = incent.refiO;
      newIncentives.refiR = incent.refiR;
      newIncentives.othWS = incent.othWS;
      newIncentives.othO = incent.othO;
      newIncentives.othR = incent.othR;

      dispatch(updateIncentivesVariableInProgress());


      update.map((item: any) => {
        newIncentives[item.id] = item.value;
      });
      
      const value = JSON.stringify(newIncentives);
      const body = { 1248: value };
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}/appsvcs/rs/svc/agents/${metadata.userId}/interactions/calls/${ids.systemCallId}/variables_2`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();
      const code = await response.ok;
      //console.log("Variable Data", data);
      if (code === true) {
        if (data[1248] === value) {
          dispatch(updateIncentivesVariableSuccess(data));
        } else {
          dispatch(updateIncentivesVariableFailed());
        }
      } else {
        dispatch(updateIncentivesVariableFailed());
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      console.error(e);
      dispatch(updateIncentivesVariableFailed());
    }
  };

export const putSkipTraceVariables =
  (metadata: any, skip: any, ids: any) => async (dispatch: any) => {
    try {
      dispatch(updateSkipTraceVariableInProgress());
      const body = { 432: skip.badNumber };
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}/appsvcs/rs/svc/agents/${metadata.userId}/interactions/calls/${ids.systemCallId}/variables_2`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();
      const code = await response.ok;

      if (code === true) {
        dispatch(updateSkipTraceVariableSuccess(data));
      } else {
        dispatch(updateSkipTraceVariableFailed());
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      console.error(e);
      dispatch(updateSkipTraceVariableFailed());
    }
  };
