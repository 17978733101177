/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import moment from "moment-timezone";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { closeEmailVivrWindow } from "../../app/actions/standardActions";
import { Modal } from "react-bootstrap";
import EmailVIVRIframe from "./EmailVIVRIframe";
import "./EmailVIVRModal.css";
import { consoleLogIt } from "../../app/log";

const EmailVIVRModal = () => {
  const dispatch = useAppDispatch();
  const timestamp = moment().tz("America/New_York").format("MM/DD/YYYY HH:mm:ss");
  const emailData: any = useAppSelector((state) => state.features.emailVivrObj);
  const callIds: any = useAppSelector((state) => state.main.callIds);
  // const link =
  //   "https://api.five9.com/ivr/1/109388/campaigns/1140672/new_ivr_session";
  const link = "https://api.five9.com/ivr/1/109388/campaigns/1140672/new_ivr_session"
  const url = `${link}?VIVR.Call_Id=${callIds.rptCallId}&emailAddress=${emailData.email.address}&VIVR.LoanNumber=${callIds.loanNumber}&phoneNum=${emailData.email.number}&template=${emailData.email.type}&timeStamp=${timestamp}`;
  return (
    <Modal
      size="lg"
      backdrop={true}
      id="email_vivr_window"
      show={emailData.status}
      onHide={() => dispatch(closeEmailVivrWindow())}
    >
      <Modal.Header closeVariant="white" closeButton>
        <Modal.Title>Send an Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="embed-responsive embed-responsive-16by9">
          <EmailVIVRIframe url={url} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EmailVIVRModal;
