import React from "react";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const FooterTickerLink = ({url}: any) => {
  if (url !== "https://null.null.com") {
    return <></>;
  } else
    return (
      <a href={url} target="_blank" rel="noreferrer">
        CLICK HERE
      </a>
    );
};

export default FooterTickerLink;
