import { createSlice, createAsyncThunk, createAction, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { consoleLogIt, consoleErrorIt } from "../../log";

export const worksheetOutcomeUpdateSuccess = createAction(
  "app/worksheetOutcomeUpdateSuccess",
  function prepare(data) {
//consoleLogIt("app/worksheetOutcomeUpdateSuccess", data)
    return {
      payload: { data, id: nanoid() },
    };
  }
);

export const worksheetOutcomeUpdateFailed = createAction(
  "app/worksheetOutcomeUpdateFailed",
  function prepare(data) {
    return {
      payload: { data, id: nanoid() },
    };
  }
);

export const updateRecieptOutcomeVariable = createAsyncThunk(
  "recieptOutcomeVariable/put",
  async (
    { metadata, callIds, outcome, update }: any,
    { dispatch, rejectWithValue }
  ) => {
    try {
      consoleLogIt("Metadata", metadata);
      const baseUrl = metadata.data.metadata.dataCenters[0].apiUrls[0].host;
      const userId = metadata.data.userId;
      const newOutcome: any = {};
      newOutcome.mood = outcome.mood;
      newOutcome.abusive = outcome.abusive;
      newOutcome.escalation = outcome.escalation;
      newOutcome.resolution = outcome.resolution;
      newOutcome.complaint = outcome.complaint;
      newOutcome[update.id] = update.value;

      const value = JSON.stringify(newOutcome);
      const body = {
        1079: value,
      };
      const res = await fetch(
        `https://${baseUrl}/appsvcs/rs/svc/agents/${userId}/interactions/calls/${callIds.systemCallId}/variables_2`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      ).then((data: any) => {
        consoleLogIt("Post Outcome Variables", data);

        return data.json();
      });
      return res;
    } catch (e) {
      consoleErrorIt("Failed to Post Outcome Variables", e);
      return rejectWithValue(e);
    }
  }
);

export interface OutcomeObject {
  apiStatus: "success" | "failed" | "pending" | "";
  mood: null | "Happy" | "Neutral" | "Mad";
  abusive: null | "Yes" | "No";
  escalation: null | "Yes" | "No";
  resolution: null | "Yes" | "No";
  complaint:
    | null
    | "Mentioned Issue"
    | "Dissatisfied with Process"
    | "Dissatisfied with Outcome"
    | "None";
}

const initialState: any = {
  apiStatus: "",
  mood: null,
  abusive: null,
  escalation: null,
  resolution: null,
  complaint: null,
} as OutcomeObject;

const outcome = createSlice({
  name: "outcome",
  initialState,
  reducers: {
    // openOffcanvas: (state, { payload }) => {
    //   consoleLogIt("Verbal Complaint Payload", payload);
    //   state[payload] = true;
    // },
    // closeOffcanvase: (state, { payload }) => {
    //   state[payload] = false;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase("GET_CALLDATA_SUCCESS", (state: any, action: any) => {
      const { payload } = action;
      const data = JSON.parse(payload.data.variables["1079"]);
      state.mood = data.mood;
      state.abusive = data.abusive;
      state.escalation = data.escalation;
      state.resolution = data.resolution;
      state.complaint = data.complaint;
    })
    .addCase(worksheetOutcomeUpdateSuccess, (state: any, action: any) => {
      const { payload } = action;
      //consoleLogIt('Update Variables Success', payload)
      const data = JSON.parse(payload.data["1079"]);
      // state.apiStatus ="success";
      state.mood = data.mood;
      state.abusive = data.abusive;
      state.escalation = data.escalation;
      state.resolution = data.resolution;
      state.complaint = data.complaint;
    });
  },
});
// thunks

// Action creators are generated for each case reducer function

// export const { } = outcome.actions;

export default outcome.reducer;
