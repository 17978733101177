/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppSelector } from "../../../../app/hooks";

import VCToggleButtons from "./VCToggleButtons";
import VCRadioButtons from "./VCRadioButtons";

const ButtonsVerbalComplaint = ({ qna }: any) => {

  const verbalComplaint: any = useAppSelector((state) => state.verbalComplaint);
  
  switch (qna.type) {
    case "radio":
      return (<VCRadioButtons
        qna={qna}
        verbalComplaint={verbalComplaint}
      />)
    default:
      return (<VCToggleButtons
        qna={qna}
        verbalComplaint={verbalComplaint}
      />)
  }
};

export default ButtonsVerbalComplaint;
