/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

/* eslint-disable react/no-unescaped-entities */
import React from "react";
import moment from "moment-timezone";

export const RefiOnlyScripts = (concatName: string) => {
  const array = [];

  // Not another 30 year mortgage
  array.push({
    id: "08",
    option: "refi",
    heading: "Not another 30 year mortgage",
    customer: `I don't want to have another 30 years on my mortgage.`,
    agent: (
      <>
        <i className="text-info">{concatName}</i>, we will allow you to keep the
        same term. There is no need to go out 30 years unless you want the
        additional monthly savings.
      </>
    ),
  });

  // High Interest Rates
  array.push({
    id: "09",
    option: "refi",
    heading: "High Interest Rates",
    customer: `No thank you, interest rates are just too high right now.`,
    agent: (
      <>
        <i className="text-info">{concatName}</i>, I understand your time is
        valuable. This is a great opportunity to lower your interest rate or to
        take cash out of your home. May I ask why you're not interested in
        possibly lowering your rate or getting cash-in-hand for any life
        expenses?
      </>
    ),
  });
  // Rate is already low
  array.push({
    id: "10",
    option: "refi",
    heading: "Rate is already low",
    customer: `My rate's already super low, there's no way you could beat it…`,
    agent: (
      <>
        <i className="text-info">{concatName}</i> your account is constantly
        monitored against the market for opportunities to save you money, and it
        is currently showing you are eligible for an interest rate reduction.
      </>
    ),
  });

  array.push({
    id: "11",
    option: "refi",
    heading: "Waiting until I have no PMI",
    customer: `I have PMI on my mortgage.  I want to be able to refinance when 
    I can get rid of my PMI.`,
    agent: (
      <>
        <i className="text-info">{concatName}</i>, we can review your loan and
        determine whether there are any options which may allow you to remove
        PMI. Even if the property value will not allow for removal of PMI, we
        still want you to have the benefit of the lower rate.
      </>
    ),
  });

  return array;
};

export const RefiAndCashOutScripts = (
  concatName: string,
  option: string,
  incentiveOptions: any
) => {
  const array = [];

  if (incentiveOptions.othWS === "null") {
    array.push({
      id: "01",
      option: option,
      heading: "Pitch",
      pitch: (
        <>
          Thank you for being a great customer! We'd like to lower your interest
          rate to save you money. First let's handle the reason for your call,
          and then I'll transfer you over to our team to see how much we can
          save you.
        </>
      ),
    });
  } else {
    array.push({
      id: "01",
      option: option,
      heading: "Pitch",
      pitch: (
        <>
          Thank you for being a great customer! We'd like to lower your interest
          rate or homeowner insurance to save you money. First let's handle the
          reason for your call, and then I'll transfer you over to our team to
          see how much we can save you.
        </>
      ),
    });
  }

  array.push({
    id: "02",
    option: option,
    heading: "Yes to Transfer",
    customer: `Ok, that would be great!`,
    agent: (
      <>
        Great, I will put you through to our loan originations team, who are
        part of our parent company, Freedom Mortgage. They can assist you with
        looking into your options.
        <br />
        <i className="text-info">{concatName}</i>, please be advised that when I
        transfer your call, there may be a brief hold, but someone will be with
        you momentarily. Thank you for being a RoundPoint Mortgage customer, I
        will transfer you now.
      </>
    ),
  });
  array.push({
    id: "03",
    option: option,
    heading: "Not Interested, Too busy, long process",
    customer: `I am not interested/very busy right now/this process takes a
    long time.`,
    agent: (
      <>
        <i className="text-info">{concatName}</i>, I understand your time is
        valuable. This is a great opportunity to lower your interest rate or to
        take cash out of your home. May I ask why you're not interested in
        possibly lowering your rate or getting cash-in-hand for any life
        expenses?
      </>
    ),
  });
  array.push({
    id: "04",
    option: option,
    heading: "Working with different lender",
    customer: `I am already talking to another lender that is willing to
    give me a lower rate than what you offer.`,
    agent: (
      <>
        <i className="text-info">{concatName}</i>, we value your business and
        will do all we can to match or provide you with a better rate.
      </>
    ),
  });
  array.push({
    id: "05",
    option: option,
    heading: "Recently refinanced",
    customer: `I just refinanced with you all, are you sure you can
    refinance my loan again in such a short time?`,
    agent: (
      <>
        <i className="text-info">{concatName}</i>, yes, our records show you are
        eligible for the opportunity to potentially lower your rate, so let me
        transfer you to our loan originations team.
      </>
    ),
  });
  array.push({
    id: "06",
    option: option,
    heading: "Refi recently declined",
    customer: `I was recently declined by your loan advisors. What do you
    mean I may be eligible again? This is a waste of my time.`,
    agent: (
      <>
        <i className="text-info">{concatName}</i>, our records show that you are
        eligible for this opportunity as of today to lower your rate, so let me
        transfer you to our loan originations team. The savings opportunity
        would be worth us checking again.
      </>
    ),
  });
  array.push({
    id: "07",
    option: option,
    heading: "Speak with Spouse/Partner/Co-Borrower",
    customer: `I need to speak to my spouse (co-borrower) about this first.
    I will call back if we agree to go through with the process?`,
    agent: (
      <>
        <i className="text-info">{concatName}</i>, this is a great opportunity
        to lower your interest rate and will only take a few minutes of your
        time. The loan originations team can give you all the information on the
        new loan to discuss with your spouse/Partner/co-borrower. Since we have
        most of your loan application information, we will be able to process
        your loan faster.
      </>
    ),
  });
  return array;
};

export const RefiAndCashOutPurchaseScripts = (
  concatName: string,
  option: string
) => {
  const array = [];
  array.push({
    id: "20",
    option: option,
    heading: "Not Interested in Refi, Purchasing a Property",
    customer: `I'm not interested in a refinance, 
    because I am purchasing a property.`,
    agent: (
      <>
        Great, and congratulations on buying a property! I can put you through
        to our purchase loan originations team, who are part of our parent
        company, Freedom Mortgage. They can assist you with looking into your
        purchase options.
        <i className="text-info">{concatName}</i>, please be advised that when I
        transfer your call, there may be a brief hold, but someone will be with
        you momentarily. Thank you for being a RoundPoint Mortgage customer, I
        will transfer you now.
      </>
    ),
  });
  array.push({
    id: "21",
    option: option,
    heading: "Yes to Purchase Transfer",
    customer: `I am interested in speaking to someone about purchasing a
    property.`,
    agent: (
      <>
        Great, I will put you through to our loan originations team, who are
        part of our parent company, Freedom Mortgage. They can assist you with
        looking into your options. <i className="text-info">{concatName}</i>,
        please be advised that when I transfer your call, there may be a brief
        hold, but someone will be with you momentarily. Thank you for being a
        RoundPoint Mortgage customer, I will transfer you now.
      </>
    ),
  });
  array.push({
    id: "22",
    option: option,
    heading: "Not Interested in Purchase",
    customer: `I'm not in the market to purchase a home at this time.`,
    agent: (
      <>
        <i className="text-info">{concatName}</i>, I completely underdstand.
        Should your needs change in the future our parent company, Freedom
        Mortgage, can help with your purchase loan.
      </>
    ),
  });
  return array;
};

export const CashOutOnlyScripts = (concatName: string) => {
  const array = [];

  // Not another 30 year mortgage
  array.push({
    id: "08",
    option: "cashout",
    heading: "Not another 30 year mortgage",
    customer: `I don't want to have another 30 years on my mortgage.`,
    agent: (
      <>
        <i className="text-info">{concatName}</i>, I'm glad you brought that up.
        That's why so many of our customers take advantage of our cash out refi
        program. A Loan Advisor can review your options for keeping your
        mortgage term the same while possibly lowering your rate AND getting
        money back. The cash can be used for paying down credit cards or other
        debts, home improvements, education expenses, or a fun vacation.
      </>
    ),
  });
  // Working on credit score
  array.push({
    id: "09",
    option: "cashout",
    heading: "Working on credit score",
    customer: `I need to work on my credit score...`,
    agent: (
      <>
        <i className="text-info">{concatName}</i>, you still may qualify for a
        cash out refinance, even with a lower credit score. I can transfer you
        to a Loan Advisor who will be able to check that for you.
      </>
    ),
  });
  // What's in it for you / Why should I do this?
  array.push({
    id: "10",
    option: "cashout",
    heading: "What's in it for you / Why should I do this?",
    customer: `What's in it for you / Why should I do this?`,
    agent: (
      <>
        <i className="text-info">{concatName}</i>, we want you to be a
        RoundPoint customer for life! As part of our protect and defend program,
        we are always looking for opportunities to save you money, or to help
        you spend more time doing the things you love. It's one of the many ways
        we show how much we value you as a customer. Can I transfer you over to
        a Loan Advisor to discuss the benefit of a cash out refinance in more
        detail?
      </>
    ),
  });

  return array;
};

export const MaticScripts = (concatName: string, incentiveOptions: any) => {
  const array = [];
  switch (incentiveOptions.otherLoan) {
    case "M":
      array.push({
        id: "01",
        option: "matic",
        heading: "Agent Pitch",
        pitch: (
          <>
            I see that your homeowner's insurance will renew soon. Now would be
            a great time to talk to our friends at Matic Insurance, who may be
            able to lower your insurance costs by comparing the rate from your
            current policy to rates from more than 40 top-rated carriers.
            <br />
            <br />
            This could reduce the monthly homeowner's insurance portion of your
            mortgage payment. Let me transfer you now to see if they can help
            you save on your insurance.
          </>
        ),
      });

      break;
    case "E":
      array.push({
        id: "01",
        option: "matic",
        heading: "Agent Pitch",
        pitch: (
          <>
            I see that we recently ran an escrow analysis for you, and I noticed
            that you may be overpaying for your homeowner's insurance. Now is a
            great time to talk to our friends at Matic Insurance, who may be
            able to lower your insurance costs by comparing the rate from your
            current policy to rates from more than 40 top-rated carriers.
            <br />
            <br />
            This could reduce the monthly homeowner's insurance portion of your
            mortgage payment. Let me transfer you now to see if they can help
            you save on your insurance.
          </>
        ),
      });
      break;
    case "R":
      array.push({
        id: "01",
        option: "matic",
        heading: "Agent Pitch",
        pitch: (
          <>
            I see that recently the price of your homeowner's insurance went up.
            Now is a great time to talk to our friends at Matic Insurance, who
            may be able to lower your insurance costs by comparing the rate from
            your current policy to rates from more than 40 top-rated carriers.
            <br />
            <br />
            This could reduce the monthly homeowner's insurance portion of your
            mortgage payment. Let me transfer you now to see if they can help
            you save on your insurance.
          </>
        ),
      });
      break;
    default:
      array.push({
        id: "01",
        option: "matic",
        heading: "Agent Pitch",
        pitch: (
          <>
            I see that you just recently became a Roundpoint customer. Now is a
            great time to talk to our partners at Matic Insurance, who may be
            able to lower your homeowners insurance costs. They can compare the
            rate from your current policy to rates from more than 40+ top-rated
            carriers. Let me transfer you now to see if they can help you save
            on your insurance.
          </>
        ),
      });
      break;
  }

  // Yes to Transfer
  array.push({
    id: "02",
    option: "matic",
    heading: "Yes to Transfer",
    customer: `"Ok, that would be great!"`,
    agent: (
      <>
        Great, I will put you through to our insurance partner, Matic, with whom
        we work to improve insurance premiums and/or coverage to ultimately help
        lower costs. They will assist you in reviewing your options. Customer
        Name, please be advised when I transfer your call, there may be a brief
        hold, but someone will be with you momentarily. Thank you for being a
        valued RoundPoint customer, I will transfer you now.
      </>
    ),
  });

  // Not Interested, Too busy, long process
  array.push({
    id: "03",
    option: "matic",
    heading: "Not Interested, Too busy, long process",
    customer: `I am not interested/very busy right now/this process takes a long time.`,
    agent: (
      <>
        <i className="text-info">{concatName}</i>, I understand your time is
        valuable. This is a great opportunity to potentially lower your
        insurance costs. May I ask why you're not interested in possibly
        lowering these costs and your mortgage monthly payment?
      </>
    ),
  });

  // Renewing existing policy
  array.push({
    id: "04",
    option: "matic",
    heading: "Renewing existing policy",
    customer: `I am going to renew my current insurance policy.`,
    agent: (
      <>
        <i className="text-info">{concatName}</i>, ok, I understand. If you
        change your mind and wish to explore lowering your current policy, we'd
        be happy to connect you with Matic.
      </>
    ),
  });

  return array;
};

export const ReverseScripts = (concatName: string) => {
  const array = [];
  // Not another 30 year mortgage

  array.push({
    id: "01",
    option: "reverse",
    heading: "Agent Pitch",
    pitch: (
      <>
        "Thank you for being a great customer! As your current lender, we
        believe you might benefit from a reverse mortgage and would like to tell
        you more about it. Our team can answer all your questions!"
      </>
    ),
  });

  // Yes to Transfer
  array.push({
    id: "02",
    option: "reverse",
    heading: "Yes to Transfer",
    customer: `"Ok, that would be great!"`,
    agent: (
      <>
        “Great, I will put you through to our reverse mortgage team. They can
        assist you with looking into your options.{" "}
        <i className="text-info">{concatName}</i>, please be advised that when I
        transfer your call, there may be a brief hold, but someone will be with
        you momentarily. Thank you for being a RoundPoint Mortgage customer, I
        will transfer you now.”
      </>
    ),
  });

  // Worried that the bank will own my home
  array.push({
    id: "03",
    option: "reverse",
    heading: "Worried that the bank will own my home",
    customer: `"I am worried that the bank will own my home and I will have no place to live."`,
    agent: (
      <>
        "<i className="text-info">{concatName}</i>, I understand your concern
        and would like to transfer you to the reverse mortgage team who can
        address that and see if a reverse mortgage is right for you."
      </>
    ),
  });

  // Reverse mortgages are designed to take advantage of retirees"
  array.push({
    id: "04",
    option: "reverse",
    heading: "Reverse mortgages are designed to take advantage of retirees",
    customer: `"I am worried that these mortgages are designed to take advantage of retirees."`,
    agent: (
      <>
        "<i className="text-info">{concatName}</i>, I understand your concern
        and would like to transfer you to the reverse mortgage team who can
        address that and see if a reverse mortgage is right for you."
      </>
    ),
  });

  // I wont be able to leave my home to my heirs
  array.push({
    id: "05",
    option: "reverse",
    heading: "I wont be able to leave my home to my heirs",
    customer: `“I want to be able to leave my home to my heirs and this wont allow me to do that.”`,
    agent: (
      <>
        "<i className="text-info">{concatName}</i>, I understand your concern
        and would like to transfer you to the reverse mortgage team who can
        address that and see if a reverse mortgage is right for you."
      </>
    ),
  });

  // Not Interested. Too busy and long process
  array.push({
    id: "06",
    option: "reverse",
    heading: "Not Interested. Too busy and long process",
    customer: `“I am not interested/very busy right now/this process takes a long time.” `,
    agent: (
      <>
        "<i className="text-info">{concatName}</i>, I understand your concern.
        Let's schedule a future time to discuss when it's convenient for you."
      </>
    ),
  });
  // Speak with Spouse/Partner/Co-Borrower
  array.push({
    id: "07",
    option: "reverse",
    heading: "Speak with Spouse/Partner/Co-Borrower",
    customer: `“I need to speak to my spouse (co-borrower) about this first. I will call back if we 
          agree to go through with the process."`,
    agent: (
      <>
        "<i className="text-info">{concatName}</i>, I understand your concern.
        Let's schedule a future time to discuss when it's convenient for you."
      </>
    ),
  });

  return array;
};

export const SafeHavenScripts = (concatName: string, hoursOfOperation: any) => {
  const array = [];
  // Not another 30 year mortgage
  moment.tz.setDefault("America/New_York");

  if (hoursOfOperation.safeHaven === false) {
    // CLOSED
    array.push({
      id: "01",
      option: "safe_haven",
      heading: "Agent Pitch",
      pitch: (
        <>
          Mr./Mrs. <i className="text-info">{concatName}</i>, you're eligible
          for a special offer for an ADT-monitored home security system. Have
          you ever thought about a monitoring system to help protect your home
          and family?
          <br />
          <br />I just noticed they're not open right now, but what I can do is
          have a Safe Haven representative call you back. When would be a
          convenient time for you?
        </>
      ),
    });
    // Yes INterested
    array.push({
      id: "02",
      option: "safe_haven",
      heading: "Yes Interested",
      customer: `Ok, that would be great!`,
      agent: (
        <>
          Wonderful, They are open Monday to Friday 9am to 7pm EST. When
          would you like a security advisor to call you back?
        </>
      ),
    });
    // Not interested
    array.push({
      id: "03",
      option: "safe_haven",
      heading: "Not interested",
      customer: `I do not think I am interested`,
      agent: (
        <>
          Just so you know, we have teamed up with Safe Haven, an ADT-Authorized
          Dealer to provide you with a special offer. The offer includes:
          <ul>
            <li>Up to $850.00 in state-of-the art equipment.</li>
            <li>
              An upgraded system including a 7” touchscreen (looks like an IPAD)
            </li>
            {/* <li>Free installation</li>
            <li>A $1,000 credit for additional equipment</li> */}
          </ul>
          Would you like a security advisor to call you back?
        </>
      ),
    });
  } else {
    // OPENED
    array.push({
      id: "01",
      option: "safe_haven",
      heading: "Agent Pitch",
      pitch: (
        <>
          Mr./Mrs. <i className="text-info">{concatName}</i>, you're eligible
          for a special offer for an ADT-monitored home security system. Have
          you ever thought about a monitoring system to help protect your home
          and family?
          <br />
          <br />I can transfer you to a Safe Haven security specialist now to
          walk you through everything, it only takes a few minutes?
        </>
      ),
    });
    // Yes to Transfer
    array.push({
      id: "02",
      option: "safe_haven",
      heading: "Yes to Transfer",
      customer: `Ok, that would be great!`,
      agent: (
        <>
          Great, I will connect you with Safe Haven where a security specialist
          can assist you.
          <i className="text-info">{concatName}</i>, please be advised that when
          I transfer your call, there may be a brief hold, but someone will be
          with you momentarily. Thank you for being a RoundPoint Mortgage
          customer, I will transfer you now.
        </>
      ),
    });
    // Not interested
    array.push({
      id: "03",
      option: "safe_haven",
      heading: "Not interested",
      customer: `I do not think I am interested`,
      agent: (
        <>
          Just so you know, we have teamed up with Safe Haven, an ADT-Authorized
          Dealer to provide you with a special offer. The offer includes:
          <ul>
            <li>Up to $850.00 in state-of-the art equipment.</li>
            <li>
              An upgraded system including a 7” touchscreen (looks like an IPAD)
            </li>
            {/* <li>Free installation</li>
            <li>A $1,000 credit for additional equipment</li> */}
          </ul>
          I can transfer you to a Safe Haven security specialist now to walk you
          through everything, it only takes a few minutes, it might be worth
          your time.
        </>
      ),
    });
  }

  // No Time
  array.push({
    id: "04",
    option: "safe_haven",
    heading: "No Time",
    customer: `...I don't have time right now...`,
    agent: (
      <>
        No problem, if you want to call them on your own, their phone number is
        877-850-0792. They are available Monday through Friday 9am to 7pm EST.
      </>
    ),
  });

  // Maybe Later
  array.push({
    id: "05",
    option: "safe_haven",
    heading: "Later",
    customer: `Maybe Later`,
    agent: (
      <>
        Great! You can respond at a more convenient time or you can contact Safe
        Haven ADT-Authorized Dealer directly from 9am to 7pm EST Monday-Friday.
        Or you can visit their website at
        smarthomeadvantage.com/roundpointwelcomekit for more info.
      </>
    ),
  });

  // Hard No
  array.push({
    id: "06",
    option: "safe_haven",
    heading: "Hard No",
    customer: `I am not interested at all.`,
    agent: (
      <>
        I completely understand, and just wanted you to know that this offer is
        available to you as a RoundPoint Mortgage customer. If you change your
        mind, you can always call them at 877-850-0792, Mon-Fri 9am-7pm EST, or
        visit their website at smarthomeadvantage.com/roundpointwelcomekit.
      </>
    ),
  });
  // Maybe Later
  array.push({
    id: "07",
    option: "safe_haven",
    heading: "Already Have a Security System",
    customer: `I already have a security system`,
    agent: (
      <>
        That's great to hear! ADT and RoundPoint Mortgage thank you for your
        business. Please check out our Marketplace page to see some of the other
        partners we work with who may be able to save you money on other home
        services like insurance!
      </>
    ),
  });
  return array;
};
