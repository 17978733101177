/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createAsyncThunk } from "@reduxjs/toolkit";

export const consoleLogIt = (title: string, object: any = undefined) => {
  const date = new Date();
  const options: any = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "America/New_York",
    timeZoneName: "short",
  };
  if (object === undefined) {
    console.log(
      "FL - ",
      new Intl.DateTimeFormat("en-US", options).format(date),
      " - ",
      title
    );
  } else {
    console.log(
      "FL - ",
      new Intl.DateTimeFormat("en-US", options).format(date),
      " - ",
      title,
      object
    );
  }
};

export const consoleErrorIt = (title: string, object: any = undefined) => {
  const date = new Date();
  const options: any = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "America/New_York",
    timeZoneName: "short",
  };
  if (object === undefined) {
    console.error(
      "FL - ",
      new Intl.DateTimeFormat("en-US", options).format(date),
      " - ",
      title
    );
  } else {
    console.error(
      "FL - ",
      new Intl.DateTimeFormat("en-US", options).format(date),
      " - ",
      title,
      object
    );
  }
};

// export const reportError = (callIds: any, title: string, text = "" ) => async () => {
//   try {

//     // eslint-disable-next-line no-undef
//     const response = await fetch(
//       "https://api.prod2.us.whendu.com/v1/webhooks/86146d82-eb2f-47f1-9d61-9816492f6965/catch/RHa8BpjgV4U5LM172vMb4OQqb8kVEz4r/event/Frontline_Error",
//       {
//         method: "Post",
//         credentials: "include",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(body),
//       }
//     );

//     const code = await response.ok;
//     if (code === true) {
//       consoleLogIt(title,text);
//     } else {
//       consoleErrorIt("Failed to Report Error - " + title + " - "+text);
//     }
//   } catch (e) {
//     // eslint-disable-next-line no-undef
//     consoleErrorIt("Failed to Report Error - " + title + " - "+text,e);
//   }
// };


export const reportError = createAsyncThunk(
  "reportError/post",
  async ({ callIds, title, text }: any, { dispatch, rejectWithValue }) => {
    try {
      const body = {
        agentUsername: callIds.userName,
        campaign: callIds.campaignId,
        callId: callIds.rptCallId,
        title: title,
        text: text,
      };
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `https://api.prod2.us.whendu.com/v1/webhooks/86146d82-eb2f-47f1-9d61-9816492f6965/catch/RHa8BpjgV4U5LM172vMb4OQqb8kVEz4r/event/Frontline_Error`,
        {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      // const data = await response.json();
      const code = await response.ok;
      if (code === true) {
        consoleLogIt(title, text);
        return response;
      } else {
        consoleErrorIt("Failed to Report Error - " + title + " - " + text);
        return rejectWithValue(response);
      }
    } catch (e) {
      consoleErrorIt("Failed to Report Error - " + title + " - " + text);
      return rejectWithValue(e);
    }
  }
);
