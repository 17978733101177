/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppDispatch } from "../../../app/hooks";
import { updateSHDate } from "./safeHavenFormSlice";
import moment from "moment-timezone";
import { Form } from "react-bootstrap";
import { consoleLogIt } from "../../../app/log";

const bankHolidays = [
  "2023-05-29",
  "2023-07-04",
  "2023-09-04",
  "2023-11-24",
  "2023-12-25",
];
const dateListArray = () => {
  const todayPlus7 = moment().add(8, "day").format("YYYY-MM-DD");
  const datesArray = (today: any, todayPlus7: any) => {
    const arr = [];
    const array = [];
    const dt: any = moment(today);
    const fmtDt = dt.format("YYYY-MM-DD");
    for (
      dt.toDate();
      dt.toDate() <= moment(todayPlus7).toDate();
      dt.add(1, "day")
    ) {
      const day = dt.format("dddd");
      if (
        day !== "Saturday" &&
        day !== "Sunday" &&
        bankHolidays.some((item) => item === fmtDt) === false
      ) {
        arr.push({ date: fmtDt, day: day });
      }
    }
    consoleLogIt("Array For Dates ", arr);
    for (let i = 0; i < 3; i++) {
      array.push(arr[i]);
    }
    return array;
  };

  return datesArray(moment().add(1, "day").format("YYYY-MM-DD"), todayPlus7);
};

const SHFormDatePicker = () => {
  const dispatch = useAppDispatch();

  const datesArray = dateListArray();
  consoleLogIt("Dates Array", datesArray);
  return (
    <Form.Select
      onChange={(e) => {
        dispatch(updateSHDate(e.target.value));
      }}
    >
      <option value="">Select Callback Date</option>
      {datesArray.map((item: any, idx: number) => {
        return (
          <option key={`safeHavenDate${idx}`} value={item.date}>
            {item.date} - {item.day}
          </option>
        );
      })}
    </Form.Select>
  );
};

export default SHFormDatePicker;
