/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { updateWorksheetTabs } from "../../app/actions/standardActions";
import { Card, Col } from "react-bootstrap";

import TransferWorksheet from "../column3/transferworksheets/TransferWorksheet";
import ConferenceWorksheet from "../column3/transferworksheets/ConferenceWorksheet";

const TransferNoLoan = () => {
  const dispatch = useAppDispatch();
  // global State
  const transferStatus: any = useAppSelector(
    (state) => state.transferData.transferStatus
  );
  const transferObject: any = useAppSelector(
    (state) => state.transferData.transferObject
  );
  const conferenceObject: any = useAppSelector(
    (state) => state.conferenceObject
  );

  const [show, setShow]: any = useState("null");

  useEffect(() => {
    if (transferStatus === "success" && transferObject.type === "transfer") {
      setShow("transfer");
      dispatch(updateWorksheetTabs("transfer"));
    } else if (
      conferenceObject.status === true &&
      transferStatus === "success" &&
      transferObject.type === "conference"
    ) {
      setShow("conference");
      dispatch(updateWorksheetTabs("conference"));
    } else {
      setShow("null");
    }
  }, [transferStatus]);

  if (show === "transfer") {
    return (
      <Col xs={4}>
        <Card id="no_loan_worksheet_wrapper">
          <Card.Header>
            <Card.Title>
              <strong>Transfer Worksheet</strong>
            </Card.Title>
          </Card.Header>
          <Card.Body className="p-0">
            <TransferWorksheet />
          </Card.Body>
        </Card>
      </Col>
    );
  } else if (show === "conference") {
    return (
      <Col xs={4}>
        <Card id="no_loan_worksheet_wrapper">
          <Card.Header>
            <Card.Title>
              <strong>Conference Worksheet</strong>
            </Card.Title>
          </Card.Header>
          <Card.Body className="p-0">
            <ConferenceWorksheet />
          </Card.Body>
        </Card>
      </Col>
    );
  } else {
    return <></>;
  }
};

export default TransferNoLoan;
