import { createSlice } from "@reduxjs/toolkit";
import { consoleLogIt } from "../../log"
interface Notifications {
  count: number;
  array: [];
}

const initialState: any = {
  count: 0,
  array: [],
} as Notifications;

export const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    errorNotification: (state, { payload }) => {
      consoleLogIt("Error Notification", payload);
      payload.type = "danger";
      state.array.push(payload);
      state.count = state.array.length;
    },
    removeNotification: (state, { payload }) => {
      const array = state.array.filter(
        (item: any, idx: number) => idx !== payload
      );
      state.array = array;
      state.count = state.array.length;
    },
    warningNotification: (state, {payload}) => {
      consoleLogIt("Warning Notification", payload);
      payload.type = "warning";
      state.array.push(payload);
      state.count = state.array.length;
    },
    successNotification: (state, {payload}) => {
      payload.type = "success";
      state.array.push(payload);
      state.count = state.array.length;
    }
  },
  extraReducers: (builder) => {
    builder.addCase('contactVerificationVariable/post/rejected', (state) => {
      state.array.push({
        type: "danger",
        title: "Failed to Update Variables",
        text: `Failed to update the Verification Variables.`,
      });
      state.count = state.array.length;
    })
    .addCase('submitVerbalComplaint/post/fulfilled', (state) => {
      state.array.push({
        type: "success",
        title: "Verbal Complaint Posted",
        text: `Verbal Complaint has been successfully posted.`,
      });
      state.count = state.array.length;
    });
  },
});
// thunks

// Action creators are generated for each case reducer function
export const { errorNotification, removeNotification,warningNotification, successNotification } = notifications.actions;

export default notifications.reducer;
