/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { Card, Row, Col } from "react-bootstrap";
import ContactVerificationObject from "./ContactVerificationObject";

const ContactVerificationCard = () => {
  const dispatch = useAppDispatch();
  // global states
  const contact: any = useAppSelector((state) => state.displayInfo.contact);
  const verification: any = useAppSelector(
    (state) => state.contactVerification
  );
  // TODO ADD REMOVE BUTTONS FOR PHONE AND EMAIL
  return (
    <Card className="border-0">
      <Card.Body className="p-1">
        <Row className="mb-0">
          <Col>
            <h4 className="mb-0">Contact Info</h4>
          </Col>
          <Col>
            <h4 className="mb-0">Result</h4>
          </Col>
        </Row>
        <hr/>
        <ContactVerificationObject
          item={contact.number1}
          value={verification.num1}
          name="num1"
        />

        <ContactVerificationObject
          item={contact.number2}
          value={verification.num2}
          name="num2"
        />
        <ContactVerificationObject
          item={contact.number3}
          value={verification.num3}
          name="num3"
        />
        <ContactVerificationObject
          item={contact.borrowerEmail}
          value={verification.email1}
          name="email1"
        />
        <ContactVerificationObject
          item={contact.coborrowerEmail}
          value={verification.email2}
          name="email2"
        />
      </Card.Body>
    </Card>
  );
};

export default ContactVerificationCard;
