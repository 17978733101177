/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppSelector } from "../../app/hooks";
import { Card, Row } from "react-bootstrap";

import InfoRowWithCopy from "./InfoRowWithCopy";

const BorrowerInfo = () => {
  const borrowerInfo: any = useAppSelector(
    (state) => state.displayInfo.borrower
  );
  return (
    <Card>
      <Card.Body>
        <h5>Borrower Information</h5>
        <Row>
          <InfoRowWithCopy group="borrower" array={borrowerInfo.display} />
        </Row>
      </Card.Body>
    </Card>
  );
};

export default BorrowerInfo;
