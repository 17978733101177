/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch} from "../../../app/hooks";
import {
  updateWorksheetTabs,
} from "../../../app/actions/standardActions";
import NavButton from "./NavButton";

const TransConfWorksheetNav = () => {
  const dispatch = useAppDispatch();
  // global State
  const transferStatus: any = useAppSelector(
    (state) => state.transferData.transferStatus
  );
  const transferObject: any = useAppSelector(
    (state) => state.transferData.transferObject
  );
  const conferenceObject: any = useAppSelector(
    (state) => state.conferenceObject
  );


  const [show, setShow]: any = useState("null");

  useEffect(() => {
    if (transferStatus === "success" && transferObject.type === "transfer") {
      setShow("transfer");
      dispatch(updateWorksheetTabs('transfer'))
    } else if (
      conferenceObject.status === true &&
      transferStatus === "success" &&
      transferObject.type === "conference"
    ) {
      setShow("conference");
      dispatch(updateWorksheetTabs('conference'))
    } else {
      setShow("null");

    }
  }, [transferStatus, conferenceObject]);

  if (show === "transfer") {
    return (
      <NavButton
        eKey="transfer"
        title="Transfer"
        className="btn-primary"
        complete={false}
      />
    );
  } else if (show === "conference") {
    return (
      <NavButton
        eKey="conference"
        title="Conference"
        className="btn-primary"
        complete={false}
      />
    );
  } else {
    return <></>;
  }
};

export default TransConfWorksheetNav;
