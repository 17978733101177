import { createSlice} from "@reduxjs/toolkit";
import {
  GET_CALLDATA_SUCCESS,
} from "../../actions/apisActions";

import { AddressObject } from "../../types"; 



const initialState = {
  propertyAddress: "",
  propertyCity: "",
  propertyState: "",
  propertyZip: "",
  mailingAddress: "",
  mailingCity: "",
  mailingState: "",
  mailingZip: "",
  propertyDisplay: [],
  mailingDisplay: [],
} as AddressObject;

export const address = createSlice({
  name: "address",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GET_CALLDATA_SUCCESS, (state, action) => {
        const { payload }: any = action;
        state.propertyDisplay = [];
        state.mailingDisplay = [];
        state.propertyAddress = payload.data.activeContact.fields["1079"];
        state.propertyCity = payload.data.activeContact.fields["1080"];
        state.propertyState = payload.data.activeContact.fields["1081"];
        state.propertyZip = payload.data.activeContact.fields["1082"];
        state.mailingAddress = payload.data.activeContact.fields["1155"];
        state.mailingCity = payload.data.activeContact.fields["1154"];
        state.mailingState = payload.data.activeContact.fields["1156"];
        state.mailingZip = payload.data.activeContact.fields["1157"];

        state.propertyDisplay.push({
          key: "propertyAddress",
          label: "Property Address:",
          value: state.propertyAddress,
          cols: 12,
        });
        state.propertyDisplay.push({
          key: "propertyCity",
          label: "City:",
          value: state.propertyCity,
          cols: 6,
        });
        state.propertyDisplay.push({
          key: "propertyState",
          label: "State:",
          value: state.propertyState,
          cols: 3,
        });
        state.propertyDisplay.push({
          key: "propertyZip",
          label: "Zip:",
          value: state.propertyZip,
          cols: 3,
        });
    
        state.mailingDisplay.push({
          key: "mailingAddress",
          label: "Mailing Address:",
          value: state.mailingAddress,
          cols: 12,
        });
        state.mailingDisplay.push({
          key: "mailingCity",
          label: "City:",
          value: state.mailingCity,
          cols: 6,
        });
        state.mailingDisplay.push({
          key: "mailingState",
          label: "State:",
          value: state.mailingState,
          cols: 3,
        });
        state.mailingDisplay.push({
          key: "mailingZip",
          label: "Zip:",
          value: state.mailingZip,
          cols: 3,
        });
      })
      .addCase("azure/contactFields/succeeded", (state, action) => {
        const { payload }: any = action;
        const fields = payload.data.items[0];
        state.propertyDisplay = [];
        state.mailingDisplay = [];
        state.propertyAddress = fields.propertyAddress;
        state.propertyCity = fields.propertyCity;
        state.propertyState = fields.propertyState;
        state.propertyZip = fields.propertyZip;
        state.mailingAddress = fields.mailingAddress;
        state.mailingCity = fields.mailingCity;
        state.mailingState = fields.mailingState;
        state.mailingZip = fields.mailingZip;

        state.propertyDisplay.push({
          key: "propertyAddress",
          label: "Property Address:",
          value: state.propertyAddress,
          cols: 12,
        });
        state.propertyDisplay.push({
          key: "propertyCity",
          label: "City:",
          value: state.propertyCity,
          cols: 6,
        });
        state.propertyDisplay.push({
          key: "propertyState",
          label: "State:",
          value: state.propertyState,
          cols: 3,
        });
        state.propertyDisplay.push({
          key: "propertyZip",
          label: "Zip:",
          value: state.propertyZip,
          cols: 3,
        });
    
        state.mailingDisplay.push({
          key: "mailingAddress",
          label: "Mailing Address:",
          value: state.mailingAddress,
          cols: 12,
        });
        state.mailingDisplay.push({
          key: "mailingCity",
          label: "City:",
          value: state.mailingCity,
          cols: 6,
        });
        state.mailingDisplay.push({
          key: "mailingState",
          label: "State:",
          value: state.mailingState,
          cols: 3,
        });
        state.mailingDisplay.push({
          key: "mailingZip",
          label: "Zip:",
          value: state.mailingZip,
          cols: 3,
        });
      });
  },
});
// thunks

// Action creators are generated for each case reducer function
// export const {
//   openTestingModal,
//   closeTestingModal,

// } = azureContactFields.actions;

export default address.reducer;
