import { createSlice } from "@reduxjs/toolkit";
import { GET_CALLDATA_SUCCESS } from "../../actions/apisActions";
import { consoleLogIt } from "../../log";

interface Item {
  num1: string | null;
  num2: string | null;
  num3: string | null;
  email1: string | null;
  email2: string | null;
  verifiedBy: string | null;
  date: string | null;
  ivaUsed: string | null;
  complete: boolean;
  window: boolean;
  contactPost: string;
  variablePost: string;
  bypassPost: string;
  bypass: string;
}

const initialState = {
  num1: "",
  num2: "",
  num3: "",
  email1: "",
  email2: "",
  verifiedBy: "",
  date: "",
  ivaUsed: "",
  complete: false,
  window: false,
  contactPost: "",
  variablePost: "",
  bypassPost: "",
  bypass: "",
} as Item;

export const contactVerification = createSlice({
  name: "contactVerification",
  initialState,
  reducers: {
    openVerificationWindow: (state) => {
      state.window = true;
    },
    closeVerificationWindow: (state) => {
      state.complete = true;
      state.window = false;
    },
    updateValue: (state: any, action: any) => {
      const { payload }: any = action;
      state[payload.name] = payload.value;
    },
    closeWindow: (state: any) => {
      state.window = false;
    },
    openWindow: (state: any) => {
      state.window = true;
    },
    cvBypassUpdate: (state: any, { payload }) => {
      state.bypassPost = payload;
    },
    cvContactUpdate: (state: any, { payload }) => {
      state.contactPost = payload;
      state.complete = true;
      state.window = false;
    },
    cvVariableUpdate: (state: any, { payload }) => {
      state.variablePost = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GET_CALLDATA_SUCCESS, (state, action) => {
        const { payload }: any = action;
        consoleLogIt("Verification Payload", payload);
        const lastVerifiedDate = new Date(
          payload.data.activeContact.fields["1540"]
        );
        // Number 1
        if (payload.data.activeContact.fields["1310"] === undefined) {
          if (payload.data.variables["1392"] === undefined) {
            state.num1 = "";
          } else {
            state.num1 = payload.data.variables["1392"];
          }
        } else {
          state.num1 = "null";
        }
        // Number 2
        if (payload.data.activeContact.fields["1311"] === undefined) {
          if (payload.data.variables["1393"] === undefined) {
            state.num2 = "";
          } else {
            state.num2 = payload.data.variables["1393"];
          }
        } else {
          state.num2 = "null";
        }
        // Number 3
        if (payload.data.activeContact.fields["1312"] === undefined) {
          if (payload.data.variables["1394"] === undefined) {
            state.num3 = "";
          } else {
            state.num3 = payload.data.variables["1394"];
          }
        } else {
          state.num3 = "null";
        }

        // email 1 (borrower email)
        if (payload.data.activeContact.fields["1142"] === undefined) {
          if (payload.data.variables["1395"] === undefined) {
            state.email1 = "";
          } else {
            state.email1 = payload.data.variables["1395"];
          }
        } else {
          state.email1 = "null";
        }
        // Email 2 (coborrower email)
        if (payload.data.activeContact.fields["1145"] === undefined) {
          if (payload.data.variables["1396"] === undefined) {
            state.email2 = "";
          } else {
            state.email2 = payload.data.variables["1396"];
          }
        } else {
          state.email2 = "null";
        }

        if (payload.data.variables["1397"] === undefined) {
          state.verifiedBy = "";
        } else {
          state.verifiedBy = payload.data.variables["1397"];
        }
        if (payload.data.activeContact.fields["1540"] === undefined) {
          state.date = "";
        } else {
          state.date = payload.data.activeContact.fields["1540"];
        }
        if (payload.data.variables["1423"] === undefined) {
          state.ivaUsed = "N";
        } else {
          state.ivaUsed = payload.data.variables["1423"];
        }
        // state.verifiedBy
        if (
          payload.data.variables["1397"] === "IVA"
        ) {
          if (
            state.num1 === "U" ||
            state.num2 === "U" ||
            state.num3 === "U" ||
            state.email1 === "U" ||
            state.email2 === "U" ||
            state.num1 === "" ||
            state.num2 === "" ||
            state.num3 === "" ||
            state.email1 === "" ||
            state.email2 === ""
          ) {
            state.complete = false;
          } else {
            state.complete = true;
          }
        } else {
          const todayMinus30 = new Date();
          todayMinus30.setDate(todayMinus30.getDate() - 30);
          if (lastVerifiedDate >= todayMinus30) {
            state.bypass = "Y";
            state.complete = true;
          } else {
            state.bypass = "";
            state.complete = false;
          }
        }
      })
      .addCase("azure/contactFields/succeeded", (state, action) => {
        const { payload }: any = action;
        const fields = payload.data.items[0];
        consoleLogIt("Verification Payload", payload);

        // Number 1
        if (fields.borrowerHomePhone === null) {
          if (state.num1 !== "null") {
            state.num1 = "NA";
          }
        } else {
          if (state.num1 === "null") {
            state.num1 = "";
          }
        }

        // Number 2
        if (fields.borrowerSecondaryPhone === null) {
          if (state.num2 !== "null") {
            state.num2 = "NA";
          }
        } else {
          if (state.num2 === "null") {
            state.num2 = "";
          }
        }

        // Number 3
        if (fields.borrowerOtherPhone1 === null) {
          if (state.num3 !== "null") {
            state.num3 = "NA";
          }
        } else {
          if (state.num3 === "null") {
            state.num3 = "";
          }
        }

        // email 1
        if (fields.borrowerEmail === null) {
          if (state.email1 !== "null") {
            state.email1 = "NA";
          }
        } else {
          if (state.email1 === "null") {
            state.email1 = "";
          }
        }

        // email 2
        if (fields.coBorrowerEmail === null) {
          if (state.email2 !== "null") {
            state.email2 = "NA";
          }
        } else {
          if (state.email2 === "null") {
            state.email2 = "";
          }
        }

      });
  },
});
// thunks

// Action creators are generated for each case reducer function
export const {
  openVerificationWindow,
  closeVerificationWindow,
  updateValue,
  closeWindow,
  openWindow,
  cvBypassUpdate,
  cvContactUpdate,
  cvVariableUpdate,
} = contactVerification.actions;

export default contactVerification.reducer;
