/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { unwrapResult } from "@reduxjs/toolkit";
import { consoleLogIt, consoleErrorIt, reportError } from "../log";
import {
  getAzureContactFieldsInProgress,
  getAzureContactFieldsSucceeded,
  getAzureContactFieldsFailure,
  getAzureTokenSucceeded,
  getAzureTokenFailure,
} from "./getContactFieldsSlice";
import { errorNotification } from "../slices/features/notificationsSlice";
import { getAzureTokenApi } from "./getTokenThunk";
import { getContactFieldsApi } from "./getContactFieldsThunks";

export const getAzureContactFields = async (
  {callIds, calldata}: any,
  dispatch: any
) => {
  try {
    dispatch(getAzureContactFieldsInProgress());

    const resultAction = await dispatch(
      getAzureTokenApi()
    );
// handle result
   const tokenResult = unwrapResult(resultAction);

    if(tokenResult.access_token === undefined) {
      dispatch(getAzureTokenFailure())
      dispatch(
        errorNotification({
          title: "Failed to Get Contact Info",
          text: "Failed to Get Contact Info. Please try again.",
        })
      );
    } else {
      dispatch(getAzureTokenSucceeded())
      const contactFieldsResult = await dispatch(getContactFieldsApi({callIds, calldata, token: tokenResult.access_token}))
      const contactFieldsReturn = unwrapResult(contactFieldsResult);

       if(contactFieldsReturn.items.length === 0) {
        dispatch(
          errorNotification({
            title: "Loan Not Found",
            text: `Loan Number ${callIds.loanNumber} was not found in the Azure Database.`,
          })
        );
        dispatch(getAzureContactFieldsFailure(contactFieldsReturn))
       } else {
        dispatch(getAzureContactFieldsSucceeded(contactFieldsReturn))
       }
    }

  } catch (e) {
    // error
    consoleErrorIt("Failed to Get Contact Fields From Azure", e);
    // dispatch(cvBypassUpdate("failed"));
    dispatch(
      reportError({
        callIds,
        title: "Failed to get Contact Info Azure",
        text: "Failed to get Contact Info Main Catch e",
      })
    );
    return "failed";
  }
};
