/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */


import React from "react";

import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { initiateTransferApi } from "../../../app/thunks/transferThunks";
import { putIncentiveVariables } from "../../../app/thunks/worksheetThunks";
import { Button } from "react-bootstrap";
import TransferOptions from "../../features/transferAssist/TransferOptions.json";

const IncentiveCompleteButton = ({
  finalButtonText,
  finalButtonDisabled,
  questionsList,
  finalOutcome,
  finalReason,
  setFinalButtonDisabled,
  transferId,

}: any) => {
  const dispatch = useAppDispatch();
  // global states
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const incentiveOptions: any = useAppSelector((state) => state.callWorksheets.incentiveOptions);

  const handleCompleteClick = () => {

    const array = [];
    array.push({ id: `${questionsList.type}O`, value: finalOutcome });
    array.push({ id: `${questionsList.type}R`, value: finalReason });
    dispatch(putIncentiveVariables(metadata.data, incentiveOptions, callIds, array));
    if (transferId !== null) {
      const selected = TransferOptions.find((item) => item.id === transferId);
      dispatch(initiateTransferApi(metadata.data, callIds, selected, true));
    } else {
      setFinalButtonDisabled(true);
    }
  };
  return (
    <div className="d-grid gap-2">
      <Button
        disabled={finalButtonDisabled}
        variant={finalButtonDisabled === false ? "primary" : "secondary"}
        size="lg"
        onClick={handleCompleteClick}
      >
        {finalButtonText}
      </Button>
    </div>
  );
};

export default IncentiveCompleteButton;
