/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { putIncentiveVariables } from "../../../app/thunks/worksheetThunks";
import NavButton from "./NavButton";

const OtherIncentiveNav = ({ appPath }: any) => {
  // global State
  const dispatch = useAppDispatch();
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const incentiveOptions: any = useAppSelector(
    (state) => state.callWorksheets.incentiveOptions
  );
  const hoursOfOperation: any = useAppSelector(
    (state) => state.main.hoursOfOperation
  );
  const [complete, setComplete] = useState(false);
  const [className, setClassName] = useState("incentive");

  useEffect(() => {
    if (incentiveOptions.othO !== "null") {
      setComplete(true);
      if (incentiveOptions.othO !== "Did Not Pitch") {
        setClassName("incentive-complete");
      }
    }
    // if (
    //   hoursOfOperation.safeHaven === false &&
    //   incentiveOptions.othWS === "SHaven" &&
    //   complete !== true
    // ) {
    //   const array = [];
    //   array.push({ id: `othO`, value: "Safe Haven Closed" });
    //   dispatch(
    //     putIncentiveVariables(metadata.data, incentiveOptions, callIds, array)
    //   );
    // }
  }, [hoursOfOperation, incentiveOptions]);
  if (appPath !== "Unauthenticated") {
    switch (incentiveOptions.othWS) {
      case "M HOI":
        return (
          <NavButton
            eKey="matic"
            title="Matic HOI"
            className={className}
            complete={complete}
          />
        );
      case "SHaven":
        return (
          <NavButton
            eKey="safe_haven"
            title="Safe Haven"
            className={className}
            complete={complete}
          />
        );

      default:
        return <></>;
    }
  } else {
    return <></>;
  }
};

export default OtherIncentiveNav;
