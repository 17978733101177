import { createAsyncThunk } from "@reduxjs/toolkit";
import { consoleLogIt, consoleErrorIt } from "../../log";
import moment from "moment";

export const postContactFieldsDate1: any = createAsyncThunk(
  "conactDate1/post",
  async (
    { metadata, callIds }: any,
    { dispatch, rejectWithValue }
  ) => {
    try {
      consoleLogIt(metadata);
      const baseUrl = metadata.data.metadata.dataCenters[0].apiUrls[0].host;
      const userId = metadata.data.userId;
      const body: any = {
        fields: {},
        id: callIds.contactId,
        modificationTime: callIds.contactModTime,
      };
      body.fields["1232"] = moment().format("YYYY-MM-DD");

      const res = await fetch(
        `https://${baseUrl}/appsvcs/rs/svc/agents/${userId}/interactions/calls/${callIds.systemCallId}/update_contact`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      ).then((data: any) => {
        consoleLogIt("Post Date1 Contact Fields", data);
        return data.json();
      });
      return res;
    } catch (e) {
      consoleErrorIt("Failed to Post Date1 Contact Fields", e);
      return rejectWithValue(e);
    }
  }
);
