import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { consoleLogIt } from "../../../app/log";
import "./HiddenIframe.css";

const HiddenIframeVC = () => {
  const verbalComplaint = useAppSelector((state) => state.verbalComplaint);
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const [send, setSend] = useState(false);
  const [url, setUrl] = useState("");
  useEffect(() => {
    if (verbalComplaint.apiStatus === "success") {
      let dataUrl = `https://api.five9.com/ivr/1/109388/campaigns/1140932/new_ivr_session?`;
      dataUrl = dataUrl + `username=${callIds.userName}`;
      dataUrl = dataUrl + `&callId=${callIds.rptCallId}`;
      dataUrl = dataUrl + `&loanNumber=${callIds.loanNumber}`;
      dataUrl =
        dataUrl +
        `&servicingProcessArea=${verbalComplaint.servicingProcessArea}`;
      dataUrl =
        dataUrl +
        `&udaapFairServicingFlag=${verbalComplaint.udaapFairServicingFlag}`;
      dataUrl = dataUrl + `&complaintSource=${verbalComplaint.complaintSource}`;
      dataUrl = dataUrl + `&complaintType=${verbalComplaint.complaintType}`;
      dataUrl =
        dataUrl + `&summaryOfComplaint=${verbalComplaint.summaryOfComplaint}`;
      dataUrl = dataUrl + `&vcIssue=${verbalComplaint.vcIssue}`;
      dataUrl = dataUrl + `&resolutionNotes=${verbalComplaint.resolutionNotes}`;
      dataUrl =
        dataUrl + `&resolutionSummary=${verbalComplaint.resolutionSummary}`;
      dataUrl = dataUrl + `&rootCause=${verbalComplaint.rootCause}`;
      setUrl(dataUrl);

      setSend(true);
    }
  }, [verbalComplaint]);
  if (send === true) {
    return (
      <iframe className="hidden" src={url} title="Send data to Five9"></iframe>
    );
  } else {
    return <></>;
  }
};

export default HiddenIframeVC;
