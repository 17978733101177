/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */ import React from "react";
import { Accordion } from "react-bootstrap";
import { useAppSelector } from "../../../app/hooks";
import StandardOutcomeRadio from "./StandardOutcomeRadio";

const StandardOutcome = ({ setActiveSection }: any) => {
  const outcome = useAppSelector((state) => state.callWorksheets.outcome);
 
  const outcomeCheckMark = () => {
    if (outcome.complaint !== null) {
      return (
        <span className="worksheet_section_check">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-check2-circle"
            viewBox="0 0 16 16"
          >
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg>
        </span>
      );
    } else {
      return <></>;
    }
  };
  return (
    <>
      <Accordion.Header
        onClick={() => {
          setActiveSection("2");
        }}
      >
        Outcome{outcomeCheckMark()}
      </Accordion.Header>
      <Accordion.Body className="single-line-buttons">
        <p className="mb-1"><b>Did any of the following occur?</b></p>
        <StandardOutcomeRadio />
      </Accordion.Body>
    </>
  );
};

export default StandardOutcome;
