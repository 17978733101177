/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React, { useState } from "react";
import {
  ButtonGroup,
  ToggleButton,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import FreedomStateCodeSelector from "../features/FreedomStateCodeSelector";

const ButtonQAndA = ({ email, setEmail, qna, updateQuestionsArray, incentivePath }: any) => {
  
  const [collapseState, setCollapseState] = useState(false);
  const selectedValue = () => {
    const selectedItem = incentivePath.find(({ name }: any) => name === qna.name);
    if (selectedItem === undefined) {
      return selectedItem;
    } else {
      return selectedItem.text;
    }
  };
  //d-flex flex-wrap justify-content-start mt-1

  switch (qna.type) {
    case "btns":
      return (
        <div
          id={qna.name}
          className={
            incentivePath.find(({ name }: any) => name === qna.name) === undefined
              ? ""
              : "selection-made"
          }
        >
          <div
            onClick={() => {
              //console.log("On click triggered");
              if (qna.answers.length > 6) {
                if (collapseState === false) {
                  //console.log("set collapse state to true");
                  setCollapseState(true);
                } else {
                  //console.log("set collapse state to false");
                  setCollapseState(false);
                }
              }
            }}
          >
            <b>{qna.question}</b>
          </div>
          <ButtonGroup className="d-flex flex-wrap justify-content-start mt-1">
            {qna.answers.map((answer: any, idx: number) => {
              const determineButtonClassName = () => {
                if (
                  qna.answers.length > 6 &&
                  collapseState === true &&
                  selectedValue() !== undefined
                ) {
                  if (selectedValue() !== answer.text) {
                    return "unchecked";
                  } else {
                    return "";
                  }
                } else {
                  return "";
                }
              };
              return (
                
                  <ToggleButton
                    key={`${qna.worksheet}-${qna.name}-${idx}`}
                    id={`${qna.worksheet}-${qna.name}-${answer.option}`}
                    type="radio"
                    variant="outline-secondary"
                    name={qna.name}
                    checked={selectedValue() === answer.text ? true : false}
                    className={determineButtonClassName()}
                    value={answer.text}
                    onChange={(e) => {
                      //console.log("button current target",e.currentTarget);
                      setCollapseState(true);
                      updateQuestionsArray(e.currentTarget);
                    }}
                  >
                    {answer.text}
                  </ToggleButton>
              
              );
            })}
          </ButtonGroup>
        </div>
      );

    case "email":
      return (
        <div id={qna.id} className="mt-1">
          <label>
            <b>Enter in the email address.</b>
          </label>
          <InputGroup>
            <FormControl
              type="email"
              placeholder="Enter email"
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
            />
            <Button
              variant="primary"
              name={qna.name}
              value="Submit"
              onClick={(e) => {
                //console.log(email);
                updateQuestionsArray(e.currentTarget);
              }}
            >
              Submit
            </Button>
          </InputGroup>
        </div>
      );

    case "fState":
      return (
        <div id={qna.id} className="mt-1">
          <label>
            <b>Please select the state.</b>
          </label>
          <FreedomStateCodeSelector />
        </div>
      );

    default:
      return <></>;
  }
};


export default ButtonQAndA;
