/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "./app/hooks";
import { getCallData, getWorksheetDataApi } from "./app/thunks/getApisThunks";
import { updateScreenWidth } from "./app/actions/standardActions";
import { getAzureContactFields } from "./app/newAzure/getContactFieldsUnwrap";
import { putIncentiveVariables } from "./app/thunks/worksheetThunks";
import Header from "./components/header/Header";
import Body from "./components/Body";
import FooterWrapper from "./components/FooterWrapper";
import NoLoanModal from "./NoLoanModal";
import TransferAssistModal from "./components/features/transferAssist/TransferAssistModal";
import { Container } from "react-bootstrap";
import "./App.css";
import Five9WorksheetCheck from "./Five9WorksheetCheck";

function AppWrapper() {
  // Declare a new state variable with the "useState" Hook
  // eslint-disable-next-line no-undef
  const [width, setWidth] = React.useState(window.innerWidth);
  const dispatch = useAppDispatch();
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const calldata: any = useAppSelector((state) => state.main.calldata);
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const azureContactFields: any = useAppSelector(
    (state) => state.azureContactFields
  );
  const incentives: any = useAppSelector(
    (state) => state.callWorksheets.incentiveOptions
  );
  const worksheetData: any = useAppSelector(
    (state) => state.main.worksheetData
  );
  // use effect window resize functions
  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  useEffect(() => {
    dispatch(updateScreenWidth(width));
  }, [width]);

  // run get call data on launch of component
  useEffect(() => {
    dispatch(getCallData(metadata.data));
  }, []);

  // run get five9 worksheet when call data returns successful
  useEffect(() => {
    if (calldata.status === "NoLoan" || calldata.status === "Loaded") {
      if (azureContactFields.status === "") {
        getAzureContactFields({ callIds, calldata }, dispatch);
      }
      const array: any = [];
      if (incentives.allowed === "false") {
        if (incentives.refiWS !== "null") {
          array.push({ id: "refiO", value: "Incentives Turned Off" });
        }
        if (incentives.othWS !== "null") {
          array.push({ id: "othO", value: "Incentives Turned Off" });
        }

        if (array.length === 0) {
          array.push({ id: "refiWS", value: incentives.refiWS })
        }
        dispatch(
          putIncentiveVariables(metadata.data, incentives, callIds, array)
        );
      }
      dispatch(getWorksheetDataApi(metadata.data, callIds));
    }
  }, [calldata]);

  return (
    <div className="App">
      {worksheetData.status === "success" ? <Five9WorksheetCheck /> : <></>}
      {calldata.status === "Loaded" ? (
        <>
          <Header />
          <Body />
          <FooterWrapper />
        </>
      ) : calldata.status === "Loading" ? (
        <div>Fetching call data.</div>
      ) : calldata.status === "NoLoan" ? (
        <>
          {" "}
          <Header />
          <Container fluid className="d-flex justify-content-center p-3">
            <NoLoanModal />
          </Container>
          <TransferAssistModal />
          <FooterWrapper />
        </>
      ) : (
        <div>Failed to get call data.</div>
      )}
    </div>
  );
}

export default AppWrapper;
