/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { openDisclosureWindow } from "../../app/actions/standardActions";
import { putVariableDisclosures } from "../../app/thunks/worksheetThunks";
import { postContactFieldsDate1 } from "../../app/slices/callWorksheet/disclosuresThunks";
import { InputGroup, Form, Button } from "react-bootstrap";

const DisclosureSwitch = ({ item }: any) => {
  const scriptCss = {
    fontSize: "15px"
  };
  const dispatch = useAppDispatch();
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const disclosures: any = useAppSelector(
    (state) => state.callWorksheets.disclosures
  );

  const callIds: any = useAppSelector((state) => state.main.callIds);
  const inProgress: any = useAppSelector(
    (state) => state.features.putInProgress
  );

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (item.id === "ca30" && item.value === "bypass") {
      setDisabled(true);
    } else {
      setDisabled(inProgress.status)
    }
  });

  const itemValue = () => {
    if (
      item.value === "agent" ||
      item.value === "ivr" ||
      item.value === "bypass"
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <InputGroup id={`input_group_disc_${item.id}`}>
      <Form.Check
        type="switch"
        disabled={disabled}
        id={`disc_${item.id}`}
        label={item.text}
        checked={itemValue()}
        onChange={(e) => {
          const disc = disclosures.data;
          if (e.currentTarget.checked === true) {
            const data: any = {};
            data.id = item.id;
            data.value = "agent";
            const array = [];
            array.push(data);
            dispatch(
              putVariableDisclosures(metadata.data, disc, callIds, array)
            );
            if(item.id === "ca30"){
              dispatch(postContactFieldsDate1({metadata, callIds}))
            }
          } else {
            const data: any = {};
            data.id = item.id;
            data.value = "false";
            const array = [];
            array.push(data);
            dispatch(
              putVariableDisclosures(metadata.data, disc, callIds, array)
            );
          }
        }}
      />
     
      <Button
        variant="link"
        className="p-0 script-launch"
        style={scriptCss}
        onClick={() => {
          dispatch(openDisclosureWindow(item.id));
        }}
      >
         {item.value === "bypass" ? (<>Provided on {disclosures.callData.hudDate}</>): <>Script</>}
      </Button>

    </InputGroup>
  );
};

export default DisclosureSwitch;
