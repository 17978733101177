/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Col,
  InputGroup,
} from "react-bootstrap";

const InfoRowWithCopy = ({ array, group }: any) => {
  const [copyState, setCopyState] = useState("Copy!");

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {copyState}
    </Tooltip>
  );
  useEffect(() => {
    if (copyState === "Copied!") {
      // eslint-disable-next-line no-undef
      setTimeout(() => {
        setCopyState("Copy!");
      }, 5000);
    }
  }, [copyState]);
  return (
    <>
      {array.map((item: any, i: number) => {
        return (
          <Col key={group + i} xs={item.cols} className="info-row">
            <Form.Group controlId={item.key}>
              <Form.Label>{item.label}</Form.Label>
              <InputGroup size="sm">
                <Form.Control
                  type="text"
                  size="sm"
                  defaultValue={item.value}
                  readOnly
                />
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 250 }}
                  overlay={renderTooltip}
                >
                  <Button
                    variant="secondary"
                    className=""
                    onClick={() => {
                      // eslint-disable-next-line no-undef
                      navigator.clipboard.writeText(item.value);
                      setCopyState("Copied!");
                    }}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      // eslint-disable-next-line react/no-unknown-property
                      strokeWidth="0"
                      viewBox="0 0 1024 1024"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"></path>
                    </svg>
                  </Button>
                </OverlayTrigger>
              </InputGroup>
            </Form.Group>
          </Col>
        );
      })}
    </>
  );
};

export default InfoRowWithCopy;
