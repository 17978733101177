import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  SafeHavenFormInterface,
  submitSafeHavenForm,
} from "./safeHavenFormSlice";
import { successNotification } from "../../../app/slices/features/notificationsSlice";
import { openTestingModal } from "../../features/testing/testingSlice";
import { consoleLogIt } from "../../../app/log";
const SHFooter = ({ emailValidation }: any) => {
  const dispatch = useAppDispatch();
  const safeHavenInfo = useAppSelector((state) => state.safeHavenForm);

  const [disabled, setDisabled] = useState(true);

  const handleSubmit = () => {
    consoleLogIt("Submit Callback", safeHavenInfo);

    dispatch(submitSafeHavenForm());
    dispatch(
      successNotification({
        title: "Data Sent to Safe Haven",
        text: `The data has successfully be sent to Safe Haven.`,
      })
    );
  };
  useEffect(() => {
    if(safeHavenInfo.sentDataToSafeHaven === true) {
      setDisabled(true);
    } else if (
      safeHavenInfo.form === "callback" &&
      safeHavenInfo.phoneNumber !== "" &&
      safeHavenInfo.date !== "" &&
      safeHavenInfo.time !== "" &&
      safeHavenInfo.email !== "" &&
      safeHavenInfo.firstName !== "" &&
      safeHavenInfo.lastName !== "" &&
      emailValidation === true
    ) {
      setDisabled(false);
    } else if (
      safeHavenInfo.form === "transfer" &&
      safeHavenInfo.phoneNumber !== "" &&
      safeHavenInfo.email !== "" &&
      safeHavenInfo.firstName !== "" &&
      safeHavenInfo.lastName !== "" &&
      emailValidation === true
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [safeHavenInfo, emailValidation]);

  return (
    <div id="verbal_complaint_footer" className="d-grid gap-2 p-2">
      <Button
        variant="secondary"
        size="lg"
        disabled={disabled}
        onClick={handleSubmit}
      >
        {safeHavenInfo.form === "transfer" ? (
          <>Submit to Safe Haven</>
        ) : (
          <>Submit Callback to Safe Haven</>
        )}
      </Button>
    </div>
  );
};

export default SHFooter;
