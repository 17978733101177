/* eslint-disable @typescript-eslint/explicit-function-return-type */
// *****************************************************
// GET APIS ACTIONS
// *****************************************************
export const GET_METADATA_IN_PROGRESS = "GET_METADATA_IN_PROGRESS";
export const getMetadataInProgress = () => ({
  type: GET_METADATA_IN_PROGRESS,
});
export const GET_METADATA_SUCCESS = "GET_METADATA_SUCCESS";
export const getMetadataSuccess = (data: any) => ({
  type: GET_METADATA_SUCCESS,
  payload: data,
});
export const GET_METADATA_FAILED = "GET_METADATA_FAILED";
export const getMetadataFailed = () => ({
  type: GET_METADATA_FAILED,
});

export const GET_CALLDATA_IN_PROGRESS = "GET_CALLDATA_IN_PROGRESS";
export const getCalldataInProgress = () => ({
  type: GET_CALLDATA_IN_PROGRESS,
});
export const GET_CALLDATA_SUCCESS = "GET_CALLDATA_SUCCESS";
export const getCalldataSuccess = (data: any) => ({
  type: GET_CALLDATA_SUCCESS,
  payload: { data },
});
export const GET_CALLDATA_SUCCESS_NO_LOAN = "GET_CALLDATA_SUCCESS_NO_LOAN";
export const getCalldataSuccessNoLoan = (data: any) => ({
  type: GET_CALLDATA_SUCCESS_NO_LOAN,
  payload: { data },
});
export const GET_CALLDATA_FAILED = "GET_CALLDATA_FAILED";
export const getCalldataFailed = () => ({
  type: GET_CALLDATA_FAILED,
});


export const UPDATE_CALLDATA_IN_PROGRESS = "UPDATE_CALLDATA_IN_PROGRESS";
export const updateCalldataInProgress = () => ({
  type: UPDATE_CALLDATA_IN_PROGRESS,
});
export const UPDATE_CALLDATA_SUCCESS = "UPDATE_CALLDATA_SUCCESS";
export const updateCalldataSuccess = (data: any) => ({
  type: UPDATE_CALLDATA_SUCCESS,
  payload: { data },
});
export const UPDATE_CALLDATA_FAILED = "UPDATE_CALLDATA_FAILED";
export const updateCalldataFailed = () => ({
  type: UPDATE_CALLDATA_FAILED,
});

// *****************************************************
// Worksheet API ACTIONS
// *****************************************************
export const GET_WORKSHEET_DATA_IN_PROGRESS = "GET_WORKSHEET_DATA_IN_PROGRESS";
export const getWorksheetDataInProgress = () => ({
  type: GET_WORKSHEET_DATA_IN_PROGRESS,
});
export const GET_WORKSHEET_DATA_SUCCESS = "GET_WORKSHEET_DATA_SUCCESS";
export const getWorksheetDataSuccess = (data: any) => ({
  type: GET_WORKSHEET_DATA_SUCCESS,
  payload: { data },
});
export const GET_WORKSHEET_DATA_FAILED = "GET_WORKSHEET_DATA_FAILED";
export const getWorksheetDataFailed = () => ({
  type: GET_WORKSHEET_DATA_FAILED,
});

export const PUT_UPDATE_WORKSHEET_IN_PROGRESS =
  "PUT_UPDATE_WORKSHEET_IN_PROGRESS";
export const putUpdateWorksheetInProgress = () => ({
  type: PUT_UPDATE_WORKSHEET_IN_PROGRESS,
});
export const PUT_UPDATE_WORKSHEET_SUCCESS = "PUT_UPDATE_WORKSHEET_SUCCESS";
export const putUpdateWorksheetSuccess = () => ({
  type: PUT_UPDATE_WORKSHEET_SUCCESS,

});
export const PUT_UPDATE_WORKSHEET_FAILED = "PUT_UPDATE_WORKSHEET_FAILED";
export const putUpdateWorksheetFailed = () => ({
  type: PUT_UPDATE_WORKSHEET_FAILED,
});


// *****************************************************
// COMMENTS API ACTIONS
// *****************************************************
export const UPDATE_COMMENTS_IN_PROGRESS = "UPDATE_COMMENTS_IN_PROGRESS";
export const updateCommentsInProgress = () => ({
  type: UPDATE_COMMENTS_IN_PROGRESS,
});
export const UPDATE_COMMENTS_SUCCESS = "UPDATE_COMMENTS_SUCCESS";
export const updateCommentsSuccess = () => ({
  type: UPDATE_COMMENTS_SUCCESS,
});
export const UPDATE_COMMENTS_FAILED = "UPDATE_COMMENTS_FAILED";
export const updateCommentsFailed = () => ({
  type: UPDATE_COMMENTS_FAILED
});

// *****************************************************
// TRANSFER API ACTIONS
// *****************************************************
export const INITIATE_TRANSFER_IN_PROGRESS = "INITIATE_TRANSFER_IN_PROGRESS";
export const initiateTransferInProgress = (transferObj: any) => ({
  type: INITIATE_TRANSFER_IN_PROGRESS,
  payload: transferObj,
});
export const INITIATE_TRANSFER_SUCCESS = "INITIATE_TRANSFER_SUCCESS";
export const initiateTransferSuccess = (transferObj: any) => ({
  type: INITIATE_TRANSFER_SUCCESS,
  payload: transferObj,
});
export const INITIATE_TRANSFER_FAILED = "INITIATE_TRANSFER_FAILED";
export const initiateTransferFailed = (transferObj: any) => ({
  type: INITIATE_TRANSFER_FAILED,
  payload: transferObj,
});

export const CANCEL_TRANSFER_IN_PROGRESS = "CANCEL_TRANSFER_IN_PROGRESS";
export const cancelTransferInProgress = (transferObj: any) => ({
  type: CANCEL_TRANSFER_IN_PROGRESS,
  payload: transferObj,
});
export const CANCEL_TRANSFER_SUCCESS = "CANCEL_TRANSFER_SUCCESS";
export const cancelTransferSuccess = (transferObj: any) => ({
  type: CANCEL_TRANSFER_SUCCESS,
  payload: transferObj,
});
export const CANCEL_TRANSFER_FAILED = "CANCEL_TRANSFER_FAILED";
export const cancelTransferFailed = (transferObj: any) => ({
  type: CANCEL_TRANSFER_FAILED,
  payload: transferObj,
});

export const COMPLETE_TRANSFER_API_IN_PROGRESS =
  "COMPLETE_TRANSFER_API_IN_PROGRESS";
export const completeTransferApiInProgress = (transferObj: any) => ({
  type: COMPLETE_TRANSFER_API_IN_PROGRESS,
  payload: transferObj,
});
export const COMPLETE_TRANSFER_API_SUCCESS = "COMPLETE_TRANSFER_API_SUCCESS";
export const completeTransferApiSuccess = (transferObj: any) => ({
  type: COMPLETE_TRANSFER_API_SUCCESS,
  payload: transferObj,
});
export const COMPLETE_TRANSFER_API_FAILED = "COMPLETE_TRANSFER_API_FAILED";
export const completeTransferApiFailed = (transferObj: any) => ({
  type: COMPLETE_TRANSFER_API_FAILED,
  payload: transferObj,
});

// *****************************************************
// CONFERENCE API ACTIONS
// *****************************************************
export const INITIATE_CONFERENCE_IN_PROGRESS =
  "INITIATE_CONFERENCE_IN_PROGRESS";
export const initiateConferenceInProgress = (obj: any) => ({
  type: INITIATE_CONFERENCE_IN_PROGRESS,
  payload: obj,
});
export const INITIATE_CONFERENCE_SUCCESS = "INITIATE_CONFERENCE_SUCCESS";
export const initiateConferenceSuccess = (obj: any) => ({
  type: INITIATE_CONFERENCE_SUCCESS,
  payload: obj,
});
export const INITIATE_CONFERENCE_FAILED = "INITIATE_CONFERENCE_FAILED";
export const initiateConferenceFailed = (obj: any) => ({
  type: INITIATE_CONFERENCE_FAILED,
  payload: obj,
});

export const CANCEL_CONFERENCE_FAILED = "CANCEL_CONFERENCE_FAILED";
export const cancelConferenceFailed = (obj: any) => ({
  type: CANCEL_CONFERENCE_FAILED,
  payload: obj,
});

export const JOIN_CONVERENCE_API_FAILED =
  "JOIN_CONVERENCE_API_FAILED";
export const joinConferenceApiFailed = (obj: any) => ({
  type: JOIN_CONVERENCE_API_FAILED,
  payload: obj,
});

export const CONFERENCE_PARTICIPANT_REMOVED_IN_PROGRESS =
  "CONFERENCE_PARTICIPANT_REMOVED_IN_PROGRESS";
export const conferenceParticipantRemovedInProgress = (obj: any) => ({
  type: CONFERENCE_PARTICIPANT_REMOVED_IN_PROGRESS,
  payload: obj,
});

export const CONFERENCE_PARTICIPANT_REMOVED_SUCCESS =
  "CONFERENCE_PARTICIPANT_REMOVED_SUCCESS";
export const conferenceParticipantRemovedSuccess = (obj: any) => ({
  type: CONFERENCE_PARTICIPANT_REMOVED_SUCCESS,
  payload: obj,
});

export const CONFERENCE_PARTICIPANT_REMOVED_FAILED =
  "CONFERENCE_PARTICIPANT_REMOVED_FAILED";
export const conferenceParticipantRemovedFailed = (obj: any) => ({
  type: CONFERENCE_PARTICIPANT_REMOVED_FAILED,
  payload: obj,
});

export const LEAVE_CONFERENCE_API_IN_PROGRESS =
  "LEAVE_CONFERENCE_API_IN_PROGRESS";
export const leaveConferenceApiInProgress = () => ({
  type: LEAVE_CONFERENCE_API_IN_PROGRESS,

});

export const LEAVE_CONFERENCE_API_SUCCESS =
  "LEAVE_CONFERENCE_API_SUCCESS";
export const leaveConferenceApiSuccess = () => ({
  type: LEAVE_CONFERENCE_API_SUCCESS,

});

export const LEAVE_CONFERENCE_API_FAILED =
  "LEAVE_CONFERENCE_API_FAILED";
export const leaveConferenceApiFailed = () => ({
  type: LEAVE_CONFERENCE_API_FAILED,

});


// *****************************************************
// UPDATE VARIABLES API ACTIONS
// *****************************************************
export const UPDATE_TRANSFER_FLAG_IN_PROGRESS =
  "UPDATE_TRANSFER_FLAG_IN_PROGRESS";
export const updateTransferFlagInProgress = () => ({
  type: UPDATE_TRANSFER_FLAG_IN_PROGRESS,

});
export const UPDATE_TRANSFER_FLAG_SUCCESS = "UPDATE_TRANSFER_FLAG_SUCCESS";
export const updateTransferFlagSuccess = (transferObj: any) => ({
  type: UPDATE_TRANSFER_FLAG_SUCCESS,
  payload: transferObj,
});
export const UPDATE_TRANSFER_FLAG_FAILED = "UPDATE_TRANSFER_FLAG_FAILED";
export const updateTransferFlagFailed = () => ({
  type: UPDATE_TRANSFER_FLAG_FAILED,

});

// **********************************************
// WEBSOCKET ACTIONS
// **********************************************

export const CREATE_CONFERENCE_OBJECT = "CREATE_CONFERENCE_OBJECT";
export const createConferenceObject = (obj: any) => ({
  type: CREATE_CONFERENCE_OBJECT,
  payload: obj,
});
export const UPDATE_CONFERENCE_OBJECT_STATE = "UPDATE_CONFERENCE_OBJECT_STATE";
export const updateConferenceObjectState = (obj: any) => ({
  type: UPDATE_CONFERENCE_OBJECT_STATE,
  payload: obj,
});

