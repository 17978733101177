/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppDispatch } from "../../../app/hooks";
import { updateSHTime } from "./safeHavenFormSlice";

import { Form } from "react-bootstrap";
import { consoleLogIt } from "../../../app/log";

const timeArray = [
  "10:00 AM EST",
  "10:30 AM EST",
  "11:00 AM EST",
  "11:30 AM EST",
  "12:00 PM EST",
  "12:30 PM EST",
  "1:00 PM EST",
  "1:30 PM EST",
  "2:00 PM EST",
  "2:30 PM EST",
  "3:00 PM EST",
  "3:30 PM EST",
  "4:00 PM EST",
  "4:30 PM EST",
  "5:00 PM EST",
  "5:30 PM EST",
  "6:00 PM EST",
  "6:30 PM EST",
];

const SHFormTimePicker = () => {
  const dispatch = useAppDispatch();

  return (
    <Form.Select
      onChange={(e) => {
        dispatch(updateSHTime(e.target.value));
      }}
    >
      <option value="">Select Callback Time</option>
      {timeArray.map((item: string, idx: number) => {
        return (
          <option value={item} key={idx}>
            {item}
          </option>
        );
      })}
      
    </Form.Select>
  );
};

export default SHFormTimePicker;
