/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { closeDCCConsultation } from "./DCCConsultationQRPCSlice";
import { Offcanvas, Button } from "react-bootstrap";
import DCCConsultationForm from "./DCCConultationForm";
import "./DCCConsultationOffCanvas.css"
const DCCConsultationOffCanvas = () => {
  const dispatch = useAppDispatch();
  const dccConsultationQRPC = useAppSelector(
    (state) => state.dccConsultationQRPC
  );
  const handleClose = () => {
    dispatch(closeDCCConsultation());
  };

  return (
    <Offcanvas
      show={dccConsultationQRPC.data.show}
      onHide={handleClose}
      enforceFocus={false}
      backdrop={false}
      placement="end"
      name="dcc_consultation_survey"
      id="dcc_consultation_survey"
    >
      <Offcanvas.Header closeVariant="white" closeButton>
        <Offcanvas.Title>DCC Consultation Survey</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <DCCConsultationForm />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default DCCConsultationOffCanvas;
