import { createSlice, createAction, nanoid } from "@reduxjs/toolkit";
import { consoleLogIt, consoleErrorIt, reportError } from "../log";

export const getAzureTokenFailure = createAction(
  "azure/getToken/failed",
  function prepare() {
    return {
      payload: { id: nanoid() },
    };
  }
);
export const getAzureTokenSucceeded = createAction(
  "azure/getToken/succeeded",
  function prepare() {
    return {
      payload: { id: nanoid() },
    };
  }
);
export const getAzureContactFieldsFailure = createAction(
  "azure/contactFields/failed",
  function prepare(data: any) {
    return {
      payload: { data, id: nanoid() },
    };
  }
);
export const getAzureContactFieldsSucceeded = createAction(
  "azure/contactFields/succeeded",
  function prepare(data) {
    return {
      payload: { data, id: nanoid() },
    };
  }
);

export const getAzureContactFieldsInProgress = createAction(
  "azure/contactFields/inProgress",
  function prepare() {
    return {
      payload: { id: nanoid() },
    };
  }
);
interface AzureResults {
  status: "" | "loading" | "failed" | "success";
  data: {};
}
const initialState = {
  status: "",
  data: {},
};

export const azureContactFields = createSlice({
  name: "azureContactFields",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase("azure/getToken/failed", (state, action) => {
        state.status = "failed";
      })
      .addCase("azure/contactFields/failed", (state, action) => {
        state.status = "failed";
      })
      .addCase("azure/contactFields/inProgress", (state, action) => {
        state.status = "loading";
      })

      .addCase(getAzureContactFieldsSucceeded, (state, action) => {
        const { payload }: any = action;
        state.data = payload.data;
        state.status = "success";
      });
  },
});
// thunks

// Action creators are generated for each case reducer function
// export const {
//   openTestingModal,
//   closeTestingModal,

// } = azureContactFields.actions;

export default azureContactFields.reducer;
