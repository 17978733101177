/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import DisclosureSwitch from "../DisclosureSwitch";
//import DisclosureSwitch from "../DisclosureSwitch";

const StandardDisclosures = () => {
  const discArray: any = useAppSelector((state) => state.callWorksheets.disclosures.array);
  const [reqDisc, setReqDisc] = useState([]);
  
  useEffect(() => {
    setReqDisc(discArray)
  },[discArray])
  return (
    <>
      {reqDisc.map((disc: any, i: number) => {
        return (
          <div key={i}>
            <DisclosureSwitch item={disc} />
          </div>
        );
      })}
    </>
  );
};

export default StandardDisclosures;
