/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/no-unescaped-entities */
import React from "react";

export const disclosureScriptList = (script: any, callIds: any,borrower: any ) => {
  switch (script) {
    case "mm":
      return {
        title: "Mini Miranda",
        english: [
          {
            header: "Mini-Miranda Script",
            body: (
              <>
                "Federal law requires we inform you that RoundPoint Mortgage
                Servicing LLC or{" "}
                <i className="text-info">{callIds.pls}</i> is a debt collector
                attempting to collect a debt and any information obtained will
                be used for that purpose."
              </>
            ),
          },
          {
            header: "Mini-Miranda Talk-Off Script",
            body: (
              <>
                "This is a standard disclosure we are required to read to all of
                our customers regardless of the account status. RoundPoint
                Mortgage Servicing LLC or{" "}
                <i className="text-info">{callIds.pls}</i> is the servicing
                division of a full service mortgage company. Because we have the
                ability to collect payments on the behalf of others, we are
                deemed a debt collector by the Fair Debt Collection Practices
                Act, which, in this case, is simply your monthly mortgage
                payment. This does not mean your mortgage is in negative
                standing, but that we are here to help you safely and securely
                make your payments to the appropriate entity."
              </>
            ),
          },
        ],
        spanish: [
          {
            header: "Mini-Miranda Script",
            body: (
              <>
                "La ley federal requiere que le informemos que RoundPoint
                Mortgage Servicing LLC o{" "}
                <i className="text-info">{callIds.pls}</i> es un cobrador de
                deudas que intenta cobrar una deuda y que cualquier información
                obtenida se utilizará para ese propósito."
              </>
            ),
          },
          {
            header: "Mini-Miranda Talk-Off Script",
            body: (
              <>
                "Esta es una divulgación estándar que debemos leer a todos
                nuestros clientes, Independientemente del estado de la cuenta.
                RoundPoint Mortgage Servicing LLC o{" "}
                <i className="text-info">{callIds.pls}</i> es la división de
                servicios de una compañía hipotecaria de servicio completo.
                Debido a que tenemos la capacidad de cobrar pagos en nombre de
                otros, la Ley de Prácticas Justas de Cobro de Deudas nos
                considera un cobrador de deudas, que, en este caso, es
                simplemente su pago hipotecario mensual. Esto no significa que
                su hipoteca esté en situación negativa, sino que estamos aquí
                para ayudarlo a realizar sus pagos a la entidad correspondiente
                de forma segura."
              </>
            ),
          },
        ],
      };
    case "cr":
      return {
        title: "Call Recording",
        english: [
          {
            header: null,
            body: (
              <>
                "Please be advised this call may be recorded for quality
                assurance purposes."
              </>
            ),
          },
        ],
        spanish: [
          {
            header: null,
            body: (
              <>
                "Tenga en cuenta que esta llamada puede ser grabada con fines de
                garantía de calidad."
              </>
            ),
          },
        ],
      };
    case "dc7":
      return {
        title: "Discharged Bankruptcy 7",
        english: [
          {
            header: `Discharged Bankruptcy 7 w/out Reaffirmation Mini-Miranda`,
            body: (
              <>
                The discharge removed any personal liability you may have had
                for this debt; however, the lien against the property is still
                in place."
              </>
            ),
          },
        ],
        spanish: [
          {
            header: `Discharged Bankruptcy 7 w/out Reaffirmation Mini-Miranda`,
            body: (
              <>
                "Nuestros registros muestran que se ingresó una descarga en su
                caso del Capítulo 7. El descargo eliminó cualquier
                responsabilidad personal que pudiera haber tenido por esta
                deuda; sin embargo, el gravaman contra la propiedad sigue
                vigente"
              </>
            ),
          },
        ],
      };
    case "bk":
      return {
        title: "Active Bankruptcy",
        english: [
          {
            header: `Active Bankruptcy Mini-Miranda`,
            body: (
              <>
                "Federal law requires us to inform you that RoundPoint Mortgage
                Servicing LLC or{" "}
                <i className="text-info">{callIds.pls}</i> is a debt collector
                attempting to collect a debt and any information obtained will
                be used for that purpose. To the extent your obligation has been
                discharged or is subject to the automatic stay in a bankruptcy
                proceeding, this notice is for informational purposes only and
                does not constitute a demand for payment or an attempt to
                collect indebtedness as your personal obligation. If you are
                represented by an attorney, please provide us with the
                attorney's name, address, and telephone number."
              </>
            ),
          },
        ],
        spanish: [
          {
            header: `Active Bankruptcy Mini-Miranda`,
            body: (
              <>
                "La ley federal requiere que le informemos que Roundpoint
                Mortgage Servicing LLC o{" "}
                <i className="text-info">{callIds.pls}</i> es un cobrador de
                deudas que intenta cobrar una deuda y que cualquier información
                obtenida se utilizará para ese propósito. En la medida en que su
                obligación se haya cumplido o esté sujeta a la suspensión
                automática de un procedimiento de quiebra, este aviso es solo
                para fines informativos y no constituye una demanda de pago o un
                intento de cobrar una deuda como su obligación personal. Si está
                representado por un abogado, indíquenos el nombre, la dirección
                y el número de teléfono del abogado."
              </>
            ),
          },
        ],
      };
    case "ca5":
      return {
        title: "California 5 Languages",
        english: [
          {
            header: null,
            body: (
              <>
                "In reviewing your trial or permanent modification documents, we
                are unable to discuss them in any other language than English.
                You can contact a HUD representative at 800-569-4287 for
                additional assistance."
              </>
            ),
          },
        ],
        spanish: [
          {
            header: null,
            body: (
              <>
                "Al revisar sus documentos de prueba o de modificación
                permanente, no podemos dialogar en ningún otro idioma que no sea
                el inglés. Puede comunicarse con un representante de HUD al
                800-569-4287 para obtener ayuda adicional."
              </>
            ),
          },
        ],
      };
    case "ca30":
      return {
        title: "CA, NV, WA 30+ Delinquent Loans",
        english: [
          {
            header: null,
            body: (
              <>
                "Mr./Mrs. <i className="text-info">{borrower.concat}</i>, I
                would like to provide you with the toll-free number to help you
                find a HUD counselor, do you have a pen and paper handy to write
                this important information down?
              </>
            ),
          },
          {
            header: null,
            body: (
              <>
                You can call 888-995-HOPE (4673) to reach the US Department of
                Housing and Urban Development (HUD) to find a HUD-certified
                housing counseling agency."
              </>
            ),
          },
        ],
        spanish: [
          {
            header: null,
            body: (
              <>
                "Sr/Sra. <i className="text-info">{borrower.concat}</i>, me
                gustaría proporcionarle el número gratuito para ayudarlo a
                encontrar un consejero de HUD, tiene lápiz y papel a mano para
                escribir esta información?
              </>
            ),
          },
          {
            header: null,
            body: (
              <>
                Puede llamar al 888-995-HOPE (4673) para comunicarse con el
                Departamento de Vivienda y Desarrollo Urbano de los EE. UU.
                (HUD) para encontrar una agencia de asesoría de vivienda
                certificada por HUD."
              </>
            ),
          },
        ],
      };
    case "cell":
      return {
        title: "Cell Phone Authorization",
        english: [
          {
            header: "CSR Authorization Script",
            body: (
              <>
                “Mr/Mrs <i className="text-info">{borrower.concat}</i>, at times{" "}
                <i className="text-info">{callIds.pls}</i> may need to reach out
                to you using a dialer or by text messages. Can {callIds.pls} use
                this cell phone number to contact you through these methods if
                necessary?”
              </>
            ),
          },
          {
            header: "What is a dialer?",
            body: (
              <>
                “The dialer is the telephony system used to queue your account
                information for an outbound call.”
              </>
            ),
          },
          {
            header: "If Declined",
            body: (
              <>
                “Mr/Mrs <i className="text-info">{borrower.concat}</i>, I have
                coded your account indicating {callIds.pls} does not have your
                authorization to contact you on this number using a dialer or by
                text message.”
              </>
            ),
          },
          {
            header: "If Approved",
            body: <>"Thank you."</>,
          },
        ],
        spanish: [
          {
            header: "CSR Authorization Script",
            body: (
              <>
                Sr/Sra. <i className="text-info">{borrower.concat}</i>, en
                ocasiones, es posible que{" "}
                <i className="text-info">{callIds.pls}</i> necesite comunicarse
                con usted mediante el marcador o por mensaje de texto. ¿Puede
                {callIds.pls} usar este número de teléfono celular para
                comunicarse con usted a través de estos métodos si es
                necesario?”
              </>
            ),
          },
          {
            header: "What is a dialer?",
            body: (
              <>
                “El marcador es un sistema de telefonía que se utiliza para
                poner en cola la información de su cuenta para una llamada
                saliente.”
              </>
            ),
          },
          {
            header: "If Declined",
            body: (
              <>
                “Sr/Sra. <i className="text-info">{borrower.concat}</i>, anoté
                su cuenta para indicar que {callIds.pls} no tiene su
                autorización para comunicarse con usted en este número usando el
                marcador o por mensaje de texto.”
              </>
            ),
          },
          {
            header: "If Approved",
            body: <>"Gracias."</>,
          },
        ],
      };
    default:
      return {};
  }
};
