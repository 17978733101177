const employmentArray = [
  {
    text: `Employed`,
    value: `Employed`,
    id: "01",
    name: "employment",
  },
  {
    text: `Retired`,
    value: `Retired`,
    id: "02",
    name: "employment",
  },
  {
    text: `Self Employed`,
    value: `Self Employed`,
    id: "03",
    name: "employment",
  },
  {
    text: `Self Employed (Corp)`,
    value: `Self Employed (Corp)`,
    id: "04",
    name: "employment",
  },
  {
    text: `Self Employed (S Corp/Partnership)`,
    value: `Self Employed (S Corp/Partnership)`,
    id: "05",
    name: "employment",
  },
  {
    text: `Self Employed (Schedule C)`,
    value: `Self Employed (Schedule C)`,
    id: "06",
    name: "employment",
  },
  {
    text: `Unemployed Permanent`,
    value: `Unemployed Permanent`,
    id: "07",
    name: "employment",
  },
  {
    text: `Unemployed Temporary`,
    value: `Unemployed Temporary`,
    id: "08",
    name: "employment",
  },
  {
    text: `Wage Earner`,
    value: `Wage Earner`,
    id: "09",
    name: "employment",
  },
];

const hardshipReasonArray = [
  {
    text: `Abandonment of Property`,
    value: `Abandonment of Property`,
    id: "01",
    name: "hardshipReason",
  },
  {
    text: `Business Failure`,
    value: `Business Failure`,
    id: "02",
    name: "hardshipReason",
  },
  {
    text: `Casualty Loss`,
    value: `Casualty Loss`,
    id: "03",
    name: "hardshipReason",
  },
  {
    text: `COVID-19`,
    value: `Energy-environment`,
    id: "08",
    name: "hardshipReason",
  },
  {
    text: `Curtailment of Income`,
    value: `Curtailment of Income`,
    id: "04",
    name: "hardshipReason",
  },
  {
    text: `Death of Mortgagor's Family Member`,
    value: `Death of Mortgagor's Family Member`,
    id: "05",
    name: "hardshipReason",
  },
  {
    text: `Death of Principal Mortgagor`,
    value: `Death of Principal Mortgagor`,
    id: "06",
    name: "hardshipReason",
  },
  {
    text: `Eligible Disaster Area`,
    value: `Eligible Disaster Area`,
    id: "07",
    name: "hardshipReason",
  },

  {
    text: `Excessive obligations`,
    value: `Excessive obligations`,
    id: "09",
    name: "hardshipReason",
  },
  {
    text: `Fraud`,
    value: `Fraud`,
    id: "10",
    name: "hardshipReason",
  },
  {
    text: `Illness of Mortgagor's Family Member`,
    value: `Illness of Mortgagor's Family Member`,
    id: "11",
    name: "hardshipReason",
  },
  {
    text: `Illness of Principal Mortgagor`,
    value: `Illness of Principal Mortgagor`,
    id: "12",
    name: "hardshipReason",
  },
  {
    text: `Inability to Rent Property`,
    value: `Inability to Rent Property`,
    id: "13",
    name: "hardshipReason",
  },
  {
    text: `Inability to Sell Property`,
    value: `Inability to Sell Property`,
    id: "14",
    name: "hardshipReason",
  },
  {
    text: `Incarceration`,
    value: `Incarceration`,
    id: "15",
    name: "hardshipReason",
  },
  {
    text: `Marital Difficulties`,
    value: `Marital Difficulties`,
    id: "16",
    name: "hardshipReason",
  },
  {
    text: `Military Service`,
    value: `Military Service`,
    id: "17",
    name: "hardshipReason",
  },
  {
    text: `National Emergency Declaration`,
    value: `National Emergency Declaration`,
    id: "18",
    name: "hardshipReason",
  },
  {
    text: `Neighborhood Problem`,
    value: `Neighborhood Problem`,
    id: "19",
    name: "hardshipReason",
  },
  {
    text: `Payment Adjustment`,
    value: `Payment Adjustment`,
    id: "20",
    name: "hardshipReason",
  },
  {
    text: `Payment Dispute`,
    value: `Payment Dispute`,
    id: "21",
    name: "hardshipReason",
  },
  {
    text: `Property Problem`,
    value: `Property Problem`,
    id: "22",
    name: "hardshipReason",
  },
  {
    text: `Servicing Problems`,
    value: `Servicing Problems`,
    id: "23",
    name: "hardshipReason",
  },
  {
    text: `Transfer of Ownership Pending`,
    value: `Transfer of Ownership Pending`,
    id: "24",
    name: "hardshipReason",
  },
  {
    text: `Unemployment`,
    value: `Unemployment`,
    id: "25",
    name: "hardshipReason",
  },
  {
    text: `Other`,
    value: `Other`,
    id: "26",
    name: "hardshipReason",
  },
];

const femaHardshipTypeArray = [
  {
    text: `Hurricane`,
    value: `Hurricane`,
    id: "01",
    name: "hardshipFemaDisasterType",
  },
  {
    text: `Earthquake`,
    value: `Earthquake`,
    id: "02",
    name: "hardshipFemaDisasterType",
  },
  {
    text: `Fire`,
    value: `Fire`,
    id: "03",
    name: "hardshipFemaDisasterType",
  },
  {
    text: `Winter Storm`,
    value: `Winter Storm`,
    id: "04",
    name: "hardshipFemaDisasterType",
  },
  {
    text: `Flooding`,
    value: `Flooding`,
    id: "05",
    name: "hardshipFemaDisasterType",
  },
  {
    text: `Tornado`,
    value: `Tornado`,
    id: "06",
    name: "hardshipFemaDisasterType",
  },
];

const propertyIntentionsArray = [
  {
    text: `Occupy as Primary Residence`,
    value: `Occupy as Primary Residence`,
    id: "01",
    name: "propertyIntentions",
  },
  {
    text: `Retain as second home`,
    value: `Retain as second home`,
    id: "02",
    name: "propertyIntentions",
  },
  {
    text: `Retain as investment`,
    value: `Retain as investment`,
    id: "03",
    name: "propertyIntentions",
  },
  {
    text: `Sell or Do Not Retain`,
    value: `Sell or Do Not Retain`,
    id: "04",
    name: "propertyIntentions",
  },
  {
    text: `Undecided`,
    value: `Undecided`,
    id: "05",
    name: "propertyIntentions",
  },
  {
    text: `Vacate`,
    value: `Vacate`,
    id: "06",
    name: "propertyIntentions",
  },
];

export const dccQuestionsList = (question: string) => {
  switch (question) {
    case "employment":
      return {
        title: "What is your current employment status?",
        name: "employment",
        type: "radio",
        answers: employmentArray,
      };
    case "hardshipReason":
      return {
        title: "What is the reason for your hardship?",
        name: "hardshipReason",
        type: "btns",
        answers: hardshipReasonArray,
      };

    case "hardshipLength":
      return {
        title: "Is your hardship temporary, long term, or permanent?",
        name: "hardshipLength",
        type: "radio",
        answers: [
          {
            text: `Temporary`,
            value: `Temporary`,
            id: "01",
            name: "hardshipLength",
          },
          {
            text: `Long Term`,
            value: `Long Term`,
            id: "02",
            name: "hardshipLength",
          },
          {
            text: `Permanent`,
            value: `Permanent`,
            id: "03",
            name: "hardshipLength",
          },
          {
            text: `Unknown`,
            value: `Unknown`,
            id: "04",
            name: "hardshipLength",
          },
        ],
      };
    case "hardshipResolved":
      return {
        title: "Has your hardship been resolved?",
        name: "hardshipResolved",
        type: "radio",
        answers: [
          {
            text: `Yes`,
            value: `Yes`,
            id: "01",
            name: "hardshipResolved",
          },
          {
            text: `No`,
            value: `No`,
            id: "02",
            name: "hardshipResolved",
          },
        ],
      };
    case "hardshipBegin":
      return {
        title: "When did your hardship begin?",
        name: "hardshipBegin",
        type: "date",
        answers: [],
      };
    case "hardshipEnd":
      return {
        title: "When did your hardship end?",
        name: "hardshipEnd",
        type: "date",
        answers: [],
      };
    case "hardshipCovid":
      return {
        title: "Is your hardship related to Covid19?",
        name: "hardshipCovid",
        type: "radio",
        answers: [
          {
            text: `Yes`,
            value: `Yes`,
            id: "01",
            name: "hardshipCovid",
          },
          {
            text: `No`,
            value: `No`,
            id: "02",
            name: "hardshipCovid",
          },
        ],
      };
    case "hardshipFemaDisaster":
      return {
        title:
          "Is your hardship related to a natural disaster in a FEMA declared disaster area?",
        name: "hardshipFemaDisaster",
        type: "radio",
        answers: [
          {
            text: `Yes`,
            value: `Yes`,
            id: "01",
            name: "hardshipFemaDisaster",
          },
          {
            text: `No`,
            value: `No`,
            id: "02",
            name: "hardshipFemaDisaster",
          },
        ],
      };
    case "hardshipFemaDisasterType":
      return {
        title: "What type of natural disaster?",
        name: "hardshipFemaDisasterType",
        type: "radio",
        answers: femaHardshipTypeArray,
      };
    case "propertyIntentions":
      return {
        title: "What are your intentions with the property?",
        name: "propertyIntentions",
        type: "radio",
        answers: propertyIntentionsArray,
      };
    case "propertyOccupancy":
      return {
        title: "What is the current occupancy status of the property?",
        name: "propertyOccupancy",
        type: "radio",
        answers: [
          {
            text: `Original owner occupied`,
            value: `Original owner occupied`,
            id: "01",
            name: "propertyOccupancy",
          },
          {
            text: `Transfer owner occupied`,
            value: `Transfer owner occupied`,
            id: "02",
            name: "propertyOccupancy",
          },
          {
            text: `Non-owner occupied`,
            value: `Non-owner occupied`,
            id: "03",
            name: "propertyOccupancy",
          },
          {
            text: `Vacant`,
            value: `Vacant`,
            id: "04",
            name: "propertyOccupancy",
          },
          {
            text: `Tenant occupied`,
            value: `Tenant occupied`,
            id: "05",
            name: "propertyOccupancy",
          },
        ],
      };
    case "propertyAbandoned":
      return {
        title: "Is the property abandoned?",
        name: "propertyAbandoned",
        type: "radio",
        answers: [
          {
            text: `Yes`,
            value: `Yes`,
            id: "01",
            name: "propertyAbandoned",
          },
          {
            text: `No`,
            value: `No`,
            id: "02",
            name: "propertyAbandoned",
          },
        ],
      };
    case "propertyCondemned":
      return {
        title: "Is the property condemned?",
        name: "propertyCondemned",
        type: "radio",
        answers: [
          {
            text: `Yes`,
            value: `Yes`,
            id: "01",
            name: "propertyCondemned",
          },
          {
            text: `No`,
            value: `No`,
            id: "02",
            name: "propertyCondemned",
          },
        ],
      };
    case "affordability":
      return {
        title: "How much are you able to pay each month?",
        name: "affordability",
        type: "radio",
        answers: [
          {
            text: `Unable to Pay`,
            value: `Unable to Pay`,
            id: "01",
            name: "affordability",
          },
          {
            text: `PITI Only`,
            value: `PITI Only`,
            id: "02",
            name: "affordability",
          },
          {
            text: `PITI+Shortage`,
            value: `PITI+Shortage`,
            id: "03",
            name: "affordability",
          },
          {
            text: `Less than PITI`,
            value: `Less than PITI`,
            id: "04",
            name: "affordability",
          },
        ],
      };
  }
};
