/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { freedomCodeSelected } from "../../app/actions/worksheetActions";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import FreedomStateCodes from "../../data/FreedomStateCodes.json";
import "./FreedomStateCodeSelector.css";
const FreedomStateCodeSelector = ({ drop = "up" }: any) => {
  const dispatch = useAppDispatch();

  const addressInfo: any = useAppSelector((state) => state.displayInfo.address);

  const [stateSelected, setStateSelected]: any = useState(() => {
    if (addressInfo.propertyState !== undefined) {
      console.log('findFreedom code')
      return FreedomStateCodes.find(
        (item) => item.abr === addressInfo.propertyState
      );
    } else {
      return { code: "001", state: "Alabama", abr: "AL" };
    }
  });
  const [stateFilter, setStateFilter]: any = useState("null");
  const [stateMenu, setStateMenu]: any = useState(FreedomStateCodes);
  useEffect(() => {
    //console.log("State Code Selection", stateSelected);
    dispatch(freedomCodeSelected(stateSelected));
  }, [stateSelected]);
  useEffect(() => {
    //console.log("State Code Selection", stateFilter);
    if (
      stateFilter === undefined ||
      stateFilter === "" ||
      stateFilter === "null"
    ) {
      setStateMenu(FreedomStateCodes);
    } else {
      const filter = stateFilter.toLowerCase();
      const newArray = FreedomStateCodes.filter(
        (item) =>
          item.state.toLowerCase().includes(filter) ||
          item.abr.toLowerCase().includes(filter)
      );
      setStateMenu(newArray);
    }
  }, [stateFilter]);

  return (
    <div id="freedom_state_code_selector">
      <DropdownButton
        variant="primary"
        title={`${stateSelected.abr} - ${stateSelected.state} - ${stateSelected.code}`}
        align="end"
        drop={drop}
      >
        <Form.Control
          type="search"
          size="sm"
          placeholder="Search..."
          onKeyUp={(e) => {
            if (
              e.currentTarget.value === undefined ||
              e.currentTarget.value === ""
            ) {
              setStateFilter(null);
            } else {
              setStateFilter(e.currentTarget.value);
            }
          }}
          onChange={(e) => {
            if (
              e.currentTarget.value === undefined ||
              e.currentTarget.value === ""
            ) {
              setStateFilter(null);
            } else {
              setStateFilter(e.currentTarget.value);
            }
          }}
        ></Form.Control>
        {stateMenu.map((item: any, i: number) => {
          if (item.state)
            return (
              <Dropdown.Item
                key={i}
                id={item.abr}
                eventKey={item.code}
                onClick={(e) => {
                  //console.log(e.currentTarget.id);
                  setStateSelected(
                    FreedomStateCodes.find(
                      (item) => item.abr === e.currentTarget.id
                    )
                  );
                }}
              >
                {item.abr} - {item.state} - {item.code}
              </Dropdown.Item>
            );
        })}
      </DropdownButton>
    </div>
  );
};

export default FreedomStateCodeSelector;
