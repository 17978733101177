/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { combineReducers } from "redux";

import {
  UPDATE_SCREEN_WIDTH,
  UPDATE_COLUMN_2_TABS,
  UPDATE_WORKSHEET_TABS,
  RETURN_PREVIOUS_WORKSHEET_TAB,
 } from "../actions/standardActions";

import {
  INITIATE_CONFERENCE_SUCCESS,
  INITIATE_TRANSFER_SUCCESS,
} from "../actions/apisActions";

import {

  SELECTED_INCENTIVE_MORTAGE_SCRIPT,
  SELECTED_INCENTIVE_OTHER_SCRIPT,
} from "../actions/worksheetActions";

const layout = (state: any = {}, action: any) => {
  const { type, payload } = action;
  let columns = "3";
  switch (type) {
    case UPDATE_SCREEN_WIDTH:
      if (payload <= 700) {
        columns = "1";
      } else if (payload <= 1200) {
        columns = "2";
      }
      return {
        ...state,
        width: payload,
        columns: columns,
      };

    default:
      return state;
  }
};

const worksheet = (
  state: any = { currentWorksheet: "null", previousWorksheet: "null" },
  action: any
) => {
  const { type, payload } = action;
  const previous = state.current;
  switch (type) {
    case INITIATE_CONFERENCE_SUCCESS:
      return {
        ...state,
        currentWorksheet: "conference_worksheet",
        previousWorksheet: previous,
      };
    case INITIATE_TRANSFER_SUCCESS:
      return { ...state, current: "transfer_worksheet", previous: previous };
    case UPDATE_WORKSHEET_TABS:
      return { ...state, current: payload };
    case RETURN_PREVIOUS_WORKSHEET_TAB:
      return { ...state, current: previous };
    default:
      return state;
  }
};

const column2Tab = (state = "", action: any) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_COLUMN_2_TABS:
      return payload;
    default:
      return state;
  }
};


export const incentiveScriptMort = (state = null, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SELECTED_INCENTIVE_MORTAGE_SCRIPT:
      return payload;
    default:
      return state;
  }
};

export const incentiveScriptOth = (state = null, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SELECTED_INCENTIVE_OTHER_SCRIPT:
      return payload;
    default:
      return state;
  }
};



export const display = combineReducers({
  layout,
  worksheet,
  column2Tab,
  incentiveScriptMort,
  incentiveScriptOth,

});
