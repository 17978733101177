/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  updateCommentsInProgress,
  updateCommentsSuccess,
  updateCommentsFailed,
  putUpdateWorksheetInProgress,
  putUpdateWorksheetSuccess,
  putUpdateWorksheetFailed,
} from "../actions/apisActions";

export const updateCommentsApi =
  (metadata: any, ids: any, update: any) =>
  async (dispatch: any) => {
    try {
      dispatch(updateCommentsInProgress());
      const comments = update;

      //transfer_to_external_number
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}/appsvcs/rs/svc/agents/${metadata.userId}/interactions/calls/${ids.systemCallId}/comments`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(comments),
        }
      );
      //const data = await response.json();
      const code = await response.ok;

      if (code === true) {
        dispatch(updateCommentsSuccess());
      } else {
        dispatch(updateCommentsFailed());
      }
    } catch (e) {
      // eslint-disable-next-line no-undef
      console.error(e);
      dispatch(updateCommentsFailed());
    }
  };

export const pingApi =
  (metadata: any) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (dispatch: any) => {
    try {
      const baseUrl = `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}`;
      const context = "/appsvcs/rs/svc";
      const userId = metadata.userId;
      //transfer_to_external_number
      // eslint-disable-next-line no-undef
      const response = await fetch(
        `${baseUrl}${context}/agents/${userId}/ping`,
        {
          method: "Put",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
   

      const code = await response.ok;

      if (code === true) {
        // eslint-disable-next-line no-undef
        console.log("success ping");
      } else {
        // eslint-disable-next-line no-undef
        console.log("unsuccessful ping");
      }
    } catch (e) {

      console.error(e);
    }
  };


  
export const updateFive9WorksheetApi =
(metadata: any, ids: any, worksheet: any, complete: boolean) => async (dispatch: any) => {
  //console.log(metadata)
  try {
    dispatch(putUpdateWorksheetInProgress());
    const baseUrl = `https://${metadata.metadata.dataCenters[0].apiUrls[0].host}`;
    const context = "/appsvcs/rs/svc";
    const body: any = {};
    body.answers = {};

    body.finished = complete;
    body.id = worksheet.id;
    body.answers[1] = "0";

    //transfer_to_external_number
    // eslint-disable-next-line no-undef
    const response = await fetch(
      `${baseUrl}${context}/agents/${metadata.userId}/interactions/calls/${ids.systemCallId}/worksheet`,
      {
        method: "Put",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    // const data = await response.json();
    const code = await response.ok;

    if (code === true) {
      dispatch(putUpdateWorksheetSuccess());
    } else {
      dispatch(putUpdateWorksheetFailed());
    }
  } catch (e) {
    //console.log(e);
    dispatch(putUpdateWorksheetFailed());
  }
};
