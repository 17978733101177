/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */

import React from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import HeaderQuickdraw from "./HeaderQuickdraw";
import logo from "../../img/frontlineLogoWhiteNew.png"
import { Button } from "react-bootstrap";
import { openVerificationWindow } from "../../app/slices/features/contactVerificationSlice";
import "./Header.css";

const Header = (props: any) => {
  const dispatch = useAppDispatch();
  const appPath: any = useAppSelector((state) => state.main.appPath);
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const privateLabel: any = useAppSelector((state) => state.main.privateLabel.label);
  return (
    <header className="container-fluid">
      <div className="row">
        <div className="col">
          <h5 className="m-1"><img src={logo} alt="Logo" height="25px"/></h5>
          {/* <Button
            onClick={() => {
              dispatch(openVerificationWindow());
            }}
          >
            verification
          </Button> */}
        </div>
        <div className="col">
          <h5 className="m-1 text-center">{privateLabel}</h5>
        </div>
        <HeaderQuickdraw appPath={appPath} callIds={callIds} />
      </div>
    </header>
  );
};

export default Header;
