
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { Col, Container, Card } from "react-bootstrap";
import Column2Tabs from "./Column2Tabs";
import "./Column2.css";


const Column2 = ({ columns }: any) => {
  return (
    <Col xs id="column_2"
    className={columns === "1" ? "cc-single-column" : ''}>
      <Container fluid className="p-0">
        <Card className="rounded-0 border-0">
          <Column2Tabs />
        </Card>
      </Container>
    </Col>
  );
};

export default Column2;
