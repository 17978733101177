/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */

import React from "react";
import { Button } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { putTransferFlagVariable } from "../../../app/thunks/transferThunks";
import { updateWorksheetTabs, clearConferenceObject } from "../../../app/actions/standardActions";

const ConferenceCompleteButton = ({
  finalButtonDisabled,
  finalOutcome,
  setFinalOutcome,
  setJoinedCalls,
  setConferenceCanceled,
  setFinalButtonDisabled
}: any) => {
  const dispatch = useAppDispatch();
  // global states
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const callIds: any = useAppSelector((state) => state.main.callIds);
  const worksheetTabs: any = useAppSelector((state) => state.display.worksheet);

  const handleCompleteClick = () => {
    dispatch(putTransferFlagVariable(metadata.data, callIds, finalOutcome));
    dispatch(updateWorksheetTabs(worksheetTabs.previous));
    dispatch(clearConferenceObject());
    setConferenceCanceled(false);
    setFinalOutcome('')
    setFinalButtonDisabled(true)
    setJoinedCalls(false)
  };
  return (
    <div className="d-grid gap-2">
      <Button
        disabled={finalButtonDisabled}
        variant={finalButtonDisabled === false ? "primary" : "secondary"}
        size="lg"
        onClick={handleCompleteClick}
      >
        Complete Worksheet
      </Button>
    </div>
  );
};

export default ConferenceCompleteButton;
