import moment from "moment-timezone";
import {
  safeHavenClosed,
  safeHavenOpen,
} from "../../../app/slices/main/hoursOfOperation";

import { consoleLogIt } from "../../../app/log";

export const checkTimeForClosings = (hoursOfOperation: any, dispatch: any) => {
  moment.tz.setDefault("America/New_York");

  if (moment().day() > 0 && moment().day() < 6) {
    if (moment().hour() > 8 && moment().hour() < 19) {
      if(hoursOfOperation.safeHaven !== true){
        dispatch(safeHavenOpen());
      }
    } else {
      if(hoursOfOperation.safeHaven !== false){
        dispatch(safeHavenClosed());
      }
    }
  } else {
    if(hoursOfOperation.safeHaven !== false){
      dispatch(safeHavenClosed());
    }
  }
};
