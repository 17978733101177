/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { closeTransferAsssitWindow } from "../../../app/actions/standardActions";
import {
  initiateTransferApi,
  initiateConferenceApi,
} from "../../../app/thunks/transferThunks";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import TransferAssistDDWrapper from "./TransferAssistDDWrapper";
import FreedomStateCodeSelector from "../FreedomStateCodeSelector";
import TransferOptions from "./TransferOptions.json";
import "./TransferAssistModal.css";

// TODO Transfer Flow Needed
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const TransferAssistModal = () => {
  //From Global
  const dispatch = useAppDispatch();
  const metadata: any = useAppSelector((state) => state.main.metadata);
  const windowStatus: any = useAppSelector(
    (state) => state.features.transferAssistModal
  );
  const callIds: any = useAppSelector((state) => state.main.callIds);

  // local
  const [selectedTransfer, setSelectedTransfer] = useState(null);
  const [warmTransfer, setWarmTransfer] = useState(true);

  const freedomStateCodeCheck = () => {
    if (selectedTransfer !== null) {
      if (
        selectedTransfer === "tran_ext_refi" ||
        selectedTransfer === "tran_ext_pur"
      ) {
        return <FreedomStateCodeSelector drop="down" />;
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  const handleInitiateClick = () => {
    const selected: any = TransferOptions.find(
      (item) => item.id === selectedTransfer
    );
    if (selected.type === "transfer") {
      dispatch(initiateTransferApi(metadata.data, callIds, selected, warmTransfer));
    }

    if (selected.type === "conference") {
      dispatch(initiateConferenceApi(metadata.data, callIds, selected));
    }

    dispatch(closeTransferAsssitWindow());
  };
  return (
    <Modal
      size="lg"
      backdrop={true}
      id="transfer_assist_modal"
      show={windowStatus}
      onHide={() => dispatch(closeTransferAsssitWindow())}
    >
      <Modal.Header closeVariant="white" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Transfer/Conference Assistant
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <TransferAssistDDWrapper
          setSelectedTransfer={setSelectedTransfer}
          selectedTransfer={selectedTransfer}
        />
        <InputGroup>
          <Form.Check
            inline
            label="Warm Transfer"
            name="warm_transfer"
            type="checkbox"
            defaultChecked={true}
            className="pt-1"
            disabled={selectedTransfer === null ? true : false}
            onClick={(e) => {
              if (e.currentTarget.checked) {
                setWarmTransfer(true);
              } else {
                setWarmTransfer(false);
              }
            }}
          />
          {freedomStateCodeCheck()}
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-grid gap-2">
          <Button
            disabled={selectedTransfer === null ? true : false}
            onClick={handleInitiateClick}
          >
            Initiate
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TransferAssistModal;
