/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  testingModal: false,
};

export const testing = createSlice({
  name: "testing",
  initialState,
  reducers: {
    openTestingModal: (state) => {

      state.testingModal = true;
    },
    closeTestingModal: (state ) => {
    
      state.testingModal = false;
    },
  },
});
// thunks

// Action creators are generated for each case reducer function
export const {
  openTestingModal,
  closeTestingModal,
  
} = testing.actions;

export default testing.reducer;
