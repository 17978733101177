/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { openWindow } from "../../../../app/slices/features/contactVerificationSlice";
import { InputGroup, Form } from "react-bootstrap";


const VerifyContactSwitch = () => {
  const dispatch = useAppDispatch();
  const verification: any = useAppSelector((state) => state.contactVerification);

  return (
    <InputGroup>
      <Form.Check
        type="switch"
        id={`verify_contact_switch`}
        label="Verify Contact Info"
        checked={verification.complete}
        onChange={() => {
          dispatch(openWindow())
        }}
      />
    </InputGroup>
  );
};

export default VerifyContactSwitch;
