/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { updateSHField } from "./safeHavenFormSlice";
import SHFormDatePicker from "./SHFormDatePicker";
import SHFormTimePicker from "./SHFormTimePicker";
import { Form, Overlay } from "react-bootstrap";

const SHFormWrapper = ({
  emailValidation,
  setEmailValidation,
  validateEmail,
}: any) => {
  const dispatch = useAppDispatch();
  const refTarget = useRef(null);
  const safeHavenForm = useAppSelector((state) => state.safeHavenForm);
  const [firstName, setFirstName] = useState(safeHavenForm.firstName);
  const [lastName, setLastName] = useState(safeHavenForm.lastName);
  const [emailAddress, setEmailAddress] = useState(() => {
    if (safeHavenForm.email === "noemailonfile@roundpointmortgage.com") {
      return "";
    } else {
      return safeHavenForm.email;
    }
  });
  const [callbackNumber, setCallbackNumber] = useState(
    safeHavenForm.phoneNumber
  );
  const [notes, setNotes] = useState(safeHavenForm.notes);
  return (
    <Form>
      <Form.Group className="mb-3" controlId="sh_first_name">
        <Form.Label>
          First Name:{" "}
          {firstName === "" ? (
            <small className="sh-required-error">(Required)</small>
          ) : (
            <small></small>
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.currentTarget.value);
          }}
          onBlur={() => {
            dispatch(updateSHField({ name: "first", value: firstName }));
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="sh_last_name">
        <Form.Label>
          Last Name:{" "}
          {lastName === "" ? (
            <small className="sh-required-error">(Required)</small>
          ) : (
            <small></small>
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => {
            setLastName(e.currentTarget.value);
          }}
          onBlur={() => {
            dispatch(updateSHField({ name: "last", value: lastName }));
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="sh_email_address">
        <Form.Label>
          Email Address:{" "}
          {/* {emailAddress === "" ? (
            <small className="sh-required-error">(Required)</small>
          ) : (
            <small></small>
          )} */}
        </Form.Label>
        <Form.Control
          ref={refTarget}
          type="email"
          placeholder="Email Address"
          className={emailValidation === true ? "" : "validation-failed"}
          value={emailAddress}
          onChange={(e) => {
            setEmailAddress(e.currentTarget.value);
          }}
          onBlur={() => {
            if(emailAddress === ""){
              dispatch(updateSHField({ name: "email", value: emailAddress }));
              setEmailValidation(true);
            } else if (validateEmail(emailAddress) === true) {
              dispatch(updateSHField({ name: "email", value: emailAddress }));
              setEmailValidation(true);
            } else {
              dispatch(updateSHField({ name: "email", value: emailAddress }));
              setEmailValidation(false);
            }
          }}
        />
        <Overlay
          target={refTarget.current}
          show={emailValidation === false ? true : false}
          placement="top"
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              style={{
                backgroundColor: "rgba(255, 100, 100, 0.85)",
                padding: "2px 10px",
                color: "white",
                borderRadius: 3,

                marginLeft: "-1rem",
                fontSize: ".9rem",
                zIndex: "1000000",
                ...props.style,
              }}
            >
              Email address is invalid.
            </div>
          )}
        </Overlay>
      </Form.Group>
      <Form.Group className="mb-3" controlId="sh_callback_number">
        <Form.Label>
          Callback Number:{" "}
          {callbackNumber === "" ? (
            <small className="sh-required-error">(Required)</small>
          ) : (
            <small></small>
          )}
        </Form.Label>
        <Form.Control
          type="tel"
          placeholder="Callback Number"
          value={callbackNumber}
          onChange={(e) => {
            setCallbackNumber(e.currentTarget.value);
          }}
          onBlur={() => {
            dispatch(updateSHField({ name: "phone", value: callbackNumber }));
          }}
        />
      </Form.Group>
      {safeHavenForm.form === "callback" ? (
        <>
          <Form.Group className="mb-3" controlId="sh_callback_date">
            <Form.Label>
              Callback Date:{" "}
              {safeHavenForm.date === "" ? (
                <small className="sh-required-error">(Required)</small>
              ) : (
                <small></small>
              )}
            </Form.Label>
            <SHFormDatePicker />
          </Form.Group>
          <Form.Group className="mb-3" controlId="sh_callback_time">
            <Form.Label>
              Callback Time:{" "}
              {safeHavenForm.time === "" ? (
                <small className="sh-required-error">(Required)</small>
              ) : (
                <small></small>
              )}
            </Form.Label>
            <SHFormTimePicker />
          </Form.Group>
        </>
      ) : (
        <></>
      )}

      <Form.Group className="mb-3" controlId="sh_notes">
        <Form.Label>Notes:</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Notes"
          value={notes}
          onChange={(e) => {
            setNotes(e.currentTarget.value.replace(/[^\w\s]/gi, ""));
          }}
          onBlur={() => {
            dispatch(updateSHField({ name: "notes", value: notes }));
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default SHFormWrapper;
