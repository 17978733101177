/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";

import { useAppSelector, useAppDispatch } from "../app/hooks";

import FooterTicker from "./FooterTicker";
import "./Footer.css";

const FooterWrapper = () => {
  const announcements: any = useAppSelector(
    (state) => state.displayInfo.tickerAnnouncements
  );
  if (announcements.count !== 0) {
    return (
      <footer>
        <div className="ticker-wrap">
          <FooterTicker announcements={announcements}/>
        </div>
      </footer>
    );
  } else {
    return <></>
  }
};

export default FooterWrapper;
