import { createSlice } from "@reduxjs/toolkit";
import { GET_CALLDATA_SUCCESS } from "../../actions/apisActions";
import { OtherLoanObject } from "../../types";
import moment from "moment-timezone";
const initialState = {
  investorName: "",
  escrowedIndicator: "",
  nextPaymentDueDate: "",
  totalMonthlyPayment: "",
  upb: "",
  servicingAcqDate: "",
  loanMaturesDate: "",
  lastFullPaymentDate: "",
  recastMilestone: "",
  lmMileStone: "",
  dealType: "",
  display: [],
} as OtherLoanObject;

export const other = createSlice({
  name: "other",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GET_CALLDATA_SUCCESS, (state, action) => {
        const { payload }: any = action;
        state.display = [];

        state.investorName = payload.data.activeContact.fields["1210"];
        state.escrowedIndicator = payload.data.activeContact.fields["1058"];
        state.nextPaymentDueDate = payload.data.activeContact.fields["1230"];
        state.totalMonthlyPayment = payload.data.activeContact.fields["1201"];
        state.upb = payload.data.activeContact.fields["1195"];
        state.servicingAcqDate = payload.data.activeContact.fields["1231"];
        state.loanMaturesDate = payload.data.activeContact.fields["1228"];
        state.lastFullPaymentDate = payload.data.activeContact.fields["1225"];
        state.recastMilestone = payload.data.activeContact.fields["1370"];
        state.lmMileStone = payload.data.activeContact.fields["1292"];
        state.dealType = payload.data.activeContact.fields["1351"];


        state.display.push({
          key: "investorName",
          label: "Investor Name:",
          value: payload.data.activeContact.fields["1150"],
          cols: 12,
        });
        state.display.push({
          key: "escrowedIndicator",
          label: "Escrowed:",
          value: payload.data.activeContact.fields["1058"],
          cols: 6,
        });
        state.display.push({
          key: "nextPaymentDueDate",
          label: "Next Due Date:",
          value: payload.data.activeContact.fields["1230"],
          cols: 6,
        });
        state.display.push({
          key: "totalMonthlyPayment",
          label: "Total Monthly Payment:",
          value: payload.data.activeContact.fields["1201"],
          cols: 6,
        });
        state.display.push({
          key: "upb",
          label: "Unpaid Principle Bal:",
          value: payload.data.activeContact.fields["1195"],
          cols: 6,
        });
        state.display.push({
          key: "servicingAcqDate",
          label: "Acq. Date/Anniversary:",
          value: payload.data.activeContact.fields["1231"],
          cols: 6,
        });
        state.display.push({
          key: "loanMaturesDate",
          label: "Loan Maturity Date:",
          value: payload.data.activeContact.fields["1228"],
          cols: 6,
        });
        state.display.push({
          key: "lastFullPaymentDate",
          label: "Last Payment Date:",
          value: payload.data.activeContact.fields["1225"],
          cols: 6,
        });
        state.display.push({
          key: "recastMilestone",
          label: "Recast Milestone:",
          value: payload.data.activeContact.fields["1370"],
          cols: 6,
        });
        state.display.push({
          key: "lmMileStone",
          label: "LM Milestone:",
          value: payload.data.activeContact.fields["1292"],
          cols: 6,
        });
        state.display.push({
          key: "dealType",
          label: "Deal Type:",
          value: payload.data.activeContact.fields["1351"],
          cols: 6,
        });
      })
      .addCase("azure/contactFields/succeeded", (state, action) => {
        const { payload }: any = action;
        const fields = payload.data.items[0];
        state.display = [];

        state.investorName = fields.investorName;
        state.escrowedIndicator = fields.escrowedIndicator;
        state.nextPaymentDueDate = fields.nextPaymentDueDate;
        state.totalMonthlyPayment = fields.totalMonthlyPayment;
        state.upb = fields.upb;
        state.servicingAcqDate = fields.servicingAcqDate;
        state.loanMaturesDate = fields.loanMaturesDate;
        state.lastFullPaymentDate = fields.lastFullPaymentDate;
        state.recastMilestone = fields.recastMilestone;
        state.lmMileStone = fields.lmmileStone;
        state.dealType = fields.dealType;


        state.display.push({
          key: "investorName",
          label: "Investor Name:",
          value: state.investorName,
          cols: 12,
        });
        state.display.push({
          key: "escrowedIndicator",
          label: "Escrowed:",
          value: state.escrowedIndicator,
          cols: 6,
        });
        state.display.push({
          key: "nextPaymentDueDate",
          label: "Next Due Date:",
          value: state.nextPaymentDueDate,
          cols: 6,
        });
        state.display.push({
          key: "totalMonthlyPayment",
          label: "Total Monthly Payment:",
          value: state.totalMonthlyPayment,
          cols: 6,
        });
        state.display.push({
          key: "upb",
          label: "Unpaid Principle Bal:",
          value: state.upb,
          cols: 6,
        });
        state.display.push({
          key: "servicingAcqDate",
          label: "Acq. Date/Anniversary:",
          value: fields.servicingAcqDate,
          cols: 6,
        });
        state.display.push({
          key: "loanMaturesDate",
          label: "Loan Maturity Date:",
          value: state.loanMaturesDate,
          cols: 6,
        });
        state.display.push({
          key: "lastFullPaymentDate",
          label: "Last Payment Date:",
          value: state.lastFullPaymentDate,
          cols: 6,
        });
        state.display.push({
          key: "recastMilestone",
          label: "Recast Milestone:",
          value: state.recastMilestone,
          cols: 6,
        });
        state.display.push({
          key: "lmMileStone",
          label: "LM Milestone:",
          value: state.lmMileStone,
          cols: 6,
        });
        state.display.push({
          key: "dealType",
          label: "Deal Type:",
          value: state.dealType,
          cols: 6,
        });
      });
  },
});
// thunks

// Action creators are generated for each case reducer function
// export const {
//   openTestingModal,
//   closeTestingModal,

// } = azureContactFields.actions;

export default other.reducer;
